import React from 'react';

import DiffEditor from './DiffEditor';
import RegularEditor from './RegularEditor';
import { IJsonEditor } from './type';
import './styles.scss';

export default function JsonEditor(props: IJsonEditor) {
  const {
    diffMode,
    diffValue,
    editValue,
    onChange,
    onValidate,
    schemaValidation,
    autoFoldBrackets,
    renderSideBySide,
  } = props;
  return (
    <div className="krg-json-editor-shell">
      {
        diffMode ? (
          <DiffEditor
            diffValue={diffValue}
            editValue={editValue}
            onChange={onChange}
            onValidate={onValidate}
            schemaValidation={schemaValidation}
            renderSideBySide={renderSideBySide}
          />
        ) : (
          <RegularEditor
            editValue={editValue}
            onChange={onChange}
            onValidate={onValidate}
            schemaValidation={schemaValidation}
            autoFoldBrackets={autoFoldBrackets}
          />
        )
      }
    </div>
  );
}
