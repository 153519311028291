import { PATHS } from "./paths";

export const DEFAULT_THUMBNAIL_URL = "https://placehold.co/70x40/jpg"; // TODO: Need to replace with the actual default image url
export const DEFAULT_PAGINATION_LIMIT = 12;
export const NavItems = {
  PUBLISHER_HUB: "Publisher Hub",
  MEDIA_GALLERY: "Media Gallery",
  DASHBOARDS: "Dashboards",
  REPORTS: "Reports",
};

export const sidebarNavItems = [
  { path: PATHS.DASHBOARDS, text: NavItems.DASHBOARDS },
  { path: PATHS.PUBLISHER_HUB, text: NavItems.PUBLISHER_HUB },
  { path: PATHS.MEDIA_GALLERY, text: NavItems.MEDIA_GALLERY },
  { path: PATHS.REPORTING, text: NavItems.REPORTS },
];
