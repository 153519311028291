import {
  ADVANCED_CREATIVE_SIZES_ENUM,
  CREATIVE_DESTINATION_ENUM,
  CREATIVE_EDITOR_ENUM,
  CREATIVE_FORMAT_ENUM,
  CREATIVE_SOCIAL_TYPE_ENUM,
} from './enums';
import { CreativeFormats } from './interfaces';

// Deprecating CREATIVE_FORMATS v2.0.33,
export const CREATIVE_CONFIG_BY_FORMAT: CreativeFormats = Object.freeze({

  [CREATIVE_FORMAT_ENUM.StandardBanner]: {
    type: CREATIVE_FORMAT_ENUM.StandardBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'standard-banner',
    title: 'Standard Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-display-1',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.StandardVideo]: {
    type: CREATIVE_FORMAT_ENUM.StandardVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'standard-video',
    title: 'Standard Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-video-1',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.LogoEnhancedBanner]: {
    type: CREATIVE_FORMAT_ENUM.LogoEnhancedBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'logo-enhanced-banner',
    title: 'Logo Enhanced Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-display-3',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.ScrollReactiveBanner]: {
    type: CREATIVE_FORMAT_ENUM.ScrollReactiveBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'scroll-reactive-banner',
    title: 'Scroll Reactive Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-display-2',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.ScrollReactiveVideo]: {
    type: CREATIVE_FORMAT_ENUM.ScrollReactiveVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'scroll-reactive-video',
    title: 'Scroll Reactive Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-video-2',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.AdhesiveBottomBanner]: {
    type: CREATIVE_FORMAT_ENUM.AdhesiveBottomBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'adhesive-bottom-banner',
    title: 'Adhesive Bottom Banner',
    resolution: '',
    cpc: true,
    viewability: true,
    engagement: false,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-adhesion-display-1',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.AdhesiveSideBanner]: {
    type: CREATIVE_FORMAT_ENUM.AdhesiveSideBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'adhesive-side-banner',
    title: 'Adhesive Side Banner',
    resolution: '',
    cpc: true,
    viewability: true,
    engagement: false,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'aba-adhesion-display-2',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.IGStandardBanner]: {
    type: CREATIVE_FORMAT_ENUM.IGStandardBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'instagram-standard-banner',
    title: 'Instagram Standard Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-banner-2',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.IGStandardVideo]: {
    type: CREATIVE_FORMAT_ENUM.IGStandardVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'instagram-standard-video',
    title: 'Instagram Standard Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-video-2',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.IGScrollReactiveBanner]: {
    type: CREATIVE_FORMAT_ENUM.IGScrollReactiveBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'instagram-scroll-reactive-banner',
    title: 'Instagram Scroll Reactive Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-banner-1',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.IGCarousel]: {
    type: CREATIVE_FORMAT_ENUM.IGCarousel,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'instagram-carousel',
    title: 'Instagram Carousel',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-carousel-1',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.FBCarousel]: {
    type: CREATIVE_FORMAT_ENUM.FBCarousel,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'facebook-carousel',
    title: 'Facebook Carousel',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-carousel-2',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.IGScrollReactiveVideo]: {
    type: CREATIVE_FORMAT_ENUM.IGScrollReactiveVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'instagram-scroll-reactive-video',
    title: 'Instagram Scroll Reactive Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-video-1',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.FBStandardBanner]: {
    type: CREATIVE_FORMAT_ENUM.FBStandardBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'facebook-standard-banner',
    title: 'Facebook Standard Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-banner-3',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.FBStandardVideo]: {
    type: CREATIVE_FORMAT_ENUM.FBStandardVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'facebook-standard-video',
    title: 'Facebook Standard Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-video-3',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.FBScrollReactiveBanner]: {
    type: CREATIVE_FORMAT_ENUM.FBScrollReactiveBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'facebook-scroll-reactive-banner',
    title: 'Facebook Scroll Reactive Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-banner-4',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.FBScrollReactiveVideo]: {
    type: CREATIVE_FORMAT_ENUM.FBScrollReactiveVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'facebook-scroll-reactive-video',
    title: 'Facebook Scroll Reactive Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
    ],
    templateId: 'social-video-4',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.TiktokVideo]: {
    type: CREATIVE_FORMAT_ENUM.TiktokVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'tiktok-video',
    title: 'Tiktok Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
      CREATIVE_DESTINATION_ENUM.WatchOnSocial,
    ],
    templateId: 'social-video-5',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.InstagramReelsVideo]: {
    type: CREATIVE_FORMAT_ENUM.InstagramReelsVideo,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Social,
    id: 'reels-video',
    title: 'Reels Video',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: true,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.MainAsset,
      CREATIVE_DESTINATION_ENUM.Description,
      CREATIVE_DESTINATION_ENUM.Cta,
      CREATIVE_DESTINATION_ENUM.SocialLogo,
      CREATIVE_DESTINATION_ENUM.WatchOnSocial,
    ],
    templateId: 'social-video-5',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.MiddleBanner]: {
    type: CREATIVE_FORMAT_ENUM.MiddleBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'middle-banner',
    title: 'Middle Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: false,
    destinationUrls: [],
    templateId: 'middle-banner-animated',
    creativeEditor: CREATIVE_EDITOR_ENUM.advanced,
    creativeDimensions: [
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._320x250,
        width: 320,
        height: 250,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._390x250,
        width: 390,
        height: 250,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._428x250,
        width: 428,
        height: 250,
      },
    ],
  },
  [CREATIVE_FORMAT_ENUM.DynamicScrollReactiveBanner]: {
    type: CREATIVE_FORMAT_ENUM.DynamicScrollReactiveBanner,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Dynamic,
    id: 'dynamic-scroll-reactive-banner',
    title: 'Scroll Reactive Banner',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: false,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'dynamic-scroll-reactive-banner',
    creativeEditor: CREATIVE_EDITOR_ENUM.lite,
  },
  [CREATIVE_FORMAT_ENUM.Breakaway]: {
    type: CREATIVE_FORMAT_ENUM.Breakaway,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'breakaway',
    title: 'Breakaway',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: false,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'breakaway',
    creativeEditor: CREATIVE_EDITOR_ENUM.advanced,
    creativeDimensions: [
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._320x150,
        width: 320,
        height: 150,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._390x150,
        width: 390,
        height: 150,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._428x150,
        width: 428,
        height: 150,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._130x150,
        width: 130,
        height: 150,
        isCollapsed: true,
      },
    ],
  },
  [CREATIVE_FORMAT_ENUM.Runway]: {
    type: CREATIVE_FORMAT_ENUM.Runway,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'runway',
    title: 'Runway',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: false,
    destinationUrls: [
      CREATIVE_DESTINATION_ENUM.EntireCreative,
    ],
    templateId: 'runway',
    creativeEditor: CREATIVE_EDITOR_ENUM.advanced,
    creativeDimensions: [
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._320x150,
        width: 320,
        height: 150,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._390x150,
        width: 390,
        height: 150,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._428x150,
        width: 428,
        height: 150,
      },
    ],
  },
  [CREATIVE_FORMAT_ENUM.Venti]: {
    type: CREATIVE_FORMAT_ENUM.Venti,
    socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
    id: 'venti',
    title: 'Venti',
    resolution: '',
    cpc: false,
    viewability: false,
    engagement: false,
    destinationUrls: [],
    templateId: 'venti-animated',
    creativeEditor: CREATIVE_EDITOR_ENUM.advanced,
    creativeDimensions: [
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._320x460,
        width: 320,
        height: 460,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._390x460,
        width: 390,
        height: 460,
      },
      {
        label: ADVANCED_CREATIVE_SIZES_ENUM._428x460,
        width: 428,
        height: 460,
      },
    ],
  },

  /* Uncomment for A/B testing */
  // [CREATIVE_FORMAT_ENUM.StandardBannerIframe]: {
  //   type: CREATIVE_FORMAT_ENUM.StandardBannerIframe,
  //   socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
  //   id: 'standard-banner-iframe',
  //   title: 'Standard Banner Iframe',
  //   resolution: '',
  //   cpc: false,
  //   viewability: false,
  //   engagement: true,
  //   destinationUrls: [],
  //   templateId: '',
  // },
  // [CREATIVE_FORMAT_ENUM.StandardVideoIframe]: {
  //   type: CREATIVE_FORMAT_ENUM.StandardVideoIframe,
  //   socialType: CREATIVE_SOCIAL_TYPE_ENUM.Standard,
  //   id: 'standard-video-iframe',
  //   title: 'Standard Video Iframe',
  //   resolution: '',
  //   cpc: false,
  //   viewability: false,
  //   engagement: true,
  //   destinationUrls: [],
  //   templateId: '',
  // },
});

export const CREATIVE_FORMAT_BY_CREATIVE_EDITOR = {
  [CREATIVE_EDITOR_ENUM.lite]: [
    CREATIVE_FORMAT_ENUM.StandardBanner,
    CREATIVE_FORMAT_ENUM.LogoEnhancedBanner,
    CREATIVE_FORMAT_ENUM.ScrollReactiveBanner,
    CREATIVE_FORMAT_ENUM.AdhesiveBottomBanner,
    CREATIVE_FORMAT_ENUM.AdhesiveSideBanner,
    CREATIVE_FORMAT_ENUM.StandardVideo,
    CREATIVE_FORMAT_ENUM.ScrollReactiveVideo,
    CREATIVE_FORMAT_ENUM.IGStandardBanner,
    CREATIVE_FORMAT_ENUM.IGStandardVideo,
    CREATIVE_FORMAT_ENUM.IGScrollReactiveBanner,
    CREATIVE_FORMAT_ENUM.IGScrollReactiveVideo,
    CREATIVE_FORMAT_ENUM.FBStandardBanner,
    CREATIVE_FORMAT_ENUM.FBStandardVideo,
    CREATIVE_FORMAT_ENUM.FBScrollReactiveBanner,
    CREATIVE_FORMAT_ENUM.FBScrollReactiveVideo,
    CREATIVE_FORMAT_ENUM.TiktokVideo,
    CREATIVE_FORMAT_ENUM.InstagramReelsVideo,
    CREATIVE_FORMAT_ENUM.IGCarousel,
    CREATIVE_FORMAT_ENUM.FBCarousel,
    CREATIVE_FORMAT_ENUM.DynamicScrollReactiveBanner,
  ],
  [CREATIVE_EDITOR_ENUM.advanced]: [
    CREATIVE_FORMAT_ENUM.MiddleBanner,
    CREATIVE_FORMAT_ENUM.Venti,
    CREATIVE_FORMAT_ENUM.Breakaway,
    CREATIVE_FORMAT_ENUM.Runway,
  ],
};
