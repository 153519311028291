/* eslint-disable react/prop-types */
import React from "react";
import { IconButton } from "@mui/material";
import useTrackedClick from "../../hooks/useTrackedClick";

export default function TrackedIconButton({
  id,
  onClick,
  ...props
}: React.ComponentProps<typeof IconButton> & { id: string }) {
  const trackedOnClick = useTrackedClick(id, onClick);
  return <IconButton id={id} {...props} onClick={trackedOnClick} />;
}
