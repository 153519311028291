import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  Button,
  MenuProps,
  styled,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Bookmark, ArrowForwardIos } from "@mui/icons-material";
import { usePublishers } from "../../hooks/usePublishers";
import { usePublisherContext } from "../../hooks/usePublisherProvider";
import ContentModal from "../Modals/ContentModal";
import useSnackbar from "../../hooks/useSnackbar";
import PublisherList from "../PublisherList/PublisherList";
import SearchDropdown from '../SearchDropdown';
import {
  setRecentSearchPublisher,
  getRecentSearchPublisher,
} from "../../helpers/recentSearchPublisher";
import { setPublisherGroup, setUsername } from "../../helpers/publisherUsername";

const useStyles = makeStyles(() => {
  const fontWeight = 500;
  const fontSize = "13px";
  const width = "100%";
  return createStyles({
    recentViewList: {
      color: "#e5eaef",
      padding: "2px 8px 1px 0px",
      fontSize: "36px",
      paddingRight: "12px",
    },
    textInputWrapper: {
      padding: "0px 7px",
    },
    textInput: {
      width,
      padding: "0px 10px",
    },
    viewAll: {
      borderTop: "1px solid #a7b3bd",
      borderBottom: "1px solid #a7b3bd",
      width,
      padding: "12px 18px",
      fontSize,
      fontFamily: "Poppins",
      fontWeight,
      lineHeight: "20px",
    },
    recent: {
      width,
      padding: "13px 19px",
      color: "#3f4853",
      fontSize,
      marginTop: "13px",
      fontWeight,
    },
    item: {
      fontSize,
      fontFamily: "Poppins",
      fontWeight,
      padding: "8px 18px",
      '&:focus': {
        outline: "none",
        boxShadow: "none",
      },
    },
    svgArrowIcon: {
      fontSize: "23px",
      color: "#fff",
      margin: "0px 18px 0px 11px",
      fontWeight: "lighter",
      transform: "rotate(90deg) ",
    },
    currentPublisher: {
      fontSize: "18px",
      color: "#fff",
      textTransform: "none",
      outline: "none",
      boxShadow: "none",
      '&:focus': {
        outline: "none",
        boxShadow: "none",
      },
    },
  });
});

function SvgArrowIcon({ className }: { className?: string; }) {
  return <ArrowForwardIos className={className} />;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 2,
    marginTop: "1em",
    minWidth: 260,
    color: "#000",
    fontSize: "13px",
  },
}));

export default function DropdownWithSearch({ asAdmin }: { asAdmin: boolean }) {
  const { snackbarSuccess } = useSnackbar();
  const classes = useStyles();
  const { publishers, query } = usePublishers({});
  const recentPublisherList = getRecentSearchPublisher() ?? [];
  const {
    switchToPublisherGroup,
    switchToPublisher,
    switchToPublisherUsername,
    publisherSlug,
  } = usePublisherContext(asAdmin);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [publisherModalIsOpen, setPublisherModalIsOpen] = React.useState(false);
  const [switchPublisher, setSwitchPublisher] = React.useState<string>(null);
  const [currentPublisherSlug, setCurrentPublisherSlug] = React.useState<string>(
    recentPublisherList[0] || publisherSlug,
  );
  const handleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleIModal = () => {
    setPublisherModalIsOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setPublisherModalIsOpen(false);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>, newInput) => {
    setSearchValue(newInput);
  };

  const findCurrentPublisher = (searchText: string) => {
    const publisher = publishers?.find(
      (pl: any) => (
        pl?.name?.toLowerCase() === searchText?.toLowerCase() || pl.slug === searchText?.toLowerCase() || pl.domains.includes(searchText.split('(')[0].trim())
      ),
    );
    if (publisher) {
      setCurrentPublisherSlug(publisher.name);
      switchToPublisher(publisher.slug);
      setRecentSearchPublisher(publisher.slug);
      switchToPublisherUsername(publisher.users[0]?.username);
      setUsername(publisher.users[0]?.username);
      switchToPublisherGroup(publisher.group);
      setPublisherGroup(publisher.group);
      snackbarSuccess(`Successfully switched to ${publisher.name}`);
      handleClose();
      setSwitchPublisher(null);
      setSearchValue('');
    }
  };

  useEffect(() => {
    findCurrentPublisher(publisherSlug);
  }, [publisherSlug]);

  const getDomainsListFromPublisher = (publisher: any) => publisher.domains
    .map((domain: string) => `${domain} ( from: ${publisher.name} )`);

  const optionsPublisherAndDomains = [
    ...(publishers.length ? publishers.map((publisher) => publisher.name) : []),
    ...(publishers.length ? publishers.map(getDomainsListFromPublisher) : []),
  ].flat();

  return (
    asAdmin && (
      <Box>
        {query.isFetched && (
          <ContentModal
            title="Choose Your Publisher"
            elementClassName="modal-publisher-list"
            content={(
              <PublisherList
                value={switchPublisher}
                publisherList={
                  publishers.length
                  && publishers.map((publisher) => publisher.name)
                }
                onChange={setSwitchPublisher}
              />
            )}
            onClose={() => {
              handleClose();
              setSwitchPublisher(null);
            }}
            isOpen={publisherModalIsOpen}
            submitButtonText="Switch Publisher"
            cancelButtonText="Cancel"
            isSubmitButtonEnabled={!!switchPublisher}
            onSubmit={() => {
              findCurrentPublisher(switchPublisher);
            }}
          />
        )}
        <Button
          disableRipple
          aria-controls="publisher-list-menu"
          aria-haspopup="true"
          onClick={handleDropdown}
          className={classes.currentPublisher}
        >
          <span>
            {currentPublisherSlug}
          </span>
          <SvgArrowIcon className={classes?.svgArrowIcon} />
        </Button>
        <Box>
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box className={classes.textInputWrapper}>
              <SearchDropdown
                searchValue={searchValue}
                options={optionsPublisherAndDomains}
                placeholder="search domain/publisher"
                onSearchChange={handleSearchChange}
                onOptionClick={(value: string) => findCurrentPublisher(value)}
              />
            </Box>
            <MenuItem
              className={classes.viewAll}
              onClick={() => handleIModal()}
            >
              View All
            </MenuItem>
            <Typography className={classes.recent} variant="subtitle2">
              RECENT VIEWS
            </Typography>
            {recentPublisherList.map((publisher) => (
              <MenuItem
                className={classes.item}
                key={publisher}
                onClick={() => {
                  findCurrentPublisher(publisher);
                }}
              >
                <Bookmark className={classes?.recentViewList} />
                <span>
                  {publisher}
                </span>
              </MenuItem>
            ))}
          </StyledMenu>
        </Box>
      </Box>
    )
  );
}
