import React from "react";
import useVideos from "../../hooks/useVideos";
import useSnackbar from "../../hooks/useSnackbar";

export default function MyLibraryUploadFiles({
  showUploadPopup,
  onFinished,
  onNewVideoToken,
  onUploadStart,
  publisherGroup,
}: {
  showUploadPopup: boolean;
  publisherGroup?: string;
  onFinished?: () => void;
  onNewVideoToken?: (object) => void;
  onUploadStart?: () => void;
}) {
  const inputFileRef = React.useRef(null);
  const { api } = useVideos({});
  const [videoToken, setVideoToken] = React.useState({ token: "" });
  const { snackbarSuccess } = useSnackbar();

  const onFileSelected = React.useCallback(
    (acceptedFiles: any) => {
      setVideoToken({ token: "" });
      Array.from(acceptedFiles.target.files).forEach((file: any) => {
        onUploadStart();
        api.create(
          {
            file,
            ...(publisherGroup && { publisherUsername: publisherGroup }),
            onCreate: ({ token }) => setVideoToken({ token }),
          },
          {
            onSuccess: () => {
              snackbarSuccess(`Video Added to Library`);
              onFinished();
            },
          },
        );
      });
    },
    [publisherGroup],
  );

  React.useEffect(() => {
    if (showUploadPopup === true) inputFileRef.current.click();
    if (videoToken.token !== "") onNewVideoToken(videoToken);
  }, [showUploadPopup, videoToken]);

  return (
    <>
      <input
        type="file"
        multiple
        accept="video/*"
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={onFileSelected}
      />
    </>
  );
}
