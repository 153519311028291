/* eslint-disable @typescript-eslint/lines-between-class-members */
import React from "react";
import { STAGING } from "../api/endpoints";
import tracker from "../helpers/tracker";

declare global {
  type PlayerAttrs = {
    video?: string;
    [key: string]: any; // TODO improve
  };
  class Player {
    constructor(_: { element: HTMLElement; attrs: PlayerAttrs });
    activate: () => void;
    destroy: () => void;
    destroyed: () => boolean;
    once: (event: string, callback: (any) => void) => void;
  }
  interface Window {
    KargoVideo: {
      Player: typeof Player;
    };
  }
}

export function VideoPlayer({
  aspectratio,
  width,
  video,
  ...props
}: PlayerAttrs) {
  const ref = React.useRef();
  const [player, setPlayer] = React.useState<Player>();
  const initPlayer = () => {
    if (!window.KargoVideo) return;
    const p = new window.KargoVideo.Player({
      element: ref.current,
      attrs: {
        aspectratio: aspectratio || "1270/720",
        width: width || "100%",
        staging: STAGING,
        video,
        autoplay: false,
        autoplaywhenvisible: false,
        irisplaylist: false,
        showsidebargallery: false,
        ...props,
      },
    });
    p.once("playing", () => tracker.track("Video Played", { video }));
    p.activate();
    setPlayer(p);
  };

  React.useEffect(() => {
    if (!player || !video) return;
    if (!player.destroyed()) player.destroy();
    initPlayer();
  }, [video]);

  React.useEffect(() => {
    if (!window.KargoVideo) {
      const loadScript = new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://static.kargovideo.com/player/latest/kargo.js";
        script.onload = resolve;
        script.onerror = reject;
        document.getElementsByTagName("head")[0].append(script);
      });
      const loadStyles = new Promise((resolve, reject) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = "https://static.kargovideo.com/player/latest/kargo.css";
        link.onload = resolve;
        link.onerror = reject;
        document.getElementsByTagName("head")[0].append(link);
      });
      Promise.all([loadScript, loadStyles]).then(() => initPlayer());
    } else initPlayer();
  }, []);

  return (
    <div
      ref={ref}
      style={{ aspectRatio: aspectratio || "1270/720", width: width || "100%" }}
    />
  );
}
