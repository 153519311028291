import React, { MouseEvent, ReactNode, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { isArray } from 'lodash';
import {
  Menu,
  MenuItem,
  PopoverOrigin,
  IconButton,
} from '@mui/material';
import { Events, THEME_ENUM, useOutsideClick } from '@kargo/shared-components.krg-shared';
import KrgVerticalNavigation, { NavigationHeader } from '@kargo/shared-components.krg-vertical-navigation';
import { MenuButtonItemV1, MenuButtonItemV2 } from './shared/interfaces';
import useStyles from './krg-header-menu-button.style';

type Props = Events & {
  /**
   * @summary
   * Button class name
   */
  className?: string,
  /**
   * @summary
   * Menu class name
   */
  menuClassName?: string,
  /**
   * @summary
   * Button style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * This is the point on the anchor where the popover's anchorEl will attach to
   * @default
   * ```
   * {
   *   vertical: 'bottom',
   *   horizontal: 'right',
   * }
   * ```
   */
  anchorOrigin?: PopoverOrigin,
  /**
   * @summary
   * This is the point on the popover which will attach to the anchor's origin.
   * @default
   * ```
   * {
   *   vertical: 'top',
   *   horizontal: 'right',
   * }
   * ```
   */
  transformOrigin?: PopoverOrigin,
  /**
   * @summary
   * rows in popover. Format supported for V1 or V2 or common React Node
   */
  menuItems: MenuButtonItemV1[] | MenuButtonItemV2[] | ReactNode,
  /**
   * @summary
   * Icon of menu button
   */
  icon: React.ReactElement,
  /**
   * @summary
   * All header properties
   * Includes title, subtitle, avatar
   */
  header?: NavigationHeader,
  /**
   * @summary
   * This controls whether menu should be seen or not
   * @default
   * false
   */
  isOpen?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * This is triggered when changed menu visibility
   */
  onVisibilityChange?: (isOpen: boolean) => void,
};

const KrgHeaderMenuButton = ({
  className = '',
  menuClassName = '',
  style,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  menuItems,
  icon,
  header = { title: '' },
  isOpen = false,
  theme = THEME_ENUM.v1,
  onVisibilityChange,
  onClick,
  onBlur,
  onFocus,
}: Props) => {
  const isV2Theme = theme === THEME_ENUM.v2;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(isOpen);

  useEffect(
    () => setIsMenuOpen(isOpen),
    [isOpen],
  );

  const ref = React.useRef<any>(null);

  useOutsideClick(ref, () => handleClose());

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    onVisibilityChange?.(true);
    setIsMenuOpen(true);
    const innerElement = e.currentTarget.childNodes[0] as HTMLElement;
    setAnchorEl(innerElement);
  };

  const handleClose = () => {
    onVisibilityChange?.(false);
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (item: MenuButtonItemV1) => {
    item.handleClick?.();
    handleClose();
  };

  return (
    <>
      <IconButton
        className={`${className} ${classes.button}`}
        TouchRippleProps={{ className: classes.buttonRippleClass }}
        style={style}
        onClick={(e: MouseEvent<HTMLElement>) => {
          onClick?.(e);
          isMenuOpen ? handleClose() : handleOpen(e);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {icon}
      </IconButton>
      <Menu
        classes={{
          paper: `${classes.menuPaper} ${menuClassName}`,
          list: classes.menuList,
        }}
        elevation={1}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={isMenuOpen}
        onClose={handleClose}
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      >
        {
          isArray(menuItems)
            ? isV2Theme
              ? <Router>
                  <KrgVerticalNavigation
                    header={header}
                    navigationList={[{ items: menuItems as MenuButtonItemV2[] }]}
                    selectedItem={''}
                    isCollapsable
                    theme={theme}
                    onSelect={handleClose}
                    onClose={handleClose}
                  />
                </Router>
              : menuItems.map((item, index) =>
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => handleClick(item as MenuButtonItemV1)}
                >
                  {item.text}
                </MenuItem>)
            : menuItems
        }
      </Menu>
    </>
  );
};

export default KrgHeaderMenuButton;

KrgHeaderMenuButton.THEME_ENUM = THEME_ENUM;
