import React from 'react';
import InputModal from './InputModal';
import { useDomain } from '../../hooks/useDomain';
import useSnackbar from '../../hooks/useSnackbar';

type Props = {
  onClose(): void
  domainToken: string
  isOpen: boolean;
  onSubmit: (k: string) => void
} & React.ComponentProps<typeof InputModal>;
export default function CreatePlayerConfigModal({
  isOpen,
  onClose,
  onSubmit,
  domainToken,
}: Props) {
  const { createConfig } = useDomain(domainToken);
  const { snackbarError, snackbarSuccess } = useSnackbar();
  return (
    <InputModal
      elementClassName="create-property-modal"
      title="Create Config"
      label="Player Name"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={(key) => {
        createConfig(
          { key },
          {
            onSuccess: () => {
              snackbarSuccess('Player Created');
            },
            onError: () => {
              snackbarError(
                'Error while creating player. Check the console for more information'
              );
            },
          },
        );
        onSubmit(key);
      }}
    />
  );
}
