import { BaseJsonSchema, createArraySchemaByItemType, createSchemaByBasicTypes } from '../../helpers';

const INode: BaseJsonSchema = {
  type: 'object',
  properties: {
    asi: createSchemaByBasicTypes('string'),
    hp: createSchemaByBasicTypes('number', 1),
    sid: createSchemaByBasicTypes('string'),
  },
};

export const ISchain: BaseJsonSchema = {
  type: 'object',
  properties: {
    config: {
      type: 'object',
      properties: {
        complete: createSchemaByBasicTypes('number', 1),
        ver: createSchemaByBasicTypes('string'),
        nodes: createArraySchemaByItemType(undefined, [INode]),
      },
    },
  },
};
