import React from 'react';

import { Tooltip } from '@mui/material';

import { Events, THEME_ENUM } from '@kargo/shared-components.krg-shared';

import { POSITION_ENUM, TYPE_ENUM } from './shared/enums';
import { TooltipPropsI } from './shared/interface';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & TooltipPropsI;

const KrgTooltip = ({
  classNames,
  style,
  children,
  text = '',
  tooltip,
  tooltipProps,
  position = POSITION_ENUM.bottom,
  type = TYPE_ENUM.default,
  hasArrow = true,
  isOpen,
  onClick,
  theme = THEME_ENUM.v1,
}: Props) => {
  const props: StyleProps = {
    type,
    isTextTooltip: typeof tooltip === 'string',
  };

  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  return (
    <Tooltip
      {...tooltipProps}
      classes={{
        arrow: `${classes.arrow} ${classNames?.arrow}`,
        popper: `${baseClasses.popper} ${classNames?.popper}`,
        tooltip: `${baseClasses.tooltip} ${classes.tooltip} ${classNames?.tooltip}`,

        // only extrernal class configuration
        popperInteractive: classNames?.popperInteractive,
        popperArrow: classNames?.popperArrow,
        popperClose: classNames?.popperClose,
        tooltipArrow: classNames?.tooltipArrow,
        touch: classNames?.touch,
        tooltipPlacementLeft: classNames?.tooltipPlacementLeft,
        tooltipPlacementRight: classNames?.tooltipPlacementRight,
        tooltipPlacementTop: classNames?.tooltipPlacementTop,
        tooltipPlacementBottom: classNames?.tooltipPlacementBottom,
      }}
      open={isOpen}
      sx={style}
      title={tooltip}
      placement={position}
      arrow={hasArrow}
      onClick={onClick}
    >
      <span>
        {children || text}
      </span>
    </Tooltip>
  );
};

KrgTooltip.POSITION_ENUM = POSITION_ENUM;
KrgTooltip.TYPE_ENUM = TYPE_ENUM;
KrgTooltip.THEME_ENUM = THEME_ENUM;

export default KrgTooltip;
