import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';
import { IStorage } from './storage';

export const I33acrossId: BaseJsonSchema = {
  type: 'object',
  properties: {
    name: createSchemaByBasicTypes('string', '', {
      enum: ['33acrossId'],
    }),
    params: {
      type: 'object',
      properties: {
        pid: createSchemaByBasicTypes('string', '0010b00002EFnxPAAT'),
      },
      required: ['pid'],
    },
    storage: IStorage,
  },
};
