import { makeStyles } from '@mui/styles';
import { TYPE_ENUM } from '../shared/enums';

export type Props = {
  type: TYPE_ENUM,
  isClickable: boolean,
};

const useBaseStyles = makeStyles({
  root: {},
});

export default useBaseStyles;
