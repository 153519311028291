/* eslint-disable react/prop-types */
import React from "react";
import KrgButton, { THEME_ENUM } from "@kargo/shared-components.krg-button";
import useTrackedClick from "../../hooks/useTrackedClick";

export default function TrackedButton({
  id,
  onClick,
  ...props
}: React.ComponentProps<typeof KrgButton> & { id: string }) {
  const trackedOnClick = useTrackedClick(id, onClick);
  return (
    <KrgButton
      id={id}
      theme={THEME_ENUM.v2}
      {...props}
      onClick={trackedOnClick}
    />
  );
}
