import { Ref, ReactNode } from 'react';
import { DEPRECATED } from '@kargo/shared-components.krg-shared';
import { TableOrder, TableOrderBy } from './types';
import { DISPLAY_ENUM, TYPE_ENUM, CELL_TYPE_ENUM, ACTION_TYPE_ENUM } from './enums';

export interface DataTableCol {
  /**
   * @summary
   * Field key
   */
  field: string;
  /**
   * @summary
   * Cell type
   * @description
   * If cell type is `thumbnail`, provide a url string array like below:
   * ```
   * const row = {
   *   id: 1,
   *   ...otherFields,
   *   thumbnailsField: ['url1', 'url2'],
   * };
   * ```
   * If cell type is `subtitle`, provide an object like below:
   * ```
   * const row = {
   *   id: 1,
   *   ...otherFields,
   *   subtitleField: {
   *     title: 'test-title',
   *     subtitle: 'test-subtitle',
   *   },
   * };
   * ```
   */
  type?: CELL_TYPE_ENUM;
  /**
   * @summary
   * Header label
   */
  headerName: string;
  /**
   * @summary
   * Set a custom width for the column
   */
  width?: string;
  /**
   * @summary
   * Checkmark icon display duration in milliseconds
   * @default
   * 2000
   */
  editFinishedIconDuration?: number;
  /**
   * @summary
   * Set this `false` if you want your column not to bubble the click action
   * for preventing unnecessary rowClick function
   */
  hasPreventBubbling?: boolean;
  /**
   * @summary
   * Is this column sortable
   */
  isSortable?: boolean;
  /**
   * @summary
   * Is this column sticky
   */
  isSticky?: boolean;
  /**
   * @summary
   * How is the text aligned
   * @default
   * left
   */
  textAlignment?: 'left' | 'center' | 'right';
  /**
   * @summary
   * Set `true` for inline editing
   * @description
   * If column is editable, an edit icon will appear inside the cell.
   * Once edit icon is clicked, inline edit will show up for the selected cell.
   * On the focus lose, it will automatically trigger `onEditFinished` event.
   * Pressing `ESC` will not trigger `onEditFinished` event.
   */
  isEditable?: boolean;
  /**
   * @summary
   * You can set this function to render custom header cell
   */
  renderHeader?: (
    column: DataTableCol,
  ) => ReactNode;
  /**
   * @summary
   * You can set this function to render custom row cell
   * @example
   * ```
   *  const columns = [
   *    ...otherColumns,
   *    {
   *       field: 'fullName',
   *       headerName: 'Full Name',
   *       renderCell: (row: DataTableRow) => {
   *         return <div>{`${row.name} ${row.surname}`}</div>;
   *       },
   *     },
   *  ]
   * ```
   */
  renderCell?: (
    row: DataTableRow,
    column: DataTableCol,
  ) => ReactNode;
  /**
   * @summary
   * This function is triggered when inline edit cell loses focus or `Enter` is clicked.
   * @param text - Edited text
   * @param column - Edited column information
   * @param row - Edited row information
   */
  onEditFinished?: (text: string, column: any, row: DataTableRow) =>
  Promise<boolean | void> | boolean | void;
  /**
   * @summary
   * This function is triggered when inline edit cell loses focus with `Escape` key.
   * @param text - Edited text
   * @param column - Edited column information
   * @param row - Edited row information
   */
  onEditCanceled?: (text: string, column: any, row: DataTableRow) => void;
  /**
   * @summary
   * This function is triggered when a column is clicked.
   * Usage of this function disables onRowCheck and onRowClick for rows.
   * @param cell - Clicked cell information
   * @param row - Clicked row information
   */
  onClick?: (cell: any, row: DataTableRow) => void;
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'hasPreventBubbling'
   */
  preventCellClickBubbling?: DEPRECATED;
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isSticky'
   */
  sticky?: DEPRECATED;
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isSortable'
   */
  sortable?: DEPRECATED;
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isEditable'
   */
  editable?: DEPRECATED;
}

export interface DataTableRow {
  [key: string]: any;
}

export interface DataTableHeaderRef {
  setOrder: (tableOrder: TableOrder, tableOrderBy: TableOrderBy) => void;
}

export interface DataTableActionButton {
  /**
   * @summary
   * Action type
   */
  type: ACTION_TYPE_ENUM;
  /**
   * @summary
   * Button's label for menu, tooltip for icon button
   */
  label?: string;
  /**
   * @summary
   * Button's tooltip
   */
  tooltip?: string | ((row: any) => string);
  /**
   * @summary
   * Show/hide button
   */
  isHidden?: boolean | ((row: any) => boolean);
  /**
   * @summary
   * Set `false` to disable the button
   */
  isEnabled?: boolean | ((row: any) => boolean);
  /**
   * @summary
   * Button's onClick function
   */
  onClick?: (row: DataTableRow) => void;
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isHidden'
   */
  hide?: DEPRECATED;
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED;
}

export interface DataTableConfig {
  /**
   * @summary
   * Table data
   * @description
   * Add table columns and rows here
   */
  data: {
    /**
     * @summary
     * Table columns
     * @description
     * Action-buttons column is added automatically
     * if any actions exists in the table
     */
    columns: DataTableCol[];
    /**
     * @summary
     * Table rows
     * @description
     * Each row must have an `id` field.
     */
    rows: DataTableRow[];
  };
  /**
   * @summary
   * Table type
   * @description
   * Set `checkbox` to enable checkboxes on the table
   * @default
   * TYPE_ENUM.standard
   */
  type?: TYPE_ENUM;
  /**
   * @summary
   * Action column.
   * @description
   * Actions column is added automatically
   * if any action exists in the table
   */
  actionMenu?: {
    /**
     * @summary
     * Action button display
     * @default
     * DISPLAY_ENUM.separate
     */
    display?: DISPLAY_ENUM.separate | DISPLAY_ENUM.menu;
    /**
     * @summary
     * Action container
     * @description
     * Add your actions here such as edit, duplicate, delete, preview etc.
     */
    actions: DataTableActionButton[];
  };
  /**
   * @summary
   * Table meta
   */
  meta?: {
    /**
     * @summary
     * Table type
     * @default
     * "standard"
     */
    headerRef?: Ref<DataTableHeaderRef>;
    /**
     * @summary
     * Set this `true` to hide `No Results` label when there is no row
     * @default
     * true
     */
    isNoResultsLabelHidden?: boolean;
    /**
     * @summary
     * Set this `true` to make first header row sticky
     * @default
     * false
     */
    isStickyHeader?: boolean;
    /**
     * @summary
     * row className according to condition
     */
    rowClassName?: (row: DataTableRow) => string;
    /**
     * @summary
     * column className according to condition
     */
    columnClassName?: (row: DataTableRow, column: DataTableCol) => string;
    /*
      DEPRECATED
    */
    /**
     * @summary
     * Deprecated on 2.0.84 in favour of 'isNoResultsLabelHidden'
     */
    hideNoResultsLabel?: DEPRECATED;
    /**
     * @summary
     * Deprecated on 2.0.84 in favour of 'isStickyHeader'
     */
    stickyHeader?: DEPRECATED;
  };
  /**
   * @summary
   * Table meta
   */
  pagination?: {
    /**
     * @summary
     * Page
     * @default
     * 1
     */
    page?: number;
    /**
     * @summary
     * Initial page size.
     * @default
     * 10
     */
    initialPageSize?: number;
    /**
     * @summary
     * Total number of rows
     * @description
     * Set this value if pagination is handled on backend
     */
    totalRowCount?: number;
    /**
     * @summary
     * Set this `true` if pagination is handled on backend
     * @default
     * false
     */
    hasStrictRows?: boolean;
    /**
     * @summary
     * Set `true` to disable interaction
     * @description
     * You can use this prop while fetching data from API
     * @default
     * false
     */
    isLoading?: boolean;
    /**
     * @summary
     * Set `true` to hide pagination part
     * @default
     * false
     */
    isHidden?: boolean;
    /*
      DEPRECATED
    */
    /**
     * @summary
     * Deprecated on 2.0.84 in favour of 'hasStrictRows'
     */
    strictRows?: DEPRECATED;
    /**
     * @summary
     * Deprecated on 2.0.84 in favour of 'isLoading'
     */
    loading?: DEPRECATED;
    /**
     * @summary
     * Deprecated on 2.0.84 in favour of 'isHidden'
     */
    hide?: DEPRECATED;
  };
  /**
   * @summary
   * Table related methods
   */
  methods?: {
    /**
     * @summary
     * This function is triggered when `order` or `orderBy` is changed.
     * @param tableOrder - Order type
     * @param tableOrderBy - Order field (Corresponds to the `field` field of `column`)
     */
    onTableOrderChange?: (
      tableOrder: TableOrder,
      tableOrderBy: TableOrderBy,
    ) => void;
    /**
     * @summary
     * This function is triggered when a row is checked or unchecked.
     * It is also triggered when all rows are checked or unchecked.
     * @param isChecked - Action type
     * @param row - Checked/unchecked row. This returns empty array
     * if all the rows checked/unchecked
     * @param rows - Currently selected rows
     */
    onRowCheck?: (
      isChecked: boolean,
      row: DataTableRow | DataTableRow[],
      rows: DataTableRow[],
    ) => void;
    /**
     * @summary
     * This function is triggered when a row is clicked.
     * Usage of this function disables onRowCheck for rows.
     * @param row - Clicked row information
     */
    onRowClick?: (row: DataTableRow) => void;
    /**
     * @summary
     * This function is triggered when `page` or `pageSize` is changed
     * @param page - New page
     * @param pageSize - New page size
     */
    onPaginationChange?: (page: number, pageSize: number) => void;
    /**
     * @summary
     * Callback fired if checkbox of table header is checked
     * @description
     * It returns if all rows are checked and there is any excluded row
     */
    onUncheckedRowsChange?: (allSelected: boolean, excludedRows: DataTableRow[]) => void;
  };
}
