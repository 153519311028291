import React, { useRef, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
} from '@mui/material';
import { ItemI } from './shared/interfaces';
import {
  ArrowDropDown as ArrowStandardIcon,
  ArrowRight as ArrowRightIcon,
  KeyboardArrowDown as ArrowOutlinedIcon,
} from '@mui/icons-material';
import { MULTIPLE_TYPE_ENUM, SIZE_ENUM } from './shared/enums';
import KrgCheckbox from '@kargo/shared-components.krg-checkbox';
import KrgChip, { TYPE_ENUM as CHIP_TYPE_ENUM } from '@kargo/shared-components.krg-chip';
import { Events, THEME_ENUM, INPUT_VARIANT_ENUM as VARIANT_ENUM, DEPRECATED } from '@kargo/shared-components.krg-shared';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV2 from './styles/style-v2';
import useStylesV1 from './styles/style-v1';

type Props = Events & {
  /**
   * @summary
   * Component ID
   */
  id?: string
  /**
   * @summary
   * Classes name (Select container, Select itself, label)
   */
  classNames?: {
    selectContainer?: string,
    select?: string,
    label?: string,
  }
  /**
   * @summary
   * Select style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Select variant
   * @default
   * VARIANT_ENUM.outlined
   */
  variant?: VARIANT_ENUM,
  /**
   * @summary
   * Select size (for V2)
   * @default
   * SIZE_ENUM.medium
   */
  size?: SIZE_ENUM,
  /**
   * @summary
   * Select label
   */
  label?: string,
  /**
   * @summary
   * Select item list
   */
  items: ItemI[],
  /**
   * @summary
   * Select value
   */
  value?: string | string[] | number | number[];
  /**
   * @summary
   * Helper text
   */
  helperText?: string,
  /**
   * @summary
   * Displaying type of selected values when multiple is selected
   * @default
   * MULTIPLE_TYPE_ENUM.chip
   */
  multipleType?: MULTIPLE_TYPE_ENUM;
  /**
   * @summary
   * Displaying error message if error is not empty
   */
  errorMessage?: string;
  /**
   * @summary
   * Determines whether multi selection is possible
   * @default
   * false
   */
  isMultiple?: boolean,
  /**
   * @summary
   * If `true`, the select will take up the full width of its container.
   * Only V1 theme support. For V2 is fullWidth or set size with classNames.selectContainer
   * @default
   * false
   */
  isFullWidth?: boolean,
  /**
   * @summary
   * Determines whether select is disabled
   * @default
   * false
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Flags styles component as rounded corners for VARIANT_ENUM.outlined variant
   * @default
   * false
   */
  isRounded?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * On change function
   */
  onChange?: (value: string | string[] | number | number[]) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isDisabled'
   */
  disabled?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.51 in favour of 'isEnabled'
   */
  isDisabled?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.51 in favour of 'items'
   */
  options?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.52 in favour of 'isMultiple'
   */
  multiple?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.52 in favour of 'isFullWidth'
   */
  fullWidth?: DEPRECATED,
};
const KrgSelect = ({
  id,
  classNames = {
    label: '',
    select: '',
    selectContainer: '',
  },
  style,
  variant = VARIANT_ENUM.outlined,
  size = SIZE_ENUM.medium,
  label,
  items,
  value,
  helperText,
  multipleType = MULTIPLE_TYPE_ENUM.chip,
  errorMessage,
  isMultiple = false,
  isFullWidth = false,
  isEnabled = true,
  isRounded = false,
  theme = THEME_ENUM.v1,
  onChange,
  onClick,
  onFocus,
  onBlur,
}: Props) => {
  const selectId = `krg-select-${id || Math.random()}`;

  const props: StyleProps = {
    variant,
    size,
    isFullWidth,
    isEnabled,
    isRounded,
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isV2Theme = theme === THEME_ENUM.v2;
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;
  const selectRef = useRef<any>();

  const isOutlinedVariant = variant === VARIANT_ENUM.outlined;

  const isValueAnObject = typeof value === 'object';

  const selectAll = 'selectAll';
  const clearAll = 'clearAll';

  const renderList = (
    items.filter(({ isHidden }) => !isHidden).map((item: ItemI) => {
      return (
        <MenuItem key={item.value} value={item.value} disabled={item.isEnabled === false}>
          {
            isMultiple && multipleType === MULTIPLE_TYPE_ENUM.checkmark &&
              <KrgCheckbox
                hasStopPropagation={false}
                isChecked={
                  isValueAnObject
                    ? value.findIndex((val: string | number) => val === item.value) > -1
                    : false
                }
                isEnabled={isEnabled &&  item.isEnabled !== false}
                theme={theme}
              />
          }

          <span>{item.text}</span>
          {/* Right icon (optional only V2theme) */}
          {isV2Theme && (item.hasArrowIcon || item.customIcon) &&
            <ListItemIcon className={classes.menuItemIcon}>
              { item.customIcon || <ArrowRightIcon /> }
            </ListItemIcon>
          }
        </MenuItem>
      );
    })
  );

  const allItemValueArr = items
    .filter((item: ItemI) => (!item.isHidden) && isEnabled && item.isEnabled !== false)
    .map((item: ItemI) => item.value);

  const selectAllButton = () => {
    if (!isMultiple && !isValueAnObject) {
      return;
    }

    return (
    <MenuItem
      key={selectAll}
      value={selectAll}
      disabled={value?.toString() === allItemValueArr.toString()}
      classes={{ root: `${baseClasses.selectAll} ${classes.selectAll}` }}>
      <span>
          Select All
      </span>
    </MenuItem>);
  };

  const clearAllButton = () => {
    if (!isMultiple && !isValueAnObject) {
      return;
    }

    return (
    <MenuItem
      key={clearAll}
      value={clearAll}
      disabled={value?.toString() === ''}
      classes={{ root: `${baseClasses.selectAll} ${classes.selectAll} ${classes.clearAll}` }}>
      <span>
          Clear All
      </span>
    </MenuItem>);
  };

  const findItem = (itemValue: string | number) =>
    items.find((item: ItemI) => item.value === itemValue);

  const renderValue = (selectedValue: string | string[] | number | number[]) => {
    if (typeof selectedValue === 'object' && isMultiple) {
      const texts = selectedValue.map((item: string | number) => {
        return findItem(item)?.text || '';
      });
      return multipleType === MULTIPLE_TYPE_ENUM.chip
        ? texts.map((text, index) =>
            <KrgChip
              className={classes.chip}
              key={index}
              type={CHIP_TYPE_ENUM.gray}
              data={{
                text: text?.toString() || '',
                id: index,
              }}
              isEnabled={isEnabled}
              theme={theme}
            />)
        : texts.join(', ');
    }
    const foundItem = findItem(selectedValue as string | number);
    return foundItem?.text || '';
  };

  const labelVariantClass = isOutlinedVariant
    ? baseClasses.labelOutlined
    : classes.labelStandard;

  const selectVariantClass = isOutlinedVariant
    ? baseClasses.selectOutlined
    : baseClasses.selectStandard;

  const selectThemeVariantClass = isOutlinedVariant
    ? classes.selectOutlined
    : classes.selectStandard;

  const handleOnChange = (e: any) => {
    // multiple selection
    if (typeof e.target.value === 'object') {
      const valueArr = e.target.value;

      valueArr.includes(selectAll)
      ? onChange?.(allItemValueArr)
      : valueArr.includes(clearAll)
        ? onChange?.([])
        : onChange?.(valueArr);

    } else if (e.target.value !== clearAll || selectAll) {
      // single selection
      onChange?.(e.target.value);
    }
  };

  return (
    <FormControl
      className={`${classes.selectContainer} ${classNames.selectContainer}`}
      variant={variant}
      error={!!errorMessage}
    >
      <InputLabel
        className={
          `${baseClasses.label}` +
          ` ${classes.label}` +
          ` ${labelVariantClass}` +
          ` ${classNames.label}` +
          ` ${isEnabled ? '' : classes.labelDisabled}`
        }
        htmlFor={selectId}
      >
        {label}
      </InputLabel>

      {/* Gap element for outlined rounded (cover rounded corner below) */}
      {isOutlinedVariant && isRounded &&
        <div
          className={`${baseClasses.select} ${classes.outlinedRoundedGap}`}
          style={{ opacity: isOpen ? 1 : 0 }}
        />
      }
      <Select
        id={id}
        ref={selectRef}
        style={style}
        label={label}
        IconComponent={
          isV2Theme
            ? isOutlinedVariant
              ? ArrowOutlinedIcon
              : ArrowStandardIcon
            : undefined
        }
        className={
          `${selectVariantClass}` +
          ` ${selectThemeVariantClass}` +
          ` ${classes.select}` +
          ` ${baseClasses.select}` +
          ` ${classNames?.select}`
        }
        defaultValue={isMultiple ? [] : ''}
        fullWidth={isFullWidth}
        labelId={selectId}
        disabled={!isEnabled}
        multiple={isMultiple}
        value={value}
        renderValue={selected => renderValue(selected)}
        MenuProps={{
          classes: {
            paper: `${baseClasses.menu}`
            + ` ${classes.menu}`
            + ` ${isOutlinedVariant ? classes.menuOutlined : undefined}`
            + ` ${errorMessage ? classes.menuError : undefined}`,
          },
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          setTimeout(() => selectRef.current?.firstChild.blur(), 0);
          setIsOpen(false);
        }}
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      >
        {selectAllButton()}
        {clearAllButton()}
        {renderList}
      </Select>
      <FormHelperText className={`${baseClasses.helperText} ${classes.helperText}`}>
        {errorMessage || helperText}
      </FormHelperText>
    </FormControl>
  );
};

KrgSelect.MULTIPLE_TYPE_ENUM = MULTIPLE_TYPE_ENUM;
KrgSelect.SIZE_ENUM = SIZE_ENUM;
KrgSelect.VARIANT_ENUM = VARIANT_ENUM;
KrgSelect.THEME_ENUM = THEME_ENUM;

export default KrgSelect;
