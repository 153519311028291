/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";

type IDragContext = {
  itemIdKey: string;
  draggableList: any[];
  onDragEnd: (dragIdx: number, dropIdx: number) => void;
  isDraggable?: boolean;
};

export const DragContext = createContext<IDragContext>({
  itemIdKey: "id",
  draggableList: [],
  onDragEnd: (dragIdx: number, dropIdx: number) => {},
  isDraggable: false,
});
