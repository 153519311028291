import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2, COLORS_V2_ALPHA } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from '../shared/enums';
import { Props } from './base-style';

const useStylesV2 = makeStyles({
  // Chip root element
  root: {
    fontSize: FONT.SIZE.BASE,
    height: '1.3125em',
    padding: '0.3125em',

    backgroundColor: ({ type }: Props) =>  type === TYPE_ENUM.gray
      ? COLORS_V2.NEUTRAL_MEDIUM
      : 'transparent',
    borderColor: ({ type }: Props) =>  type === TYPE_ENUM.transparent
      ? COLORS_V2.NEUTRAL_BLACK
      : 'unset',

    // Label text
    '& > .MuiChip-label': {
      fontSize: FONT.SIZE.SMALL,
      fontWeight: FONT.WEIGHT.MEDIUM,
      color: COLORS_V2.NEUTRAL_BLACK,
      padding: '0 0.4375em',
    },

    // Adornment Icon
    '& > .MuiChip-icon': {
      color: COLORS_V2.NEUTRAL_BLACK,
      fontSize: FONT.SIZE.LARGE,
      margin: 0,
    },

    // Adornment Avatar
    '& > .MuiAvatar-root': {
      fontSize: FONT.SIZE.SMALL,
      margin: 0,
      backgroundColor: COLORS_V2.NEUTRAL_DARK,
      color: COLORS_V2.NEUTRAL_WHITE,
      width: '1.4em',
      height: '1.4em',
    },

    // Delete Icon
    '& > .MuiChip-deleteIcon': {
      color: COLORS_V2.NEUTRAL_DARK,
      fontSize: FONT.SIZE.LARGE,
      marginRight: '-0.1041665em',
      marginLeft: 0,
      background:  ({ type }: Props) =>  type === TYPE_ENUM.gray
        ? `radial-gradient(circle at center, ${COLORS_V2.NEUTRAL_LIGHT} 0, ${COLORS_V2.NEUTRAL_LIGHT} 50%, transparent 51%, transparent 100%)`
        : `radial-gradient(circle at center, ${COLORS_V2.NEUTRAL_WHITE} 0, ${COLORS_V2.NEUTRAL_WHITE} 50%, transparent 51%, transparent 100%)`,

      '&:hover, &:focus': {
        color: COLORS_V2.PRIMARY_700,
      },
    },

    // Chip + Disabled
    '&.Mui-disabled': {
      backgroundColor: ({ type }: Props) =>  type === TYPE_ENUM.gray
        ? COLORS_V2.NEUTRAL_INACTIVE
        : 'transparent',
      borderColor: ({ type }: Props) =>  type === TYPE_ENUM.transparent
        ? COLORS_V2.NEUTRAL_INACTIVE
        : 'unset',
      opacity: 1,

      // Label text + Disabled
      '& > .MuiChip-label': {
        color: ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? COLORS_V2.NEUTRAL_DARK
          : COLORS_V2.NEUTRAL_INACTIVE,
      },

      // Adornment Icon + Disabled
      '& > .MuiChip-icon': {
        color: ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? COLORS_V2.NEUTRAL_DARK
          : COLORS_V2.NEUTRAL_INACTIVE,
      },

      // Adornment Avatar + Disabled
      '& > .MuiAvatar-root': {
        color: COLORS_V2.NEUTRAL_WHITE,
        backgroundColor: ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? COLORS_V2.NEUTRAL_DARK
          : COLORS_V2.NEUTRAL_INACTIVE,
      },

      // Delete Icon + Disabled
      '& > .MuiChip-deleteIcon': {
        background:  ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? `radial-gradient(circle at center, ${COLORS_V2.NEUTRAL_INACTIVE} 0, ${COLORS_V2.NEUTRAL_INACTIVE} 50%, transparent 51%, transparent 100%)`
          : `radial-gradient(circle at center, ${COLORS_V2.NEUTRAL_WHITE} 0, ${COLORS_V2.NEUTRAL_WHITE} 50%, transparent 51%, transparent 100%)`,
        color: ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? COLORS_V2.NEUTRAL_LIGHT
          : COLORS_V2.NEUTRAL_INACTIVE,
      },
    },

    '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
      backgroundColor: ({ type }: Props) =>  type === TYPE_ENUM.gray ? COLORS_V2.PRIMARY_50 : 'transparent',
      borderColor: ({ type }: Props) =>  type === TYPE_ENUM.transparent ? COLORS_V2.PRIMARY_700 : 'unset',

      // Label text + Hover
      '& > .MuiChip-label': {
        color: ({ type }: Props) =>  type === TYPE_ENUM.transparent
          ? COLORS_V2.PRIMARY_700
          : COLORS_V2.NEUTRAL_BLACK,
      },

      // Adornment Icon + Hover
      '& > .MuiChip-icon': {
        color: ({ type }: Props) =>  type === TYPE_ENUM.transparent
          ? COLORS_V2.PRIMARY_700
          : COLORS_V2.NEUTRAL_BLACK,
      },

      // Avatar  + Hover
      '& > .MuiAvatar-root': {
        backgroundColor: ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? COLORS_V2.NEUTRAL_DARK
          : COLORS_V2.PRIMARY_700,
      },

      // Delete Icon + Hover
      '& > .MuiChip-deleteIcon': {
        background:  ({ type }: Props) =>  type === TYPE_ENUM.gray
          ? `radial-gradient(circle at center, ${COLORS_V2.PRIMARY_50} 0, ${COLORS_V2.PRIMARY_50} 50%, transparent 51%, transparent 100%)`
          : `radial-gradient(circle at center, ${COLORS_V2.NEUTRAL_WHITE} 0, ${COLORS_V2.NEUTRAL_WHITE} 50%, transparent 51%, transparent 100%)`,
      },
    },

    '&:active': {
      boxShadow: ({ isClickable }: Props) => isClickable
        ? `0 0.25em 0.25em ${COLORS_V2_ALPHA.NEUTRAL_BLACK_SHADOW}`
        : 'none',
    },
  },

  colorPrimary: {},
  iconColorPrimary: {},
  deleteIconColorPrimary: {},
});

export default useStylesV2;
