import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2, COLORS_V2_ALPHA } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from '../shared/enums';
import { Props } from './base-style';

const useStylesV2 = makeStyles({
  alert: {
    minWidth: 'auto',
    minHeight: '4em',
    padding: '0.625em 0.625em 0.625em 1em',
    borderRadius: '0.625em',
    boxShadow: `0 0.25em 0.25em ${COLORS_V2_ALPHA.NEUTRAL_BLACK_SHADOW}`,
    backgroundColor: COLORS_V2.NEUTRAL_BLACK.replace(')', ', 0.75)'), // Add opacity
  },

  icon: {
    height: '2em',
    margin: '0 0.4375em 0 0.125em',

    color: ({ type }: Props) => (
      type === TYPE_ENUM.dark
        ? COLORS_V2.NEUTRAL_WHITE
        : type === TYPE_ENUM.success
          ? COLORS_V2.SUCCESS_LIGHT
          : type === TYPE_ENUM.warning
            ? COLORS_V2.WARNING_DARK
            : type === TYPE_ENUM.error
              ? COLORS_V2.ERROR_DARK
              : COLORS_V2.NEUTRAL_DARK
    ),

    background: ({ type }: Props) => (
      type === TYPE_ENUM.dark
        ? 'unset'
        : `radial-gradient(circle at center, ${COLORS_V2.NEUTRAL_WHITE} 0, ${COLORS_V2.NEUTRAL_WHITE} 50%, transparent 51%, transparent 100%)`
    ),

    '& > .MuiSvgIcon-root': {
      fontSize: '2.1em',
    },
  },

  textContainer: {
    color: COLORS_V2.NEUTRAL_WHITE,
    marginRight: '1em',
    minWidth: '8em',
    lineHeight: '1.125em',
  },

  title: {
    fontSize: FONT.SIZE.LARGE,
    fontWeight: FONT.WEIGHT.BOLD,
    lineHeight: 'inherit',
    marginBottom: '0.1875em',
    marginRight: 0,
  },

  text: {
    fontSize: FONT.SIZE.LARGE,
    fontWeight: FONT.WEIGHT.NORMAL,
    lineHeight: 'inherit',
    marginRight: 0,
  },

  button: {
    fontSize: FONT.SIZE.LARGE,
    fontWeight: FONT.WEIGHT.SEMI_BOLD,
    textTransform: 'uppercase',
    borderRadius: '1em',
    margin: 0,
    padding: '0.25em 0.714287em',
    color: COLORS_V2.NEUTRAL_WHITE,
    height: '2.1428571em',

    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_BLACK,
    },
  },
  closeIcon: {
    color: COLORS_V2.NEUTRAL_WHITE,
    padding: 0,
    margin: '0 0.416667em 0 0',

    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_DARK,
    },
  },
});

export default useStylesV2;
