import React, { ChangeEvent } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { DEPRECATED, Events, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { SIZE_ENUM } from './shared/enums';
import KrgTooltip, { TooltipPropsI } from '@kargo/shared-components.krg-tooltip';
import useBaseStyles from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Class name
   */
  className?: string,
  /**
   * @summary
   * Style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Switch size
   * @default
   * 'medium'
   */
  size?: SIZE_ENUM,
  /**
   * @summary
   * An events object to reference events
   */
  events?: Events,
  /**
   * @summary
   * Checkbox label
   */
  label?: string,
  /**
   * @summary
   * Tooltip props for the switch
   */
  tooltipConfig?: TooltipPropsI,
  /**
   * @summary
   * Is switch track includes an selection indicator icon
   * @default
   * false
   */
  hasTrackIcon?: boolean,
  /**
   * @summary
   * Checked state of switch
   */
  isChecked?: boolean,
  /**
   * @summary
   * Is switch disabled / disabled
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * Callback fired when the `checked` state is changed.
   */
  onToggle?: (checked: boolean) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.33 in favour of 'isChecked'
   */
  checked?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.33 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
};

const KrgSwitch = ({
  className = '',
  style,
  size = SIZE_ENUM.medium,
  label = '',
  tooltipConfig,
  hasTrackIcon = false,
  isChecked,
  isEnabled = true,
  theme = THEME_ENUM.v1,
  onToggle,
  onClick,
  onFocus,
  onBlur,
}: Props) => {
  const isV2Theme = theme === THEME_ENUM.v2;
  const baseClasses = useBaseStyles();
  const classesV1 = useStylesV1();
  const classesV2 = useStylesV2();
  const classes = isV2Theme ? classesV2 : classesV1;

  const onSwitchToggle = (e: ChangeEvent<HTMLInputElement>) =>
    onToggle?.(e.target.checked);

  const SwitchComponent =
    <Switch
      className={className}
      classes={{
        root: classes.switchRoot,
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: `${classes.track} ${hasTrackIcon ? classes.trackIcons : ''}`,
        disabled: `${baseClasses.disabled} ${classes.disabled}`,
      }}
      style={style}
      checked={isChecked}
      size={size}
      disabled={!isEnabled}
      color="default"
      onChange={onSwitchToggle}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={e => e.stopPropagation()}
    />;

  const MainComponent = tooltipConfig?.tooltip
    ? <KrgTooltip {...tooltipConfig}>{SwitchComponent}</KrgTooltip>
    : SwitchComponent;

  return (
    <>
      { label
          ? <FormControlLabel
              classes={{
                root: baseClasses.root,
                label: classes.label,
              }}
              control={ MainComponent }
              labelPlacement="start"
              label={label}
            />
          : MainComponent
      }
    </>
  );
};

KrgSwitch.SIZE_ENUM = SIZE_ENUM;
KrgSwitch.THEME_ENUM = THEME_ENUM;

export default KrgSwitch;
