import { COLORS } from '@kargo/shared-components.krg-shared';
import { makeStyles } from '@mui/styles';

const useStylesV1 = makeStyles({
  // Checkbox classes
  root: {},

  // Selected (Checked/Indeterminate)
  selected: {
    color: COLORS.PRIMARY_500_ACCENT,
  },

  // Disabled color
  disabled: {
    color: COLORS.GRAY_DARK,
    cursor: 'default',
  },

  // Label text
  label: {},
});

export default useStylesV1;
