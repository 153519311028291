import React  from 'react';

export const usePlayVideo = (
  onVideoEnd: () => void,
  video?: string,
  videoRef?: any,
  interval?: any,
) => {
  React.useEffect(
    () => {
      if (!!video && videoRef.current) {
        onVideoEnd();
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current?.play().catch(() => videoRef.current ? videoRef.current.pause() : null);
      }
      return () => {
        if (interval) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearInterval(interval.current);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [video],
  );
};
