import {
  BaseJsonSchema,
  createArraySchemaByItemType,
  createSchemaByBasicTypes,
} from "../../helpers";
import { IAppnexusBid } from "./Bidders/IAppnexusBid";
import { ICriteoBid } from "./Bidders/ICriteoBid";
import { IIxBid } from "./Bidders/IIxBid";
import { IMDS } from "./Bidders/IMDS";
import { IMediaNet } from "./Bidders/IMedianet";
import { IPubmaticBid } from "./Bidders/IPubmaticBid";
import { IRubiconBid } from "./Bidders/IRubiconBid";
import { IShareThoroughBid } from "./Bidders/ISharedthroughBid";
import { ITripleLiftBid } from "./Bidders/ITripleliftBid";
import { IKargoBid } from "./Bidders/IkargoBid";

const IRollConfig: BaseJsonSchema = {
  type: "object",
  properties: {
    repositoryId: createSchemaByBasicTypes("string"),
    prebidbids: createArraySchemaByItemType(undefined, [
      IKargoBid,
      IPubmaticBid,
      IRubiconBid,
      IAppnexusBid,
      IIxBid,
      ITripleLiftBid,
      ICriteoBid,
      IShareThoroughBid,
      IMediaNet,
      IMDS,
    ]),
    prebiddfpad: {
      type: "object",
      properties: {
        cust_params: createArraySchemaByItemType("string"),
        ui: createSchemaByBasicTypes("string"),
      },
    },
    htlkey: createSchemaByBasicTypes("string"),
    longitude: {
      type: "object",
      description:
        "LongitudeAds configuration. Defining this overrides all other auction types (htl/prebid/tam)",
      properties: {
        unitId: createSchemaByBasicTypes("string", "videotest"),
      },
    },
    adtagurl: createSchemaByBasicTypes("string"),
  },
  additionalProperties: {
    type: ["number", "string", "boolean", "array", "object", "null"],
    description:
      "no type declaration on this property, you are adding a new one",
  },
};

export default IRollConfig;
