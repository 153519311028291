import React, { useEffect } from 'react';
import useSession from './useSession';
import { initLookerSDK } from '../helpers/looker';

export const LookerContext = React.createContext<{
}>(null);

export function LookerProvider({ children }: React.PropsWithChildren) {
  const session = useSession();

  useEffect(() => {
    if (!session) return;
    initLookerSDK(session.getAccessToken().getJwtToken());
  }, [session]);

  return (
    <LookerContext.Provider value={React.useMemo(() => ({ }), [])}>
      { children }
    </LookerContext.Provider>
  );
}

export default function useLookerContext() {
  return React.useContext(LookerContext);
}
