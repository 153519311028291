import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';

export type Props = {
  textColor?: string,
  labelColor?: string,
  placeholderColor?: string,
  iconColor?: string,
  borderColor?: string,
  mediaLabel?: string,
  hasStartAdornment: boolean,
  hasEndAdornment: boolean,
  hasCaret: boolean,
  isAutoHeight?: boolean,
  isPillShaped: boolean,
};

const useBaseStyles = makeStyles({
  /*
    Input container class
  */
  inputContainer: {
    borderRadius: 0,
    caretColor: ({ hasCaret }: Props) => hasCaret ? 'auto' : 'transparent',
    color: ({ textColor }: Props) => textColor || COLORS.BLACK_HIGH_EMPHASIS,
    fontSize: FONT.SIZE.EXTRA_LARGE,
    lineHeight: 1.2,
  },

  /*
    Textfield classes
  */
  textField: {
    fontSize: FONT.SIZE.BASE,
    height: ({ isAutoHeight }: Props) => isAutoHeight ? 'auto' : '3.65em',
    // Input container
    '& .MuiInput-formControl': {
      marginTop: '0',
    },
    // Textfield label
    '& .MuiInputLabel-root': {
      fontSize: '0.9em',
    },
    // Search icon
    '& .MuiSvgIcon-root': {
      fontSize: '1.3em',
    },
    // Start Adornment
    '& .MuiInputBase-adornedStart': {
      paddingLeft: ({ hasStartAdornment, mediaLabel }: Props) =>
        (!hasStartAdornment || mediaLabel) ? '0' : '0.8em',
      '& .MuiSvgIcon-root': {
        display: ({ hasStartAdornment }: Props) => !hasStartAdornment ? 'none' : 'block',
      },
      transition: 'padding-left .2s linear',
    },
    // Input
    '& input': {
      paddingLeft: ({ hasStartAdornment }: Props) => (hasStartAdornment ? '0.4em' : '0'),
      paddingRight: ({ hasEndAdornment }: Props) => (hasEndAdornment ? '0.4em' : '0'),
    },
    '& ::-webkit-input-placeholder': {
      color: ({ placeholderColor }: Props) => placeholderColor || COLORS.BLACK_HIGH_EMPHASIS,
    },
  },
  textFieldStandard: {
    // Label
    '& label': {
      marginTop: '-0.7em',
    },
    // Textfield label
    '& .MuiInputLabel-root': {
      fontSize: '0.9em',
    },
    '& .MuiInput-root': {
      marginTop: 0,
    },
  },
  textFieldOutlined: {
    // Input
    '& input': {
      paddingLeft: ({ hasStartAdornment }: Props) => (hasStartAdornment ? '0.4em' : '0.8em'),
      paddingRight: ({ hasEndAdornment }: Props) => (hasEndAdornment ? '0.4em' : '0.8em'),
    },
  },
  textFieldError: {
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.ERROR_DARK, // Solid underline on focus
    },
    '& .MuiFormHelperText-root': {
      color: COLORS.ERROR_DARK,
      overflow: ({ isAutoHeight }: Props) => isAutoHeight ? 'initial' : 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: ({ isAutoHeight }: Props) => isAutoHeight ? 'normal' : 'nowrap',
    },
    '& svg': {
      color: COLORS.ERROR_DARK,
    },
    '& .MuiInputLabel-root': {
      color: COLORS.ERROR_DARK,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.ERROR_DARK,
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.ERROR_DARK,
        opacity: 0.7,
      },
    },
  },
  textFieldDisabled: {
    '& .MuiInput-underline:before': {
      borderBottomColor: COLORS.GRAY_DIVIDER, // Semi-transparent underline
      borderBottomStyle: 'solid',
    },
    '& svg': {
      color: COLORS.BLACK_INACTIVE,
    },
  },
  errorIcon: {
    color: COLORS.ERROR_DARK,
  },
  startAdornmentWrapper: {
    width: '2.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  startAdormentSvgWrapper: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediaLabelWrapper: {
    position: 'absolute',
    fontSize: '0.65em',
    color: COLORS.GRAY_DARK,
    left: '100%',
    top: '-23%',
    backgroundColor: COLORS.WHITE,
    zIndex: 1,
    padding: '0 0.5em',
    whiteSpace: 'nowrap',
  },
});

export default useBaseStyles;
