import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import KrgSnackbar from "@kargo/shared-components.krg-snackbar";
import { debounce } from "lodash";
import useVideos from "../../hooks/useVideos";
import SearchInput from "../../components/SearchInput";
import CategoriesSelect from "../../components/CategoriesSelect/CategoriesSelect";
import DurationSelect from "../../components/DurationSelect/DurationSelect";
import { DatePicker, DateRange } from "../../components/DatePicker/DatePicker";
import LoadingCircle from "../../components/LoadingCircle";
import tracker from "../../helpers/tracker";
import Snackbar from "../../components/Snackbar";
import { VideoCard } from "../../components/VideoCard";
import VideosTable from "../../components/VideosTable";
import useSnackbar from "../../hooks/useSnackbar";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
  };
  selected: string[];
  onChangeSelected: (selected: string[]) => void;
  uploadPreviewVideoToken?: { token: string };
};

export default function VideoLibrary({
  asAdmin,
  publisherSlug,
  selected,
  onChangeSelected,
  uploadPreviewVideoToken,
}: Props) {
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedDurations, setSelectedDurations] = useState<number[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const { snackbarWarning } = useSnackbar();

  // Query configuration based on filters
  const query = {
    ...(search && { text: search }),
    ...(selectedCategories.length > 0 && { category: selectedCategories }),
    ...(selectedDurations.length > 0 && { duration: selectedDurations }),
    ...(dateRange &&
      dateRange.to &&
      dateRange.from && { date_range: dateRange }),
    ...(publisherSlug && { slug: publisherSlug }),
  };

  const { videos, query: videosQuery } = useVideos(query);

  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );

  // Handle toggling selection for all videos
  const handleToggleAll = (checked: boolean) => {
    if (videos.length === 0 && checked) {
      snackbarWarning("Video Library selected is Empty");
      return;
    }
    if (checked) {
      const allVideoTokens = videos.map((video) => video.token);
      onChangeSelected(allVideoTokens);
    } else {
      onChangeSelected([]);
    }
  };

  // Handle toggling selection for a single video
  const handleVideoToggle = (videoToken: string, isSelected: boolean) => {
    if (isSelected) {
      onChangeSelected([...selected, videoToken]);
    } else {
      onChangeSelected(selected.filter((token) => token !== videoToken));
    }
  };

  // Update showPlaylist based on admin status and publisher slug
  useEffect(() => {
    setShowPlaylist((asAdmin && Boolean(publisherSlug)) || !asAdmin);
  }, [asAdmin, publisherSlug]);

  // Track search and filter usage
  useEffect(() => {
    if (
      search ||
      selectedCategories?.length > 0 ||
      selectedDurations?.length > 0 ||
      dateRange
    ) {
      debouncedTrack("Search", {
        resource: "video",
        text: search,
        categories: selectedCategories,
        durations: selectedDurations,
        date: dateRange,
      });
    }
  }, [search, selectedCategories, selectedDurations, dateRange]);
  return (
    <Box>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          gap={3}
          justifyContent="flex-start"
        >
          <SearchInput placeholder="Search" onDebounceEnd={setSearch} />
          <Grid item xs={12} sm={3}>
            <CategoriesSelect
              asAdmin={asAdmin}
              value={selectedCategories}
              onChange={(cat: string) => {
                setSelectedCategories([...selectedCategories, cat]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DurationSelect
              asAdmin={asAdmin}
              value={selectedDurations}
              onChange={(duration: number) => {
                setSelectedDurations([...selectedDurations, duration]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DatePicker
              onDateRangeChange={(selectedDate) => {
                setDateRange(selectedDate);
              }}
              value={dateRange}
            />
          </Grid>
        </Grid>
      </Grid>
      {videosQuery.isFetched ? (
        <>
          {uploadPreviewVideoToken?.token && (
            <Box style={{ padding: "10px 20px 20px 20px" }}>
              <Box display="flex">
                <VideoCard video={uploadPreviewVideoToken} />
              </Box>
            </Box>
          )}
          {showPlaylist && (
            <VideosTable
              videos={videos}
              selected={selected}
              onToggleAll={handleToggleAll}
              onVideoToggle={handleVideoToggle}
            />
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 259px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingCircle />
        </div>
      )}
      {asAdmin && !showPlaylist && (
        <Snackbar
          autoHideDuration={6000}
          type={KrgSnackbar.TYPE_ENUM.warning}
          isOpen={isOpen}
          theme={KrgSnackbar.THEME_ENUM.v2}
          button={{
            text: "continue",
            onClick: () => {
              setIsOpen(false);
              console.warn(
                "Please switch to a publisher account at the top right-hand corner to view uploaded videos, MRSS feeds and playlists",
              );
            },
          }}
          text={
            <div style={{ fontSize: "13px" }}>
              <p>Please switch to a publisher account at the top right-hand</p>
              <p>corner to view uploaded videos, MRSS feeds and playlists</p>
            </div>
          }
        />
      )}
    </Box>
  );
}
