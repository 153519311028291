import { makeStyles } from '@mui/styles';
import { FONT, COLORS, COLORS_V2, THEME_ENUM } from '@kargo/shared-components.krg-shared';

export type Props = {
  theme?: THEME_ENUM,
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    fontSize: FONT.SIZE.MEDIUM,
  },

  /*
    Cell
  */
  cell: {
    alignItems: 'center',
    display: 'flex',

    '& > span' : {
      display: 'inline-flex',
    },
  },

  /*
    Edit icon
  */
  icon: {
    fontSize: '1.6em',
    color: ({ theme }: Props) => theme === THEME_ENUM.v1
      ? COLORS.BLACK
      : COLORS_V2.NEUTRAL_BLACK,

    '&:hover': {
      color: ({ theme }: Props) => theme === THEME_ENUM.v1
      ? COLORS.PRIMARY_500_ACCENT
      : COLORS_V2.PRIMARY_700,
    },
  },
  editIcon: {
    flexShrink: 0,
    width: '1.62em',
  },
  checkIcon: {
    height: '0.6em',
    marginLeft: '0.4em',
    width: '0.6em',
  },
  editIconPlaceholder: {
    marginLeft: '1em',
    width: '1.6em',
  },
});

export default useStyles;
