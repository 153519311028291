/* eslint-disable no-nested-ternary, react/prop-types, @typescript-eslint/no-unused-vars */
import React from "react";
import KrgSnackbar from "@kargo/shared-components.krg-snackbar";
import { makeStyles } from "@mui/styles";
import { CheckCircle, Error, RemoveCircle } from "@mui/icons-material";

// TODO: Look into whether or not we need `useStyles` method && `classes` attribute in this file.
const useStyles = makeStyles({
  snackbar: {
    "&>div": {
      height: "50px",
      minHeight: "auto",
      paddingLeft: "7px",
      paddingRight: "2px",
      "&>div": {
        alignItems: "center",
        "&:has(svg)": {
          background:
            "radial-gradient(circle at center center, rgb(255, 255, 255) 0px, rgb(255, 255, 255) 49%, transparent 50%, transparent 100%)",
        },
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "24px",
    },
  },
});

export default function Snackbar({
  ...props
}: React.ComponentProps<typeof KrgSnackbar>) {
  const classes = useStyles();
  return (
    <KrgSnackbar
      autoHideDuration={2000}
      // className={classes.snackbar}
      theme={KrgSnackbar.THEME_ENUM.v2}
      // TODO: Look into creating ternary helper function instead?
      icon={
        props.type === KrgSnackbar.TYPE_ENUM.error ? (
          <RemoveCircle />
        ) : props.type === KrgSnackbar.TYPE_ENUM.warning ? (
          <Error />
        ) : (
          <CheckCircle />
        )
      }
      {...props}
    />
  );
}
