export const PATHS = {
  // Main routes
  ROOT: "/",
  PUBLISHER_HUB: "/publisher-hub",
  PLAYLISTS: "/playlists",
  MEDIA_GALLERY: "/media-gallery",
  DASHBOARDS: "/dashboards",
  REPORTING: "/reporting",

  // Admin routes
  ADMIN: "/admin",
  EDIT: "/edit",
  CLONE: "/clone",
  ADMIN_USERS: "/admin/users",
  ADMIN_DOMAIN: "/admin/domain",
  ADMIN_PUBLISHER_ONBOARD: "/admin/publisher/onboard",
  ADMIN_BULK_UPDATE: "/admin/bulk-update",
  ADMIN_BULK_DELETE: "/admin/bulk-delete",

  // admin dynamic routes
  ADMIN_DOMAIN_CONFIG: "/admin/domain/:domainToken",
  ADMIN_CONFIG_EDIT: "/admin/domain/:domainToken/add-config/edit",
  ADMIN_PLAYER_CONFIG: "/admin/domain/:domainToken/:playerToken",
  ADMIN_PLAYER_EDIT: "/admin/domain/:domainToken/:playerToken/edit",
  ADMIN_REVISION_EDIT: "/admin/domain/:domainToken/:playerToken/:revision/edit",
  ADMIN_PLAYER_CLONE: "/admin/domain/:domainToken/:playerToken/clone",

  // Bulk and not found
  BULK_EDIT: "/bulk",
  NOT_FOUND: "*",
};
