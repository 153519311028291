/* eslint-disable @typescript-eslint/no-unused-vars, no-template-curly-in-string */
import { Config } from "../api/types";

export default function generateExportTag({
  config,
  id,
}: {
  config: Config;
  id: string;
}) {
  function script(attrs, i) {
    window.top.kso_disablevp = true;
    function init() {
      const p = new window.KargoVideo.Player({
        element: document.getElementById(i),
        attrs,
      });
      p.activate();
    }
    const uri = "https://static.kargovideo.com/player/latest/kargo";
    if (window.KargoVideo) init();
    else {
      const ls = new Promise((resolve, reject) => {
        const s = document.createElement("script");
        s.src = `${uri}.js`;
        s.onload = resolve;
        s.onerror = reject;
        document.getElementsByTagName("head")[0].append(s);
      });
      const ll = new Promise((resolve, reject) => {
        const l = document.createElement("link");
        l.rel = "stylesheet";
        l.type = "text/css";
        l.href = `${uri}.css`;
        l.onload = resolve;
        l.onerror = reject;
        document.getElementsByTagName("head")[0].append(l);
      });
      Promise.all([ls, ll]).then(() => init());
    }
  }
  const minified =
    '!function(e,t){function o(){new window.KargoVideo.Player({element:document.getElementById(t),attrs:e}).activate()}window.top.kso_disablevp=!0;const n="https://static.kargovideo.com/player/latest/kargo";if(window.KargoVideo)o();else{const e=new Promise(((e,t)=>{const o=document.createElement("script");o.src=`${n}.js`,o.onload=e,o.onerror=t,document.getElementsByTagName("head")[0].append(o)})),t=new Promise(((e,t)=>{const o=document.createElement("link");o.rel="stylesheet",o.type="text/css",o.href=`${n}.css`,o.onload=e,o.onerror=t,document.getElementsByTagName("head")[0].append(o)}));Promise.all([e,t]).then((()=>o()))}}';
  return `<div id="${id}"></div><script>${minified}(window["${id}-config"] || ${JSON.stringify(config.config || {})}, "${id}")</script>`;
}
