import React, { useEffect } from 'react';

export const useInterval = (callback:() => void, delay: number) => {

  const intervalId = React.useRef<any>(null);
  const savedCallback = React.useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(
    () => {
      const tick = () => savedCallback.current();
      intervalId.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalId.current);
    },
    [delay],
  );
  return intervalId.current;
};
