export function getUsername() {
  return sessionStorage.getItem("publisherUsername");
}

export function setUsername(username: string) {
  if (!username) {
    sessionStorage.setItem("publisherUsername", "");
    return;
  }
  sessionStorage.setItem("publisherUsername", username);
}

export function getPublisherGroup() {
  return sessionStorage.getItem("publisherGroup");
}

export function setPublisherGroup(groupName: string) {
  if (!groupName) {
    sessionStorage.setItem("publisherGroup", "");
    return;
  }
  sessionStorage.setItem("publisherGroup", groupName);
}
