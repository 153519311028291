import { makeStyles } from '@mui/styles';
import { FONT } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from '../shared/enums';

export type Props = {
  type: TYPE_ENUM,
  isTextTooltip: boolean,
};

const useBaseStyles = makeStyles({
  popper: {
    fontSize: FONT.SIZE.BASE,
  },
  tooltip: {
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,

    '&:not(.MuiTooltip-tooltipArrow).MuiTooltip-tooltipPlacement': {
      '&Top': {
        marginBottom: '0.7272727em',
      },
      '&Right': {
        marginLeft: '0.7272727em',
      },
      '&Bottom': {
        marginTop: '0.7272727em',
      },
      '&Left': {
        marginRight: '0.7272727em',
      },
    },
  },
});

export default useBaseStyles;
