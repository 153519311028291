/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import KrgModal from "@kargo/shared-components.krg-modal";
import { VARIANT_ENUM as BUTTON_VARIANT_ENUM } from "@kargo/shared-components.krg-button";
import { Box } from "@mui/material";
import Select from "../Select";
import TrackedKrgButton from "../TrackedButton/TrackedKrgButton";

type SortOptions = "default" | "only_recent";

export default function SortVideosModal({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => any;
  onSubmit: (selectedOption: SortOptions) => any;
}) {
  const [sort, setSort] = React.useState<SortOptions>("default");
  return (
    <KrgModal
      className="modal"
      title="Sort Videos"
      isOpen={isOpen}
      onClose={onClose}
      inputContainer={
        <>
          <Select
            label="Sort Videos"
            value={sort}
            onChange={setSort}
            style={{ width: "200px" }}
            items={[
              {
                text: "Newest Videos By Date",
                value: "default",
              },
              {
                text: "Stop Videos Older than 30 Days",
                value: "only_recent",
              },
            ]}
          />
          <Box display="flex" gap="20px" justifyContent="flex-end">
            <TrackedKrgButton
              id="cancel-update-video-order"
              variant={BUTTON_VARIANT_ENUM.outlined}
              text="Cancel"
            />
            <TrackedKrgButton
              id="confirm-update-video-order"
              variant={BUTTON_VARIANT_ENUM.contained}
              text="Update Video Order"
            />
          </Box>
        </>
      }
      theme={KrgModal.THEME_ENUM.v2}
    />
  );
}
