import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: "black",
      fontSize: "12px",
    },
    radioButton: {
      "& .MuiSvgIcon-root": {
        height: 20,
      },
    },
    radioButtonColor: {
      "& .MuiSvgIcon-root": {
        color: "#0033ff",
      },
    },
  }),
);

type Props = {
  options?: any;
  value?: string;
  onChange?: (publisher: string) => void;
};

export default function RadioButton({ options, onChange, value }: Props) {
  const classes = useStyles();
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <RadioGroup value={value} onChange={handleRadioChange}>
      {options?.sort()?.map((option) => (
        <FormControlLabel
          style={{
            padding: "8px",
            margin: "2px 0px",
            width: "100%",
            backgroundColor: value === option ? "#e5ebff" : "",
          }}
          classes={{
            label: classes.label,
          }}
          control={
            <Radio
              className={`${classes.radioButton} ${
                value === option ? classes.radioButtonColor : ""
              }`}
            />
          }
          label={option}
          value={option}
        />
      ))}
    </RadioGroup>
  );
}
