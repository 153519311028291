import React from "react";
import KrgModal from "@kargo/shared-components.krg-modal";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import { IconButton, Typography, Box } from "@mui/material";
import { Delete, Edit, OpenWith } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Playlist } from "../../api/types";
import { VideosCollection } from "../../components/VideosCollection";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import usePlaylists from "../../hooks/usePlaylists";
import useSnackbar from "../../hooks/useSnackbar";
import { getRangeSelection } from "../../helpers/videos";
import useShiftKeyListener from "../../hooks/useShiftKeyListener";
import TrackedKrgButton from "../../components/TrackedButton/TrackedKrgButton";

let prevSelected;

const useStyles = makeStyles({
  title: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "510px",
  },
});

function Inner({
  playlist,
  onClose,
}: { playlist: Playlist } & Pick<
  React.ComponentProps<typeof KrgModal>,
  "onClose"
>) {
  const { api } = usePlaylists();
  const { snackbarSuccess } = useSnackbar();
  const [selected, setSelected] = React.useState([]);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] =
    React.useState(false);
  const [isDraggable, setIsDraggable] = React.useState(false);
  const [shiftSelectionState, setShiftSelectionState] = React.useState(false);
  const [medias, setMedias] = React.useState(playlist.medias);
  const [playlistTitle, setPlaylistTitle] = React.useState(playlist.title);
  const [playlistTitleEditable, setPlaylistTitleEditable] =
    React.useState(false);
  const classes = useStyles();
  useShiftKeyListener(setShiftSelectionState, [selected]);
  const handleMove = (oldIndex: number, newIndex: number) => {
    if (newIndex >= medias.length || newIndex < 0) return;
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    setMedias((oldArr) => {
      const newArr = [...oldArr];
      newArr.splice(oldIndex, 1)[0];
      newArr.splice(newIndex, 0, oldArr[oldIndex]);
      return newArr;
    });
    /* eslint-enable @typescript-eslint/no-unused-expressions */
  };
  const handleVideoToggle = (video: string, isSelected: boolean) => {
    const index = selected.indexOf(video);
    const selections = getRangeSelection(
      video,
      medias,
      shiftSelectionState,
      prevSelected,
    );
    let newSelected;
    if (isSelected && index === -1) {
      newSelected = [...selected, ...selections];
    } else if (!isSelected && index > -1) {
      newSelected = selected.filter((i) => !selections.includes(i));
    }
    prevSelected = shiftSelectionState ? undefined : video;
    setSelected(newSelected);
    setShiftSelectionState(false);
  };
  return (
    <div>
      <Box width="100%" height="54px">
        <Box display="flex" alignItems="center" sx={{ padding: "10px 0" }}>
          {playlistTitleEditable ? (
            <KrgTextInput
              isFullWidth
              text={playlistTitle}
              theme={KrgTextInput.THEME_ENUM.v2}
              onDebounceEnd={setPlaylistTitle}
              debounceTime={0}
              label="Playlist Title (unsaved)"
            />
          ) : (
            <>
              <Typography className={classes.title}>
                {playlist.title}
              </Typography>
              <IconButton
                className="iconbutton"
                onClick={() => setPlaylistTitleEditable((last) => !last)}
              >
                <KrgTooltip
                  theme={KrgTooltip.THEME_ENUM.v2}
                  tooltip="Edit Playlist Title"
                >
                  <Edit
                    style={{
                      color: isDraggable ? "#0033FF" : "black",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </KrgTooltip>
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <ConfirmModal
        isOpen={confirmDeleteModalIsOpen}
        onClose={() => setConfirmDeleteModalIsOpen(false)}
        onSubmitClick={() => {
          setConfirmDeleteModalIsOpen(false);
          setMedias(medias.filter((token) => !selected.includes(token)));
          snackbarSuccess("Successfully Removed Videos");
          setSelected([]);
        }}
        header="Are you sure you want to remove the selected videos from the playlist?"
        submitButtonText="Remove Videos"
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          className="iconbutton"
          disableRipple
          onClick={() => setIsDraggable((last) => !last)}
        >
          <KrgTooltip
            theme={KrgTooltip.THEME_ENUM.v2}
            tooltip="Drag and drop videos to reorder the playlist.."
          >
            <OpenWith
              style={{
                color: isDraggable ? "#0033FF" : "black",
                width: "15px",
                height: "15px",
              }}
            />
          </KrgTooltip>
        </IconButton>
        <IconButton
          className="iconbutton"
          disabled={!selected || selected.length === 0 || isDraggable}
          disableRipple
          onClick={() => setConfirmDeleteModalIsOpen(true)}
        >
          <Delete />
        </IconButton>
      </div>
      <div
        className="edit-playlist-modal-videos-collection"
        style={{
          padding: "24px",
          border: "1px solid #C8D0D6",
          borderRadius: "10px",
          marginTop: "20px",
          marginBottom: "47px",
          overflowY: "scroll",
          maxHeight:
            "calc(100vh - 64px - 10px - 23px - 27.5px - 8px - 30px - 20px - 31.01px - 47px)", // need to recalculate if modal dimensions are changed
        }}
      >
        <VideosCollection
          isNumbered
          isSelectable={!isDraggable}
          isDraggable={isDraggable}
          selected={selected}
          onMove={handleMove}
          onVideoToggle={handleVideoToggle}
          videos={medias.map((token: string) => ({ token }))}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <TrackedKrgButton
          id="cancel-edit-playlist"
          style={{ marginRight: "20px" }}
          text="Cancel"
          size={BUTTON_SIZE_ENUM.small}
          onClick={onClose}
        />
        <TrackedKrgButton
          id="save-playlist"
          text="Save Changes"
          size={BUTTON_SIZE_ENUM.small}
          variant={BUTTON_VARIANT_ENUM.contained}
          onClick={(e) => {
            api.update(
              {
                token: playlist.token,
                data: {
                  ...playlist,
                  medias,
                  title: playlistTitle,
                },
              },
              {
                onSuccess: () => {
                  snackbarSuccess(
                    `${playlistTitle || playlist.token} Playlist Saved`,
                  );
                  onClose(e);
                },
              },
            );
          }}
        />
      </div>
    </div>
  );
}

export default function EditPlaylistModal({
  isOpen,
  onClose,
  playlist,
}: { playlist: Playlist } & Pick<
  React.ComponentProps<typeof KrgModal>,
  "isOpen" | "onClose"
>) {
  return (
    <KrgModal
      className="modal full-width-modal"
      isOpen={isOpen}
      onClose={onClose}
      theme={KrgModal.THEME_ENUM.v2}
      inputContainer={<Inner onClose={onClose} playlist={playlist} />}
    />
  );
}
