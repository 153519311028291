import React, { ReactNode, forwardRef } from 'react';
import { Button } from '@mui/material';
import { Events, BACKGROUND_ENUM, THEME_ENUM, DEPRECATED } from '@kargo/shared-components.krg-shared';
import { SIZE_ENUM, HORIZONTAL_POS_ENUM, VARIANT_ENUM } from './shared/enums';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Button id
   */
  id?: string,
  /**
   * @summary
   * Button class name
   */
  className?: string,
  /**
   * @summary
   * Class name for button text container
   */
  textContainerClassName?: string,
  /**
   * @summary
   * Button style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Button text
   */
  text?: string,
  /**
   * @summary
   * Button size
   * @default
   * SIZE_ENUM.medium
   */
  size?: SIZE_ENUM,
  /**
   * @summary
   * Button variant
   * @default
   * VARIANT_ENUM.outlined
   */
  variant?: VARIANT_ENUM,
  /**
   * @summary
   * Button parent container background (for style buttons)
   * @default
   * BACKGROUND_ENUM.white
   */
  background?: BACKGROUND_ENUM,
  /**
   * @summary
   * Button icon which stands before button text
   */
  startIcon?: ReactNode,
  /**
   * @summary
   * Button icon which stands after button text
   */
  endIcon?: ReactNode,
  /**
   * @summary
   * Font size of the `startIcon`
   */
  iconFontSize?: string,
  /**
   * @summary
   * Position of button text
   * @default
   * HORIZONTAL_POS_ENUM.center
   */
  buttonTextPosition?: HORIZONTAL_POS_ENUM,
  /**
   * @summary
   * If `true`, the button icon will have a circle background.
   */
  hasIconBackground?: boolean,
  /**
   * @summary
   * If `false`, the button will be disabled
   */
  isEnabled?: boolean,
  /**
   * @summary
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean,
  /**
   * @summary
   * If `true`, the button will be border squared
   * @default
   * false
   */
  isSquaredBorder?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'isFullWidth'
   */
  fullWidth?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'background'
   */
  knockout?: DEPRECATED,
};

const KrgButton = forwardRef<HTMLButtonElement, Props>(({
  id,
  className = '',
  textContainerClassName = '',
  text,
  size = SIZE_ENUM.medium,
  variant = VARIANT_ENUM.outlined,
  background = BACKGROUND_ENUM.white,
  startIcon,
  endIcon,
  iconFontSize,
  style,
  buttonTextPosition = HORIZONTAL_POS_ENUM.center,
  hasIconBackground = false,
  isEnabled = true,
  isSquaredBorder = false,
  isFullWidth = false,
  theme = THEME_ENUM.v1,
  onBlur,
  onClick,
  onFocus,
}: Props, ref) => {
  const isIconButton = !!startIcon && !text;
  const props: StyleProps = {
    variant,
    background,
    buttonTextPosition,
    iconFontSize,
    hasIconBackground,
    isEnabled,
    isIconButton,
    isFullWidth,
    isSquaredBorder,
    hasIcon: !!startIcon,
  };

  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props)(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  const buttonSizeClass = classes[`${size}Button`];
  const buttonVariantClass = classes[`${variant}${background !== BACKGROUND_ENUM.white ? 'Knockout' : ''}Button`];
  const textSizeContainerClass = classes[`${size}TextContainer`];

  const buttonRippleVariantClass = VARIANT_ENUM.outlined ? classes.buttonRipple : '';

  return (
    <Button
      ref={ref}
      id={id}
      className={`${baseClasses.button} ${classes.button} ${buttonVariantClass} ${buttonSizeClass} ${className}`}
      TouchRippleProps={{ className: buttonRippleVariantClass }}
      variant={variant}
      disabled={!isEnabled}
      fullWidth={isFullWidth}
      style={style}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <div className={baseClasses.innerContainer}>
        {startIcon}
        <div className={`${baseClasses.textContainer} ${classes.textContainer} ${textSizeContainerClass} ${textContainerClassName}`}>
          {text}
        </div>
        {endIcon}
      </div>
    </Button>
  );
});

export default KrgButton;

export {
  HORIZONTAL_POS_ENUM,
  SIZE_ENUM,
  VARIANT_ENUM,
  BACKGROUND_ENUM,
  THEME_ENUM,
};
