export function getRecentSearchPublisher() {
  return JSON.parse(sessionStorage.getItem("publisherList"));
}

export function setRecentSearchPublisher(publisher: string) {
  if (!publisher) {
    sessionStorage.setItem("publisherList", JSON.stringify([]));
    return;
  }
  const publisherList = getRecentSearchPublisher() || [];
  const hasDuplicate = publisherList?.includes(publisher);
  const publisherIndex = publisherList?.indexOf(publisher);

  if (publisherList?.length > 2 && !hasDuplicate) publisherList?.pop();
  else if (hasDuplicate && publisherIndex >= 0)
    publisherList?.splice(publisherIndex, 1);

  publisherList?.unshift(publisher);

  sessionStorage.setItem("publisherList", JSON.stringify(publisherList));
}
