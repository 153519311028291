import React, { useState } from 'react';
import { TableBody as TableBodyMui, TableCell, TableRow } from '@mui/material';
import { DEPRECATED, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import TextCell from '../cells/text-cell.react';
import SubtitleCell from '../cells/subtitle-cell.react';
import ThumbnailCell from '../cells/thumbnail-cell.react';
import { CELL_TYPE_ENUM, TEXT_ALIGNMENT } from '../../shared/enums';
import { DataTableCol, DataTableRow } from '../../shared/interfaces';
import useStylesV2 from './styles/table-body-style-v2';
import useStylesV1 from './styles/table-body-style-v1';
import useBaseStyles from './styles/table-body-base-style';

type Props = {
  /**
   * @summary
   * Component Id
   */
  id?: string,
  /**
   * @summary
   * Table rows.
   * @description
   * Each row must have an `id` field.
   */
  rows: DataTableRow[],
  /**
   * @summary
   * Table columns.
   */
  columns: DataTableCol[],
  /**
   * @summary
   * Checkbox table status.
   * @default
   * false
   */
  hasCheckboxSelection?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * row className according to condition
   */
  rowClassName?: (row: DataTableRow) => string | string,
  /**
   * @summary
   * column className according to condition
   */
  columnClassName?: (row: DataTableRow, column: DataTableCol) => string | string,
  /**
   * @summary
   * This function is triggered when row is clicked to check/uncheck.
   * @param isChecked - Checked status (checked/unchecked)
   * @param checkedRow - Checked/unchecked row
   */
  onRowChecked?: (isChecked: boolean, checkedRow: DataTableRow) => void,
  /**
   * @summary
   * This function is triggered when a row is clicked.
   * Usage of this function disables onRowChecked for rows.
   * @param row - Clicked row information
   */
  onRowClick?: (row: DataTableRow) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'hasCheckboxSelection'
   */
  checkboxTable?: DEPRECATED,
};

const TableBody = ({
  id,
  rows,
  columns,
  hasCheckboxSelection = false,
  theme,
  onRowChecked,
  onRowClick,
  rowClassName,
  columnClassName,
}: Props) => {
  const baseStyles = useBaseStyles();
  const stylesV2 = useStylesV2();
  const stylesV1 = useStylesV1();
  const classes = { ...baseStyles, ...(theme === THEME_ENUM.v2 ? stylesV2 : stylesV1) };
  const [tableBodyId] = useState(`tableBody-${ id || Math.random() }`);
  const [firstRowOfTable, setFirstRowOfTable] = useState<HTMLTableRowElement>();

  const renderCustomCell = (row: DataTableRow, column: DataTableCol) => {
    if (!column.renderCell) return;
    return (
      <div
      className={ classes.customCell }
      style={{
        justifyContent: column.textAlignment || TEXT_ALIGNMENT.left,
        paddingRight: column.isSortable ? '2em' : 0,
      }}>
        { column.renderCell(row, column) }
      </div>
    );
  };

  const clickCell = (row: DataTableRow, column: DataTableCol) => {
    if (column.hasPreventBubbling) return;

    if (column.onClick) {
      column.onClick(row[column.field], row);
    } else if (onRowClick) {
      onRowClick?.(row);
    } else if (hasCheckboxSelection) {
      onRowChecked?.(!row.isChecked, row);
    }
  };

  const tableCellAlign = (column: DataTableCol, index: number) => {
    if (!column.isSticky) {
      return {};
    }
    setTimeout(() => {
      const table = document.getElementById(tableBodyId) as HTMLTableSectionElement;
      const tableRow = table.rows[0];
      setFirstRowOfTable(tableRow);
    // tslint:disable-next-line: align
    }, 100);

    if (firstRowOfTable && firstRowOfTable.cells.length > 0) {
      const lengthOfColumn = firstRowOfTable.cells[index]
        ? firstRowOfTable.cells[index].getBoundingClientRect().x
        : 20;
      return { left: lengthOfColumn - 20 };
    }
    return {};
  };

  const tableRowClassName = (row: DataTableRow) => {
    if (rowClassName === undefined) {
      return '';
    }
    if (typeof rowClassName === 'string') {
      return rowClassName;
    }
    return rowClassName(row);
  };

  const tableColumnClassName = (row: DataTableRow, column: DataTableCol) => {
    if (columnClassName === undefined) {
      return '';
    }
    if (typeof columnClassName === 'string') {
      return columnClassName;
    }
    return columnClassName(row, column);
  };

  return (
    <TableBodyMui id={ tableBodyId }>
      {
        rows.map(row =>
          <TableRow
            key={ row.id }
            className={
              `${ classes.tableRow } ${ tableRowClassName(row) }` +
              ` ${ onRowClick || hasCheckboxSelection ? classes.tableRowClickable : ''}` +
              ` ${ row.isChecked ? classes.tableRowSelected : ''}`
            }
          >
            {
              columns.map((column, idx) =>
                <TableCell
                  key={ column.field }
                  style={{
                    ...tableCellAlign(column, idx),
                  }}
                  className={
                    `${ column.isSticky ? classes.stickyColumn : ''}` +
                    ` ${ classes.tableRowCell } ${ tableColumnClassName(row, column) }` +
                    ` ${ column.onClick ? classes.tableCellClickable : ''}` +
                    ` ${ column.field === 'isChecked' ? classes.tableRowCellCheckbox : ''}` +
                    ` ${ (column.type !== CELL_TYPE_ENUM.checkbox && idx === 0) ? classes.tableRowFirstCellPadding : ''}`
                  }
                  scope="row"
                  onClick={ () => clickCell(row, column) }
                >
                  {
                    // Custom rendered cell
                    column.renderCell ? renderCustomCell(row, column) :
                    // Subtitle cell
                    column.type === 'subtitle'
                      ? <SubtitleCell row={ row } column={ column } theme={ theme } />
                      : column.type === 'thumbnail'
                        ? <ThumbnailCell row={ row } column={ column } />
                        : <TextCell row={ row } column={ column } theme={ theme } />
                  }
                </TableCell>,
              )
            }
          </TableRow>,
        )
      }
    </TableBodyMui>
  );
};

export default TableBody;
