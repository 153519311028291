import React from "react";
import { Box } from "@mui/material";
import UsersTable from "../../components/UsersTable";
import UserProfile from "../../components/UserProfile";
import useSession from "../../hooks/useSession";
import UserForm from "../../components/UserForm";
import ContentModal from "../../components/Modals/ContentModal";
import { useDomains } from "../../hooks/useDomains";
import { User, UserPermissionPick } from "../../api/types";
import useUsers from "../../hooks/useUsers";
import { usePublisherContext } from "../../hooks/usePublisherProvider";
import LoadingCircle from "../../components/LoadingCircle";
import useSnackbar from "../../hooks/useSnackbar";
import useAPI from "../../api/useAPI";

type UserManagementProps = {
  slug: string;
  isAdmin: boolean
  user: UserPermissionPick[]
};

export default function UserManagement({
  slug,
  isAdmin,
  user: userList,
}: UserManagementProps) {
  const padding = "20px";
  const session = useSession();
  const username = session?.getAccessToken().decodePayload().username;
  const groups = session?.getAccessToken().decodePayload()["cognito:groups"];
  const { userProfile } = useAPI();
  const { publisherSlug } = usePublisherContext(isAdmin);
  const publisherQuery = {
    ...(isAdmin && { slug: publisherSlug || slug }),
  };
  const { users, query, api } = useUsers({
    ...publisherQuery,
  });

  const [userFormModalIsOpen, setUserFormModalIsOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<User>({
    username: "",
  });
  const [userFormSubmitButtonEnabled, setUserFormSubmitButtonEnabled] = React.useState(true);
  const [userFormCancelButtonEnabled, setUserFormCancelButtonEnabled] = React.useState(true);

  const { domains, query: domainsQuery } = useDomains({
    query: { ...publisherQuery },
  });

  const { snackbarError, snackbarSuccess } = useSnackbar();

  if (!query.isFetched || !userProfile) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - var(--header-height)))",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingCircle />
      </div>
    );
  }
  const userRole = userList?.find((user) => user?.username === username)?.role;
  const user = {
    username,
    role: userRole,
    ...userProfile.attributes,
    groups,
  };
  return (
    <Box padding={padding}>
      {domainsQuery.isFetched && (
        <ContentModal
          isOpen={userFormModalIsOpen}
          title={`${
            selectedUser.username.length
              ? "Edit Account Profile"
              : "Invite User"
          }`}
          elementClassName="modal-edit-user"
          content={(
            <UserForm
              user={selectedUser}
              domains={domains?.length && domains?.map((d) => d.domain)}
              submit={async (newUser) => {
                setUserFormSubmitButtonEnabled(false);
                setUserFormCancelButtonEnabled(false);
                if (newUser.username.length) {
                  const update = {
                    name: newUser.name,
                    username: newUser.username,
                    email: newUser.email,
                    domains: newUser.domains,
                    role: newUser.role,
                    verify_email: newUser.email !== selectedUser.email,
                    groups: selectedUser.groups || [],
                  };
                  api.update(
                    { username: selectedUser.username, data: update },
                    {
                      onSuccess: () => {
                        /* Maybe add a loader or something while the table updates?
                        We can use the mutation states to see if it's still loading */
                        snackbarSuccess("User Updated");
                        setUserFormModalIsOpen(false);
                        setUserFormSubmitButtonEnabled(true);
                        setUserFormCancelButtonEnabled(true);
                      },
                      onError: (e) => {
                        console.error(e);
                        snackbarError(
                          "Error while updating user. Check the console for more information",
                        );
                        setUserFormSubmitButtonEnabled(true);
                        setUserFormCancelButtonEnabled(true);
                      },
                    },
                  );
                } else {
                  const userInvite = { ...newUser, username: newUser.email, slug: publisherSlug };
                  api.invite(userInvite, {
                    onSuccess: () => {
                      /* Maybe add a loader or something while the table updates?
                      We can use the mutation states to see if it's still loading */
                      snackbarSuccess("User Invited");
                      setUserFormModalIsOpen(false);
                      setUserFormSubmitButtonEnabled(true);
                      setUserFormCancelButtonEnabled(true);
                    },
                    onError: (e) => {
                      console.error(e);
                      snackbarError(
                        "Error while inviting user. Check the console for more information",
                      );
                      setUserFormSubmitButtonEnabled(true);
                      setUserFormCancelButtonEnabled(true);
                    },
                  });
                }
              }}
              cancelButtonEnabled={userFormCancelButtonEnabled}
              changeCancelButtonState={setUserFormCancelButtonEnabled}
              submitButtonEnabled={userFormSubmitButtonEnabled}
              changeSubmitButtonState={setUserFormSubmitButtonEnabled}
              close={() => setUserFormModalIsOpen(false)}
            />
          )}
          onClose={() => setUserFormModalIsOpen(false)}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft="15px"
      >
        <UserProfile
          user={user}
          show_edit
          setSelectedUser={setSelectedUser}
          setUserFormModalIsOpen={setUserFormModalIsOpen}
        />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <UsersTable
          users={users}
          setSelectedUser={setSelectedUser}
          setUserFormModalIsOpen={setUserFormModalIsOpen}
        />
      </Box>
    </Box>
  );
}
