import React, { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Sidebar, Header } from "../components";
import tracker from "../helpers/tracker";

export default function Template({
  children,
  asAdmin,
  isPublisherAdmin,
}: PropsWithChildren & { asAdmin: boolean; isPublisherAdmin: boolean }) {
  const location = useLocation();
  React.useEffect(() => {
    tracker.track_pageview();
  }, [location]);
  return (
    <>
      <Header asAdmin={asAdmin} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "260px calc(100vw - 260px)",
          overflow: "hidden",
        }}
      >
        <Sidebar isPublisherAdmin={isPublisherAdmin} />
        {children}
      </Box>
    </>
  );
}
