import { useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../api/useAPI";
import useSnackbar from "./useSnackbar";
import { User } from "../api/types";

export default function useUser(username: string, initialData?: any) {
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const query = useQuery({
    queryKey: ["users", username],
    queryFn: () =>
      username && api.readUser(username).then((data) => data.publisher),
    initialData,
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: username && didLoad,
  });

  const updateMutation = useMutation({
    mutationFn: (data: Partial<User>) => api.Media.update(username, data),
    onSuccess: ({ user }: { user: User }) => {
      snackbarSuccess("Updated User");
      queryClient.setQueryData(["users", username], user);
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (e) => {
      console.error(e);
      snackbarError(
        "Error while updating the user. Check the console for more information."
      );
    },
  });

  return {
    ...query,
    slug: query?.data?.slug,
    group: query?.data?.group,
    user: query?.data?.users,
    api: {
      update: updateMutation.mutate,
    },
    mutations: {
      updateMutation,
    },
  };
}
