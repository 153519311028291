import React, { useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import KrgDataTable from "@kargo/shared-components.krg-data-table";
import KrgChip from "@kargo/shared-components.krg-chip";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { Video } from "../../api/types";
import VideoModal from "../Modals/VideoModal";
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_THUMBNAIL_URL,
} from "../../constants/constants";
import { formatDuration } from "../../helpers/videos";

dayjs.extend(duration);

const useStyles = makeStyles({
  table: {
    "& thead th": {
      background: "transparent",
      color: "black",
      fontSize: "11px",
      fontWeight: 600,
    },
    "& tbody": {
      borderTop: "2px solid black",
      borderBottom: "2px solid black",
      "& tr:hover": {
        background: "#EEF3F7",
      },
    },
  },
});

const chipStyle = {
  margin: "2px",
  backgroundColor: "#e5eaef",
  padding: "7px 11px",
  border: "none",
};
interface VideosTableProps {
  videos: Video[];
  selected?: string[];
  onVideoToggle?: (video: string, isSelected: boolean) => void;
  onToggleAll?: (checked: boolean) => void;
}

const videoTagChips = (tags: string[]) => (
  <>
    {tags.map((tag) => (
      <KrgChip
        key={tag}
        style={chipStyle}
        theme={KrgChip.THEME_ENUM.v2}
        type={KrgChip.TYPE_ENUM.transparent}
        data={{ text: tag, id: tag }}
      />
    ))}
  </>
);
export default function VideosTable({
  videos,
  selected = [],
  onVideoToggle,
  onToggleAll,
}: VideosTableProps) {
  const classes = useStyles();

  // State for the modal video index
  const [modalVideoIndex, setModalVideoIndex] = useState(-1);

  // Check if all videos are selected
  const isAllChecked = videos.every((video) => selected.includes(video.token));

  // Retrieve the currently selected video for the modal
  const modalVideo = videos[modalVideoIndex];

  // Total number of videos
  const totalVideos = videos.length;

  return (
    <>
      {/* Video Modal for viewing details of a video */}
      <VideoModal
        video={modalVideo?.token}
        isOpen={modalVideoIndex > -1}
        onClose={() => setModalVideoIndex(-1)}
        onNavigatePrev={() =>
          setModalVideoIndex((modalVideoIndex - 1 + totalVideos) % totalVideos)
        }
        onNavigateNext={() =>
          setModalVideoIndex((modalVideoIndex + 1) % totalVideos)
        }
      />

      <Box sx={{ border: "1px solid #91A0AD" }}>
        {/* Data Table to display videos */}
        <KrgDataTable
          theme={KrgDataTable.THEME_ENUM.v2}
          className={classes.table}
          config={{
            pagination: {
              initialPageSize: DEFAULT_PAGINATION_LIMIT,
            },
            data: {
              columns: [
                {
                  type: KrgDataTable.CELL_TYPE_ENUM.checkbox,
                  headerName: "",
                  field: "isChecked",
                  renderHeader: () => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={isAllChecked}
                      onToggle={onToggleAll}
                    />
                  ),
                  renderCell: (row) => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={row.isChecked}
                      onToggle={(isChecked) =>
                        onVideoToggle?.(row.token, isChecked)
                      }
                    />
                  ),
                },
                {
                  headerName: "Thumbnail",
                  type: KrgDataTable.CELL_TYPE_ENUM.thumbnail,
                  field: "thumbnail",
                  renderCell: (row) => (
                    <img
                      src={row.thumbnail}
                      alt={row.title}
                      style={{ width: "70px", height: "40px" }}
                    />
                  ),
                  onClick: (_, row) => setModalVideoIndex(row.index),
                  width: "130px",
                },
                {
                  headerName: "Video Title",
                  type: KrgDataTable.CELL_TYPE_ENUM.subtitle,
                  field: "video_title",
                  onClick: (_, row) => setModalVideoIndex(row.index),
                  width: "450px",
                },
                {
                  headerName: "Category",
                  field: "category",
                  width: "130px",
                },
                {
                  headerName: "Tags",
                  field: "tags",
                  width: "300px",
                  renderCell: (row) => {
                    const [visibleTags, extraTags] = [
                      row.tags?.slice(0, 2),
                      row.tags?.slice(2),
                    ];

                    return (
                      <>
                        {videoTagChips(visibleTags)}
                        {extraTags.length > 0 && (
                          <KrgTooltip
                            theme={KrgTooltip.THEME_ENUM.v2}
                            tooltip={videoTagChips(extraTags)}
                            placement="top"
                            arrow
                          >
                            <KrgChip
                              style={chipStyle}
                              theme={KrgChip.THEME_ENUM.v2}
                              type={KrgChip.TYPE_ENUM.transparent}
                              data={{ text: "...", id: "extra" }}
                            />
                          </KrgTooltip>
                        )}
                      </>
                    );
                  },
                },
                {
                  headerName: "Publish Date",
                  field: "publish_date",
                  width: "120px",
                },
                {
                  headerName: "Duration",
                  field: "duration",
                  width: "110px",
                },
                {
                  headerName: "Status",
                  field: "state",
                  width: "100px",
                },
                {
                  headerName: "Actions",
                  field: "actions",
                  width: "120px",
                },
              ],
              rows: videos.map((video, index) => ({
                ...video,
                thumbnail:
                  video.default_stream?.image_url || DEFAULT_THUMBNAIL_URL,
                video_title: {
                  title: video.title,
                },
                publish_date: dayjs(video.created_at).format("MM/DD/YYYY"),
                duration: formatDuration(
                  video.local?.duration ||
                    video.media_info?.duration ||
                    video.default_stream?.media_info?.duration ||
                    0,
                ),
                isChecked: selected.includes(video.token),
                index,
              })),
            },
          }}
        />
      </Box>
    </>
  );
}
