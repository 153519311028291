import { PopperProps } from '@mui/material';

export const getTablePopperProps = (): Partial<PopperProps> => {
  return {
    style: { pointerEvents: 'none' },
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, -3],
        },
      }],
    },
  };
};
