import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { IUserSession } from '../api/types';

export default () => {
  const [userSession, setUserSession] = useState<IUserSession>();
  const fetchSession = async () => {
    const userAttributes = await Auth.currentUserInfo();
    setUserSession(userAttributes);
  };

  useEffect(() => {
    fetchSession(); // initially fetch the session
    const intervalId = setInterval(fetchSession, 60 * 1000); // check every minute

    // clean up on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return userSession;
};
