import {
  ChangeEvent,
  ClipboardEvent,
  DetailedHTMLProps,
  FocusEvent,
  FormEventHandler,
  KeyboardEvent,
  MouseEvent,
  DragEvent,
} from 'react';

import {
  CREATIVE_DESTINATION_ENUM,
  CREATIVE_EDITOR_ENUM,
  CREATIVE_FORMAT_ENUM,
  CREATIVE_SOCIAL_TYPE_ENUM,
} from './enums';

export interface CreativeFormat {
  type: CREATIVE_FORMAT_ENUM;
  socialType: CREATIVE_SOCIAL_TYPE_ENUM;
  title: string;
  resolution: string;
  cpc: boolean;
  viewability: boolean;
  engagement: boolean;
  destinationUrls: CREATIVE_DESTINATION_ENUM[];
  templateId: string;
  creativeEditor: CREATIVE_EDITOR_ENUM;
  creativeDimensions?: CreativeDimension[];
}

export interface CreativeDimension {
  label: string;
  width: number;
  height: number;
  isCollapsed?: boolean;
}

export interface CreativeFormats {
  [key: number]: CreativeFormat;
}

export interface Events extends DetailedHTMLProps<any, any> {
  // Focus Events
  onFocus?: (e: FocusEvent<any>) => void;
  onFocusCapture?: (e: FocusEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  onBlurCapture?: (e: FocusEvent<any>) => void;

  // Form Events
  onChange?: (e: ChangeEvent<any> | any, value?: any) => void;
  onChangeCapture?: FormEventHandler<any>;
  onBeforeInput?: FormEventHandler<any>;
  onBeforeInputCapture?: FormEventHandler<any>;
  onInput?: FormEventHandler<any>;
  onInputCapture?: FormEventHandler<any>;
  onReset?: FormEventHandler<any>;
  onResetCapture?: FormEventHandler<any>;
  onSubmit?: FormEventHandler<any>;
  onSubmitCapture?: FormEventHandler<any>;
  onInvalid?: FormEventHandler<any>;
  onInvalidCapture?: FormEventHandler<any>;

  // Keyboard Events
  onKeyDown?: (e: KeyboardEvent<any>) => void;
  onKeyDownCapture?: (e: KeyboardEvent<any>) => void;
  onKeyPress?: (e: KeyboardEvent<any>) => void;
  onKeyPressCapture?: (e: KeyboardEvent<any>) => void;
  onKeyUp?: (e: KeyboardEvent<any>) => void;
  onKeyUpCapture?: (e: KeyboardEvent<any>) => void;

  // MouseEvents
  onAuxClick?: (e: MouseEvent<any> | any) => void;
  onAuxClickCapture?: (e: MouseEvent<any> | any) => void;
  onClick?: (e: MouseEvent<any> | any) => void;
  onClickCapture?: (e: MouseEvent<any> | any) => void;
  onContextMenu?: (e: MouseEvent<any> | any) => void;
  onContextMenuCapture?: (e: MouseEvent<any> | any) => void;
  onDoubleClick?: (e: MouseEvent<any> | any) => void;
  onDoubleClickCapture?: (e: MouseEvent<any> | any) => void;
  onDrag?: (e: DragEvent<any>) => void;
  onDragCapture?: (e: DragEvent<any>) => void;
  onDragEnd?: (e: DragEvent<any>) => void;
  onDragEndCapture?: (e: DragEvent<any>) => void;
  onDragEnter?: (e: DragEvent<any>) => void;
  onDragEnterCapture?: (e: DragEvent<any>) => void;
  onDragExit?: (e: DragEvent<any>) => void;
  onDragExitCapture?: (e: DragEvent<any>) => void;
  onDragLeave?: (e: DragEvent<any>) => void;
  onDragLeaveCapture?: (e: DragEvent<any>) => void;
  onDragOver?: (e: DragEvent<any>) => void;
  onDragOverCapture?: (e: DragEvent<any>) => void;
  onDragStart?: (e: DragEvent<any>) => void;
  onDragStartCapture?: (e: DragEvent<any>) => void;
  onDrop?: (e: DragEvent<any>) => void;
  onDropCapture?: (e: DragEvent<any>) => void;
  onMouseDown?: (e: MouseEvent<any> | any) => void;
  onMouseDownCapture?: (e: MouseEvent<any> | any) => void;
  onMouseEnter?: (e: MouseEvent<any> | any) => void;
  onMouseLeave?: (e: MouseEvent<any> | any) => void;
  onMouseMove?: (e: MouseEvent<any> | any) => void;
  onMouseMoveCapture?: (e: MouseEvent<any> | any) => void;
  onMouseOut?: (e: MouseEvent<any> | any) => void;
  onMouseOutCapture?: (e: MouseEvent<any> | any) => void;
  onMouseOver?: (e: MouseEvent<any> | any) => void;
  onMouseOverCapture?: (e: MouseEvent<any> | any) => void;
  onMouseUp?: (e: MouseEvent<any> | any) => void;
  onMouseUpCapture?: (e: MouseEvent<any> | any) => void;

  // Clipboard Events
  onCopy?: (e: ClipboardEvent<any> | any) => void;
  onCopyCapture?: (e: ClipboardEvent<any> | any) => void;
  onCut?: (e: ClipboardEvent<any> | any) => void;
  onCutCapture?: (e: ClipboardEvent<any> | any) => void;
  onPaste?: (e: ClipboardEvent<any> | any) => void;
  onPasteCapture?: (e: ClipboardEvent<any> | any) => void;
}
