import { useCallback } from "react";
import {
  useMutation, useQuery, useQueryClient,
} from "react-query";
import useAPI from "../api/useAPI";
import { PublisherQuery, CreatePublisherInput } from "../api/types";

export function usePublishers(inputQuery: PublisherQuery = {}) {
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const fetchPublishers = useCallback(
    () => api && api.getPublishers(inputQuery),
    [api, inputQuery],
  );
  const query = useQuery(["publishers", inputQuery], () => fetchPublishers(), {
    enabled: didLoad,
    staleTime: Infinity,
  });
  const createNewPublisherMutation = useMutation({
    mutationFn: (data: CreatePublisherInput) => api.createPublisher(data),
  });

  return {
    query,
    queryClient,
    createNewPublisherMutation,
    publishers: query.data?.users || [],
  };
}
