import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';

export type Props = {
  isMenuOpen: boolean;
};

const useStylesV1 = makeStyles({
  /* Inner Container  */
  innerContainerText: { /* not apply */ },
  moreIcon: { /* not apply */ },
  smallInnerContainerText: {
    fontSize: '0.8em',
  },
  mediumInnerContainerText: {
    fontSize: '0.8em',
  },
  largeInnerContainerText: {
    fontSize: FONT.SIZE.EXTRA_LARGE,
  },
  xLargeInnerContainerText: {
    fontSize: FONT.SIZE.EXTRA_LARGE,
  },
  /* Menu */
  paper: {
    borderRadius: 0,
    background: COLORS.WHITE,
    border: `1px solid ${COLORS.PRIMARY_500_ACCENT}`,
    boxShadow: `0 0.25em 0.25em ${COLORS.BLACK_SHADOW}`,
    fontSize: FONT.SIZE.BASE,
  },
  listItem: {
    '&:hover': {
      backgroundColor: COLORS.PRIMARY_50,
      color: COLORS.PRIMARY_500_ACCENT,
      '& .MuiSvgIcon-root': {
        fill: COLORS.PRIMARY_500_ACCENT,
      },
    },
  },
});

export default useStylesV1;
