import React from 'react';
import useStyles from './thumbnail-cell.style';
import { DataTableRow } from './../../shared/interfaces';
import { DataTableCol } from '../../shared/interfaces';

type Props = {
  /**
   * @summary
   * Row of button for onClick function
   */
  row: DataTableRow,
  /**
   * @summary
   * Table column
   */
  column: DataTableCol,
};

const ThumbnailCell = ({
  row,
  column,
}: Props) => {
  const classes = useStyles();
  const thumbnails = row[column.field];

  return (
    <div className={classes.cell}>
      {
        thumbnails.slice(0, 6).map((thumbnail: string, idx: number) =>
          <div className={classes.container} key={idx}>
            <img className={classes.thumbnail} src={thumbnail} alt="thumbnail" />
          </div>,
        )
      }
    </div>
  );
};

export default ThumbnailCell;
