import { makeStyles } from '@mui/styles';
import { FONT, COLORS, INPUT_VARIANT_ENUM as VARIANT_ENUM } from '@kargo/shared-components.krg-shared';
import { SIZE_ENUM } from '../shared/enums';

export type Props = {
  variant?: VARIANT_ENUM,
  size?: SIZE_ENUM
  isFullWidth?: boolean,
  isEnabled?: boolean,
  isRounded? : boolean,
};

const useBaseStyles = makeStyles({
  label: {
    fontSize: '0.9em',
    '&.Mui-error': {
      color: COLORS.ERROR_DARK,
      '&.MuiInputLabel-shrink.Mui-focused': {
        color: COLORS.ERROR_DARK,
      },
    },
  },
  labelOutlined: {
    marginTop: '-0.47em',
    '&.MuiInputLabel-filled.MuiInputLabel-shrink' : {
      marginTop: '-0.47em',
    },
    '&.MuiInputLabel-shrink': {
      marginTop: '0',
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25em',
    width: ({ isFullWidth }: Props) => isFullWidth ? '100%' : '15.625em',
    borderRadius: 0,
    padding: 0,
    color: COLORS.BLACK_HIGH_EMPHASIS,
    opacity: ({ isEnabled }: Props) => isEnabled ? 1 : 0.6,
    '& .MuiSelect-root': {
      fontSize: '0.9em',
    },
    '&.Mui-focused > .MuiSelect-select': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-icon': {
      color: COLORS.GRAY_DARK,
    },
    '&.Mui-error': {
      '& .MuiSvgIcon-root': {
        color: COLORS.ERROR_DARK,
      },
    },
  },
  helperText: {
    margin: '0.25em 0.0625em',
    fontSize: FONT.SIZE.MEDIUM,
    '&.Mui-error': {
      color: COLORS.ERROR_DARK,
    },
  },
  selectOutlined: {
    '& .MuiSelect-root': {
      padding: '0.5em 0.8em',
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.ERROR_DARK,
      },
    },
    '&:hover': {
      borderColor: COLORS.GRAY_DARK,
    },
  },
  selectStandard: {
    marginTop: '0 !important',
    '& .MuiFormLabel-root' : {
      marginTop: '0',
    },
    '& .MuiSelect-root': {
      padding: '0.7em 0',
    },
    '&.MuiInput-underline:before': {
      borderBottomColor: COLORS.GRAY_DARK,
    },
    '&.MuiInput-underline:hover:before': {
      borderBottom: `0.0625em solid ${COLORS.GRAY_DARK}`,
      opacity: 0.4,
    },
    '&.Mui-error': {
      '&.MuiInput-underline:before': {
        borderBottomColor: COLORS.ERROR_DARK,
      },
      '&.MuiInput-underline:after': {
        borderBottomColor: COLORS.ERROR_DARK,
      },
    },
  },
  menu: {
    marginTop: ({ variant }: Props) => variant === VARIANT_ENUM.outlined ? '-0.25em' : '0.0625em',
    borderRadius: '0.25em',
    paddingBottom: '0.5em',
    boxShadow: `0 0.25em 0.25em ${COLORS.BLACK_SHADOW}`,
    '& .MuiList-root': {
      minWidth: '10.625em',
      width: 'auto',
      padding: 0,
      maxHeight: '18.75em',
      background: COLORS.WHITE,
      overflow: 'hidden auto',
    },
    '& .MuiListItem-root': {
      fontSize: '0.9em',
    },
  },
  selectAll: {
    '&.MuiMenuItem-root': {
      width: '40%',
      display: 'inline-flex',
      margin: '1em 0 0.5em 0',
      backgroundColor: 'white',
      fontWeight: 500,
    },
    '&:hover.MuiMenuItem-root': {
      backgroundColor: 'white',
    },
  },
});

export default useBaseStyles;
