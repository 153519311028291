export function isUrlValid(str: string) {
  const regex = /^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+(\/[\w-.~%]*)*(\?[\w-.~%&=]*)?(#[\w-.~%]*)?(\/[\w-.~%]*\*)?\/?$|^(\/[\w-.~%]+)*(\/[\w-.~%]*\*)?(\?[\w-.~%&=]*)?(#[\w-.~%]*)?$/;
  return regex.test(str);
}

/**
 *
 * @param url to be normalized, removing prefix, domain and any unecessary '/'
 * @returns a clean path from url
 */
export function normalizeUrl(url: string) {
  return url.replace(/^(https?:\/\/)?(www\.)?[^/?]+/, '').replace(/\/+$/, '').replace(/^\/+/, '');
}

/**
 *
 * @param url string to be verified
 * @param domain string to be compared with
 * @returns a boolean value indicated if both string has the same domain
 */
export function isSameDomain(url: string, domain: string): boolean {
  // Remove the protocol (http or https) e 'www.' se presente
  const cleanedUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
  const urlParts = cleanedUrl.split('/');
  const urlDomain = urlParts[0].includes('?') ? '' : urlParts[0];
  const cleanedDomain = domain.replace(/^(https?:\/\/)?(www\.)?/, '');
  return urlDomain.trim() ? urlDomain === cleanedDomain : true;
}

export function removePrefix(url) {
  const regex = /^(https?:\/\/)?(www\.)?/;
  return url.replace(regex, '');
}
