import { BaseJsonSchema, createArraySchemaByItemType, createSchemaByBasicTypes } from '../../helpers';
import { IPriceGranularity } from './priceGranularity';
import { ISchain } from './schain';

export const IPrebidConfig: BaseJsonSchema = {
  type: 'object',
  properties: {
    schain: ISchain,
    priceGranularity: IPriceGranularity,
  },
};

export const IPrebidGlobals: BaseJsonSchema = {
  type: 'object',
  properties: {
    methods: createSchemaByBasicTypes('string'),
    args: createArraySchemaByItemType(undefined, [
      {
        type: ['object', 'boolean'],
        additionalProperties: {
          type: ['number', 'string', 'boolean', 'array', 'object', 'null'],
          description: 'no type declaration on this property, you are adding a new one',
        },
      },
    ]),
  },
};
