import React, { ReactNode, MouseEvent } from 'react';
import { DateFormatter, DateRange } from 'react-day-picker';
import { isValid, parse, isBefore } from 'date-fns';

// Format top day of the week text to one letter
export const formatWeekdayName: DateFormatter = weekNumber =>
  weekNumber.toLocaleString('en-us', { weekday: 'narrow' });

// Builds the icon box for the input
export const getIcon = (
  icon: ReactNode,
  className: string,
  isEnabled: boolean,
  onClick: (e: MouseEvent<HTMLDivElement>) => void) =>
  <div
    className={isEnabled ? className : undefined}
    onClick={isEnabled ? onClick : undefined}
  >
    {icon}
  </div>;

// Formats date string into a date object
export const getDateFromText = (dateText: string) =>
  parse(dateText, 'P', new Date());

// Formats date range string ('<mm/dd/yy> - <mm/dd/yy>') into a DateRange
export const getDateRangeFromText = (dateRangeText: string): DateRange => {
  const dateParts = dateRangeText?.split('-');

  return {
    from: getDateFromText(dateParts[0].trim()),
    to: getDateFromText(dateParts[1].trim()),
  };
};

// Returns 'true' if date (string) is valid (if date has value) to be parsed as date
export const isValidDate = (dateText?: string) => {
  let ret = true;
  if (dateText) {
    const date = getDateFromText(dateText);
    ret = isValid(date);
    ret = ret && date.getFullYear().toString().length === 4;
  }
  return ret;
};

// Returns 'true' if date range ('<mm/dd/yy> - <mm/dd/yy>') is valid parseable DateRange
export const isValidDateRange = (dateRangeText?: string) => {
  let ret = !dateRangeText;
  const dateParts = dateRangeText?.split('-');

  // Check format
  if (dateRangeText && dateParts?.length === 2) {
    const from = dateParts[0].trim();
    const to = dateParts[1].trim();

    // Check if values are correct
    if (!!from === !!to) {
      ret = isValidDate(from);
      ret = ret && isValidDate(to);

      // Check if 'from' is before 'to'
      if (ret && from && to) {
        ret = isBefore(getDateFromText(from), getDateFromText(to));
      }
    }
    if (from === to) {
      ret = true;
    }
  }
  return ret;
};
