import React, { ComponentProps } from "react";
import {
  CheckCircle,
  Delete,
  Error,
  Pending,
  Sort,
  Sync,
  SyncDisabled,
} from "@mui/icons-material";
import VideosAccordion from "./VideosAccordion";
import { MRSSFeed } from "../../api/types";
import useMRSSFeeds from "../../hooks/useMRSSFeeds";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import SortVideosModal from "../../components/Modals/SortVideosModal";

type Props = Pick<
  ComponentProps<typeof VideosAccordion>,
  "selected" | "onChangeSelected" | "query"
> & {
  feed: MRSSFeed;
};

// TODO: Look into helper function for nested ternary.
/* eslint-disable no-nested-ternary */
export function FeedState({ feed }: { feed: MRSSFeed }) {
  return !feed.active ? (
    <>
      <SyncDisabled fontSize="small" /> Disconnected
    </>
  ) : !feed.healthy ? (
    <>
      <Error fontSize="small" color="warning" /> Unhealthy
    </>
  ) : feed.running ? (
    <>
      <Pending fontSize="small" color="primary" /> Running
    </>
  ) : Date.now() - new Date(feed.updated_at).getTime() < 24 * 60 * 60 * 1000 ? (
    <>
      <CheckCircle fontSize="small" color="success" /> Updated
    </>
  ) : (
    ""
  );
}
/* eslint-enable no-nested-ternary */

export default function FeedAccordion({
  feed,
  onChangeSelected,
  query,
  selected,
}: Props) {
  const [sortQuery, setSortQuery] = React.useState({});
  const [showSortingModal, setShowSortingModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [shouldConfirmDisconnect, setShouldConfirmDisconnect] =
    React.useState(false);
  const { api } = useMRSSFeeds({});
  return (
    <>
      <SortVideosModal
        isOpen={showSortingModal}
        onClose={() => setShowSortingModal(false)}
        onSubmit={(s) => {
          if (s === "only_recent") {
            setSortQuery({
              created_at: { $gt: Date.now() - 30 * 24 * 60 * 60 * 1000 },
            });
          } else setSortQuery({});
        }}
      />
      <ConfirmModal
        isOpen={shouldConfirmDisconnect}
        header="Are you sure you want to disconnect the MRSS Feed Link?"
        body="This will stop new videos from being uploaded into the Library."
        submitButtonText="Disconnect"
        onClose={() => setShouldConfirmDisconnect(false)}
        onSubmitClick={() => {
          api.update({
            token: feed.token,
            data: {
              ...feed,
              active: false,
            },
          });
          setShouldConfirmDisconnect(false);
        }}
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        header="Are you sure you want to delete the MRSS Feed?"
        submitButtonText="Delete MRSS Feed"
        onClose={() => setShowDeleteModal(false)}
        onSubmitClick={() => {
          api.delete({ token: feed.token });
          setShowDeleteModal(false);
        }}
      />
      <VideosAccordion
        menuButtonProps={{
          items: [
            {
              key: "sync",
              inner: feed.active ? (
                <>
                  <SyncDisabled />
                  Disconnect MRSS Feed Link
                </>
              ) : (
                <>
                  <Sync />
                  Connect MRSS Feed Link
                </>
              ),
              onClick: () => {
                if (feed.active) setShouldConfirmDisconnect(true);
                else {
                  api.update({
                    token: feed.token,
                    data: {
                      ...feed,
                      active: false,
                    },
                  });
                }
              },
            },
            {
              key: "sorting",
              inner: (
                <>
                  <Sort />
                  Change Sort Videos
                </>
              ),
              onClick: () => {
                setShowSortingModal(true);
              },
            },
            {
              key: "deleting",
              inner: (
                <>
                  <Delete />
                  Delete MRSS Feed Link
                </>
              ),
              onClick: () => {
                setShowDeleteModal(true);
              },
            },
          ],
        }}
        title={`MRSS Feed: ${feed.feed_url}`}
        subtitle={feed.token ? `Feed Token: ${feed.token}` : ""}
        query={{
          ...query,
          ...sortQuery,
          imported_from: feed.token,
        }}
        state={<FeedState feed={feed} />}
        selected={selected}
        onChangeSelected={onChangeSelected}
      />
    </>
  );
}
