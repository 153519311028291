import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { KrgProvider } from '@kargo/shared-components.krg-shared';
import theme from './theme';
import { SnackbarProvider } from './hooks/useSnackbar';
import { UserPublisherProvider } from './hooks/usePublisherProvider';
import awsconfig from './aws-exports';
import Router from './routes/router';
import { UserAPIProvider } from './api/context';
import './styles.scss';
import Authenticator from './components/Authenticator';

let loadedConfig = awsconfig;
if (process.env.COGNITO_CLIENT_ID) {
  loadedConfig = {
    Auth: {
      userPoolId: process.env.COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
    },
  };
}
Amplify.configure(loadedConfig);
const queryClient = new QueryClient();

export function App() {
  return (
    <KrgProvider theme={theme}>
      <Authenticator>
        <UserAPIProvider>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
              <UserPublisherProvider>
                <Router />
              </UserPublisherProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </UserAPIProvider>
      </Authenticator>
    </KrgProvider>
  );
}
