import { createTheme } from '@mui/material';

export default createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
    palette: {
        success: {
            main: '#00947E'
        }
    }
});
