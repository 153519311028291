import { makeStyles } from '@mui/styles';
import { FONT } from '@kargo/shared-components.krg-shared';
import { SCRIM_ENUM, STATUS_ENUM } from '../shared/enums';

export type Props = {
  scrim?: SCRIM_ENUM;
  headerIconType?: STATUS_ENUM,
};

const useBaseStyles = makeStyles({
  dialogContainer: {
    fontSize: FONT.SIZE.BASE,
  },
  dialogPaper: {
    minWidth: '25.5em',
    borderRadius: '0.125em',
  },
  closeActionButton: {
    cursor: 'pointer',
  },
});

export default useBaseStyles;
