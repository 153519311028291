import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KrgModal from '@kargo/shared-components.krg-modal';
import {
  Download, Edit, NavigateBefore, NavigateNext,
} from '@mui/icons-material';
import KrgTextInput from '@kargo/shared-components.krg-text-input';
import Menu from '../Menu';
import useVideo from '../../hooks/useVideo';
import { VideoPlayer } from '../VideoPlayer';
import { getDisplayTitle } from '../../helpers/videos';
import useSnackbar from '../../hooks/useSnackbar';
import TrackedIconButton from '../TrackedButton/TrackedIconButton';

const useStyles = makeStyles({
  modal: {
    '& .MuiDialog-container .MuiDialog-paper': {
      borderRadius: '10px',
      padding: 0,
      width: '531px',
      '& .MuiDialogActions-root': {
        padding: '10px',
        '& svg': {
          fontSize: '20px',
          color: '#303237',
        },
      },
    },
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    height: '100% !important',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '402px',
  },
  arrow: {
    padding: 0,
    '& svg': {
      color: 'black',
      fontSize: '50px',
    },
  },
});

type InnerProps = { video: string, onNavigateNext: () => void, onNavigatePrev: () => void };
function Inner({ video: token, onNavigateNext, onNavigatePrev }: InnerProps) {
  const { snackbarError } = useSnackbar();
  const { video, api } = useVideo(token);
  const classes = useStyles();
  const [isEditingTitle, setIsEditingTitle] = React.useState(false);
  const [title, setTitle] = React.useState(video?.title);
  const handleEditFinished = () => {
    if (title !== video?.title) api.update({ title });
    setIsEditingTitle(false);
  };
  const handleEditCanceled = () => {
    setTitle(video?.title);
    setIsEditingTitle(false);
  };
  return (
    <Box className={classes.inner}>
      <TrackedIconButton id="navigate-to-prev-video" className={classes.arrow} onClick={onNavigatePrev} disableRipple>
        <NavigateBefore />
      </TrackedIconButton>
      <Box width="100%" height="100%">
        <Box display="flex" alignItems="center" sx={{ padding: '10px 0' }}>
          { isEditingTitle ? (
            <KrgTextInput
              style={{ height: 'auto' }}
              isFullWidth
              theme={KrgTextInput.THEME_ENUM.v2}
              label="Video Title"
              text={title}
              debounceTime={0}
              onDebounceEnd={setTitle}
              onTextChange={setTitle}
              onBlur={async () => handleEditFinished()}
              onKeyDown={({ key }) => {
                if (key === 'Enter') handleEditFinished();
                else if (key === 'Escape') handleEditCanceled();
              }}
            />
          ) : <Typography className={classes.title}>{getDisplayTitle(video)}</Typography>}
          <Menu menuItems={[{
            name: 'Edit',
            icon: <Edit />,
            isEnabled: true,
            onClick: () => {
              setTitle(video?.title);
              setIsEditingTitle(true);
            },
          }, {
            name: 'Download',
            icon: <Download />,
            isEnabled: true,
            onClick: () => {
              if (!video?.default_stream?.url) {
                snackbarError('Video not available for download yet.');
                return;
              }
              window.open(`${video.default_stream.url}?download=true`, '_self');
            },
          }]}
          />
        </Box>
        <VideoPlayer video={token} />
      </Box>
      <TrackedIconButton id="navigate-to-next-video" className={classes.arrow} onClick={onNavigateNext} disableRipple>
        <NavigateNext />
      </TrackedIconButton>
    </Box>
  );
}

type Props = InnerProps & { isOpen: boolean, onClose: () => void };
export default function VideoModal({
  video, isOpen, onClose, onNavigateNext, onNavigatePrev,
}: Props) {
  const classes = useStyles();
  return (
    <KrgModal
      className={classes.modal}
      theme={KrgModal.THEME_ENUM.v2}
      isOpen={isOpen}
      onClose={onClose}
      inputContainer={
        <Inner video={video} onNavigateNext={onNavigateNext} onNavigatePrev={onNavigatePrev} />
      }
    />
  );
}
