import React, { MouseEvent, ChangeEvent } from 'react';
import { Checkbox } from '@mui/material';
import { DEPRECATED, Events, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { LABEL_ALIGNMENT_ENUM } from './shared/enums';
import useBaseStyles from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Component Id
   */
  id?: string,
  /**
   * @summary
   * Class name
   */
  className?: string,
  /**
   * @summary
   * Style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Checkbox label
   */
  label?: string,
  /**
   * @summary
   * Checkbox label alignment
   * @default
   * "right"
   */
  labelAlignment?: LABEL_ALIGNMENT_ENUM,
  /**
   * @summary
   * Determines whether the click event
   * will be propagated
   * @default
   * true
   */
  hasStopPropagation?: boolean,
  /**
   * @summary
   * Checked state of switch
   */
  isChecked?: boolean,
  /**
   * @summary
   * If `true`, the component appears indeterminate.
   */
  isIndeterminate?: boolean,
  /**
   * @summary
   * Value to enable/disable checkbox button
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * Callback fired when the `checked` state is changed.
   */
  onToggle?: (checked: boolean) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'hasStopPropagation'
   */
  stopPropagation?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'isIndeterminate'
   */
  indeterminate?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'isChecked'
   */
  checked?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.36 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
};

const KrgCheckbox = ({
  id,
  className = '',
  style,
  label,
  labelAlignment = LABEL_ALIGNMENT_ENUM.right,
  hasStopPropagation = true,
  isChecked = false,
  isIndeterminate = false,
  isEnabled = true,
  theme = THEME_ENUM.v1,
  onToggle,
  onClick,
  onBlur,
  onFocus,
}: Props) => {
  const baseClasses = useBaseStyles();
  const classesV1 = useStylesV1();
  const classesV2 = useStylesV2();
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;
  const checkboxId = `krg-checkbox-${id || Math.random()}`;

  const getLabel = () =>
    <label
      className={`${baseClasses.label} ${classes.label} ${isEnabled ? '' : classes.disabled}`}
      htmlFor={checkboxId}
    >
      {label}
    </label>;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onToggle) {
      onToggle(e.target.checked);
    }
  };

  return (
    <div
      className={`${baseClasses.container} ${className}`}
      style={style}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {/* Left label */}
      { labelAlignment === LABEL_ALIGNMENT_ENUM.left && getLabel() }

      {/* Checkbox */}
      <Checkbox
        id={checkboxId}
        color="default"
        disabled={!isEnabled}
        classes={{
          root: `${baseClasses.root} ${classes.root}`,
          checked: classes.selected,
          indeterminate: classes.selected,
          disabled: classes.disabled,
        }}
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={onChange}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          if (hasStopPropagation) {
            e.stopPropagation();
          }
        }}
      />

      {/* Right label */}
      { labelAlignment === LABEL_ALIGNMENT_ENUM.right && getLabel() }

    </div>
  );
};

KrgCheckbox.LABEL_ALIGNMENT_ENUM = LABEL_ALIGNMENT_ENUM;
KrgCheckbox.THEME_ENUM = THEME_ENUM;

export default KrgCheckbox;
