import { makeStyles } from '@mui/styles';
import { SCRIM_ENUM } from '../shared/enums';
import  { Props } from './base-style';

const useStylesV1 = makeStyles({
  backdrop: {
    backgroundColor: ({ scrim }: Props) => (`rgba(0, 0, 0, ${scrim === SCRIM_ENUM.light ? 0.38 : 0.54 })`),
  },
  dialogPaper: {},
  closeActionContainer: {},
});

export default useStylesV1;
