export const formWrapper = {display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' };
export const textWrapper = {
  display: "flex",
  flexFlow: "column",
  width: 500,
  margin: "40px 0 24px 0"
};
export const inputWrapper = {
  display: "flex",
  flexFlow: "column",
  width: 500,
};
export const listContainer = {
  py: 0,
  width: "100%",
  maxWidth: 360,
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
}