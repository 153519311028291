import React from 'react';
import { IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  FilterNone as FilterNoneIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  CheckCircleOutline as ApproveIcon,
  DoNotDisturb as DenyIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  Forward as ForwardIcon,
  ForwardToInbox as ForwardToInboxIcon,
} from '@mui/icons-material';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgTooltip from '@kargo/shared-components.krg-tooltip';
import { DataTableActionButton, DataTableRow } from './../shared/interfaces';
import { ACTION_TYPE_ENUM } from './../shared/enums';
import { getTablePopperProps } from '../shared/utils';
import useStyles, { Props as StyleProps } from './action-button.style';

type Props = {
  /**
   * @summary
   * Action config
   */
  action: DataTableActionButton,
  /**
   * @summary
   * Row of button.
   * @description
   * The buttons are also called with this parameter.
   */
  row: DataTableRow,
  /**
   * @summary
   * Table component base font-size.
   */
  fontSize?: string | number | (string & {}) | undefined,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
};

const ActionButton = ({
  action,
  row,
  fontSize,
  theme,
}: Props) => {
  const props: StyleProps = {
    fontSize,
    theme,
  };
  const classes = useStyles(props);
  const { type, label, isEnabled, tooltip, onClick } = action;

  const buttonLabel = label ? label :
    type === ACTION_TYPE_ENUM.edit ? 'Edit' :
    type === ACTION_TYPE_ENUM.duplicate ? 'Duplicate' :
    type === ACTION_TYPE_ENUM.preview ? 'Preview' :
    type === ACTION_TYPE_ENUM.logo ? 'Logo' :
    type === ACTION_TYPE_ENUM.archive ? 'Archive' :
    type === ACTION_TYPE_ENUM.approve ? 'Approve' :
    type === ACTION_TYPE_ENUM.archiveUser ? 'Archive' :
    type === ACTION_TYPE_ENUM.unarchive ? 'Unarchive' :
    type === ACTION_TYPE_ENUM.reactivateUser ? 'Resend Okta invite' :
    'Deny';

  const icon = type === ACTION_TYPE_ENUM.edit ? <EditIcon className={classes.actionButtonIcon} /> :
    type === ACTION_TYPE_ENUM.duplicate ? <FilterNoneIcon className={`${classes.actionButtonIcon} ${classes.duplicateIconActionButton}`} /> :
    type === ACTION_TYPE_ENUM.preview ? <VisibilityIcon className={classes.actionButtonIcon} /> :
    type === ACTION_TYPE_ENUM.logo ? <ImageIcon className={classes.actionButtonIcon} /> :
    type === ACTION_TYPE_ENUM.archive ? <DeleteIcon className={classes.actionButtonIcon} /> :
    type === ACTION_TYPE_ENUM.approve ? <ApproveIcon className={classes.actionButtonIcon} /> :
    type === ACTION_TYPE_ENUM.archiveUser
      ? <ArchiveOutlinedIcon className={classes.actionButtonIcon} /> :
    type === ACTION_TYPE_ENUM.unarchive ? <ForwardIcon className={`${classes.actionButtonIcon} ${classes.unarchiveActionButton}`} /> :
    type === ACTION_TYPE_ENUM.reactivateUser ? <ForwardToInboxIcon className={`${classes.actionButtonIcon}`} /> :
    <DenyIcon className={classes.actionButtonIcon} />;

  const isButtonEnabled = () => {
    if (isEnabled === undefined) {
      return true;
    }
    if (typeof isEnabled === 'boolean') {
      return isEnabled;
    }
    return isEnabled(row);
  };

  const getTooltip = () => {
    if (tooltip === undefined) {
      return buttonLabel;
    }
    if (typeof tooltip === 'string') {
      return tooltip;
    }
    return tooltip(row);
  };

  return (
    <KrgTooltip
      tooltip={getTooltip()}
      position={KrgTooltip.POSITION_ENUM.top}
      tooltipProps={{
        PopperProps: getTablePopperProps(),
      }}
      hasArrow={false}
      theme={theme}
    >
      <span>
        <IconButton
          className={classes.actionButton}
          TouchRippleProps={{ className: classes.actionButtonRipple }}
          onClick={() => onClick?.(row)}
          disabled={!isButtonEnabled()}
        >
          {icon}
          {type === ACTION_TYPE_ENUM.duplicate
            && <span className={classes.actionButtonPlus}>+</span>}
        </IconButton>
      </span>
    </KrgTooltip>
  );
};

export default ActionButton;
