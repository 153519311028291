import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import KrgButton, {
  THEME_ENUM as BUTTON_THEME_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
} from '@kargo/shared-components.krg-button';
import './styles.scss';
import { InfoOutlined } from '@mui/icons-material';
import { FONT_FAMILY_ENUM, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgDataTable, { ACTION_TYPE_ENUM, DISPLAY_ENUM } from '@kargo/shared-components.krg-data-table';
import KrgTooltip from '@kargo/shared-components.krg-tooltip';
import ContentModal from '../Modals/ContentModal';
import UserProfile from '../UserProfile';
import { User } from '../../api/types';
import useSnackbar from '../../hooks/useSnackbar';
import useUsers from '../../hooks/useUsers';

type UsersTableProps = {
  setSelectedUser: (user: User) => any,
  setUserFormModalIsOpen: (open: boolean) => any,
  users: User[],
};

export default function UsersTable({
  setSelectedUser, setUserFormModalIsOpen, users,
}: UsersTableProps) {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [deletingUser, setDeletingUser] = React.useState<User>({ username: '' });
  const [deleteModalButtonsFlag, setDeleteModalButtonsFlag] = React.useState(true);
  const { api } = useUsers();
  const { snackbarError, snackbarSuccess } = useSnackbar();

  return (
    <Box display="flex" flexDirection="row">
      <ContentModal
        isOpen={deleteModalIsOpen}
        title={`Are you sure you want to delete ${deletingUser.name || deletingUser.username}`}
        content={(
          <UserProfile
            style={{ marginTop: '25px', marginBottom: '25px' }}
            username={deletingUser.username}
            groups={deletingUser.groups}
          />
        )}
        submitButtonText="Delete Account"
        isSubmitButtonEnabled={deleteModalButtonsFlag}
        isCancelButtonEnabled={deleteModalButtonsFlag}
        onClose={() => setDeleteModalIsOpen(false)}
        onSubmit={async () => {
          setDeleteModalButtonsFlag(false);
          api.delete(deletingUser.username, {
            onSuccess: () => {
              snackbarSuccess('User Deleted');
              setDeleteModalIsOpen(false);
              setDeleteModalButtonsFlag(true);
            },
            onError: (e) => {
              console.error(e);
              snackbarError('Error while deleting user. Check the console for more information');
              setDeleteModalButtonsFlag(true);
            },
          });
        }}
      />
      <Box sx={{ overflowX: 'hidden', width: '100%' }}>
        <Box display="flex" justifyContent="flex-end">
          <KrgButton
            theme={BUTTON_THEME_ENUM.v2}
            variant={BUTTON_VARIANT_ENUM.contained}
            className="button-height-35"
            text="Invite User"
            onClick={() => {
              setSelectedUser({ username: '' });
              setUserFormModalIsOpen(true);
            }}
          />
        </Box>
        <Box>
          <Typography variant="h6" color="text.primary" sx={{ marginLeft: '20px' }}>
            Account Users
          </Typography>
        </Box>
        <KrgDataTable
          theme={THEME_ENUM.v2}
          style={{ fontFamily: FONT_FAMILY_ENUM.poppins }}
          className="data-table"
          config={{
            pagination: {
              initialPageSize: 15,
            },
            actionMenu: {
              display: DISPLAY_ENUM.separate,
              actions: [
                {
                  type: ACTION_TYPE_ENUM.edit,
                  isHidden: (row) => row.id === 1,
                  onClick: (row) => {
                    setSelectedUser(row as User);
                    setUserFormModalIsOpen(true);
                  },
                },
                {
                  type: ACTION_TYPE_ENUM.archive,
                  label: 'Delete Account',
                  isHidden: (row) => row.id === 1,
                  onClick: (row) => {
                    setDeletingUser(row as User);
                    setDeleteModalIsOpen(true);
                  },
                },
              ],
            },
            data: {
              columns: [{
                headerName: 'User Name',
                field: 'name',
              }, {
                headerName: 'Email',
                field: 'email_or_user',
              }, {
                headerName: 'User Role',
                field: 'role',
                width: '100px',
              }, {
                headerName: 'Properties',
                field: 'domains',
                renderCell: (r) => (
                  <div style={{ textTransform: 'uppercase', paddingLeft: 0 }}>
                    <KrgTooltip theme={KrgTooltip.THEME_ENUM.v2} tooltip={r.properties_row_tooltip}>
                      <InfoOutlined sx={{ color: '#91a0ad', fontSize: '20px' }} />
                    </KrgTooltip>
                    {` ${r.domains.join(',')}`}
                  </div>
                ),
              }],
              rows: users.map((u, i) => {
                let role = u?.publisher.users?.find(({ username }) => username === u?.username)?.role;
                role = role?.charAt(0)?.toUpperCase()?.concat(role?.substring(1));
                return {
                  ...u,
                  id: u.username,
                  key: u.username,
                  name: u.name || u.username,
                  email_or_user: u.email || u.username,
                  role,
                  index: i,
                  properties_row_tooltip: (
                    <div>
                      <ul className="circle-list">{u.domains.map((item) => <li key={item}>{item}</li>)}</ul>
                    </div>
                  ),
                };
              }),
            },
          }}
        />
      </Box>
    </Box>
  );
}
