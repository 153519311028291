import { makeStyles } from '@mui/styles';

const useBaseStyles = makeStyles({
  root: {
    height: '2.8em',
  },
  tableHeaderCellCheckbox: {
    textAlign: 'center',
    width: '3em',
  },
  tableHeaderActionsCell: {
    width: '10em',
  },
  tableHeaderFirstCellPadding: {
    paddingLeft: '1.5em',
  },
  tableHeaderCellDisabled: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
});

export default useBaseStyles;
