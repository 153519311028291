import React from "react";
import { Box, Typography } from "@mui/material";
import KrgButton, {
  THEME_ENUM as BUTTON_THEME_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
} from "@kargo/shared-components.krg-button";

type Props = {
  isEnabled?: boolean;
  discriptions?: string;
  onSave?: () => any;
  onCancel?: () => any;
  show?: boolean;
  actions?: string;
};

export default function BulkEditConfirmation({
  isEnabled = true,
  discriptions,
  onSave,
  onCancel,
  actions,
}: Props) {
  return (
    <div>
      <Box mb={2}>
        <Typography
          variant="h6"
          component="h1"
          fontWeight="bold"
          sx={{ color: "#333", marginBottom: "16px" }}
        >
          Confirm Bulk {actions}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          sx={{ marginBottom: "16px" }}
        >
          You are about to perform a bulk {actions} on the following publishers:
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginBottom: "24px" }}
        >
          {discriptions}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontStyle: "italic" }}
        >
          Please click <strong>Confirm</strong> to proceed or{" "}
          <strong>Cancel</strong> to abort the operation.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
        <KrgButton
          theme={BUTTON_THEME_ENUM.v2}
          variant={BUTTON_VARIANT_ENUM.outlined}
          sx={{
            fontSize: "14px",
            padding: "8px 20px",
            borderColor: "#b0bec5",
            color: "#b0bec5",
            "&:hover": {
              borderColor: "#90a4ae",
              color: "#90a4ae",
            },
          }}
          text="Cancel"
          onClick={onCancel}
        />
        <KrgButton
          theme={BUTTON_THEME_ENUM.v2}
          variant={BUTTON_VARIANT_ENUM.contained}
          sx={{
            fontSize: "14px",
            padding: "8px 20px",
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          text="Confirm"
          onClick={onSave}
          isEnabled={isEnabled}
        />
      </Box>
    </div>
  );
}
