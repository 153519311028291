import { makeStyles } from '@mui/styles';
import { COLORS } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';
import { STATUS_ENUM, TYPE_ENUM } from '../../shared/enums';

const useStylesV1 = makeStyles({
  /* Dialog Header */
  header: {
    padding: '1.25em 3em',

    /* Header Icon */
    '& > .MuiSvgIcon-root': {
      marginRight: '0.45em',

      color: ({ headerIconType }: Props) => headerIconType === STATUS_ENUM.confirmation
        ? COLORS.SUCCESS_DARK
        : headerIconType === STATUS_ENUM.warning
          ? COLORS.WARNING_DARK
          : headerIconType === STATUS_ENUM.error && COLORS.ERROR_DARK,
    },

    /* Header Text */
    '& > .MuiTypography-root': {
      position: 'relative',
      top: '0.05em',

      color: ({ headerIconType }: Props) => headerIconType === STATUS_ENUM.confirmation
        ? COLORS.SUCCESS_DARK
        : headerIconType === STATUS_ENUM.warning
          ? COLORS.WARNING_DARK
          : headerIconType === STATUS_ENUM.error && COLORS.ERROR_DARK,
    },
  },

  /* Content body */
  body: { /* only V2 */ },

  /* Content title */
  title: {
    padding: '1.5em 3em 1.25em 3em',
  },

  /* Spacer with no content, only for layout spacing */
  spacer: {
    height: ({ type }: Props) => type === TYPE_ENUM.info
        ? '2.6875em'
        : '1.4375em',
  },

  /* Content title modifier for V1 Submit*/
  titleSubmit: {
    padding: '0 1em 2.4375em 1em',
    margin: '0',
  },

  /* Content input */
  input: {
    padding: ({ type }: Props) => type === TYPE_ENUM.info
        ? '0.5em 1em 3.9375em 1em'
        : '0.5em 1em 2.6875em 1em',
  },

  /* Content link */
  linksWrapper: { /* only V2 */ },
  linkContainer: { /* only V2 */ },
  linkWrapper: { /* only V2 */ },
  linkLabel: { /* only V2 */ },
  link: { /* only V2 */ },
  linkCopyText: { /* only V2 */ },
  tooltip: { /* only V2 */ },
  divider: { /* only V2 */ },

  /* Buttons container */
  buttons: {
    padding: '0 .75em .75em .75em',
  },

  /* Button */
  button: {
    padding: '1.25em 1em 0.75em',
  },
});

export default useStylesV1;
