import React, { ReactElement } from 'react';
import { Avatar, Chip } from '@mui/material';
import { DEPRECATED, Events, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from './shared/enums';
import { ChipData } from './shared/interfaces';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Chip id
   */
  id?: string,
  /**
   * @summary
   * Chip class name
   */
  className?: string,
  /**
   * @summary
   * Chip style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Chip data
   */
  data: ChipData,
  /**
   * @summary
   * Chip icon which stands before Chip text
   */
  startAdornment?: ReactElement | string,
  /**
   * @summary
   * Type of chip
   */
  type: TYPE_ENUM,
  /**
   * @summary
   * If `true`, the chip will appear clickable, and will raise when pressed,
   * even if the onClick prop is not defined.
   * If `false`, the chip will not be clickable, even if onClick prop is defined.
   * This can be used, for example, along with the component prop
   * to indicate an anchor Chip is clickable.
   * @default
   * false
   */
  isClickable?: boolean,
  /**
   * @summary
   * If `false`, the chip will be disabled.
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * This is triggered when remove icon is clicked
   */
  onDelete?: (id: number | string) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.37 in favour of 'isClickable'
   */
  clickable?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.37 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
};

const KrgChip = ({
  id,
  className = '',
  data,
  startAdornment,
  style,
  type,
  isClickable = false,
  isEnabled = true,
  theme = THEME_ENUM.v1,
  onDelete,
  onBlur,
  onClick,
  onFocus,
}: Props) => {
  const props: StyleProps = {
    type,
    isClickable,
  };
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  const variant = type === TYPE_ENUM.transparent ? 'outlined' : 'filled';
  const color = type === TYPE_ENUM.primary ? 'primary' : 'default';
  const avatarBlock = <Avatar>{startAdornment}</Avatar>;
  const isAvatarAdornment = typeof startAdornment === 'string';

  const chipClasses = {
    root: `${baseClasses.root} ${classes.root}`,
    colorPrimary: classes.colorPrimary,
    iconColorPrimary: classes.iconColorPrimary,
    deleteIconColorPrimary: classes.deleteIconColorPrimary,
  };

  return (
    <Chip
      id={id}
      className={className}
      classes={chipClasses}
      style={style}
      avatar={isAvatarAdornment ? avatarBlock : undefined}
      icon={isAvatarAdornment ? undefined : startAdornment}
      label={data.text}
      variant={variant}
      color={color}
      disabled={!isEnabled}
      clickable={isClickable}
      onDelete={!!onDelete ? () => onDelete(data.id) : undefined}
      onClick={isClickable && onClick ? () => onClick(data.id) : undefined}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

KrgChip.TYPE_ENUM = TYPE_ENUM;
KrgChip.THEME_ENUM = THEME_ENUM;

export default KrgChip;
