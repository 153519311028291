import React from 'react';

type ISvg = {
  width?: string,
  height?: string,
  color?: string,
};

export default function FabrikLogo(props: ISvg) {
  const { width = '72', height = '21', color = '#FFFF' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 149.786 45.522">
      <g transform="translate(0)">
        <ellipse cx="4.856" cy="4.856" rx="4.856" ry="4.856" transform="translate(109.315 2.232) rotate(-13.283)" fill={color} />
        <path d="M526.853,478.586a1.494,1.494,0,0,1-.487.066.853.853,0,0,1-.7-.251.81.81,0,0,1-.153-.411h-.8a1.194,1.194,0,0,0,.451.988,1.946,1.946,0,0,0,1.239.358,1.782,1.782,0,0,0,1.2-.364,1.158,1.158,0,0,0,.424-.916.988.988,0,0,0-.361-.825,1.861,1.861,0,0,0-.689-.29l-.695-.169a2.258,2.258,0,0,1-.525-.164.351.351,0,0,1-.191-.323.419.419,0,0,1,.193-.366.912.912,0,0,1,.517-.131,1.1,1.1,0,0,1,.492.1.58.58,0,0,1,.317.519h.809a1.2,1.2,0,0,0-.473-.982,1.764,1.764,0,0,0-1.085-.336,1.674,1.674,0,0,0-1.159.358,1.166,1.166,0,0,0-.394.9.977.977,0,0,0,.41.869,2.628,2.628,0,0,0,.87.312l.427.093a1.823,1.823,0,0,1,.547.192.343.343,0,0,1,.175.306A.469.469,0,0,1,526.853,478.586Z" transform="translate(-382.821 -473.972)" fill={color} />
        <path d="M536.967,476.729c0-.08,0-.191,0-.331s0-.249,0-.326l.755,3.38h.82l.76-3.38q0,.115,0,.326c0,.14,0,.251,0,.331v2.723h.788v-4.031h-1.214l-.727,3.172-.733-3.172H536.18v4.031h.787Z" transform="translate(-390.376 -474.193)" fill={color} />
        <path d="M202.534,520.724h-.124c-1.8-2.977-4.9-4.963-9.554-4.963-7.507,0-13.772,5.459-13.772,15.261s6.266,15.261,13.772,15.261c4.653,0,7.755-1.985,9.554-4.963h.124v4.032H210.1V516.692h-7.568Zm-7.754,18.363c-4.591,0-7.755-3.226-7.755-8.065s3.164-8.065,7.755-8.065,7.754,3.226,7.754,8.065S199.37,539.087,194.78,539.087Z" transform="translate(-155.185 -500.762)" fill={color} />
        <path d="M297.9,489.07c-4.653,0-7.755,1.985-9.554,4.963h-.124v-18.8h-7.63v43.426h7.63V514.63h.124c1.8,2.977,4.9,4.963,9.554,4.963,7.506,0,13.772-5.459,13.772-15.261S305.4,489.07,297.9,489.07ZM295.975,512.4c-4.591,0-7.755-3.226-7.755-8.065s3.164-8.065,7.755-8.065,7.755,3.226,7.755,8.065S300.566,512.4,295.975,512.4Z" transform="translate(-222.039 -474.071)" fill={color} />
        <path d="M384.943,522.891h-.124v-6.08h-7.507v28.661h7.568V533.748c0-7.01,2.916-9.616,9-9.616h.868v-8.189h-.31C389.719,515.943,386.432,518.424,384.943,522.891Z" transform="translate(-285.742 -500.882)" fill={color} />
        <rect width="7.631" height="28.661" transform="translate(111.357 15.93)" fill={color} />
        <path d="M117.024,482.648H135.4V475.2H109.075v43.469h7.949V500.656h14.03v-7.328h-14.03Z" transform="translate(-109.075 -474.044)" fill={color} />
        <path d="M494.748,490.5h-9.486l-10.4,12.258V475.727h-7.631v43.426h7.631V506.687l10.4,12.258,9.486.018-12.073-14.258Z" transform="translate(-344.962 -474.395)" fill={color} />
      </g>
    </svg>

  );
}
