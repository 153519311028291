import { makeStyles } from '@mui/styles';
import { FONT, COLORS, COLORS_V2, THEME_ENUM } from '@kargo/shared-components.krg-shared';

export type Props = {
  fontSize?: string | number | (string & {}) | undefined,
  theme?: THEME_ENUM,
};

const useStyles = makeStyles({
  /*
    Action button
  */
  actionButton: {
    fontSize: ({ fontSize }: Props) => fontSize || FONT.SIZE.BASE,
    color: ({ theme }: Props) => theme === THEME_ENUM.v1
      ? COLORS.BLACK
      : COLORS_V2.NEUTRAL_BLACK,
    padding: '0.375em',

    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ theme }: Props) => theme === THEME_ENUM.v1
        ? COLORS.PRIMARY_500_ACCENT
        : COLORS_V2.PRIMARY_700,
    },
  },

  actionButtonIcon: {
    fontSize: FONT.SIZE.EXTRA_LARGE,
  },

  unarchiveActionButton: {
    transform: 'rotate(-90deg)',
  },

  actionButtonPlus: {
    position: 'absolute',
    top: '0.65em',
    left: '0.7em',
    fontSize: '0.8em',
    fontWeight: FONT.WEIGHT.BOLD,
  },

  duplicateIconActionButton: {
    transform: 'scaleX(-1) scaleY(-1)',
  },

  actionButtonRipple: {
    opacity: 0.5,
  },
});

export default useStyles;
