import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { Domain } from "../../api/types";
import PlayersTableRow from "./TableRow";
import ScrollPaddle from "./ScrollPaddle";
import "./styles.scss";

const colWidths = [
  98.37, 150, 155.56, 150, 150, 115.95, 90, 152, 152, 114, 150, 140, 152, 92,
];
const breakpoints = [
  0,
  ...colWidths.reduce((prev, curr) => {
    prev.push(curr + (prev[prev.length - 1] || 0));
    return prev;
  }, []),
];

type PlayersTableProps = {
  asAdmin?: boolean;
  domain: Domain;
  search?: string;
  isCreating?: boolean;
  isEditing?: boolean;
  query?: any;
  onUpdate?: (data: Domain) => any;
  setConfigUpdatingStatus?: (status: boolean) => void;
  onCreateFinished?: (domain?: Domain) => void;
};
export default function PlayersTable({
  asAdmin,
  domain,
  search,
  isCreating,
  isEditing,
  query,
  onUpdate,
  setConfigUpdatingStatus,
  onCreateFinished,
}: PlayersTableProps) {
  if (!domain) return null;
  const [leftArrowDisable, setLeftArrowDisable] = React.useState(true);
  const [rightArrowDisable, setRightArrowDisable] = React.useState(false);
  const elementRef = React.useRef(null);
  const [showPaddles, setShowPaddles] = React.useState(true);
  const floatingDesktopTooltip = (
    <div>
      Configure the player float position on desktop to the bottom right, bottom
      left, top right, top left of the screen.
    </div>
  );

  // TODO: Look into moving this method into a utils file?
  function handleHorizontalScroll(element, direction) {
    let distance = element.clientWidth;
    if (direction > 0) {
      for (let i = 0; i < breakpoints.length; i++) {
        if (breakpoints[i] > element.scrollLeft + element.clientWidth - 190)
          break;
        else distance = breakpoints[i];
      }
    } else {
      for (let i = breakpoints.length - 1; i >= 0; i--) {
        if (breakpoints[i] < element.scrollLeft - element.clientWidth + 190)
          break;
        else distance = breakpoints[i];
      }
    }
    element.scroll({
      left: distance,
      behavior: "smooth",
    });
    setLeftArrowDisable(distance === 0);
    setRightArrowDisable(distance + element.clientWidth > element.scrollWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      if (
        elementRef?.current?.scrollWidth <= elementRef?.current?.clientWidth
      ) {
        setShowPaddles(false);
      } else {
        setShowPaddles(true);
      }
    });
  }, []);

  return (
    <Box display="flex" flexDirection="row">
      <Box
        width="30px"
        marginRight="10px"
        display={showPaddles ? "block" : "none"}
      >
        <ScrollPaddle
          buttonText="left"
          disabled={leftArrowDisable}
          onClick={() => {
            handleHorizontalScroll(elementRef.current, -1);
          }}
        />
      </Box>
      <Box sx={{ overflowX: "hidden", width: "100%" }}>
        <TableContainer
          className="domain-card-players-table"
          sx={{ overflowX: "hidden" }}
          ref={elementRef}
        >
          <Table
            sx={{ minWidth: 650, marginBottom: "10px", overflowX: "hidden" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{ borderBottom: "2px solid black", textAlign: "center" }}
            >
              <TableRow>
                <TableCell
                  sx={{ position: "sticky", left: 0, backgroundColor: "white" }}
                >
                  Video Player Name
                </TableCell>
                <TableCell>URL target</TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Disables video content. Enables player floating on desktop and mobile. Changes ad request structure to outstream."
                  >
                    Outstream
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Assign a playlist. This content supersedes videos from Fabrik’s syndicated content library."
                  >
                    Playlist
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Assign a mrss feed. This content supersedes videos from Fabrik’s syndicated content library and the playlist."
                  >
                    MRSS Feed
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Access videos from Fabrik’s syndicated content library."
                  >
                    Syndicated Content
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Categories of syndicated content that can serve in the video player."
                  >
                    Video Category
                  </KrgTooltip>
                </TableCell>
                <TableCell>Video Playback</TableCell>
                <TableCell>Sound On/Off</TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Video player floats when scrolled out of view"
                  >
                    Floating
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Set the height of the floating player on mobile, or hide it completely"
                  >
                    Mobile (Height)
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip={floatingDesktopTooltip}
                  >
                    Desktop (Location)
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Loads video gallery when player is on desktop"
                  >
                    Video Gallery
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Enables next video to play automatically if no user interaction with the player."
                  >
                    Next Video Settings
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Seconds into video before initiating countdown timer."
                  >
                    Next Video Length
                  </KrgTooltip>
                </TableCell>
                <TableCell>
                  <KrgTooltip
                    theme={KrgTooltip.THEME_ENUM.v2}
                    tooltip="Countdown length before going to next video."
                  >
                    Countdown Length
                  </KrgTooltip>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isCreating && (
                <PlayersTableRow
                  index={-1}
                  asAdmin={asAdmin}
                  domain={domain}
                  setConfigUpdatingStatus={setConfigUpdatingStatus}
                  onCreateFinished={(updatedDomain) => {
                    onCreateFinished(updatedDomain);
                  }}
                  isCreating
                />
              )}
              {domain.configs.map((c, i) => (
                <PlayersTableRow
                  asAdmin={asAdmin}
                  query={query}
                  key={c.token}
                  index={i}
                  search={search}
                  domain={domain}
                  config={c}
                  isEditing={isEditing}
                  onUpdate={onUpdate}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        width="30px"
        marginLeft="10px"
        display={showPaddles ? "block" : "none"}
      >
        <ScrollPaddle
          buttonText="right"
          disabled={rightArrowDisable}
          onClick={() => {
            handleHorizontalScroll(elementRef.current, 1);
          }}
        />
      </Box>
    </Box>
  );
}
