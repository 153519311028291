export enum BUTTON_SIZE_ENUM {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

export enum BUTTON_VARIANT_ENUM {
  text = 'text',
  outlined = 'outlined',
  contained = 'contained',
  icon = 'icon',
}

export enum BUTTON_ICON_ENUM {
  moreVert = 'more_vert',
  filterList = 'filter_list',
}
