import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from '../shared/enums';
import { Props } from './base-style';

const useStylesV1 = makeStyles({

  root: {
    fontSize: FONT.SIZE.BASE,
  },

  alert: {
    backgroundColor: ({ type }: Props) => (
      type === TYPE_ENUM.white ? COLORS.WHITE :
      type === TYPE_ENUM.dark ? COLORS.SURFACE_SNACKBAR :
      type === TYPE_ENUM.accent ? COLORS.PRIMARY_50 :
      type === TYPE_ENUM.warning ? COLORS.WARNING_LIGHT :
      type === TYPE_ENUM.error ? COLORS.ERROR_LIGHT :
      type === TYPE_ENUM.success ? COLORS.SUCCESS_LIGHT :
      COLORS.GRAY_CHIP_MEDIUM
    ),
  },

  textContainer: {},
  title: {},

  text: {
    color: ({ type }: Props) => (
      type === TYPE_ENUM.white ? COLORS.BLACK_HIGH_EMPHASIS :
      type === TYPE_ENUM.dark ? COLORS.WHITE :
      type === TYPE_ENUM.accent ? COLORS.PRIMARY_400 :
      type === TYPE_ENUM.warning ? COLORS.WARNING_DARK :
      type === TYPE_ENUM.error ? COLORS.ERROR_DARK :
      type === TYPE_ENUM.success ? COLORS.SUCCESS_DARK :
      COLORS.BLACK_MEDIUM_EMPHASIS
    ),
    fontSize: FONT.SIZE.SEMI_LARGE,
    lineHeight: 1.2,
    marginRight: '0.625em',
  },

  icon: {
    color: ({ type }: Props) => (
      type === TYPE_ENUM.white ? COLORS.BLACK_MEDIUM_EMPHASIS :
      type === TYPE_ENUM.dark ? COLORS.WHITE_MEDIUM_EMPHASIS :
      type === TYPE_ENUM.accent ? COLORS.PRIMARY_500_ACCENT :
      type === TYPE_ENUM.warning ? COLORS.WARNING_BASE :
      type === TYPE_ENUM.error ? COLORS.ERROR_DARK :
      type === TYPE_ENUM.success ? COLORS.SUCCESS_DARK :
      COLORS.BLACK_DISABLED
    ),
  },

  button: {
    color: ({ type }: Props) => (
      type === TYPE_ENUM.white ? COLORS.PRIMARY_500_ACCENT :
      type === TYPE_ENUM.dark ? COLORS.PRIMARY_500_ACCENT :
      type === TYPE_ENUM.accent ? COLORS.PRIMARY_500_ACCENT :
      type === TYPE_ENUM.warning ? COLORS.WARNING_DARK :
      type === TYPE_ENUM.error ? COLORS.ERROR_DARK :
      type === TYPE_ENUM.success ? COLORS.SUCCESS_DARK :
      COLORS.BLACK_DISABLED
    ),
    fontSize: FONT.SIZE.REGULAR,
    marginLeft: 'auto',
    minWidth: 'unset',
  },
  closeIcon: {},
});

export default useStylesV1;
