import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';

const useStylesV2 = makeStyles({
  // Checkbox classes
  root: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.25em',
    },
  },

  // Selected (Checked/Indeterminate)
  selected: {
    color: COLORS_V2.PRIMARY_700,
  },

  // Disabled color
  disabled: {
    color: COLORS_V2.NEUTRAL_INACTIVE,
    cursor: 'default',
  },

  // Label text
  label: {
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
    paddingLeft: '0.1875em',
  },
});

export default useStylesV2;
