import React, { FC, useState, useEffect } from 'react';
import { MenuItem, Select } from '@mui/material';

type Props = {
  label?: string,
  options: any[],
  storageKey?: string,
  onChange: (value: any) => void,
};

const KrgStyleSelector: FC<Props> = ({
  label,
  options,
  storageKey,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(
    storageKey
      ? (localStorage.getItem(storageKey) || options[0])
      : options[0],
  );

  useEffect(
    () => {
      onChange(selectedValue);
      if (storageKey) {
        localStorage.setItem(storageKey, selectedValue);
        window.dispatchEvent(new Event('storage'));
      }
    },
    [selectedValue, storageKey, onChange],
  );

  return (
    <div style={{ padding: '1em 1em 1em 0', display: 'inline-block' }}>
      <Select
        name={label}
        value={selectedValue}
        style={{ width: '200px', height: '36px', borderRadius: 0 }}
        onChange={({ target }) => setSelectedValue(target.value as string) }>
          {options.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
        </Select>
    </div>
  );
};

export default KrgStyleSelector;
