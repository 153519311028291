import { useQuery } from 'react-query';
import useAPI from '../api/useAPI';

export function useLooker() {
  const { api, didLoad } = useAPI();
  const query = useQuery({
    queryKey: ['dashboards', 'looker-get-objects'],
    queryFn: () => api && api.Looker.getUserObjects(),
    enabled: !!api && didLoad,
    staleTime: Infinity,
  });

  return {
    ...query,
    dashboardId: query.data?.dashboard_id,
    videoPerformanceExploreId: query.data?.events_explore,
    monetizationExploreId: query.data?.ad_events_explore,
    userDomains: query.data?.domains,
    userAdUnits: query.data?.ad_units
  };
}
