import React from "react";
import { Box, Typography } from "@mui/material";

export default function StaticEditor(actions) {
  const displayText = {
    Update: `
      {
        // to update an value:
        "outstream": false,
      
        // to update an object propertie value
        "floatingoptions": {
      
          // noFloatOnMobile will be update or added inside floatingoptions 
          // if floatingoptions is not present on the config, it will be added too.
          "noFloatOnMobile": false,
      
          // same will happen to mobile and height
          "mobile": {
            "height": 120
          }
        },
        "query": {
           // array it will push the values passed here 
          // to the existing array on the config
          "categories": [
            "style and fashion"
          ]
        }
      }`,
    Delete: `
    {
      // to delete an propertie just pass a true value to it:
      "outstream": true,

      // to delete the whole object, just passed the value of the object as true
      "prerollconfig": true,
      
      // to delete an object propertie just do the same thing
      "floatingoptions": {

        // noFloatOnMobile will be deleted inside floatingoptions 
        // if floatingoptions is not present it will be ignored.
        "noFloatOnMobile": true,

        // same will happen to mobile and height
        "mobile": {
          "height": true
        }
      },
      "query": {
        // array it will remove the values passed here 
        // on the existing array on the config
        "categories": [
          "style and fashion"
        ]
      }
    }`,
  };
  return (
    <Box sx={{ display: "flex", flexFlow: "column", height: "90%" }}>
      <Typography
        variant="h6"
        component="h1"
        fontWeight="bold"
        sx={{ color: "#333", marginBottom: "16px", marginLeft: "14px" }}
      >
        Example Of {actions} Properties
      </Typography>
      <Box sx={{ color: "green", marginLeft: "14px" }}>
        <pre>
          <code>{displayText[actions]}</code>
        </pre>
      </Box>
    </Box>
  );
}
