import React from "react";
import { Autocomplete, TextField, InputAdornment, Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() =>
  createStyles({
    textInpute: {
      width: "100%",
      padding: "0px 10px",
      margin: "10px 0px 15px 0px",
    },
    inputRoot: {
      padding: "0px 7px !important",
      height: "40px",
      textAlign: "center",
    },
    noBorderRadius: {
      borderRadius: 0,
    },
    placeHolder: {
      fontSize: 12,
    },
    clearIndicator: {
      display: "none",
    },
    listbox: {
      fontSize: 12,
      fontWeight: "bold",
    },
  }),
);

enum widthValue /* eslint-disable-line @typescript-eslint/naming-convention */ {
  minWidth = "250px",
  fullWidth = "100%",
}
interface SearchDropdownProps {
  searchValue: string;
  placeholder?: string;
  widthSize?: "minWidth" | "fullWidth";
  options: string[];
  onSearchChange: (event: React.ChangeEvent<{}>, value: string | null) => void;
  onOptionClick: (value: string) => void;
}

/* eslint-disable-next-line react/function-component-definition */
const SearchDropdown: React.FC<SearchDropdownProps> = ({
  searchValue,
  options,
  widthSize,
  placeholder,
  onSearchChange,
  onOptionClick,
}) => {
  const classes = useStyles();
  const filterOptions =
    searchValue?.trim() === ""
      ? []
      : options?.filter((option) => {
        if (option.includes('from')) {
          const targetOption = option.split('(')[0];
          return targetOption?.toLowerCase()?.includes(searchValue?.toLowerCase());
        }
        return option?.toLowerCase()?.includes(searchValue?.toLowerCase());
      });
  return (
    <Box sx={{ width: widthValue[widthSize] }}>
      <Autocomplete
        classes={{
          inputRoot: classes.inputRoot,
          clearIndicator: classes.clearIndicator,
          listbox: classes.listbox,
        }}
        freeSolo
        options={filterOptions}
        onChange={(e, value) => onOptionClick(value)}
        onInputChange={onSearchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textInpute}
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            autoFocus
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: classes.noBorderRadius,
                input: classes.placeHolder,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: false,
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchDropdown;
