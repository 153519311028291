export enum MULTIPLE_TYPE_ENUM {
  default = 'default',
  checkmark = 'checkmark',
  chip = 'chip',
}

export enum SIZE_ENUM {
  small = 'small',
  medium = 'medium',
}
