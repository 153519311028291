import { BaseJsonSchema, createSchemaByBasicTypes } from "../../helpers";

export const AsterioBidAnalytics: BaseJsonSchema = {
  type: 'object',
  properties: {
    provider: createSchemaByBasicTypes(['string'], '', {
      enum: ['asteriobid'],
    }),
    options: {
      type: 'object',
      properties: {
        url: createSchemaByBasicTypes('string'),
        bundleId: createSchemaByBasicTypes('string')
      },
      required: ['url', 'bundleId'],
    }
  },
};