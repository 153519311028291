import React from "react";
import KrgModal from "@kargo/shared-components.krg-modal";
import { VARIANT_ENUM as BUTTON_VARIANT_ENUM } from "@kargo/shared-components.krg-button";
import KrgSelect from "@kargo/shared-components.krg-select";
import { Box } from "@mui/material";
import TrackedKrgButton from "../TrackedButton/TrackedKrgButton";
import usePlaylists from "../../hooks/usePlaylists";
import { Playlist } from "../../api/types";
import useSnackbar from "../../hooks/useSnackbar";
import Select from "../Select";

export default function SortPlaylistsVideosModal({
  isOpen,
  onClose,
  onSubmit,
  playlist,
}: {
  isOpen: boolean;
  onClose?: (shuffle) => any;
  onSubmit?: () => any;
  playlist: Playlist;
}) {
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const { api } = usePlaylists();
  const [sortSelected, setSortSelected] = React.useState("shuffle");
  const { snackbarSuccess, snackbarError } = useSnackbar();
  return (
    <KrgModal
      className="modal"
      title="Sort Videos"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      inputContainer={
        <>
          <Select
            style={{ width: 250 }}
            value={sortSelected}
            items={[
              {
                text: "Shuffle",
                value: "shuffle",
              },
              {
                text: "un-Shuffle",
                value: "un-shuffle",
              },
              {
                text: "Newest Videos By Date",
                value: "newest",
              },
              {
                text: "Stop Videos Older than 30 Days",
                value: "last_30_days",
              },
            ]}
            variant={KrgSelect.VARIANT_ENUM.outlined}
            size={KrgSelect.SIZE_ENUM.small}
            onChange={(e) => {
              setSortSelected(e);
            }}
          />
          <Box display="flex" gap="20px" justifyContent="flex-end">
            <TrackedKrgButton
              id="cancel-update-video-order"
              variant={BUTTON_VARIANT_ENUM.outlined}
              text="Cancel"
              onClick={onClose}
            />
            <TrackedKrgButton
              id="confirm-update-video-order"
              variant={BUTTON_VARIANT_ENUM.contained}
              text={(isUpdating && "Updating...") || "Update Video Order"}
              isEnabled={!isUpdating}
              onClick={() => {
                const data: any = {};
                if (
                  sortSelected === "shuffle" ||
                  sortSelected === "un-shuffle"
                ) {
                  data.shuffle = sortSelected === "shuffle";
                } else {
                  data.sort = sortSelected;
                }
                setIsUpdating(true);
                api.update(
                  {
                    token: playlist.token,
                    data,
                  },
                  {
                    onSuccess: () => {
                      setIsUpdating(false);
                      if (
                        sortSelected === "shuffle" ||
                        sortSelected === "un-shuffle"
                      ) {
                        if (sortSelected === "shuffle") {
                          onClose("shuffle");
                        } else {
                          snackbarSuccess(
                            `"${playlist.title || playlist.token}" Playlist Saved`,
                          );
                          onClose("");
                        }
                      } else {
                        snackbarSuccess(
                          `"${playlist.title || playlist.token}" Playlist Saved`,
                        );
                        onClose("");
                      }
                    },
                    onError: (error) => {
                      console.error(error);
                      setIsUpdating(false);
                      snackbarError(
                        `Error while update sorting on ${playlist.title || playlist.token}, check the console for more information.`,
                      );
                    },
                  },
                );
              }}
            />
          </Box>
        </>
      }
      theme={KrgModal.THEME_ENUM.v2}
    />
  );
}
