import { makeStyles } from '@mui/styles';

export type Props = {
  isMenuOpen: boolean;
};

const useStyles = makeStyles({
  /* Inner Container  */
  innerContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  innerContainerText: {
    width: 'auto',
  },
  innerContainerIcon: {
    minWidth: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
    },
  },
  moreIcon: {
    margin: 'auto',
  },
  /* Menu */
  popper: {
    zIndex: 1,
  },
  list: {
    padding: 0,
    width: 'auto',
    minWidth: '10.625em',
    '& .MuiList-padding': {
      padding: 0,
    },
  },
});

export default useStyles;
