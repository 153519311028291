import React, { ReactNode } from 'react';
import {
  Edit as EditIcon,
  FilterNone as FilterNoneIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  CheckCircleOutline as ApproveIcon,
  DoNotDisturb as DenyIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  Forward as ForwardIcon,
  ForwardToInbox as ForwardToInboxIcon,
} from '@mui/icons-material';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgMenu, { BUTTON_VARIANT_ENUM } from '@kargo/shared-components.krg-menu';
import ActionButton from '../action-button.react';
import { DataTableActionButton, DataTableRow } from './../../shared/interfaces';
import { ACTION_TYPE_ENUM } from './../../shared/enums';
import useStyles, { Props as StyleProps } from './action-cell.style';

type Props = {
  /**
   * @summary
   * Action config array for action cell
   */
  actions: DataTableActionButton[],
  /**
   * @summary
   * Row of button for onClick function
   */
  row: DataTableRow,
  /**
   * @summary
   * If `true`, the buttons will be wrapped inside a menu
   */
  isActionMenu?: boolean,
  /**
   * @summary
   * Table component base font-size.
   */
  fontSize?: string | number | (string & {}) | undefined,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
};

const ActionCell = ({
  actions,
  row,
  fontSize,
  isActionMenu,
  theme,
}: Props) => {
  const props: StyleProps = {
    buttonCount: isActionMenu ? 1 : actions.length,
  };
  const classes = useStyles(props);

  const getMenuLabel = (type: ACTION_TYPE_ENUM, label?: string): string => {
    return label ? label :
      type === ACTION_TYPE_ENUM.edit ? 'Edit' :
      type === ACTION_TYPE_ENUM.duplicate ? 'Duplicate' :
      type === ACTION_TYPE_ENUM.preview ? 'Preview' :
      type === ACTION_TYPE_ENUM.logo ? 'Logo' :
      type === ACTION_TYPE_ENUM.archive ? 'Archive' :
      type === ACTION_TYPE_ENUM.approve ? 'Approve' :
      type === ACTION_TYPE_ENUM.archiveUser ? 'Archive' :
      type === ACTION_TYPE_ENUM.unarchive ? 'Unarchive' :
      type === ACTION_TYPE_ENUM.reactivateUser ? 'Resend Okta invite' :
      'Deny';
  };

  const getMenuIcon = (type: ACTION_TYPE_ENUM): ReactNode => {
    return type === ACTION_TYPE_ENUM.edit ? <EditIcon /> :
      type === ACTION_TYPE_ENUM.duplicate ? <FilterNoneIcon /> :
      type === ACTION_TYPE_ENUM.preview ? <VisibilityIcon /> :
      type === ACTION_TYPE_ENUM.logo ? <ImageIcon /> :
      type === ACTION_TYPE_ENUM.archive ? <DeleteIcon /> :
      type === ACTION_TYPE_ENUM.approve ? <ApproveIcon /> :
      type === ACTION_TYPE_ENUM.unarchive ? <ForwardIcon /> :
      type === ACTION_TYPE_ENUM.archiveUser ? <ArchiveOutlinedIcon /> :
      type === ACTION_TYPE_ENUM.reactivateUser ? <ForwardToInboxIcon /> :
      <DenyIcon />;
  };

  const filteredActions = actions.filter((a: DataTableActionButton) => {
    if (a.isHidden === undefined) {
      return a;
    }
    if (typeof a.isHidden === 'boolean') {
      return !a.isHidden;
    }
    return !a.isHidden(row);
  });

  const isButtonEnabled = (isEnabled?: boolean | ((row: any) => boolean)) => {
    if (isEnabled === undefined) {
      return true;
    }
    if (typeof isEnabled === 'boolean') {
      return isEnabled;
    }
    return isEnabled(row);
  };

  return (
    <div className={`${classes.root}`}>
      { isActionMenu
          ? <KrgMenu
              buttonVariant={BUTTON_VARIANT_ENUM.icon}
              menuPlacement="bottom-end"
              theme={theme}
              menuItems={
                filteredActions.map(action => ({
                  name: getMenuLabel(action.type, action.label),
                  icon: getMenuIcon(action.type),
                  isEnabled: isButtonEnabled(action.isEnabled),
                  onClick: () => action.onClick?.(row),
                }))
              }
            />
          : filteredActions.map((action, idx) =>
              <ActionButton
                key={idx}
                action={{
                  ...action,
                  isEnabled: isButtonEnabled(action.isEnabled),
                }}
                row={row}
                fontSize={fontSize}
                theme={theme}
              />,
            )
      }
    </div>
  );
};

export default ActionCell;
