import React from 'react';
import { makeStyles } from '@mui/styles';
import { VARIANT_ENUM as BUTTON_VARIANT_ENUM, SIZE_ENUM as BUTTON_SIZE_ENUM } from '@kargo/shared-components.krg-button';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Domain } from '../../api/types';
import ConfirmModal from '../Modals/ConfirmModal';
import useSnackbar from '../../hooks/useSnackbar';
import { useDomains } from '../../hooks/useDomains';
import EditPropertyModal from '../Modals/EditPropertyModal';
import { useDomain } from '../../hooks/useDomain';
import TrackedKrgButton from '../TrackedButton/TrackedKrgButton';
import Table from '../Table';

const useStyles = makeStyles({
  selected: {
    color: '#0033FF',
  },
});

function ButtonsCell({ domain }: { domain: Domain }) {
    const { snackbarSuccess, snackbarError } = useSnackbar();
    const { update } = useDomain(domain?.token, domain);
    const api = useDomains();
    const [confirmModalIsOpen, setConfirmModalIsOpen] = React.useState(false);
    const [userFormModalIsOpen, setUserFormModalIsOpen] = React.useState(false);
    const handleEdit = () => setUserFormModalIsOpen(true);
    const handleDelete = () => setConfirmModalIsOpen(true);
    return (
        <div style={{ float: 'right' }}>
            <ConfirmModal
                isOpen={confirmModalIsOpen}
                header={`Delete ${domain?.domain}`}
                body={`Are you sure you want to delete the property ${domain?.domain}? This action can't be undone.`}
                submitButtonText="Confirm"
                onClose={() => setConfirmModalIsOpen(false)}
                onSubmitClick={() => {
                    api.delete(domain?.token, {
                        onSuccess: () => snackbarSuccess('Deleted Property'),
                        onError: (error) => {
                            console.error(error);
                            snackbarError(`Error deleting property ${domain?.domain}, check the console for more information.`);
                        },
                    });
                    setConfirmModalIsOpen(false);
                }}
            />
            <EditPropertyModal
                token={domain?.token}
                isOpen={userFormModalIsOpen}
                onClose={() => setUserFormModalIsOpen(false)}
                onSubmit={(value) => {
                    setUserFormModalIsOpen(false);
                    update({ domain: value }, {
                        onSuccess: () => snackbarSuccess('Edit Saved'),
                        onError: (error) => {
                            console.error(error);
                            snackbarError(`Error while editing property ${domain?.token}, check the console for more information.`);
                        },
                    });
                }}
            />
            <IconButton style={{ color: 'black' }} onClick={handleEdit}>
                <Edit style={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton style={{ color: 'black' }} onClick={handleDelete}>
                <Delete style={{ fontSize: '20px' }} />
            </IconButton>
        </div>
    );
}

export default function PropertiesList({
  properties,
  selected,
  onSelected,
  onCreateButtonClick,
}: {
  properties: Domain[],
  selected: Domain,
  onSelected: (d: Domain) => any,
  onCreateButtonClick: () => any,
}) {
  const classes = useStyles();
  const columns = [{
    field: 'domain',
    headerName: 'Properties',
    onClick: (_, row) => {
      onSelected(row.ogdomain);
    },
    renderCell: (row) => (
      <p className={row.token === selected.token ? classes.selected : undefined}>{row.domain}</p>
    ),
  }, {
    field: 'playerscount',
    headerName: 'No. of Video Players',
    onClick: (_, row) => onSelected(row.ogdomain),
    renderCell: (row) => <p className={row.token === selected.token ? classes.selected : undefined} style={{ textAlign: 'end', width: '122px' }}>{row.playerscount}</p>,
  }, {
    field: '',
    headerName: '',
    onClick: (_, row) => onSelected(row.ogdomain),
    renderHeader: () => (
      <TrackedKrgButton
        id="create-property"
        text="Create Property"
        onClick={onCreateButtonClick}
        variant={BUTTON_VARIANT_ENUM.contained}
        size={BUTTON_SIZE_ENUM.small}
        style={{ float: 'right' }}
      />
    ),
    renderCell: (row) => <ButtonsCell domain={row.ogdomain} />,
  }];
  const rows = properties?.map((d) => ({
    ...d,
    ogdomain: d,
    playerscount: d.configs.length,
    id: d.token,
  })) || [];

  return (
    <Table
      size={6}
      columns={columns}
      rows={rows}
    />
  );
}
