import { MouseEvent, ReactNode } from 'react';
import { DEPRECATED } from '@kargo/shared-components.krg-shared';

export interface MenuItemData {
  name?: string;
  icon?: ReactNode;
  content?: ReactNode;
  isHidden?: boolean;
  isEnabled?: boolean;
  onClick?: (e: MouseEvent<HTMLLIElement>) => void;
  /* DEPRECATED */
  disabled?: DEPRECATED; // Deprecated on 2.0.35 in favour of 'isEnabled'
  hidden?: DEPRECATED; // Deprecated on 2.0.35 in favour of 'isHidden'
}
