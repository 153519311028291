import { FONT_FAMILY_ENUM, BACKGROUND_ENUM } from './enums';

export const COLORS = {
  BLACK: 'rgb(0, 0, 0)',
  BACKGROUND_DARK: 'rgb(167, 179, 189)',
  BLACK_DISABLED: 'rgb(145, 160, 173)',
  BLACK_HIGH_EMPHASIS: 'rgb(48, 50, 55)',
  BLACK_INACTIVE: 'rgb(209, 217, 222)',
  BLACK_MEDIUM_EMPHASIS: 'rgb(63, 72, 83)',
  BLACK_SHADOW: 'rgba(0, 0, 0, 0.25)',
  CREATIVE_TITLE: 'rgb(47, 47, 47)',
  ERROR_DARK: 'rgb(255, 48, 55)',
  ERROR_LIGHT: 'rgb(255, 234, 235)',
  GRAY_10: 'rgb(43, 49, 58)',
  GRAY_3: 'rgb(222, 226, 230)',
  GRAY_CHIP_MEDIUM: 'rgb(224, 224, 224)',
  GRAY_DARK: 'rgb(101, 108, 117)',
  GRAY_DARK_2: 'rgb(112, 112, 112)',
  GRAY_DARK_3: 'rgb(139, 139, 139)',
  GRAY_DARK_4: 'rgb(155, 155, 155)',
  GRAY_DARK_5: 'rgb(66, 66, 66)',
  GRAY_DIVIDER: 'rgb(167, 179, 189)',
  GRAY_LIGHT: 'rgb(238, 240, 242)',
  GRAY_LIGHT_1: 'rgb(223, 220, 220)',
  GRAY_LIGHT_2: 'rgb(236, 236, 236)',
  GRAY_LIGHT_3: 'rgb(196, 196, 196)',
  GRAY_LIGHT_4: 'rgb(229, 229, 229)',
  GRAY_LIGHT_5: 'rgb(199, 199, 199)',
  GRAY_LINK_INACTIVE: 'rgb(128, 131, 137)',
  GRAY_PREVIEW_TEXT: 'rgb(199, 207, 213)',
  GRAY_SPONSOR_TEXT: 'rgb(142, 148, 160)',
  GRAY_BORDER: 'rgb(167, 179, 189)',
  GREEN_LOADER: 'rgb(0, 199, 177)',
  PREVIEW_HEADER_TEXT: 'rgb(60, 60, 60)',
  PRIMARY_10: 'rgb(219, 237, 251)',
  PRIMARY_100: 'rgb(179, 233, 246)',
  PRIMARY_400: 'rgb(51, 196, 232)',
  PRIMARY_50: 'rgb(230, 248, 252)',
  PRIMARY_500_ACCENT: 'rgb(0, 181, 226)',
  PRIMARY_900: 'rgb(0, 79, 124)',
  PRIMARY_DARK_TEXT: 'rgb(0, 72, 128)',
  SUCCESS_DARK: 'rgb(0, 148, 126)',
  SUCCESS_LIGHT: 'rgb(230, 249, 247)',
  SURFACE_SNACKBAR: 'rgb(32, 32, 32)',
  WARNING_BASE: 'rgb(255, 205, 0)',
  WARNING_DARK: 'rgb(255, 174, 0)',
  WARNING_LIGHT: 'rgb(255, 250, 230)',
  WHITE: 'rgb(255, 255, 255)',
  WHITE_MEDIUM_EMPHASIS: 'rgba(255, 255, 255, 0.7)',
  LOADER_BORDER: 'rgb(0, 199, 177)',
  FACEBOOK_BANNER_DIVIDER: 'rgb(197, 195, 195)',
  LOADER_LIGHT_BLUE: 'rgb(79, 180, 221)',
  LOADER_GREEN: 'rgb(88, 196, 178)',
  GRAY_HELPER: 'rgb(129, 129, 129)',
};

export const COLORS_V2 = {
  PRIMARY_50:  'rgb(229, 235, 255)',  // #E5EBFF
  PRIMARY_100: 'rgb(204, 214, 255)',  // #CCD6FF
  PRIMARY_200: 'rgb(165, 183, 255)',  // #A5B7FF
  PRIMARY_300: 'rgb(123, 150, 255)',  // #7B96FF
  PRIMARY_400: 'rgb(91, 124, 255)',   // #5B7CFF
  PRIMARY_500: 'rgb(60, 97, 243)',    // #3C61F3
  PRIMARY_600: 'rgb(37, 80, 250)',    // #2550FA
  PRIMARY_700: 'rgb(0, 51, 255)',     // #0033FF
  PRIMARY_800: 'rgb(1, 33, 160)',     // #0121A0
  PRIMARY_900: 'rgb(8, 8, 48)',       // #080830

  NEUTRAL_BLACK:    'rgb(0, 0, 0)',       // #000000
  NEUTRAL_DARK:     'rgb(145, 160, 173)', // #91A0AD
  NEUTRAL_INACTIVE: 'rgb(209, 217, 222)', // #D1D9DE
  NEUTRAL_MEDIUM:   'rgb(229, 234, 239)', // #E5EAEF
  NEUTRAL_LIGHT:    'rgb(238, 243, 246)', // #EEF3F6
  NEUTRAL_WHITE:    'rgb(255, 255, 255)', // #FFFFFF

  SUCCESS_DARK:     'rgb(0, 148, 126)',   // #00947E
  SUCCESS_LIGHT:    'rgb(0, 199, 177)',   // #00C7B1
  SUCCESS_LIGHTEST: 'rgb(230, 246, 243)', // #E6F6F3

  WARNING_DARK:     'rgb(255, 174, 0)',   // #FFAE00
  WARNING_LIGHT:    'rgb(255, 205, 0)',   // #FFCD00
  WARNING_LIGHTEST: 'rgb(255, 246, 212)', // #FFF6D4

  ERROR_DARK:       'rgb(255, 48, 55)',   // #FF3037
  ERROR_LIGHT:      'rgb(255, 131, 135)', // #FF8387
  ERROR_LIGHTEST:   'rgb(255, 234, 235)', // #FFEAEB
};

export const COLORS_V2_ALPHA = {
  NEUTRAL_BLACK_SHADOW: COLORS_V2.NEUTRAL_BLACK.replace(')', ', 0.25)'),  // #00000040
  NEUTRAL_BLACK_SCRIM:  COLORS_V2.NEUTRAL_BLACK.replace(')', ', 0.4)'),   // #00000066
  NEUTRAL_WHITE_FADE:   COLORS_V2.NEUTRAL_WHITE.replace(')', ', 0.54)'),  // #FFFFFF8a
};

export const STORE_KEY_FONT_FAMILY = 'demo_font_family';

export const STORE_KEY_THEME = 'demo_theme';

export const DATA_UNIT_CONVERSION = 1024;

export const FONT = {
  WEIGHT: {
    // Light
    THIN: 100,
    EXTRA_LIGHT: 200,
    LIGHT: 300,

    // Regular
    NORMAL: 400,
    MEDIUM: 500,

    // Bold
    SEMI_BOLD: 600,
    BOLD: 700,
    EXTRA_BOLD: 800,
  },
  SIZE: {
    // Regular Text
    SMALL: '0.625em',       // 10px
    REGULAR: '0.6875em',    // 11px
    MEDIUM: '0.75em',       // 12px
    SEMI_LARGE: '0.8125em', // 13px
    LARGE: '0.875em',       // 14px
    EXTRA_LARGE: '1em',     // 16px

    // Set default
    BASE: '16px',        // Default font browser

    // Titles
    H2: '1.125em',          // 18px
    H1: '2.25em',           // 36px
  },
  FAMILY: {
    COLFAX: FONT_FAMILY_ENUM.colfax,
    POPPINS: FONT_FAMILY_ENUM.poppins,
    VERDANA: 'Verdana, sans-serif',
    HELVETICA: 'Helvetica, sans-serif',
  },
};

export const BACKGROUND_COLOR = {
  [BACKGROUND_ENUM.black]: COLORS_V2.NEUTRAL_BLACK,
  [BACKGROUND_ENUM.white]: COLORS_V2.NEUTRAL_WHITE,
  [BACKGROUND_ENUM.blue]: COLORS_V2.PRIMARY_700,
};

export const MAX_FILE_SIZE = 4;

export const MAX_VIDEO_SIZE = 20;

export const MAX_VIDEO_DURATION = 65;
