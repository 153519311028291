import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useSnackbar from './useSnackbar';
import useAPI from '../api/useAPI';
import { PlaylistInput } from '../api/types';

type PlaylistQuery = {
  asAdmin?: boolean;
  title?: string;
};
export default function usePlaylists(inputQuery: PlaylistQuery = {}) {
  const queryClient = useQueryClient();
  const {snackbarSuccess} = useSnackbar();
  const { api, didLoad } = useAPI();
  const fetchPlaylists = useCallback(() => api && api.getPlaylists(inputQuery), [api, inputQuery]);
  const query = useQuery(['playlists', inputQuery], () => fetchPlaylists(), { enabled: didLoad, staleTime: Infinity });
  const createMutation = useMutation({
    mutationFn: (input: PlaylistInput) => api.createPlaylist(input),
    onSuccess: () => {
      snackbarSuccess('Playlist Successfully Created');
      queryClient.invalidateQueries({ queryKey: ['playlists'] });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: (token: string) => api.deletePlaylist(token),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['playlists'] });
    },
  });
  const updateMutation = useMutation({
    mutationFn: ({ token, data }: { token: string, data: PlaylistInput }) => (
      api.updatePlaylist(token, data)
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['playlists'] });
    },
  });
  return {
    query,
    playlists: query.data?.playlists || [],
    api: {
      create: createMutation.mutate,
      delete: deleteMutation.mutate,
      update: updateMutation.mutate,
    },
    mutations: {
      createMutation,
      deleteMutation,
      updateMutation,
    },
  };
}
