import { makeStyles } from '@mui/styles';
import { FONT } from '@kargo/shared-components.krg-shared';

const useBaseStyles = makeStyles({
  /*
    Table body
  */
  tableRowCheckable: {
    cursor: 'pointer',
  },
  tableRowCellCheckbox: {
    textAlign: 'center',
    width: '3em',
  },
  tableRowClickable: {
    cursor: 'pointer',
  },
  tableCellClickable: {
    cursor: 'pointer',
  },
  tableRowFirstCellPadding: {
    paddingLeft: '1.2em',
  },
  stickyColumn: {
    position: 'sticky',
    zIndex: 1,
  },

  /*
    Custom cell
  */
  customCell: {
    display: 'flex',
    fontSize: FONT.SIZE.MEDIUM,
  },
});

export default useBaseStyles;
