import { makeStyles } from '@mui/styles';
import { COLORS_V2, FONT, FONT_FAMILY_ENUM } from '@kargo/shared-components.krg-shared';

const useBaseStyles = makeStyles({

  // Content container (paper) of popover
  popoverPaper: {
    borderRadius: 0,
    margin: '0.625em 0 0 -0.75em',
    padding: '0.575em 0.5em',
  },

  // icons (calendar and clear)
  icon: {
    display: 'flex',
    cursor: 'pointer',
    width: '2em',
  },

  // Main container
  pickerRoot: {
    fontSize: FONT.SIZE.BASE,
    fontFamily: FONT_FAMILY_ENUM.poppins,
    margin: '0.25em 0.25em 0 0.25em',
  },

  // Container for change year and month
  changeMonthYearContainer: {
    display: 'flex',
    height: '2.25em',
  },

  // Label for change year and month
  changeMonthYearLabel: {
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
    padding: '0.5em 0.5em 0 0.75em',
  },

  // Dropdown for change year and month
  changeMonthYearDropdown: {
    position: 'relative',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS_V2.PRIMARY_700,
    },
  },

  // Label for day of week (top)
  dayOfWeek: {
    fontSize: FONT.SIZE.SMALL,
    fontWeight: FONT.WEIGHT.MEDIUM,
    color: COLORS_V2.NEUTRAL_DARK,
    height: '2.75em',
  },

  // Label for day of month (1-31)
  day: {
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
    width: '3.63637em',
    height: '3.63637em',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_LIGHT,
      color: COLORS_V2.NEUTRAL_BLACK,
    },
  },

  // Label for day + today
  dayToday: {
    border: `1px solid ${COLORS_V2.PRIMARY_700}`,
    color: COLORS_V2.PRIMARY_700,
  },

  // Label for day + selected
  daySelected: {
    backgroundColor: COLORS_V2.PRIMARY_700,
    color: COLORS_V2.NEUTRAL_WHITE,
  },

  // Label for day + disabled
  dayDisabled: {
    pointerEvents: 'none',
    color: COLORS_V2.NEUTRAL_INACTIVE,
  },

  dayRangeStart: {
    borderRadius: '100% 0 0 100%',
  },
  dayRangeMiddle: {
    color: COLORS_V2.NEUTRAL_BLACK,
    backgroundColor: COLORS_V2.PRIMARY_50,
    borderRadius: 0,
  },
  dayRangeEnd: {
    borderRadius: '0 100% 100% 0',
  },
});

export default useBaseStyles;
