import React, { useState } from 'react';
import _ from 'lodash';
import { TablePagination as TablePaginationMui } from '@mui/material';
import { DEPRECATED, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import useBaseStyles, { Props as StyleProps } from './styles/table-pagination-base-style';
import useV1Styles from './styles/table-pagination-style-v1';
import useV2Styles from './styles/table-pagination-style-v2';

type Props = {
  /**
   * @summary
   * Table row count.
   */
  rowCount: number,
  /**
   * @summary
   * Current page
   * @description
   * Set page to control page by parent
   */
  page: number,
  /**
   * @summary
   * Current page size
   */
  pageSize: number,
  /**
   * @summary
   * Initial page size.
   */
  initialPageSize: number,
  /**
   * @summary
   * Table component base font-size.
   */
  fontSize?: string | number | (string & {}) | undefined,
  /**
   * @summary
   * Set `true` to disable interaction
   * @default
   * false
   */
  isLoading?: boolean,
  /**
   * @summary
   * This function is triggered when `page` or `pageSize` is changed.
   * @param page - Page (starts from 1)
   * @param pageSize - Page size
   */
  onPaginationChange?: (page: number, pageSize: number) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isLoading'
   */
  loading?: DEPRECATED,
  /**
   * @summary
   * Specify the theme in use
   */
  theme: THEME_ENUM,
};

const TablePagination = ({
  rowCount,
  page,
  pageSize,
  initialPageSize,
  fontSize,
  isLoading = false,
  onPaginationChange,
  theme,
}: Props) => {
  const props: StyleProps = {
    fontSize,
  };
  const baseStyles = useBaseStyles(props);
  const v1Styles = useV1Styles(props);
  const v2Styles = useV2Styles(props);
  const classes = { ...baseStyles, ...(theme === THEME_ENUM.v2 ? v2Styles : v1Styles) };
  const [isOpen, setOpen] = useState(false);

  const pageSizes = _.uniq([initialPageSize, 10, 50, 100].sort((a, b) => a - b));
  const disabledClass = isLoading ? classes.tablePaginationDisabled : '';
  const openClass = isOpen ? classes.tablePaginationOpen : '';

  return (
    <TablePaginationMui
      className={`${ classes.tablePagination } ${ disabledClass } ${ openClass }`}
      classes={{ menuItem: classes.tablePaginationMenuItem }}
      rowsPerPageOptions={ pageSizes }
      component="div"
      count={ rowCount }
      rowsPerPage={ pageSize }
      page={ page - 1 }
      SelectProps={{ MenuProps: {
        classes: {
          paper: classes.tablePaginationMenuPaper,
        },
      },
        onOpen: () => { setOpen(true); },
        onClose: () => { setOpen(false); },
      }}
      onPageChange={(e, newPage: number) => {
        onPaginationChange?.(newPage + 1, pageSize);
      }}
      onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newPageSize = parseInt(e.target.value, 10);
        onPaginationChange?.(1, newPageSize);
      }}
    />
  );
};

export default TablePagination;
