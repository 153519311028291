import tracker from "../helpers/tracker";

/* eslint-disable-next-line arrow-body-style */
const useTrackedClick = (id: string, onClick?: (...args: any[]) => void) => {
  return (...args: any[]) => {
    tracker.track_button_click(id);
    if (onClick) {
      onClick(...args);
    }
  };
};

export default useTrackedClick;
