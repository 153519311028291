import React, { useContext } from "react";
import { useHistory } from "react-router";
import { IPath, KrgBreadcrumbs } from "../components/Breadcrumbs";
import FloatEditButtons from "../components/FloatEditButtons/FloatEditButtons";
import { useDomainConfig } from "../hooks/useDomainConfig";
import useSnackbar from "../hooks/useSnackbar";
import { normalizeUrl } from "../helpers/validateUrl";
import { Config } from "../api/types";
import { usePublisherContext } from "../hooks/usePublisherProvider";
import { useDomains } from "../hooks/useDomains";
import { useDomain } from "../hooks/useDomain";
import { capitalizeStrings } from "../helpers/capitilizeStrings";
import {
  IHttpMethod,
  ISuperAdminContext,
  ISuperAdminContextProps,
} from "./superadmin_types";
import { PATHS } from "../constants/paths";

const SuperAdminContext = React.createContext<ISuperAdminContext>(null);

export function SuperAdminProvider({
  isAdmin,
  children,
  slug,
}: ISuperAdminContextProps) {
  // Auth validation
  if (!isAdmin) return null;

  // States
  const [showEditButtons, setShowEditButtons] = React.useState(false);
  const [editConfig, setEditConfig] = React.useState<Record<string, any>>({});
  const [editDomainConfig, setEditDomainConfig] = React.useState<Config>(
    {} as Config,
  );
  const [advancedEditMode, setAdvancedEditMode] = React.useState(false);
  const [conflictedData, setConflictedData] = React.useState<Record<
    string,
    any
  > | null>(null);
  const [initialData, setInitialData] = React.useState({} as Config);
  const [hasValidationErros, setHasValidationsErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [httpMethod, setHttpMethod] = React.useState<IHttpMethod>();
  const { snackbarWarning, snackbarSuccess, snackbarError } = useSnackbar();
  const history = useHistory();
  const [params, setParams] = React.useState({
    playerToken: "",
    domainToken: "",
  });
  const { update } = useDomainConfig(
    params.playerToken,
    params.domainToken,
    initialData,
  );

  const { publisherSlug } = usePublisherContext(isAdmin);
  const query = isAdmin && { slug: publisherSlug || slug };
  const { domains, ...domainsAPI } = useDomains({ enabled: true, query });
  const [paths, setPaths] = React.useState<IPath[]>([
    { path: PATHS.ADMIN_DOMAIN, pathName: "Domains" },
  ]);
  const [selectedDomainToken, setSelectedDomainToken] = React.useState(
    paths[1]?.path.split("/")[3],
  );
  const { createConfig } = useDomain(selectedDomainToken);
  // Methods
  // Urls targeting has a dedicated service to update its changes.
  function targetingUrlManager(
    newConfig: Config,
    oldConfig: Config,
    domainName: string,
    domainToken: string,
    playerToken: string,
  ) {
    const urlsAlreadyOnServer = oldConfig?.config?.targeting_urls || [];
    // compare each url inside the updated config with the old urls
    const urlsToBeSaved = newConfig?.config?.targeting_urls
      ?.filter((url: string) => {
        // if old urls do not includes the url, means it a new one to be added.
        if (!urlsAlreadyOnServer?.includes(url)) return url;
        return false;
      })
      ?.map((url: string) => normalizeUrl(url));

    // delete urls will be the ones that is on the server but not anymore in the updated config
    const deletedUrls: string[] = urlsAlreadyOnServer?.filter((url: string) => {
      if (!newConfig.config.targeting_urls?.includes(url)) return url;
      return false;
    });
    // if any to be deleted call the server to delete
    if (deletedUrls?.length) {
      deletedUrls.forEach((url) => {
        domainsAPI.deleteTargetingUrl({
          url,
          token: domainToken,
          configToken: params.playerToken,
        });
      });
    }
    // if any to be saved call the server to save it
    if (urlsToBeSaved?.length) {
      domainsAPI.saveTargetingUrls({
        targeting_urls: urlsToBeSaved,
        token: domainToken,
        configToken: playerToken,
        domain: domainName,
      });
    }
  }
  const removePathFromBreadcrumbs = (idx: number) => {
    const slicedPaths = paths.slice(0, idx + 1);
    setPaths(slicedPaths);
  };
  const addPathToBreadCrumb = (ctxPaths: IPath[]) => {
    const tempArr = [...paths];
    ctxPaths.forEach((p) => {
      if (!paths.some((item) => item.path === p.path)) {
        if (!paths.some((item) => item.path === p.path)) {
          tempArr.push(p);
        }
      }
    });
    setPaths(tempArr);
  };
  const saveEditChanges = () => {
    const toBeSavedConfig = {
      ...(conflictedData || editConfig),
    };
    setConflictedData(null);
    setIsLoading(true);
    let newConfig: Config = {
      ...initialData,
      notes: editDomainConfig.notes,
      type: editDomainConfig.type,
      is_config_deletable: editDomainConfig.is_config_deletable,
      config: { ...toBeSavedConfig },
    };
    if (advancedEditMode) {
      newConfig = editDomainConfig;
    }
    const domainName = paths.length > 0 && paths[1]?.pathName;
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const targeting_urls = newConfig.config?.targeting_urls;
    targetingUrlManager(
      newConfig,
      initialData,
      domainName,
      params.domainToken,
      params.playerToken,
    );
    delete newConfig.config?.targeting_urls;
    update(newConfig, {
      onSuccess: () => {
        snackbarSuccess("Player Updated!");
        setIsLoading(false);
        history.goBack();
      },
      onError: (e) => {
        const error = { e };
        if (error.e.message) {
          const data = JSON.parse(error.e.message);
          setAdvancedEditMode(false);
          setConflictedData({
            ...data.domain.conflicted.config,
            targeting_urls,
          });
          setEditConfig({
            ...data.domain.current.config,
            targeting_urls: editConfig.targeting_urls,
          });
          setInitialData(data.domain.current);
          setIsLoading(false);
        }
      },
    });
  };
  const saveCloneChanges = () => {
    const newConfig = {
      key: editDomainConfig?.key || "",
      notes: editDomainConfig.notes,
      type: editDomainConfig.type,
      config: { ...editConfig },
    };

    createConfig(newConfig, {
      onSuccess: (response) => {
        const { domain } = response;
        const { token: domainToken, domain: domainName } = domain;
        const config: Config = {
          ...newConfig,
          created_at: domain.created_at,
          updated_at: domain.updated_at,
          updated_by: domain.updated_by,
          created_by: domain.created_by,
          token: domainToken,
        };
        const playerToken = response.domain.configs.find(
          (c) => c.key === editDomainConfig.key,
        ).token;
        targetingUrlManager(
          config,
          initialData,
          domainName,
          domainToken,
          playerToken,
        );
        delete newConfig.config?.targeting_urls;
        snackbarSuccess("Player Clone Created");
        history.goBack();
      },
      onError: (e: { message: string }) => {
        if (e.message.includes("412")) {
          snackbarError(
            `Error duplicated player name: ${newConfig.key} already exist at the domain destination`,
          );
        } else {
          snackbarError("Something went wrong!");
        }
      },
    });
  };

  const handleCloneOrEditChanges = () => {
    if (hasValidationErros) {
      snackbarWarning("Fix syntax erros before saving it!");
      return;
    }
    if (!editDomainConfig.notes.trim() || editDomainConfig.notes.length > 56) {
      snackbarWarning(
        "Please leave a note for this changes of maximun 56 characteris.",
      );
      return;
    }
    if (httpMethod === "create") {
      saveCloneChanges();
    } else {
      saveEditChanges();
    }
  };

  const cancelEditChanges = () => {
    removePathFromBreadcrumbs(paths.length - 2);
    snackbarWarning("Undone changes!");
    setEditConfig(initialData?.config);
    history.goBack();
  };

  const handlePublisherSwitch = (current_slug: string) => {
    const LS_PUBLISHER_SLUG = "publisherSlug";
    const lsSlug = localStorage.getItem(LS_PUBLISHER_SLUG);
    if (lsSlug && lsSlug !== current_slug) {
      localStorage.setItem(LS_PUBLISHER_SLUG, publisherSlug);
      removePathFromBreadcrumbs(0);
      history.push(PATHS.ADMIN_DOMAIN);
    } else {
      localStorage.setItem(LS_PUBLISHER_SLUG, publisherSlug);
    }
  };

  // LifeCycles
  React.useEffect(() => {
    window.addEventListener("popstate", () => {
      const tempPaths = [...paths];
      if (paths.length > 1) {
        tempPaths.pop();
        setPaths(tempPaths);
      }
    });
  });

  React.useEffect(() => {
    const { pathname } = window.location;
    setParams({
      playerToken: pathname.split("/").at(4),
      domainToken: pathname.split("/").at(3),
    });

    if (pathname === PATHS.ADMIN_PUBLISHER_ONBOARD) {
      setPaths([
        {
          path: pathname,
          pathName: "Onboard publisher",
        },
      ]);
    }
    if (pathname.includes("bulk")) {
      const splitedPathname = pathname.split("/")[2];
      setPaths([
        {
          path: pathname,
          pathName: `${capitalizeStrings(splitedPathname, "-")} Domains`,
        },
      ]);
    }
    if (pathname === PATHS.ADMIN_DOMAIN) {
      setPaths([{ path: PATHS.ADMIN_DOMAIN, pathName: "Domains" }]);
    }
  }, [window.location.pathname]);

  // redirect to domains page when switching publisher
  React.useEffect(() => {
    handlePublisherSwitch(publisherSlug);
  }, [publisherSlug]);
  // reset bread crumbs path to domains page when navigating back to domain
  React.useEffect(() => {
    removePathFromBreadcrumbs(0);
  }, [paths.length > 1 && window.location.pathname.split("/").length === 3]);

  // Context
  const contextValues = React.useMemo(
    () => ({
      removePathFromBreadcrumbs,
      addPathToBreadCrumb,
      setShowEditButtons,
      handleCloneOrEditChanges,
      editConfig,
      setEditConfig,
      setHttpMethod,
      initialData,
      setInitialData,
      setHasValidationsErrors,
      slug,
      paths,
      setSelectedDomainToken,
      selectedDomainToken,
      conflictedData,
      setConflictedData,
      isLoading,
      editDomainConfig,
      setEditDomainConfig,
      advancedEditMode,
      setAdvancedEditMode,
    }),
    [
      addPathToBreadCrumb,
      removePathFromBreadcrumbs,
      setShowEditButtons,
      handleCloneOrEditChanges,
      editConfig,
      setEditConfig,
      setHttpMethod,
      initialData,
      setInitialData,
      setHasValidationsErrors,
      paths,
      setSelectedDomainToken,
      selectedDomainToken,
      conflictedData,
      setConflictedData,
      isLoading,
      editDomainConfig,
      setEditDomainConfig,
      advancedEditMode,
      setAdvancedEditMode,
    ],
  );

  return (
    <SuperAdminContext.Provider value={contextValues}>
      <div>
        <KrgBreadcrumbs paths={paths} updatePaths={removePathFromBreadcrumbs} />
        {children}
        <FloatEditButtons
          show={showEditButtons}
          cancel={cancelEditChanges}
          save={handleCloneOrEditChanges}
        />
      </div>
    </SuperAdminContext.Provider>
  );
}

export const useSuperAdminContext = () => useContext(SuperAdminContext);
