import { makeStyles } from '@mui/styles';
import { BACKGROUND_ENUM, FONT } from '@kargo/shared-components.krg-shared';
import { VARIANT_ENUM, HORIZONTAL_POS_ENUM } from '../shared/enums';

export type Props = {
  variant: VARIANT_ENUM,
  background: BACKGROUND_ENUM
  buttonTextPosition: HORIZONTAL_POS_ENUM,
  iconFontSize?: string,
  hasIcon: boolean,
  hasIconBackground: boolean,
  isEnabled: boolean,
  isIconButton: boolean,
  isFullWidth: boolean,
  isSquaredBorder: boolean,
};

const useBaseStyles = makeStyles({
  /*
    Icon and text wrapper
  */
  innerContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },

  /*
    Text container and its sizes
  */
  textContainer: {
    display: ({ isIconButton }: Props) => isIconButton ? 'none' : 'block',
    width: '100%',
    textAlign: ({ buttonTextPosition }: Props) => buttonTextPosition,
  },

  /*
    Default button style
  */
  button: {
    fontSize: FONT.SIZE.BASE,
    fontWeight: FONT.WEIGHT.MEDIUM,
    lineHeight: 1.2,
    boxShadow: 'none',
    minWidth: ({ isIconButton }: Props) => isIconButton ? '0' : '4em',

    '&:active': {
      boxShadow: 'none',
    },

    '&:hover': {
      boxShadow: 'none',
    },

    '& .MuiButton-startIcon': {
      marginRight: '0',
    },
  },
});

export default useBaseStyles;
