import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';

const useStylesV2 = makeStyles({
  switchRoot: {
    // Disabled switch color
    '& .MuiSwitch-switchBase.Mui-disabled': {
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },

    // Disabled track color
    '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: COLORS_V2.NEUTRAL_INACTIVE,
      opacity: 0.5,
    },
  },

  // Unchecked color bullet
  switchBase: {
    color: COLORS_V2.NEUTRAL_LIGHT,
  },

  track: {
    backgroundColor: COLORS_V2.NEUTRAL_DARK,
    opacity: 1,
  },

  // Track selection icon indicators
  trackIcons: {
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '0.625em',
      height: '0.625em',
      backgroundRepeat: 'no-repeat',
    },

    // Checked icon
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg width="11" height="8" viewBox="0 -1 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="2.64645" x2="4.35355" y2="6.64645" stroke="white"/><line x1="10.1653" y1="0.318348" x2="4.38556" y2="7.31835" stroke="white"/></svg>')`,
      left: '1em',
    },

    // Unchecked icon
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.353553" y1="1.64645" x2="7.35355" y2="8.64645" stroke="white"/><line x1="7.35355" y1="1.23568" x2="0.474468" y2="8.64645" stroke="white"/></svg>')`,
      right: '1em',
    },
  },

  // Checked color
  checked: {
    color: COLORS_V2.PRIMARY_700,

    '&.Mui-checked+.MuiSwitch-track' : {
      backgroundColor: COLORS_V2.PRIMARY_100,
      opacity: 1,
    },
  },

  label: {
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
    marginRight: '1.5em',
    minWidth: '8em',
    color: COLORS_V2.NEUTRAL_BLACK,

    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },
  },

  disabled: {
    '& > .MuiSwitch-thumb' : {
      boxShadow: 'none',
    },
  },
});

export default useStylesV2;
