import React, { useEffect, useState } from 'react';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import clone from 'lodash/clone';
import { isAfter, isValid, parseISO } from 'date-fns';
import { Paper, Table, TableContainer } from '@mui/material';
import { Events, FONT, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgCheckbox from '@kargo/shared-components.krg-checkbox';
import TableHeader from './components/table/table-header.react';
import TablePagination from './components/table/table-pagination.react';
import TableBody from './components/table/table-body.react';
import ActionCell from './components/cells/action-cell.react';
import { DataTableCol, DataTableConfig, DataTableRow } from './shared/interfaces';
import { CELL_TYPE_ENUM, DISPLAY_ENUM, TYPE_ENUM } from './shared/enums';
import { TableOrder, TableOrderBy } from './shared/types';
import useBaseStyles from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Table id
   */
  id?: string,
  /**
   * @summary
   * Custom table class name.
   * @description
   * It can be used to add additional class to the table
   */
  className?: string,
  /**
   * @summary
   * Custom table style.
   * @description
   * It can be used to add additional style to the table
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Custom table container class name.
   * @description
   * It can be used to add additional class to the table container
   */
  containerClassName?: string,
  /**
   * @summary
   * Custom table container style.
   * @description
   * It can be used to add/override additional style to the table container
   */
  containerStyle?: React.CSSProperties,
  /**
   * @summary
   * Table config object
   */
  config: DataTableConfig,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
};

const KrgDataTable = ({
  id,
  className = '',
  style,
  containerClassName = '',
  containerStyle,
  config,
  theme = THEME_ENUM.v1,
  onClick,
  onBlur,
  onFocus,
}: Props) => {
  const baseStyles = useBaseStyles();
  const stylesV1 = useStylesV1();
  const stylesV2 = useStylesV2();
  const isV2Theme = theme === THEME_ENUM.v2;
  const classes = { ...baseStyles, ...(isV2Theme ? stylesV2 : stylesV1) };
  const {
    data,
    type,
    actionMenu,
    meta,
    methods,
    pagination,
  } = config;
  const initialPageSize = pagination?.initialPageSize || 10;
  const hidePagination = pagination?.isHidden || false;
  const parentPage = pagination?.page || 1;

  const [page, setPage] = useState<number>(parentPage);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [order, setOrder] = useState<TableOrder>(false);
  const [orderBy, setOrderBy] = useState<TableOrderBy>(null);
  const [rows, setRows] = useState<DataTableRow[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [notChecked, setNotChecked] = useState<DataTableRow[]>([]);
  const [checkedRows, setCheckedRows] = useState<DataTableRow[]>([]);

  const hideActionsColumn = !actionMenu || actionMenu?.actions.length === 0;

  const prependDefaultCheckboxToRows = (rowArray: DataTableRow[]) => {
    return rowArray.map(row => ({
      ...row,
      isChecked: isAllChecked
        ? !notChecked.map(notCheckedRow => notCheckedRow.id).includes(row.id)
        : checkedRows.some(checkedRow => checkedRow.id === row.id),
    }));
  };

  useEffect(
    () => {
      if (pagination?.page) {
        setPage(pagination?.page);
      }
    },
    [pagination?.page],
  );

  useEffect(
    () => {
      setRows(type === TYPE_ENUM.checkbox ? prependDefaultCheckboxToRows(data.rows) : data.rows);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, data.rows],
  );

  const isSortable = (col: DataTableCol) => {
    const defaultUnsortableTypes = ['thumbnail', 'actions', 'checkbox'];
    return !(col.type && defaultUnsortableTypes.includes(col.type));
  };

  const actionsColumn: DataTableCol = {
    field: 'actions',
    headerName: theme === THEME_ENUM.v2 ? 'Actions' : 'ACTIONS',
    type: CELL_TYPE_ENUM.actions,
    hasPreventBubbling: true,
    renderCell: (row: DataTableRow, _: DataTableCol) => {
      return (
        <ActionCell
          actions={ actionMenu?.actions || []}
          row={ row }
          fontSize={ style?.fontSize }
          isActionMenu={ actionMenu?.display === DISPLAY_ENUM.menu }
          theme={ theme }
        />
      );
    },
  };

  const checkboxColumn: DataTableCol = {
    field: 'isChecked',
    headerName: '',
    type: CELL_TYPE_ENUM.checkbox,
    renderCell: (row: DataTableRow, _: DataTableCol) => {
      return (
        <KrgCheckbox
          style={{ fontSize: FONT.SIZE.EXTRA_LARGE, justifyContent: 'center' }}
          isChecked={!!row.isChecked }
          theme={ theme }
          onToggle={(isChecked: boolean) => checkRow(isChecked, row)}
        />
      );
    },
    renderHeader: (_: DataTableCol) => {
      return (
        <KrgCheckbox
          style={{ fontSize: FONT.SIZE.EXTRA_LARGE, justifyContent: 'center' }}
          isIndeterminate={
            getCheckedRows().length > 0 && rows.length !== getCheckedRows().length
          }
          isChecked={
            getCheckedRows().length > 0 && rows.length === getCheckedRows().length
          }
          theme={ theme }
          onToggle={(isChecked: boolean) => checkAllRows(isChecked)}
        />
      );
    },
  };

  const getPrependedColumns = () => {
    const prependedColumns: DataTableCol[] = [];
    if (type === TYPE_ENUM.checkbox) {
      prependedColumns.push(checkboxColumn);
    }
    return prependedColumns;
  };
  const getAppendedColumns = () => hideActionsColumn ? [] : [actionsColumn];
  const tableColumns: DataTableCol[] =
    [
      ...getPrependedColumns(),
      ...data.columns,
      ...getAppendedColumns(),
    ]
    .map(column => ({
      isSortable: isSortable(column),
      ...column,
    }));

  const getRows = () => {
    if (pagination?.hasStrictRows) {
      return rows;
    }

    let modifiedRows: DataTableRow[];

    // Sort
    if (order && orderBy) {
      modifiedRows = sortRows(rows);
    } else {
      modifiedRows = rows;
    }

    // Paginate
    return modifiedRows.slice(
      (page - 1) * pageSize, (page - 1) * pageSize + pageSize,
    );
  };

  const getCheckedRows = (rowArray?: DataTableRow[]) => {
    return (rowArray || rows).filter(row => row.isChecked);
  };

  const sortRows = (rowArray?: DataTableRow[]) => {
    return clone(rowArray || rows).sort((a, b) => {
      let f1 = a[orderBy as string];
      let f2 = b[orderBy as string];

      let sortOrder = 1;
      const multiplier = order === 'asc' ? 1 : -1;
      const sortedColumn = data.columns.find(c => c.field === orderBy) as DataTableCol;

      // Use inner title if it is a subtitle column
      if (sortedColumn.type === 'subtitle') {
        f1 = f1.title;
        f2 = f2.title;
      }

      if (isNumber(f1) && isNumber(f2)) {
        // Number comparison
        sortOrder = f1 - f2;
      } else if (isValid(parseISO(f1)) && isValid(parseISO(f2))) {
        // Date comparison
        sortOrder = isAfter(parseISO(f1), parseISO(f2)) ? 1 : -1;
      } else if (isString(f1) && isString(f2)) {
        // String comparison
        sortOrder = f1.localeCompare(f2);
      } else {
        // Other
        sortOrder = f1 > f2 ? 1 : -1;
      }

      return multiplier * sortOrder;
    });
  };

  const checkRow = (isChecked: boolean, checkedRow: DataTableRow) => {
    // Get modified states
    const newCheckedRow: DataTableRow = { ...checkedRow, isChecked };
    const newRows = rows.map(row => row.id !== newCheckedRow.id ? row : ({
      ...row,
      isChecked,
    }));

    // Set rows
    setRows(newRows);
    if (isAllChecked) {
      const tmpNotChecked = notChecked;
      if (!isChecked) {
        tmpNotChecked.push(checkedRow);
        setNotChecked([...tmpNotChecked]);
      } else {
        setNotChecked([...tmpNotChecked.filter(row => checkedRow.id !== row.id)]);
      }
      methods?.onUncheckedRowsChange?.(
        (pagination?.totalRowCount || rows.length) !== tmpNotChecked.length,
        [...tmpNotChecked],
      );
    }
    // Inform parent object about the isChecked row if the function is defined
    let tmpCheckedRows = checkedRows;
    // Adds isChecked items to checkedRows stack
    tmpCheckedRows = tmpCheckedRows.concat(
      newRows.filter(
        row =>
          row.isChecked &&
          !tmpCheckedRows
            .some(uncheckedRow => uncheckedRow.id === row.id),
      ),
    );
    const tmpUncheckedRows = newRows.filter(row => !row.isChecked);
    // Removes unchecked rows from checkedRows stack
    tmpCheckedRows = tmpCheckedRows.filter(
      row =>
        !tmpUncheckedRows
          .some(uncheckedRow => uncheckedRow.id === row.id),
    );
    setCheckedRows(tmpCheckedRows);

    methods?.onRowCheck?.(isChecked, newCheckedRow, tmpCheckedRows);
  };

  const checkAllRows = (isChecked: boolean) => {
    methods?.onUncheckedRowsChange?.(isChecked, []);
    setNotChecked([]);
    setIsAllChecked(isChecked);
    // Get modified states
    const newRows = rows.map(row => ({
      ...row,
      isChecked,
    }));

    // Set rows
    setRows(newRows);

    // Inform parent component about the checked rows if the function is defined.
    // Instead of a diff array, an empty array is set as new checked rows
    // because of performance concerns
    methods?.onRowCheck?.(isChecked, [], getCheckedRows(newRows));
  };

  const changeTableOrder = (
    orderParam: TableOrder,
    orderByParam: TableOrderBy,
  ) => {
    setOrder(orderParam);
    setOrderBy(orderByParam);
    methods?.onTableOrderChange?.(orderParam, orderByParam);
  };

  const changePagination = (pageParam: number, pageSizeParam: number) => {
    setPage(pageParam);
    setPageSize(pageSizeParam);
    methods?.onPaginationChange?.(pageParam, pageSizeParam);
  };

  const noResults = (
    <div className={ classes.noResults }>No Results</div>
  );

  return (
    <Paper
      className={ classes.paper }
      onFocus={ onFocus }
      onBlur={ onBlur }
      onClick={ onClick }
    >
      <TableContainer
        className={`${ classes.tableContainer } ${ containerClassName }`}
        style={ containerStyle }
      >
        <Table
          id={ id }
          className={`${ classes.table } ${ className }`}
          style={ style }
          stickyHeader={ meta?.isStickyHeader }
        >
          <TableHeader
            ref={ meta?.headerRef }
            columns={ tableColumns }
            onTableOrderChange={ changeTableOrder }
            isLoading={ pagination?.isLoading }
            id={ id }
            theme={ theme }

          />

          <TableBody
            id={ id }
            columns={ tableColumns }
            rows={ getRows()}
            hasCheckboxSelection={ type === TYPE_ENUM.checkbox }
            rowClassName={ meta?.rowClassName }
            columnClassName={ meta?.columnClassName }
            theme={ theme }
            onRowChecked={ checkRow }
            onRowClick={ methods?.onRowClick }
          />
        </Table>
      </TableContainer>

      {/* No results */}
      {(getRows().length === 0 && !meta?.isNoResultsLabelHidden) && noResults }

      {!hidePagination && (
        <TablePagination
          page={ page }
          pageSize={ pageSize }
          initialPageSize={ initialPageSize }
          rowCount={ pagination?.totalRowCount || rows.length }
          fontSize={ style?.fontSize }
          isLoading={ pagination?.isLoading }
          onPaginationChange={ changePagination }
          theme={ theme }
        />
      )}
    </Paper>
  );
};

KrgDataTable.CELL_TYPE_ENUM = CELL_TYPE_ENUM;
KrgDataTable.DISPLAY_ENUM = DISPLAY_ENUM;
KrgDataTable.TYPE_ENUM = TYPE_ENUM;
KrgDataTable.THEME_ENUM = THEME_ENUM;

export default KrgDataTable;
