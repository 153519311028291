import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import { LookerEmbeddedExplore } from '../../components';
import useQueryParam from '../../hooks/useQueryParam';
import PaginatedTabs from '../../components/PaginatedTabs';
import { useLooker } from '../../hooks/useLooker';
import './styles.scss';

export default function Reporting({ asAdmin }: { asAdmin: boolean } = { asAdmin: false }) {
  const { videoPerformanceExploreId, monetizationExploreId } = useLooker();
  const padding = '20px';
  const tabs = [{ label: 'Video Performance' }, { label: 'Monetization' }];
  const tab = useQueryParam('tab', (t) => (parseInt(t, 10) || 0));
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState(tab);
  React.useEffect(() => {
    if (selectedTab === tab) return;
    history.push(`${window.location.pathname}?tab=${selectedTab}`);
  }, [selectedTab]);

  React.useEffect(() => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  return (
    <Box padding={padding}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <PaginatedTabs
          tabsConfig={{
            onChange: (e: any, value?: any) => setSelectedTab(value),
            selectedTab,
            tabs,
          }}
        />
        <Divider
        variant="fullWidth"
        sx={{
          mt: '-1px',
          borderBottomWidth: '1px',
          borderColor: 'rgb(209, 217, 222)',
        }}
      />
        <div id="video-explore-container" className={tab === 0 ? '' : 'hidden'}>
          <LookerEmbeddedExplore
            asAdmin={asAdmin}
            exploreId={videoPerformanceExploreId}
          />
        </div>
        <div id="monetization-explore-container" className={tab === 1 ? '' : 'hidden'}>
          <LookerEmbeddedExplore
            asAdmin={asAdmin}
            exploreId={monetizationExploreId}
          />
        </div>
      </Box>
    </Box>
  );
}
