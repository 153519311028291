import { BaseJsonSchema, createArraySchemaByItemType, createSchemaByBasicTypes } from '../../helpers';

const IBucket: BaseJsonSchema = {
  type: 'object',
  properties: {
    max: createSchemaByBasicTypes('number'),
    increment: createSchemaByBasicTypes('number', 0.05),
  },
};

export const IPriceGranularity: BaseJsonSchema = {
  type: 'object',
  properties: {
    buckets: createArraySchemaByItemType(undefined, [IBucket]),
  },
};
