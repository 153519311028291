import React from "react";

function useShiftKeyListener(
  set: React.Dispatch<React.SetStateAction<boolean>>,
  dependency: any[],
) {
  React.useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.shiftKey) {
        set(true);
        window.addEventListener('keyup', (ev) => {
          if (ev.key.toLocaleLowerCase().includes('shift')) {
            set(false);
          }
        });
      }
    });
  }, dependency);
}

export default useShiftKeyListener;
