import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';
import { IStorage } from './storage';

export const ILiveIntentId: BaseJsonSchema = {
  type: 'object',
  properties: {
    name: createSchemaByBasicTypes('string', '', {
      enum: ['liveIntentId'],
    }),
    params: {
      type: 'object',
      properties: {
        publisherId: createSchemaByBasicTypes('string', '78242'),
      },
      required: ['publisherId'],
      storage: IStorage,
    },
  },
};
