import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

export default () => {
  const [session, setSession] = useState<CognitoUserSession>();

  const fetchSession = async () => {
    const currentSession = await Auth.currentSession();
    setSession(currentSession);
  };

  useEffect(() => {
    fetchSession(); // initially fetch the session
    const intervalId = setInterval(fetchSession, 60 * 1000); // check every minute

    // clean up on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return session;
};
