/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../api/useAPI";
import { Domain, EditableConfig } from "../api/types";

export function useDomainConfig(
  token: string,
  domainToken: string,
  initialData?: any,
) {
  const { api } = useAPI();
  let queryParams = {
    queryKey: ["domain-config", { token }],
    queryFn: () =>
      api &&
      api
        .domain(domainToken)
        .getConfig(token)
        .then((res) => res.config),
    enabled: !!(api && token),
    staleTime: Infinity,
  };
  if (initialData) queryParams = { ...queryParams, initialData };
  const { data } = useQuery(queryParams);
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationFn: (data: EditableConfig) =>
      api.domain(domainToken).setConfig(token, data),
    onSuccess: (response: { domain: Domain }) => {
      queryClient.setQueryData(["domain", { token }], response.domain);
      response.domain.configs.forEach((c) =>
        queryClient.setQueryData(["domain-config", { token: c.token }], c),
      );
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: () => api.domain(domainToken).deleteConfig(token),
    onSuccess: () => {
      queryClient.setQueryData(["domain-config", { token }], undefined);
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  const getRevisions = useMutation({
    mutationFn: (configToken: string) =>
      api.getRevisionByConfigToken(configToken),
  });
  return {
    data,
    getRevisions: getRevisions.mutate,
    update: updateMutation.mutate,
    deleteConfig: (
      mutateOptions: Parameters<typeof deleteMutation.mutate>[1],
    ) => deleteMutation.mutate(undefined, mutateOptions),
  };
}
