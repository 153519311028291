import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';
import { IStorage } from './storage';

export const IId5Id: BaseJsonSchema = {
  type: 'object',
  properties: {
    name: createSchemaByBasicTypes('string', '', {
      enum: ['id5Id'],
    }),
    params: {
      type: 'object',
      properties: {
        partner: createSchemaByBasicTypes('number', 562),
      },
      required: ['partner'],
      storage: IStorage,
    },
  },
};
