import React, { useEffect } from 'react';
import { useMonaco, DiffEditor} from '@monaco-editor/react';
import { IJsonEditor } from './type';
import useDiffEditorSync from './useDiffEditor';

export default function KrgDiffEditor(props: IJsonEditor) {
  const {
    schemaValidation,
    onChange,
    onValidate,
    editValue,
    diffValue,
    renderSideBySide,
  } = props;
  const monaco = useMonaco();
  const { onMount } = useDiffEditorSync(onChange, onValidate);

  useEffect(() => {
    if (monaco) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: 'schemas.json',
            fileMatch: ['*'],
            schema: schemaValidation,
          },
        ],
      });
    }
  }, [monaco]);

  return (
    <DiffEditor
      height="100%"
      language="json"
      modified={diffValue}
      original={editValue}
      onMount={onMount}
      options={{
        renderSideBySide,
      }}
    />
  );
}
