export enum VARIANT_ENUM {
  text = 'text',
  outlined = 'outlined',
  contained = 'contained',
}

export enum SIZE_ENUM {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

export enum HORIZONTAL_POS_ENUM {
  left = 'left',
  center = 'center',
  right = 'right',
}
