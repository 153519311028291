/* eslint-disable no-promise-executor-return, jsx-a11y/alt-text */
import React from "react";

type ResilientSrc = {
  src: string;
  retries?: number;
};

const timeoutPromise = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export default function ResilientImage({
  src,
  retries = 2,
  delay = 1000,
  fallbacks,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> &
  ResilientSrc & {
    delay?: number;
    fallbacks?: Array<string | ResilientSrc>;
  }) {
  const [url, setUrl] = React.useState(src);
  const [retryCount, setRetryCount] = React.useState(0);
  const [fallbackIndex, setFallbackIndex] = React.useState(0);
  const [fallbackRetryCount, setFallbackRetryCount] = React.useState(0);
  const handleError: React.ReactEventHandler<HTMLImageElement> = async () => {
    await timeoutPromise(delay); // delay of 1 second before retrying
    if (retryCount < retries) {
      setUrl(`${src}?retry=${retryCount}`);
      setRetryCount(retryCount + 1);
    } else if (fallbacks?.length > 0 && fallbackIndex < fallbacks.length) {
      if (typeof fallbacks[fallbackIndex] === "string") {
        setUrl(fallbacks[fallbackIndex] as string);
        setFallbackIndex(fallbackIndex + 1);
        setFallbackRetryCount(0);
      } else {
        const currFallback = fallbacks[fallbackIndex] as ResilientSrc;
        setUrl(`${currFallback.src}?retry=${fallbackRetryCount}`);
        if (fallbackRetryCount > currFallback.retries) {
          setFallbackIndex(fallbackIndex + 1);
          setFallbackRetryCount(0);
        } else {
          setFallbackRetryCount(fallbackRetryCount + 1);
        }
      }
    }
  };
  return <img {...props} src={url} onError={handleError} draggable={false} />;
}
