import { makeStyles } from '@mui/styles';
import { COLORS_V2, COLORS_V2_ALPHA } from '@kargo/shared-components.krg-shared';
import  { Props } from './base-style';

const useStylesV2 = makeStyles({
  backdrop: {
    backgroundColor: COLORS_V2_ALPHA.NEUTRAL_BLACK_SCRIM,
  },
  dialogPaper: {
    padding: '3.2em 3.75em 1.875em 3.75em',
    boxShadow: `0 0.025em 0.025em ${COLORS_V2_ALPHA.NEUTRAL_BLACK_SHADOW}`,
    color: COLORS_V2.NEUTRAL_BLACK,
    minHeight: ({ headerIconType }: Props) => headerIconType ? '12.5em' : 'unset',
  },
  closeActionContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1.5em',

    '& > svg': {
      fontSize: '2em',
      color: COLORS_V2.NEUTRAL_DARK,

      '&:hover': {
        color: COLORS_V2.PRIMARY_700,
      },
    },
  },
});

export default useStylesV2;
