import React from "react";
import { Pagination as MUIPagination } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  pagination: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiPaginationItem-page": {
      margin: "0 4px",
    },
    "& .MuiPaginationItem-previousNext": {
      margin: 0,
    },
    "& .MuiPaginationItem-page.MuiPaginationItem-sizeSmall": {
      fontSize: "12px",
      minWidth: "20px",
      height: "20px",
    },
  },
});

export default function Pagination(
  props: React.ComponentProps<typeof MUIPagination>,
) {
  const classes = useStyles();
  return <MUIPagination className={classes.pagination} {...props} />;
}
