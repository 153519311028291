import { COLORS } from '@kargo/shared-components.krg-shared';
import { makeStyles } from '@mui/styles';

const useStylesV1 = makeStyles({
  switchRoot: {
    // Checked track color
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLORS.PRIMARY_100,
      opacity: 1,
    },
    // Disabled track color
    '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: COLORS.GRAY_LIGHT,
      opacity: 1,
    },
  },

  // Unchecked track color
  track: {
    backgroundColor: COLORS.GRAY_DARK,
    opacity: 1,
  },

  // Unchecked color
  switchBase: {
    color: COLORS.GRAY_LIGHT,
  },

  // Checked color
  checked: {
    color: COLORS.PRIMARY_500_ACCENT,
  },

  trackIcons: {},
  label: {},
  disabled: {},
});

export default useStylesV1;
