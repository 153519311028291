import SectionNavBar from './SectionNavBar';

export default SectionNavBar;

export {
  THEME_ENUM,
  VARIANT_ENUM as TABS_VARIANT_ENUM,
} from '@kargo/shared-components.krg-tabs';
export {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM,
} from '@kargo/shared-components.krg-button';
