import React, { useState, useContext, useEffect, useMemo } from "react";
import { getRecentSearchPublisher } from "../helpers/recentSearchPublisher";
import { getPublisherGroup, getUsername } from "../helpers/publisherUsername";

const DEFAULT_PUBLISHER_NAME = process.env.DEFAULT_PUBLISHER || "kargo";

const PublisherContext = React.createContext<{
  switchToPublisher: (publisherSlug: string) => void;
  publisherSlug: string;
  publisherUsername: string;
  switchToPublisherUsername: (publisherUsername: string) => void;
  publisherGroup: string;
  switchToPublisherGroup: (publisherGroup: string) => void;
}>(null);

export function UserPublisherProvider({ children }: React.PropsWithChildren) {
  const [publisherSlug, setPublisherSlug] = useState(null);
  const [publisherUsername, setPublisherUsername] = useState(null);
  const [publisherGroup, setPublisherGroup] = useState(null);
  const switchToPublisher = (plSlug: string) => setPublisherSlug(plSlug);
  const switchToPublisherUsername = (username: string) =>
    setPublisherUsername(username);
  const switchToPublisherGroup = (groupName: string) =>
    setPublisherGroup(groupName);

  useEffect(() => {
    const recentPublisherList = getRecentSearchPublisher();
    const plSlug = recentPublisherList?.length
      ? recentPublisherList[0].toLowerCase().replace(/ /g, "-")
      : DEFAULT_PUBLISHER_NAME;
    switchToPublisher(plSlug);
  }, []);
  useEffect(() => {
    switchToPublisherUsername(getUsername() || "");
  }, []);

  useEffect(() => {
    const plUsername = getPublisherGroup() || DEFAULT_PUBLISHER_NAME;
    switchToPublisherGroup(plUsername);
  }, []);

  const contextValues = useMemo(
    () => ({
      publisherSlug,
      switchToPublisher,
      publisherUsername,
      switchToPublisherUsername,
      publisherGroup,
      switchToPublisherGroup,
    }),
    [
      publisherSlug,
      switchToPublisher,
      publisherUsername,
      switchToPublisherUsername,
      publisherGroup,
      switchToPublisherGroup,
    ],
  );

  return (
    <PublisherContext.Provider value={contextValues}>
      {children}
    </PublisherContext.Provider>
  );
}

export const usePublisherContext = (isAdmin: boolean) => {
  const context = useContext(PublisherContext);
  if (!isAdmin) {
    return {
      publisherSlug: "",
      publisherGroup: "",
      publisherUsername: "",
      switchToPublisherGroup: () => "",
      switchToPublisher: () => "",
      switchToPublisherUsername: () => "",
    };
  }
  return context;
};
