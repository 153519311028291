export enum DISPLAY_ENUM {
  separate = 'separate',
  menu = 'menu',
}

export enum TYPE_ENUM {
  standard = 'standard',
  checkbox = 'checkbox',
}

export enum CELL_TYPE_ENUM {
  thumbnail = 'thumbnail',
  subtitle = 'subtitle',
  actions = 'actions',
  checkbox = 'checkbox',
}

export enum ACTION_TYPE_ENUM {
  edit = 'edit',
  duplicate = 'duplicate',
  preview = 'preview',
  archive = 'archive',
  logo = 'logo',
  approve = 'approve',
  deny = 'deny',
  unarchive = 'unarchive',
  archiveUser = 'archive-user',
  reactivateUser = 'reactivate-user',
}

export enum TEXT_ALIGNMENT {
  left = 'left',
  center = 'center',
  right = 'right',
}
