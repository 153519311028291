import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAPI from '../api/useAPI';
import { Config, Domain } from '../api/types';

export function useDomain(token: string, initialData?: Domain) {
  const queryClient = useQueryClient();
  const { api } = useAPI();
  let queryParams: {
    initialData?: Domain;
    queryKey: [string, { token: string }];
    queryFn: () => Promise<Domain>;
    enabled: boolean;
    staleTime: number;
    onSuccess: (response: Domain) => void;
  } = {
    queryKey: ['domain', { token }],
    queryFn: () => api && token && api.getDomain(token).then((res) => res.domain),
    enabled: !!api,
    staleTime: Infinity,
    onSuccess: (response: Domain) => {
      response?.configs?.forEach((c) => queryClient.setQueryData(['domain-config', { token: c.token }], c));
    },
  };
  if (initialData) queryParams = { ...queryParams, initialData };
  const query = useQuery(queryParams);

  const updateMutation = useMutation({
    mutationFn: (data: Partial<Domain>) => api.domain(token).update(data),
    onSuccess: (response: { domain: Domain }) => {
      queryClient.setQueryData(['domain', { token }], response.domain);
      response.domain.configs.forEach((config) => queryClient.setQueryData([
        'domain-config', { token: config.token },
      ], config));
      queryClient.invalidateQueries({ queryKey: ['domain-configs'] });
    },
  });

  const createConfigMutation = useMutation({
    mutationFn: (data: Partial<Config>) => api.domain(token).createConfig(data),
    onSuccess: (response: { domain: Domain }) => {
      queryClient.setQueryData(['domain', { token }], response.domain);
      response.domain.configs.forEach((config) => queryClient.setQueryData([
        'domain-config', { token: config.token },
      ], config));
      queryClient.invalidateQueries({ queryKey: ['domain-configs'] });
    },
  });

  return {
    ...query,
    update: updateMutation.mutate,
    createConfig: createConfigMutation.mutate,
  };
}
