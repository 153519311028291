import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';
import { Props } from './table-pagination-base-style';

const useV1Styles = makeStyles({
  tablePagination: {
    fontSize: ({ fontSize }: Props) => fontSize || FONT.SIZE.BASE,

    '& .MuiTablePagination-toolbar': {
      minHeight: '2.8em',
    },

    '& .MuiIconButton-label .MuiSvgIcon-root': {
      fontSize: FONT.SIZE.SEMI_LARGE,
    },

    '& .MuiTablePagination-caption': {
      fontSize: FONT.SIZE.SEMI_LARGE,
      color: COLORS.BLACK_MEDIUM_EMPHASIS,
    },

    '& .MuiTablePagination-select': {
      fontSize: FONT.SIZE.SEMI_LARGE,
      color: COLORS.BLACK_MEDIUM_EMPHASIS,
    },
  },

  tablePaginationMenuItem: {
    color: COLORS.BLACK_MEDIUM_EMPHASIS,
    fontSize: FONT.SIZE.SEMI_LARGE,
  },
  tablePaginationMenuPaper: {},
  tablePaginationOpen: {},
});

export default useV1Styles;
