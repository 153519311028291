import React, { FC } from 'react';
import { KrgStyleSelector } from '../';
import { FONT_FAMILY_ENUM } from '../enums';
import { STORE_KEY_FONT_FAMILY } from '../constants';

type Props = {
  setFontFamily: (fontFamily: FONT_FAMILY_ENUM) => void,
};

const KrgFontSelector: FC<Props> = ({ setFontFamily }) => {

  return (
    <KrgStyleSelector
      label="Select Font"
      options={[FONT_FAMILY_ENUM.colfax, FONT_FAMILY_ENUM.poppins]}
      storageKey={STORE_KEY_FONT_FAMILY}
      onChange={setFontFamily} />
  );
};

export default KrgFontSelector;
