import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TableHead, TableRow, TableSortLabel, TableCell, capitalize } from '@mui/material';
import { DEPRECATED, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { DataTableCol, DataTableHeaderRef } from '../../shared/interfaces';
import { CELL_TYPE_ENUM, TEXT_ALIGNMENT } from '../../shared/enums';
import { TableOrder, TableOrderBy } from '../../shared/types';
import useStylesV1 from './styles/table-header-style-v1';
import useStylesV2 from './styles/table-header-style-v2';
import useBaseStyles from './styles/table-header-base-style';

type Props = {
  /**
   * @summary
   * Component Id
   */
  id?: string,
  /**
   * @summary
   * Table columns.
   */
  columns: DataTableCol[],
  /**
   * @summary
   * Set `true` to disable interaction
   * @default
   * false
   */
  isLoading?: boolean,
  /**
   * @summary
   * This function is triggered when `order` or `orderBy` is changed.
   * @param tableOrder - Order type
   * @param tableOrderBy - Order field (Corresponds to the `field` field of `column`)
   */
  onTableOrderChange?: (tableOrder: TableOrder, tableOrderBy: TableOrderBy) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.84 in favour of 'isLoading'
   */
  loading?: DEPRECATED,
  theme: THEME_ENUM,
};

const TableHeader = forwardRef<DataTableHeaderRef, Props>((
  {
    columns,
    onTableOrderChange,
    isLoading,
    id,
    theme,
  },
  ref,
) => {
  const baseStyles = useBaseStyles();
  const stylesV1 = useStylesV1();
  const stylesV2 = useStylesV2();
  const classes = { ...baseStyles, ...(theme === THEME_ENUM.v2 ? stylesV2 : stylesV1) };
  const [order, setOrder] = useState<TableOrder>(false);
  const [orderBy, setOrderBy] = useState<TableOrderBy>(null);
  const [tableRowId] = useState(`tableRow-${id || Math.random()}`);

  useImperativeHandle(ref, () => ({
    setOrder: (tableOrder: TableOrder, tableOrderBy: TableOrderBy) => {
      let newOrder: TableOrder;
      let newOrderBy: TableOrderBy;

      const columnExists = columns.find(column => column.field === tableOrderBy);
      if (columnExists) {
        newOrder = tableOrder;
        newOrderBy = tableOrderBy;
      } else {
        newOrder = false;
        newOrderBy = null;
      }

      setOrder(newOrder);
      setOrderBy(newOrderBy);
      if (onTableOrderChange) {
        onTableOrderChange(newOrder, newOrderBy);
      }
    },
  }));

  const toggleOrder = (field: string) => {
    const isNewOrderField = field !== orderBy;
    const newOrder =
      isNewOrderField ? 'asc' :
      order === 'asc' ? 'desc' :
      order === 'desc' ? false :
      'asc';
    const newField = newOrder ? field : null;

    setOrder(newOrder);
    setOrderBy(field);
    if (onTableOrderChange) {
      onTableOrderChange(newOrder, newField);
    }
  };

  const tableCellAlign = (column: DataTableCol, index: number) => {
    if (!column.isSticky) {
      return {};
    }
    const table = document.getElementById(tableRowId) as HTMLTableRowElement;
    if (table && table.cells.length > 0) {
      const lengthOfColumn = table.cells[index] ? table.cells[index].getBoundingClientRect().x : 20;
      return { left: lengthOfColumn - 20 };
    }
    return {};
  };

  return (
    <TableHead className={ classes.root }>
      <TableRow id={ tableRowId }>
        {
          columns.map((column, idx) =>
            <TableCell
              key={ column.field }
              className={
                `${ column.isSticky ? classes.stickyColumn : ''}` +
                ` ${ classes.tableHeaderCell }` +
                ` ${ column.field === 'isChecked' ? classes.tableHeaderCellCheckbox : ''}` +
                ` ${ column.type === 'actions' ? classes.tableHeaderActionsCell : ''}` +
                ` ${ (column.type !== CELL_TYPE_ENUM.checkbox && idx === 0) ? classes.tableHeaderFirstCellPadding : ''}` +
                ` ${ isLoading ? classes.tableHeaderCellDisabled : ''}`
              }
              sortDirection={ orderBy === column.field ? order : false }
              style={{
                ...(
                  column.width ?
                  {
                    minWidth: column.width,
                    maxWidth: column.width,
                    width: column.width,
                  } :
                  {}
                ),
                ...tableCellAlign(column, idx),
                textAlign: column.textAlignment || TEXT_ALIGNMENT.left,
              }}
            >
              {
                column.renderHeader ?
                // Custom header render
                column.renderHeader(column) :
                // Sorted header render
                column.isSortable ?
                <TableSortLabel
                  active={ orderBy === column.field && !!order }
                  direction={ orderBy === column.field ? (order || 'asc') : 'asc'}
                  onClick={ () => toggleOrder(column.field) }
                >
                  { theme === THEME_ENUM.v2 ? capitalize(column.headerName)
                      : column.headerName?.toLocaleUpperCase() }
                </TableSortLabel> :
                // Default header render
                <span>{ theme === THEME_ENUM.v2 ? capitalize(column.headerName)
                    : column.headerName?.toLocaleUpperCase() }</span>
              }
            </TableCell>,
          )
        }
      </TableRow>
    </TableHead>
  );
});

export default TableHeader;
