import { BaseJsonSchema, createSchemaByBasicTypes } from "../../helpers";

export const IGoogleAdTagDefaults: BaseJsonSchema = {
  type: "object",
  properties: {
    cust_params: {
      type: "object",
      properties: {
        kv_test: createSchemaByBasicTypes("string"),
      },
    },
  },
};
