import React, { useState, useEffect, MouseEvent } from 'react';
import {
  DayPicker,
  DayModifiers,
  Matcher,
  ClassNames,
  DayPickerProps,
  DateRange,
} from 'react-day-picker';
import { format } from 'date-fns';

// Material UI
import { Popover } from '@mui/material';
import {
  CalendarToday as CalendarIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';

// Kargo components
import { Events, THEME_ENUM, INPUT_VARIANT_ENUM as VARIANT_ENUM } from '@kargo/shared-components.krg-shared';
import KrgTextInput from '@kargo/shared-components.krg-text-input';

// Component
import { MAX_NUMBER_YEARS } from './shared/constants';
import {
  formatWeekdayName,
  getIcon,
  getDateFromText,
  getDateRangeFromText,
  isValidDate,
  isValidDateRange,
} from './helpers';

// Styles
import useBaseStyles from './styles/base-style';
import 'react-day-picker/dist/style.css';

type Props = Events & {
  /**
   * @summary
   * Date (single selection) value
   */
  date?: Date | null,
  /**
   * @summary
   * Dates (range selection) value
   */
  dateRange?: DateRange | null,
  /**
   * @summary
   * Class names for input and popover
   */
  classNames?: {
    input?: string,
    popover?: string,
    popoverPaper?: string,
  }
  /**
   * @summary
   * Class names for inner components of day picker
   */
  dayPickerClassNames?: ClassNames,
  /**
   * @summary
   * Input style
   */
  inputStyle?: React.CSSProperties,
  /**
   * @summary
   * Input variant
   * @default
   * VARIANT_ENUM.outlined
   */
  inputVariant?: VARIANT_ENUM,
  /**
   * @summary
   * Input label
   */
  label?: string,
  /**
   * @summary
   * Input placeholder
   */
  placeholder?: string,
  /**
   * @summary
   * Set disabled day/s in the date picker component
   */
  disabledDates?: Matcher | Matcher[],
  /**
   * @summary
   *  Shows error text message under input
   */
  errorMessage?: string,
  /**
   * @summary
   * If `true`, the date picker will be range selection (instead of single)
   * @default
   * false
   */
  isRange?: boolean,
  /**
   * @summary
   * If `false`, the input / date picker will be disabled
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Set this `false` to hide clear icon
   * @default
   * true
   */
  isClearable?: boolean,
  /**
   * @summary
   * If `true`, the calendar will be open once it is cleared.
   * @default
   * false
   */
  isOpenCalendarOnClear?: boolean,
  /**
   * @summary
   * Function triggered on click clear icon
   */
  onDateClear?: () => void,
  /**
   * @summary
   * Function triggered on change single selection date
   */
  onDateChange?: (date: Date | null) => void,
  /**
   * @summary
   * Function triggered on range single selection date
   */
  onDateRangeChange?: (dateRange: DateRange | undefined) => void,
};

const KrgDatePicker = (
  {
    date,
    dateRange,
    classNames = {
      input: '',
      popover: '',
      popoverPaper: '',
    },
    dayPickerClassNames,
    inputStyle,
    inputVariant = VARIANT_ENUM.outlined,
    label,
    placeholder,
    disabledDates,
    errorMessage,
    isRange = false,
    isEnabled = true,
    isClearable = true,
    isOpenCalendarOnClear = false,
    onDateClear,
    onDateChange,
    onDateRangeChange,
    onBlur,
    onClick,
    onFocus,
  }: Props) => {
  const now = new Date();
  const theme = THEME_ENUM.v2;
  const classes = useBaseStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dateInputText, setDateInputText] = useState<string>('');

  const isValidDateText = isRange
    ? isValidDateRange(dateInputText)
    : isValidDate(dateInputText);

  useEffect(
    () => {
      let newDateInputText = '';

      if (isRange && dateRange?.from && dateRange?.to) {
        newDateInputText = `${format(dateRange.from, 'P')} - ${format(dateRange.to, 'P')}`;
      } else if (!isRange && date) {
        newDateInputText = format(date, 'P');
      }

      setDateInputText(newDateInputText);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, dateRange],
  );

  const onCalendarClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget.children[0] as HTMLElement);
    onClick?.(e);
  };

  const onSelectDate = (newDate: Date, { disabled: dateDisabled }: DayModifiers) => {
    if (!dateDisabled) {
      setAnchorEl(null);
      onBlur?.(null as any);
      onDateChange?.(newDate);
    }
  };

  const onSelectDateRange = (selectedRange: DateRange | undefined, selectedDay: Date) => {
    let newRange = selectedRange;

    if (dateRange?.from && dateRange?.to) {
      // Emulate toggling selection. first click from, second click to, third click from...
      newRange = {
        from: selectedDay,
        to: undefined,
      };
    }

    onBlur?.(null as any);
    onDateRangeChange?.(newRange);
  };

  const onClearClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    isRange
      ? onDateRangeChange?.(undefined)
      : onDateChange?.(null);

    onDateClear?.();

    if (isOpenCalendarOnClear) {
      setAnchorEl(e.currentTarget.parentElement);
    }
  };

  const isRangeSameDay = () => {
    if (dateInputText && isRange) {
      const dateParts = dateInputText.split('-');
      const from = dateParts[0].trim();
      const to = dateParts[1].trim();
      return from === to;
    }
    return false;
  }

  // Style classes definition for date picker
  const datePickerClassNames: ClassNames = {
    root: classes.pickerRoot,
    caption_dropdowns: classes.changeMonthYearContainer,
    caption_label: classes.changeMonthYearLabel,
    dropdown_month: classes.changeMonthYearDropdown,
    dropdown_year: classes.changeMonthYearDropdown,
    head_cell: classes.dayOfWeek,
    button: classes.day,
    day_today: classes.dayToday,
    day_selected: classes.daySelected,
    day_disabled: classes.dayDisabled,
    day_range_start: isRangeSameDay() ? classes.daySelected : classes.dayRangeStart,
    day_range_middle: classes.dayRangeMiddle,
    day_range_end: dateRange?.to ? isRangeSameDay() ? classes.daySelected : classes.dayRangeEnd : classes.dayRangeStart,
    ...dayPickerClassNames, // Add custom classes (from props)
  };

  // Common Date picker props (single and range)
  const datePickerProps: Partial<DayPickerProps> = {
    classNames: datePickerClassNames,
    defaultMonth: date || dateRange?.from || now,
    disabled: disabledDates || undefined,
    captionLayout: 'dropdown',
    fromYear: (date || now).getFullYear() - MAX_NUMBER_YEARS / 2,
    toYear: (date || now).getFullYear() + MAX_NUMBER_YEARS / 2,
    formatters: { formatWeekdayName },
  };

  return (
    <>
      <KrgTextInput
        className={classNames.input}
        style={{ ...inputStyle }}
        variant={inputVariant}
        placeholder={placeholder}
        label={label}
        text={dateInputText}
        startAdornment={
          getIcon(<CalendarIcon/>, classes.icon, isEnabled, onCalendarClick)
        }
        endAdornment={
          (date || dateRange) && isClearable
            ? getIcon(<ClearIcon/>, classes.icon, isEnabled, onClearClick)
            : undefined
        }
        errorMessage={errorMessage || (isValidDateText ? undefined : 'Error Format')}
        isEnabled={isEnabled}
        theme={theme}
        onTextChange={setDateInputText}
        onFocus={onFocus}
        onBlur={(e) => {
          onBlur?.(e);
          if (isValidDateText) {
            // If type date and is valid, emit its corresponding date object to apply it
            isRange
              ? onDateRangeChange?.(dateInputText ? getDateRangeFromText(dateInputText) : undefined)
              : onDateChange?.(dateInputText ? getDateFromText(dateInputText) : null);
          }
        }}
        onClick={onClick}
      />

      <Popover
        classes={{ paper: `${classes.popoverPaper} ${classNames.popoverPaper}` }}
        className={classNames.popover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus
        disableEnforceFocus
        onClose={(e: any) => setAnchorEl(null)}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      >
        {isRange
          ? <DayPicker
            {...datePickerProps}
            mode="range"
            numberOfMonths={2}
            selected={dateRange || undefined}
            onSelect={onSelectDateRange}
          />
          : <DayPicker
            {...datePickerProps}
            mode="default"
            selected={date || undefined}
            onDayClick={onSelectDate}
          />
        }
      </Popover>
    </>
  );
};

KrgDatePicker.VARIANT_ENUM = VARIANT_ENUM;

export default KrgDatePicker;
