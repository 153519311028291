import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import { Search } from "@mui/icons-material";
import RadioButtonList from "../RadioButtonList";

const useStyles = makeStyles(() =>
  createStyles({
    conatiner: {
      marginTop: "-2px",
    },
    searchInput: {
      width: "50%",
      padding: "0px 10px",
      marginLeft: "-10px",
    },
    label: {
      color: "black",
      fontSize: "12px",
    },
    radioButtonContainer: {
      height: "400px",
      overflowY: "scroll",
      boxSizing: "border-box",
    },
  }),
);

type Props = {
  publisherList?: any;
  value?: string;
  onChange?: (publisher: string) => void;
};

export default function PublisherList({
  publisherList,
  onChange,
  value,
}: Props) {
  const [inputValue, setInputValue] = useState(null);
  const classes = useStyles();
  const filterPublisherList = (publisher: string) => {
    if (!publisher) return publisherList;
    return publisherList.filter((publisherslug) =>
      publisherslug?.toLowerCase().includes(publisher?.toLowerCase()),
    );
  };
  const filterPublisher = filterPublisherList(inputValue);
  return (
    <Box className={classes?.conatiner}>
      <KrgTextInput
        theme={KrgTextInput.THEME_ENUM.v2}
        startAdornment={<Search />}
        className={classes.searchInput}
        placeholder="Search Publisher"
        onDebounceEnd={(publisher) => {
          setInputValue(publisher);
        }}
      />
      <Box
        sx={{
          border: "solid 1px #d1d9de",
          borderRadius: "7px",
        }}
      >
        <Typography sx={{ padding: "10px 43px" }} className={classes.label}>
          PUBLISHER
        </Typography>
        <Divider sx={{ border: "1px solid black" }} />
        <Box className={`${classes?.radioButtonContainer}`}>
          <Box>
            <RadioButtonList
              options={filterPublisher}
              value={value}
              onChange={onChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
