import React from "react";
import { Button, Box, AlertTitle, Alert } from "@mui/material";

type Props = {
  hideButton?: boolean;
  title?: string;
  severity?: "warning" | "success";
  message?: string;
  extraMessages?: string[];
  buttonTitle?: "DISMISS" | "CONTINUE";
  onClose?: () => void;
};
export default function AlertBox({
  message,
  severity,
  title,
  hideButton = true,
  extraMessages,
  onClose,
  buttonTitle,
}: Props) {
  return (
    <Box sx={{ width: "90%", marginBottom: "19px", marginLeft: "14px" }}>
      <Alert
        severity={severity}
        onClose={onClose}
        action={
          !hideButton && (
            <Button color="inherit" size="small" onClick={onClose}>
              {buttonTitle}
            </Button>
          )
        }
      >
        <AlertTitle>{title}</AlertTitle>
        <Box>{message}</Box>
        {extraMessages?.length > 0 &&
          extraMessages?.map((msg) => <Box key={msg}>{msg}</Box>)}
      </Alert>
    </Box>
  );
}
