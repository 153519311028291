import { BaseJsonSchema, createSchemaByBasicTypes } from '../../../helpers';

export const ICriteoBid: BaseJsonSchema = {
  type: 'object',
  properties: {
    bidder: {
      type: 'string',
      enum: ['criteo'],
    },
    params: {
      type: 'object',
      properties: {
        networkId: createSchemaByBasicTypes('number'),
        pubid: createSchemaByBasicTypes('string'),
        zoneId: createSchemaByBasicTypes('number'),
      },
      required: ['networkId', 'pubid', 'zoneId'],
    },
  },
  additionalProperties: {
    type: ['number', 'string', 'boolean', 'array', 'object', 'null'],
    description: 'no type declaration on this property, you are adding a new one',
  },
  required: ['bidder', 'params'],
};
