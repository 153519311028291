import { makeStyles } from '@mui/styles';
import { FONT, COLORS, COLORS_V2 } from '@kargo/shared-components.krg-shared';

export type Props = {
  isMenuOpen: boolean;
};

const useStylesV2 = makeStyles({
  /* Inner Container  */
  innerContainerText: {
    textTransform: 'none',
    fontWeight: FONT.WEIGHT.MEDIUM,
  },
  moreIcon: {
    height: '1.125em',
  },
  smallInnerContainerText: {
    fontSize: FONT.SIZE.REGULAR,
    lineHeight: '1.25em',
  },
  mediumInnerContainerText: {
    fontSize: FONT.SIZE.REGULAR,
    lineHeight: '1.25em',
  },
  largeInnerContainerText: {
    fontSize: FONT.SIZE.LARGE,
    lineHeight: '1.825em',
  },
  xLargeInnerContainerText: { /* not apply */ },

  /* Menu */
  paper: {
    position: 'relative',
    width: '125%',
    borderRadius: '0.25em',
    background: COLORS.WHITE,
    boxShadow: `0 0.25em 0.25em ${COLORS.BLACK_SHADOW}`,
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
  },
  listItem: {
    minHeight: '2em',
    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_LIGHT,
      color: COLORS_V2.NEUTRAL_BLACK,
      '& .MuiSvgIcon-root': {
        fill: COLORS_V2.NEUTRAL_BLACK,
      },
    },
  },
});

export default useStylesV2;
