import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { FileUpload, Edit } from "@mui/icons-material";
import Playlists from "./playlists";
import SectionNavBar, {
  BUTTON_VARIANT_ENUM,
} from "../../components/SectionNavBar";
import useQueryParam from "../../hooks/useQueryParam";
import AddToPlaylist from "./AddToPlaylist";
import MyLibraryUploadFiles from "./MyLibraryUploadFiles";
import { usePublisherContext } from "../../hooks/usePublisherProvider";
import MrssFeeds from "./MrssFeeds";
import VideoLibrary from "./VideoLibrary";

export default function MediaGallery(
  {
    asAdmin,
    publisherGroup: groupName,
  }: { asAdmin?: boolean; publisherGroup: string } = {
    asAdmin: false,
    publisherGroup: "",
  },
) {
  const { publisherSlug, publisherGroup, publisherUsername } =
    usePublisherContext(asAdmin);
  const padding = "20px";
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
  const [uploadFilesText, setUploadFilesText] = useState("Upload");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const createOptions = {
    isVisible: isCreateFormVisible,
    onClose: () => setIsCreateFormVisible(false),
    onSubmit: () => {
      setIsCreateFormVisible(false);
    },
  };
  const tabs = [
    { label: "Video Library" },
    { label: "Playlists" },
    { label: "MRSS Feed" },
  ];
  const tab = useQueryParam("tab", (t) => parseInt(t, 10) || 0);
  const history = useHistory();
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tab);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [videoToken, setVideoToken] = useState({ token: "" });
  const buttonsConfig =
    selectedTab === 0
      ? [
          {
            id: "upload-videos",
            key: "upload-videos",
            text: uploadFilesText,
            startIcon: <FileUpload />,
            isEnabled: selectedVideos.length === 0 && !isCreateFormVisible,
            onClick: () => {
              setShowUploadPopup(true);
            },
          },
          {
            id: "edit-videos",
            key: "edit-videos",
            text: "Edit",
            variant: BUTTON_VARIANT_ENUM.outlined,
            startIcon: <Edit />,
            isEnabled: selectedVideos.length > 0,
            onClick: () => {
              // TODO: Implement edit functionality here
            },
          },
          {
            id: "add-to-playlist",
            key: "add-to-playlist",
            text: "Add to Playlist",
            variant: BUTTON_VARIANT_ENUM.outlined,
            isEnabled: selectedVideos.length > 0,
            onClick: (e) => {
              setAnchorEl(e.currentTarget);
            },
          },
        ]
      : [
          {
            id: "create-playlist",
            key: "create-playlist",
            text: "Create Playlist",
            onClick: () => setIsCreateFormVisible(true),
            isEnabled: !isCreateFormVisible,
          },
        ];

  useEffect(() => {
    if (selectedTab === tab) return;
    if (isCreateFormVisible) setIsCreateFormVisible(false);
    history.push(`${window.location.pathname}?tab=${selectedTab}`);
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (showUploadPopup) setShowUploadPopup(false);
  }, [showUploadPopup]);

  return (
    <Box padding={padding}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <SectionNavBar
          buttonsConfig={buttonsConfig}
          tabsConfig={{
            onChange: (e: any, value?: any) => setSelectedTab(value),
            selectedTab,
            tabs,
          }}
        />
        {/* Upload files popup modal */}
        <MyLibraryUploadFiles
          showUploadPopup={showUploadPopup}
          publisherGroup={groupName || publisherGroup}
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onNewVideoToken={(videoToken) => setVideoToken(videoToken)}
          onUploadStart={() => {
            setIsCreateFormVisible(true);
            setUploadFilesText("Uploading...");
          }}
          onFinished={() => {
            setIsCreateFormVisible(false);
            setUploadFilesText("Upload Videos");
            setShowUploadPopup(false);
          }}
        />
        {/* Add To Playlist popup modal */}
        <AddToPlaylist
          asAdmin={asAdmin}
          anchorEl={anchorEl}
          publisherSlug={publisherSlug}
          onClose={() => setAnchorEl(null)}
          onSubmit={() => setSelectedVideos([])}
          videos={selectedVideos}
        />
        {selectedTab === 0 && (
          <VideoLibrary
            uploadPreviewVideoToken={videoToken}
            asAdmin={asAdmin}
            publisherSlug={publisherSlug}
            createOptions={createOptions}
            selected={selectedVideos}
            onChangeSelected={setSelectedVideos}
          />
        )}
        {selectedTab === 1 && (
          <Playlists
            asAdmin={asAdmin}
            publisherSlug={publisherSlug}
            publisherGroup={groupName || publisherGroup}
            publisherUsename={publisherUsername}
            createOptions={createOptions}
          />
        )}
        {selectedTab === 2 && (
          <MrssFeeds
            uploadPreviewVideoToken={videoToken}
            asAdmin={asAdmin}
            publisherSlug={publisherSlug}
            createOptions={createOptions}
            selected={selectedVideos}
            onChangeSelected={setSelectedVideos}
          />
        )}
      </Box>
    </Box>
  );
}
