import { makeStyles } from '@mui/styles';
import { COLORS_V2 } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from '../shared/enums';
import { Props } from './base-style';

const getColorByType = (type: TYPE_ENUM) =>
  type === TYPE_ENUM.default
    ? COLORS_V2.NEUTRAL_BLACK.replace(')', ', 0.75)') // Add opacity
    : type === TYPE_ENUM.success
      ? COLORS_V2.SUCCESS_LIGHTEST
      : type === TYPE_ENUM.warning
        ? COLORS_V2.WARNING_LIGHTEST
        : type === TYPE_ENUM.error
          ? COLORS_V2.ERROR_LIGHTEST
          : type === TYPE_ENUM.neutral
            ? COLORS_V2.NEUTRAL_WHITE
            : COLORS_V2.NEUTRAL_BLACK;

const useStylesV2 = makeStyles({
  tooltip: {
    padding: ({ type }: Props) => type === TYPE_ENUM.neutral ? '0' : '1.363637em',

    maxWidth: ({ isTextTooltip }) => isTextTooltip ? '20em' : 'auto',
    backgroundColor: ({ type }: Props) => getColorByType(type),
    color: ({ type }: Props) =>
      type === TYPE_ENUM.default
        ? COLORS_V2.NEUTRAL_WHITE
        : COLORS_V2.NEUTRAL_BLACK,
  },
  arrow: {
    color: ({ type }: Props) => getColorByType(type),
  },

});

export default useStylesV2;
