import React, { useEffect, useRef, useState } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { LookerProvider } from '../../hooks/useLookerContext';
import './styles.scss';
import { pagePropertiesChangedHandler } from '../../helpers/looker';
import LoadingCircle from '../LoadingCircle';

export function LookerEmbeddedExplore({ exploreId, className }: { exploreId?: string, className?: string }) {
  const exploreRef = useRef(null);
  const [exploreReady, setExploreReady] = useState<boolean>(false);

  useEffect(() => {
    if (!exploreId) return;
    LookerEmbedSDK.createExploreWithId(exploreId)
      // Allow fullscreen tile visualizations
      .withAllowAttr('fullscreen')
      .appendTo(exploreRef.current)
      .on('page:changed', () => {
        if (!exploreReady) {
          setExploreReady(true);
          pagePropertiesChangedHandler({ height: window.screen.height - Math.floor(window.screen.height * 0.20), type: 'PagePropertiesChangedEvent' }, exploreRef.current);
        }
      })
      // Give the embedded content a class for styling purposes
      .withClassName('looker-embed')
      .withTheme('light')
      // Finalize the build
      .build()
      // Connect to Looker
      .connect();
  }, [exploreId]);

  return (
    <LookerProvider>
      <div ref={exploreRef} className={className || ''}>
        { !exploreReady && (
          <div style={{
            width: '100%', height: 'calc(100vh - 259px)', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <LoadingCircle />
          </div>
        )}
      </div>
    </LookerProvider>
  );
}
