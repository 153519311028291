import { ReactElement } from 'react';
import { NavigationListItem } from '@kargo/shared-components.krg-vertical-navigation';

export interface MenuButtonItemV1 {
  text: string | ReactElement;
  handleClick?: () => void;
}

// tslint:disable-next-line: no-empty-interface
export interface MenuButtonItemV2 extends NavigationListItem {}
