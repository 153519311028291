import React from 'react';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import KrgTextInput from '@kargo/shared-components.krg-text-input';

const useStyles = makeStyles({
  search: (props: { isFullWidth?: boolean }) => ({
    ...(!props.isFullWidth && { width: '318px' }),
    height: 'auto',
    '& .MuiInputBase-root': {
      height: '40px',
    },
    '& input': {
      fontSize: '14px',
    },
  }),
});

type Props = Pick<React.ComponentProps<typeof KrgTextInput>, 'placeholder' | 'onDebounceEnd' | 'isFullWidth' | 'style'>;
export default function SearchInput({
  isFullWidth,
  onDebounceEnd,
  placeholder,
  style,
}: Props) {
  const classes = useStyles({ isFullWidth });
  return (
    <KrgTextInput
      className={classes.search}
      isFullWidth={isFullWidth}
      onDebounceEnd={onDebounceEnd}
      placeholder={placeholder}
      startAdornment={<Search />}
      style={style}
      theme={KrgTextInput.THEME_ENUM.v2}
    />
  );
}
