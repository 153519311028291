import React, { useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { Close, Create, Delete, Add } from "@mui/icons-material";
import KrgSelect from "@kargo/shared-components.krg-select";
import KrgSwitch from "@kargo/shared-components.krg-switch";
import { makeStyles } from "@mui/styles";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import {
  SIZE_ENUM as BUTTON_SIZE_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
} from "@kargo/shared-components.krg-button";
import allCategories from "../../api/categories";
import { Config, Domain } from "../../api/types";
import { useDomainConfig } from "../../hooks/useDomainConfig";
import generateExportTag from "../../helpers/generateExportTag";
import ConfirmModal from "../Modals/ConfirmModal";
import InputModal from "../Modals/InputModal";
import useSnackbar from "../../hooks/useSnackbar";
import { useDomain } from "../../hooks/useDomain";
import PlaylistsSelect from "../PlaylistsSelect";
import MRSSFeedsSelect from "../MRSSFeedsSelect";
import Select from "../Select";
import TrackedKrgButton from "../TrackedButton/TrackedKrgButton";
import ContentModal from "../Modals/ContentModal";
import {
  isSameDomain,
  isUrlValid,
  normalizeUrl,
} from "../../helpers/validateUrl";
import { ellipesisString } from "../../helpers/ui";
import { getPlayerNameByConfig } from "../../helpers/videos";

function Code() {
  const code = new URL("./code.svg", import.meta.url);
  return <img src={code} style={{ fontSize: "20px" }} alt="code" />;
}

const useStyles = makeStyles({
  actionCell: {
    whiteSpace: "nowrap",
  },
  editableCell: {
    "& .MuiInputBase-root": { width: "160px" },
    "& .MuiIconButton-root": { visibility: "hidden" },
    "&:hover .MuiIconButton-root": { visibility: "visible" },
  },
  selectCell: {
    "& .MuiInputBase-root": {
      width: "120px",
    },
  },
});

type Props = {
  asAdmin?: boolean;
  config?: Config;
  domain: Domain;
  index: number;
  search?: string;
  isCreating?: boolean;
  isEditing?: boolean;
  query?: any;
  onUpdate?: (data: Domain) => any;
  setConfigUpdatingStatus?: (status: boolean) => void;
  onCreateFinished?: (domain?: Domain) => void;
};

export default function PlayersTableRow({
  asAdmin,
  search,
  config,
  index,
  domain,
  isCreating,
  query,
  setConfigUpdatingStatus,
  onCreateFinished,
  isEditing,
  onUpdate,
}: Props) {
  // STATES
  const [confirmModalIsOpen, setConfirmModalIsOpen] = React.useState(false);
  const [isUrlTargetModalOpen, setIsUrlTargetModalOpen] = React.useState(false);
  const [invalidUrlMessage, setInvalidUrlMessage] = useState("");
  const [urlInputState, setUrlInputState] = React.useState("");
  const [deletedUrls, setDeletedUrl] = React.useState<string[]>([]);
  const [targetingUrls, setTargetingUrls] = React.useState<string[]>(
    config?.config?.targeting_urls || [],
  );
  const [exportTagID] = React.useState(`krg-${config?.token}-${Date.now()}`);
  const [exportTagModalIsOpen, setExportTagModalIsOpen] = React.useState(false);
  const [showNext, setShowNext] = React.useState();
  const [noEngageNext, setNoEngageNext] = React.useState();
  const [selectedCategories, setSelectedCategories] = React.useState(["auto"]);
  const [key, setKey] = React.useState(config?.key);
  if (!key && key?.trim() !== "" && config?.key) setKey(config?.key);
  const [isEditingTitle, setIsEditingTitle] = React.useState(
    isCreating || false,
  );
  // HOOKS
  const classes = useStyles();
  const { snackbarSuccess, snackbarError, snackbarWarning } = useSnackbar();
  const { createConfig } = useDomain(domain.token, domain);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { update, deleteConfig } = useDomainConfig(
    config?.token,
    domain.token,
    config,
  );
  // CONSTANTS
  const targetUrls = (config?.config?.targeting_urls as string[]) || [];
  const outstream = config?.config?.outstream || false;
  const floating = config?.config?.sticky || false;
  const isConfigDeletable = !!config?.is_config_deletable;
  const relatedContent = !!config?.config?.irisplaylist || false;
  const autoplay =
    config?.config?.autoplaywhenvisible !== false &&
    config?.config?.autoplay !== false;
  const muted = config?.config?.muted !== false;
  // METHODS
  const editingSnackBarWarning = () =>
    snackbarWarning("Click Edit Video Player button on the right");
  /* eslint-disable-next-line consistent-return */
  const handleUpdate = (updater: (c: Config) => Config) => {
    if (!isEditing) return editingSnackBarWarning();
    if (onUpdate) {
      onUpdate({
        ...domain,
        configs: domain.configs.map((cfg) => {
          if (cfg.token !== config?.token) return cfg;
          return updater(cfg);
        }),
      });
    }
  };
  const handleConfigUpdate = (configUpdater: (c: Config) => Config["config"]) =>
    handleUpdate((cfg) => ({
      ...cfg,
      config: {
        ...cfg.config,
        ...configUpdater(cfg),
      },
    }));
  const handleEditFinished = async () => {
    setIsEditingTitle(false);
    if (isCreating) {
      if (!key) {
        onCreateFinished();
        return;
      }
      setConfigUpdatingStatus(true);
      createConfig(
        { key },
        {
          onSuccess: (response) => {
            snackbarSuccess("Player Created");
            onCreateFinished(response.domain);
            setConfigUpdatingStatus(false);
          },
          onError: (e) => {
            console.error(e);
            snackbarError(
              "Error while creating player. Check the console for more information",
            );
            onCreateFinished();
          },
        },
      );
    } else if (key && key !== config?.key) {
      handleUpdate(() => ({ ...config, key }));
    }
  };

  const renderPlayerName = () => {
    let displayPlayername = getPlayerNameByConfig(config);
    displayPlayername = ellipesisString(displayPlayername, 29);
    if (displayPlayername.length > 29) {
      return (
        <KrgTooltip theme={KrgTooltip.THEME_ENUM.v2} tooltip={config?.key}>
          {displayPlayername}
        </KrgTooltip>
      );
    }

    return displayPlayername;
  };

  const renderAddUrlButton = (onClick: () => void) => (
    <TrackedKrgButton
      startIcon={<Add style={{ marginRight: -15, color: "inherit" }} />}
      id={config?.key}
      text="add URL"
      onClick={onClick}
      size={BUTTON_SIZE_ENUM.small}
      variant={BUTTON_VARIANT_ENUM.outlined}
      style={{
        width: 100,
        padding: "0 12px 0 10px",
      }}
    />
  );

  const handleErrorMessageUrl = (text: string) => {
    if (text === "") return text;
    if (!isUrlValid(text)) return "Invalid Url";
    if (!isSameDomain(text, domain.domain)) return "Invalid Domain";
    return "";
  };

  const renderUrlModalContent = () => {
    const onTextChange = (text: string) => {
      setUrlInputState(text);
      setInvalidUrlMessage(handleErrorMessageUrl(text));
    };

    const saveAndCleanInput = () => {
      if (
        targetingUrls.some(
          (url) => normalizeUrl(url) === normalizeUrl(urlInputState),
        )
      ) {
        setInvalidUrlMessage("URL already added, try a diferent one");
        return;
      }
      if (isUrlValid(urlInputState)) {
        setUrlInputState("");
        setTargetingUrls([urlInputState, ...targetingUrls]);
      }
    };

    return (
      <div className="wrapper-modal-content">
        <div className="center-div">
          <KrgTextInput
            variant={KrgTextInput.VARIANT_ENUM.outlined}
            text={urlInputState}
            placeholder="URL Target"
            isAutoHeight
            isFullWidth
            errorMessage={invalidUrlMessage}
            theme={KrgTextInput.THEME_ENUM.v2}
            onClick={(e) => e.stopPropagation()}
            onTextChange={onTextChange}
            debounceTime={0}
            onDebounceEnd={setUrlInputState}
          />
          <div>{renderAddUrlButton(saveAndCleanInput)}</div>
        </div>
        {targetingUrls.length
          ? targetingUrls.map((url: string) => (
              <div key={url} className="left-div">
                <div className="outline-div">
                  <KrgTooltip tooltip={url}>
                    <div className="text-overflow">{url}</div>
                  </KrgTooltip>
                </div>
                <button /* eslint-disable-line jsx-a11y/control-has-associated-label */
                  className="button-close"
                  type="button"
                  onClick={() => {
                    const urlIndex = targetingUrls.indexOf(url);
                    if (targetUrls.includes(targetingUrls[urlIndex])) {
                      setDeletedUrl([...deletedUrls, targetingUrls[urlIndex]]);
                    }
                    const newList = [...targetingUrls];
                    newList.splice(urlIndex, 1);
                    setTargetingUrls(newList);
                  }}
                >
                  <Close />
                </button>
              </div>
            ))
          : null}
      </div>
    );
  };

  React.useEffect(() => {
    setShowNext(config?.config?.shownext || 30);
    setNoEngageNext(config?.config?.noengagenext || 10);
    if (config?.config?.targeting_urls) {
      setTargetingUrls(config.config.targeting_urls);
    }
  }, [config]);

  const handleOpenModalTargetingURL = () => {
    if (!isEditing) return editingSnackBarWarning();
    return setIsUrlTargetModalOpen(true);
  };

  return (
    <TableRow
      key={config?.token}
      sx={{
        "& td": { border: 0 },
        height: "60px",
        backgroundColor: index % 2 === 0 ? "white" : "#EEF3F7",
        ...(search?.length > 0 && config?.token.includes(search)
          ? { backgroundColor: "#EEF3F7" }
          : {}),
      }}
    >
      <TableCell
        sx={{
          position: "sticky",
          left: 0,
          zIndex: 3,
          backgroundColor: index % 2 === 0 ? "white" : "#EEF3F7",
        }}
        className={classes.editableCell}
      >
        {isEditingTitle ? (
          <KrgTextInput
            label="Player Name"
            variant={KrgTextInput.VARIANT_ENUM.outlined}
            text={key}
            hasAutoFocus
            isAutoHeight
            isFullWidth
            theme={KrgTextInput.THEME_ENUM.v2}
            onClick={(e) => e.stopPropagation()}
            onTextChange={setKey}
            debounceTime={0}
            onDebounceEnd={setKey}
            onBlur={async () => handleEditFinished()}
            /* eslint-disable-next-line @typescript-eslint/no-shadow */
            onKeyDown={async ({ key }) => {
              if (key === "Enter") {
                await handleEditFinished();
              } else if (key === "Escape") {
                if (isCreating) onCreateFinished();
                setIsEditingTitle(false);
                setKey(config?.config?.key);
              }
            }}
          />
        ) : (
          <>
            <div
              className="div-with-hint"
              style={{ width: 230, overflow: "hidden", whiteSpace: "nowrap" }}
            >
              {renderPlayerName()}
              <IconButton
                onClick={() => {
                  if (!isEditing) {
                    return editingSnackBarWarning();
                  }
                  return setIsEditingTitle(true);
                }}
                disableRipple
              >
                <Create sx={{ fontSize: "18px" }} />
              </IconButton>
            </div>
            <p className="hint">{ellipesisString(config?.token, 12)}</p>
          </>
        )}
      </TableCell>
      <TableCell width={120}>
        <div className="left-div">
          <div>{renderAddUrlButton(handleOpenModalTargetingURL)}</div>
          {targetingUrls?.length ? (
            <div className="urls-counter">
              <KrgTooltip
                theme={KrgTooltip.THEME_ENUM.v2}
                tooltip={
                  <>
                    {targetingUrls.map((s, idx) => (
                      <div key={s} style={{ marginTop: idx === 0 ? idx : 8 }}>
                        {s}
                      </div>
                    ))}
                  </>
                }
              >
                {targetingUrls.length}
              </KrgTooltip>
            </div>
          ) : null}
        </div>
        {isUrlTargetModalOpen && (
          <ContentModal
            isOpen={isUrlTargetModalOpen}
            title={`Add URL to ${config?.key} Player`}
            submitButtonText="Save"
            cancelButtonText="Cancel"
            content={renderUrlModalContent()}
            isCancelButtonEnabled
            isSubmitButtonEnabled={targetUrls.length !== targetingUrls.length}
            onClose={() => {
              setTargetingUrls(targetUrls);
              setUrlInputState("");
              setIsUrlTargetModalOpen(false);
            }}
            onSubmit={() => {
              if (!isEditing) setTargetingUrls(targetUrls);
              handleConfigUpdate(() => ({
                targeting_urls: targetingUrls,
                deletedUrls,
              }));
              setIsUrlTargetModalOpen(false);
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <KrgSwitch
          theme={KrgSwitch.THEME_ENUM.v2}
          hasTrackIcon
          isChecked={outstream}
          onToggle={() =>
            handleConfigUpdate(() => ({
              outstream: !outstream,
            }))
          }
        />
      </TableCell>
      <TableCell>
        {!isCreating && (
          <PlaylistsSelect
            asAdmin={asAdmin}
            query={query}
            isEnabled={!outstream}
            value={config?.config?.playlisttoken || "None"}
            onChange={(pl) =>
              handleConfigUpdate(() => ({
                playlisttoken: pl === "None" ? undefined : pl,
              }))
            }
          />
        )}
      </TableCell>
      <TableCell>
        <MRSSFeedsSelect
          asAdmin={asAdmin}
          query={query}
          isEnabled={!outstream}
          value={
            `${config?.config?.query?.imported_from}#${config?.config?.query?.group}` ||
            "None"
          }
          onChange={(mf) =>
            handleConfigUpdate(() => ({
              query: {
                imported_from: mf === "None" ? undefined : mf.split("#")[0],
                group: mf.split("#")[1],
                limit: 10,
              },
            }))
          }
        />
      </TableCell>
      <TableCell className="related-content-cell">
        {!isCreating && (
          <KrgSwitch
            theme={KrgSwitch.THEME_ENUM.v2}
            hasTrackIcon
            isChecked={relatedContent && !outstream}
            isEnabled={!outstream}
            onToggle={() =>
              handleConfigUpdate(() => ({
                irisplaylist: !relatedContent,
              }))
            }
          />
        )}
      </TableCell>
      <TableCell>
        {!isCreating && (
          <Select
            // TODO: Look into why there are two `style` attributes and determine which one to use.
            // style={{ width: 250 }}
            value={config?.config?.query?.categories || selectedCategories}
            items={["Auto Selecting"]
              .concat(Object.keys(allCategories))
              .map((cat) => ({
                text: cat,
                value: cat === "Auto Selecting" ? "auto" : allCategories[cat],
                isHidden: cat === "Auto Selecting",
              }))}
            style={{ width: 235 }}
            isMultiple
            isEnabled={relatedContent && !outstream}
            multipleType={KrgSelect.MULTIPLE_TYPE_ENUM.checkmark}
            variant={KrgSelect.VARIANT_ENUM.outlined}
            size={KrgSelect.SIZE_ENUM.small}
            onChange={(value: string[]) => {
              let cat;
              if (value.length > 0) {
                if (value[0] === "auto") cat = value.slice(1);
                else cat = value;
              } else cat = ["auto"];
              if (isEditing) setSelectedCategories(cat);
              handleConfigUpdate((cfg) => ({
                query: {
                  ...cfg.config?.query,
                  categories: cat?.sort(),
                },
              }));
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <Select
          items={[
            {
              text: "Click to play",
              value: 0,
            },
            {
              text: "Autoplay",
              value: 1,
            },
          ]}
          value={autoplay ? 1 : 0}
          onChange={(value: number) =>
            handleConfigUpdate(() => ({
              autoplay: !!value,
              autoplaywhenvisible: !!value,
            }))
          }
        />
      </TableCell>
      <TableCell>
        <KrgSwitch
          theme={KrgSwitch.THEME_ENUM.v2}
          hasTrackIcon
          isChecked={!muted}
          onToggle={() =>
            handleConfigUpdate(() => ({
              muted: !muted,
            }))
          }
        />
      </TableCell>
      <TableCell>
        <KrgSwitch
          theme={KrgSwitch.THEME_ENUM.v2}
          hasTrackIcon
          isChecked={floating}
          onToggle={() =>
            handleConfigUpdate((cfg) => ({
              sticky:
                !floating && cfg.config?.stickyCondition
                  ? cfg.config.stickyCondition
                  : !floating,
            }))
          }
        />
      </TableCell>
      <TableCell className={classes.selectCell}>
        <Select
          value={
            config?.config?.floatingoptions?.mobile?.height !== undefined
              ? config?.config?.floatingoptions?.mobile?.height
              : 175
          }
          items={[
            {
              text: "Exclude Device",
              value: 0,
            },
            {
              text: "120px",
              value: 120,
            },
            {
              text: "175px",
              value: 175,
            },
            {
              text: "200px",
              value: 200,
            },
          ]}
          isEnabled={floating}
          variant={KrgSelect.VARIANT_ENUM.outlined}
          size={KrgSelect.SIZE_ENUM.small}
          onChange={(value: number) =>
            handleConfigUpdate((cfg) => ({
              floatingoptions: {
                ...cfg.config?.floatingoptions,
                noFloatOnMobile: value === 0,
                sidebar: value === 120,
                mobile: {
                  ...cfg.config?.floatingoptions?.mobile,
                  height: value,
                },
              },
            }))
          }
        />
      </TableCell>
      <TableCell className={classes.selectCell}>
        <Select
          value={
            config?.config?.floatingoptions?.desktop?.height === 0
              ? "exclude"
              : config?.config?.floatingoptions?.desktop?.position ||
                "bottom-right"
          }
          items={[
            {
              text: "Exclude Device",
              value: "exclude",
            },
            {
              text: "Bottom Right",
              value: "bottom-right",
            },
            {
              text: "Bottom Left",
              value: "bottom-left",
            },
            {
              text: "Top Right",
              value: "top-right",
            },
            {
              text: "Top Left",
              value: "top-left",
            },
          ]}
          isEnabled={floating}
          variant={KrgSelect.VARIANT_ENUM.outlined}
          size={KrgSelect.SIZE_ENUM.small}
          onChange={(value: string) =>
            handleConfigUpdate((cfg) => ({
              floatingoptions: {
                ...cfg.config?.floatingoptions,
                noFloatOnDesktop: value === "exclude",
                desktop: {
                  ...cfg.config?.floatingoptions?.desktop,
                  height: value === "exclude" ? 0 : undefined,
                  position: value !== "exclude" ? value : undefined,
                },
              },
            }))
          }
        />
      </TableCell>
      <TableCell>
        <KrgSwitch
          theme={KrgSwitch.THEME_ENUM.v2}
          hasTrackIcon
          isChecked={
            !(
              // TODO: Look into the naming of the `config` attribute...
              (
                Object.hasOwn(config?.config, "presetkey") &&
                config.config.presetkey !== "responsive"
              )
            )
          }
          onToggle={(checked) =>
            handleConfigUpdate(() => ({
              presetkey: checked ? "responsive" : null,
            }))
          }
        />
      </TableCell>
      <TableCell>
        <KrgSwitch
          theme={KrgSwitch.THEME_ENUM.v2}
          hasTrackIcon
          isChecked={config?.config?.nextwidget !== false}
          onToggle={(checked) =>
            handleConfigUpdate(() => ({
              nextwidget: checked,
            }))
          }
        />
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {isEditing ? (
          <KrgTextInput
            theme={KrgTextInput.THEME_ENUM.v2}
            text={showNext}
            isAutoHeight
            isFullWidth
            onTextChange={setShowNext}
            debounceTime={0}
            onDebounceEnd={setShowNext}
            onBlur={() =>
              handleConfigUpdate(() => ({
                shownext: Number(showNext) || 30,
              }))
            }
          />
        ) : (
          config?.config?.shownext || 30
        )}
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {isEditing ? (
          <KrgTextInput
            theme={KrgTextInput.THEME_ENUM.v2}
            text={noEngageNext}
            isAutoHeight
            isFullWidth
            onTextChange={setNoEngageNext}
            debounceTime={0}
            onDebounceEnd={setNoEngageNext}
            onBlur={() =>
              handleConfigUpdate(() => ({
                noengagenext: Number(noEngageNext) || 10,
              }))
            }
          />
        ) : (
          config?.config?.noengagenext || 10
        )}
      </TableCell>
      <TableCell className={classes.actionCell}>
        {!isCreating && config && (
          <>
            <InputModal
              elementClassName="export-tag-modal"
              title={config?.key || config?.token}
              isOpen={exportTagModalIsOpen}
              submitButtonText="Copy"
              onClose={() => setExportTagModalIsOpen(false)}
              onSubmit={(exportTag) => {
                navigator.clipboard.writeText(exportTag);
                snackbarSuccess("Copied to Clipboard");
              }}
              label="Export Tag"
              text={generateExportTag({ config, id: exportTagID })}
            />
            <KrgTooltip
              theme={KrgTooltip.THEME_ENUM.v2}
              tooltip="Embed the video player on a specific page."
            >
              <IconButton
                disableRipple
                sx={{ padding: "5px" }}
                onClick={() => setExportTagModalIsOpen(true)}
              >
                <Code />
              </IconButton>
            </KrgTooltip>
            <ConfirmModal
              isOpen={confirmModalIsOpen}
              header={`Delete ${key} - ID ${config?.token.substring(0, 6)}`}
              body={`Are you sure you want to delete the player "${key} - ID ${config?.token.substring(
                0,
                6,
              )}"? This action can't be undone.`}
              submitButtonText="Confirm"
              onClose={() => setConfirmModalIsOpen(false)}
              onSubmitClick={() => {
                deleteConfig({
                  onSuccess: () => {
                    snackbarSuccess("Player Deleted");
                  },
                  onError: (error) => {
                    console.error(error);
                    snackbarError(
                      "Error while deleting Player, check the console for more information.",
                    );
                  },
                });
                setConfirmModalIsOpen(false);
              }}
            />
            <KrgTooltip
              theme={KrgTooltip.THEME_ENUM.v2}
              tooltip="Deletes this video player from the property. Please note that if there are no players associated with a property, no player will load on site."
            >
              {isConfigDeletable && (
                <IconButton
                  disableRipple
                  sx={{ padding: "5px" }}
                  onClick={() => setConfirmModalIsOpen(true)}
                >
                  <Delete sx={{ fontSize: "20px" }} />
                </IconButton>
              )}
            </KrgTooltip>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
