import { makeStyles } from '@mui/styles';
import { COLORS_V2, COLORS_V2_ALPHA, FONT, INPUT_VARIANT_ENUM as VARIANT_ENUM } from '@kargo/shared-components.krg-shared';
import { SIZE_ENUM } from '../shared/enums';
import { Props } from './base-style';

const useStylesV2 = makeStyles({
  selectContainer: {
    fontSize: FONT.SIZE.BASE,
    height: ({ size }: Props) => size === SIZE_ENUM.small ? '1.9375em' : '2.3125em',
    minWidth: '3.125em',
    width: '100%',

    '&:hover > .MuiInputLabel-root:not(.Mui-error)': {
      color: ({ isEnabled }: Props) => isEnabled
        ? COLORS_V2.NEUTRAL_BLACK
        : COLORS_V2.NEUTRAL_INACTIVE,
    },
  },
  select: {
    height: ({ size }: Props) => size === SIZE_ENUM.small ? '1.9375em' : '2.3125em',
    width: '100%',

    '& .MuiSelect-select': {
      fontSize: FONT.SIZE.REGULAR,
      color: COLORS_V2.NEUTRAL_BLACK,
      fontWeight: FONT.WEIGHT.MEDIUM,

      paddingLeft: ({ isRounded, variant, size }: Props) =>
        isRounded && variant === VARIANT_ENUM.outlined
          ? size === SIZE_ENUM.small
            ? '2.81818em'
            : '3.363636em'
          : variant === VARIANT_ENUM.standard
            ? '0'
            : '1.454545em',

      '&.Mui-disabled': {
        opacity: 1,
        WebkitTextFillColor: 'unset', // override MUI
        color: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },

    '&.Mui-focused .MuiSelect-select': {
      color: COLORS_V2.PRIMARY_700,
    },

    '&.Mui-error .MuiSelect-select': {
      color: COLORS_V2.ERROR_DARK,
    },

    // Borders Outline
    '& .MuiOutlinedInput-notchedOutline' : {
      borderRadius: ({ isRounded }: Props) => isRounded ? '2em' : 0,
      borderColor: COLORS_V2.NEUTRAL_DARK,
      borderWidth: '0.0625em',
      backgroundColor: COLORS_V2.NEUTRAL_WHITE,

      '& > legend': {
        marginLeft: ({ isRounded, variant, size }: Props) =>
          isRounded && variant === VARIANT_ENUM.outlined
            ? size === SIZE_ENUM.small
              ? '0.5em'
              : '0.75em'
            : '0.1666em',

        '& > span:not(.notranslate)': {
          fontSize: '0.91667em',
          padding: '0 0.363635em',
        },
      },
    },

    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) > .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS_V2.NEUTRAL_BLACK,
      borderWidth: '0.0625em',
    },

    '&.Mui-focused': {
      color: COLORS_V2.PRIMARY_700,
    },

    '&.Mui-error': {
      color: COLORS_V2.ERROR_DARK,
    },

    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS_V2.PRIMARY_700,
      borderWidth: '0.125em',
    },

    '&.Mui-error > .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS_V2.ERROR_DARK,
      borderWidth: '0.125em',
    },

    '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS_V2.NEUTRAL_INACTIVE,
      borderWidth: '0.0625em',
    },

    '&.MuiOutlinedInput-root.Mui-disabled': {
      opacity: 1,
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },

    // Borders standard
    '&.MuiInput-underline' : {
      '&:before': {
        borderWidth: '0.0625em',
        borderColor: COLORS_V2.NEUTRAL_DARK,
        borderBottomStyle: 'solid',
      },

      '&.Mui-disabled:before': {
        borderColor: COLORS_V2.NEUTRAL_INACTIVE,
        opacity: 1,
      },

      '&.Mui-focused:before': {
        borderColor: COLORS_V2.PRIMARY_700,
        borderWidth: '0.125em',
      },

      '&:hover:not(.Mui-disabled):not(.Mui-focused):before': {
        borderWidth: '0.0625em',
        opacity: 1,
        borderColor: COLORS_V2.NEUTRAL_BLACK,
      },

      '&:not(.Mui-error):after': {
        transform: 'scaleX(1)',
        border: 'none',
      },

      '&.Mui-error.Mui-disabled:after': {
        border: 'none',
      },
    },

    // Force keep same height to the container
    '& .notranslate': {
      display: 'block',
    },
    // Shown over the fieldset element
    '& > .MuiSelect-select' : {
      zIndex: 1,
    },

    '& > .MuiSvgIcon-root': {
      zIndex: 1,
      color: ({ isEnabled }: Props) => isEnabled
        ? COLORS_V2.NEUTRAL_DARK
        : COLORS_V2.NEUTRAL_INACTIVE,
    },

    '&.Mui-error > .MuiSvgIcon-root': {
      color: ({ isEnabled }: Props) => isEnabled
        ? COLORS_V2.ERROR_DARK
        : COLORS_V2.NEUTRAL_INACTIVE,
    },

    '&.Mui-focused:not(.Mui-error) > .MuiSvgIcon-root': {
      color: COLORS_V2.PRIMARY_700,
    },

    '&:hover:not(.Mui-error) > .MuiSvgIcon-root': {
      color: ({ isEnabled }: Props) => isEnabled
        ? COLORS_V2.NEUTRAL_BLACK
        : COLORS_V2.NEUTRAL_INACTIVE,
    },
  },
  selectStandard: {
    '& > .MuiInput-input': {
      height: ({ size }: Props) => size === SIZE_ENUM.small ? '1.9375em' : '2.3125em',
      minHeight: ({ size }: Props) => size === SIZE_ENUM.small ? '2em' : '2.5452em',
    },

    '& .MuiSelect-select': {
      lineHeight: ({ size }: Props) => size === SIZE_ENUM.small ? '3.181818em' : '4.272727em',
    },

    '&.Mui-disabled': {
      opacity: 1,
      WebkitTextFillColor: 'unset', // override MUI
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },
  },
  selectOutlined: {},
  label: {
    color: COLORS_V2.NEUTRAL_DARK,
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
    transition: 'cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

    height: '1em',
    lineHeight: '1em',
    marginTop: ({ size, variant }: Props) =>
      variant === VARIANT_ENUM.outlined
        ? size === SIZE_ENUM.medium
          ? '-0.272727em'
          : '-0.5625em'
        : size === SIZE_ENUM.medium
          ? '-0.625em'
          : '-0.875em',
    marginLeft: ({ isRounded, variant, size }: Props) =>
      isRounded && variant === VARIANT_ENUM.outlined
        ? size === SIZE_ENUM.small
          ? '1.454545em'
          : '2em'
        : variant === VARIANT_ENUM.outlined
          ? '0.181818em'
          : 0,

    '&.MuiInputLabel-shrink': {
      fontWeight: FONT.WEIGHT.NORMAL,
      fontSize: '0.83em',

      marginTop: ({ size, variant }: Props) =>
        size === SIZE_ENUM.small && variant === VARIANT_ENUM.standard
          ? '-0.15em'
          : '0.3em',

      marginLeft: ({ isRounded, variant, size }: Props) =>
      isRounded && variant === VARIANT_ENUM.outlined
        ? size === SIZE_ENUM.small
          ? '0.4285715em'
          : '0.6428571em'
        : variant === VARIANT_ENUM.outlined
          ? '0.142857em'
          : 0,

      '&.Mui-focused': {
        color: COLORS_V2.PRIMARY_700,
      },
    },
  },
  labelStandard: {},
  labelDisabled: {
    color: COLORS_V2.NEUTRAL_INACTIVE,

    '&.Mui-error': {
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },
  },
  menu: {
    marginTop:  0,
    boxShadow: `0 0.25em 0.25em ${COLORS_V2_ALPHA.NEUTRAL_BLACK_SHADOW}`,
    background: COLORS_V2.NEUTRAL_WHITE,

    '& .MuiList-root': {
      minWidth: 'auto',
    },

    '& > ul > li': {
      color: COLORS_V2.NEUTRAL_BLACK,
      fontWeight: FONT.WEIGHT.MEDIUM,
      height: ({ size }: Props) => size === SIZE_ENUM.small ? '1.9375em' : '2.3125em',
      paddingLeft: '0.75em',

      '& > span': {
        fontSize: FONT.SIZE.REGULAR,
        flex: 1,
      },

      '&.Mui-selected': {
        background: COLORS_V2.PRIMARY_50,
      },

      '&.Mui-disabled': {
        color: COLORS_V2.NEUTRAL_INACTIVE,
        opacity: 1,

        '& .MuiSvgIcon-root': {
          color: COLORS_V2.NEUTRAL_INACTIVE,
        },
      },

      '&:hover, &.Mui-selected:hover': {
        background: COLORS_V2.PRIMARY_100,
      },

      '& .MuiCheckbox-root': {
        marginLeft: '-0.4375em',
        marginRight: '0.625em',
      },
    },
  },
  menuOutlined: {
    borderTop: 0,
    padding: 0,
    paddingTop: ({ isRounded }: Props) => isRounded ? '1em' : 0,
    paddingBottom: '0.5em',
  },
  menuError: {
    borderColor: COLORS_V2.ERROR_DARK,
    borderWidth: '0.125em',
  },

  menuItemIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: 'auto',

      '& > .MuiSvgIcon-root': {
        fontSize: '1.25em',
      },
    },
  },
  outlinedRoundedGap: {
    position: 'absolute',
    height: '50%',
    width: 'inherit',
    backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    bottom: 0,
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  chip: {
    '&.MuiChip-root': {
      margin:  ({ variant }: Props) =>
        variant === VARIANT_ENUM.standard
          ? '0 0.25em 0.5em 0'
          : '0 0.25em 0 0',
    },
  },
  helperText: {
    fontSize: FONT.SIZE.SMALL,
    marginLeft: ({ isRounded, size, variant }: Props) =>
      isRounded && variant === VARIANT_ENUM.outlined
        ? size === SIZE_ENUM.small
          ? '1.25em'
          : '1.5em'
        : 0,
  },
  selectAll: {
    '&.MuiMenuItem-root': {
      width: '30%',
      marginTop: ({ isRounded, variant }: Props) =>
      isRounded && variant === VARIANT_ENUM.outlined ? 0 : '1em',
      marginLeft: 0,
      marginBottom: 0,
      paddingLeft: '0.75em',
    },
    '&:hover.MuiMenuItem-root': {
      color: COLORS_V2.PRIMARY_700,
    },
  },
  clearAll: {
    '&.MuiMenuItem-root': {
      marginLeft: '40%',
      paddingLeft: '1.5em',
    },
  },
});

export default useStylesV2;
