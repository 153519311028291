import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';

const useStylesV2 = makeStyles({
  /*
    Table body
  */
  tableRow: {
    backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    borderColor: COLORS_V2.NEUTRAL_INACTIVE,
    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_MEDIUM,
      boxShadow: '0 0.05rem .2rem 0 rgba(0, 0, 0, 0.2),' +
          '0 0.05rem 0.05rem 0 rgba(0, 0, 0, 0.14),' +
          '0 .1rem 0.05rem -0.05rem rgba(0, 0, 0, 0.12)',
      borderColor: COLORS_V2.PRIMARY_50,
    },
  },
  tableRowSelected: {
    backgroundColor: COLORS_V2.PRIMARY_50,
    '&:hover': {
      backgroundColor: COLORS_V2.PRIMARY_50,
      boxShadow: '0 0.05rem .2rem 0 rgba(0, 0, 0, 0.2),' +
          '0 0.05rem 0.05rem 0 rgba(0, 0, 0, 0.14),' +
          '0 .1rem 0.05rem -0.05rem rgba(0, 0, 0, 0.12)',
    },
  },
  tableRowCell: {
    color: COLORS_V2.NEUTRAL_BLACK,
    fontSize: FONT.SIZE.EXTRA_LARGE,
    lineHeight: 1.2,
    padding: '0.5em',
  },
});

export default useStylesV2;
