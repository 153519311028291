import { makeStyles } from '@mui/styles';
import { FONT } from '@kargo/shared-components.krg-shared';

const useBaseStyles = makeStyles({
  root: {
    fontSize: FONT.SIZE.BASE,
    marginLeft: 0,
  },

  disabled: {
    pointerEvents: 'none',
  },
});

export default useBaseStyles;
