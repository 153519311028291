export enum TYPE_ENUM {
  info = 'info',
  submit = 'submit',
  continue = 'continue',
  confirmation = 'confirmation',
}

export enum SCRIM_ENUM {
  light = 'light',
  dark = 'dark',
}

export enum STATUS_ENUM {
  confirmation = 'confirmation',
  warning = 'warning',
  error = 'error',
}

export enum SIZE_ENUM {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}
