import React from 'react';
import KrgSnackbar from '@kargo/shared-components.krg-snackbar';
import Snackbar from '../components/Snackbar';

export const SnackbarContext = React.createContext<{
  snackbarSuccess:(text: string) => void,
  snackbarError: (text: string) => void,
  snackbarWarning: (text: string) => void,
}>(null);

export function SnackbarProvider({ children }: React.PropsWithChildren) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const [type, setType] = React.useState(KrgSnackbar.TYPE_ENUM.dark);

  function snackbarSuccess(newText: string) {
    setText(newText);
    setType(KrgSnackbar.TYPE_ENUM.success);
    setIsOpen(true);
  }

  function snackbarError(newText: string) {
    setText(newText);
    setType(KrgSnackbar.TYPE_ENUM.error);
    setIsOpen(true);
  }

  function snackbarWarning(newText: string) {
    setText(newText);
    setType(KrgSnackbar.TYPE_ENUM.warning);
    setIsOpen(true);
  }

  return (
    <SnackbarContext.Provider value={React.useMemo(() => ({ snackbarSuccess, snackbarError, snackbarWarning }), [])}>
      <Snackbar
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        text={text}
        type={type}
      />
      { children }
    </SnackbarContext.Provider>
  );
}

export default function useSnackbar() {
  return React.useContext(SnackbarContext);
}
