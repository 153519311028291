import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "./styles.scss";
import KrgButton, {
  THEME_ENUM as BUTTON_THEME_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
} from "@kargo/shared-components.krg-button";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { stringAvatar } from "../../helpers/ui";
import UserAvatar from "../UserAvatar";
import { User } from "../../api/types";

type UsersTableProps = {
  user?: User;
  username?: string;
  groups?: string[];
  show_edit?: boolean;
  style?: React.CSSProperties;
  setSelectedUser?: (user: User) => any;
  setUserFormModalIsOpen?: (open: boolean) => any;
};
export default function UserProfile({
  user,
  style,
  username,
  groups,
  show_edit = false,
  setSelectedUser,
  setUserFormModalIsOpen,
}: UsersTableProps) {
  let userObj;
  if (!user) {
    userObj = {
      username,
      groups,
      AdministrativeRole: groups?.includes("admin") ? "Administrator" : "Publisher",
    };
  } else
    userObj = {
      ...user,
      AdministrativeRole: user.groups?.includes("admin") ? "Administrator" : "Publisher",
    };

  const profileProps = stringAvatar(userObj.name || userObj.username);

  return (
    <Box sx={style} display="flex" flexDirection="row" alignItems="center">
      <Box sx={{ display: "flex", flexFlow: "column", marginTop: "-10px" }}>
        <UserAvatar
          text={profileProps?.text}
          color={profileProps?.color}
          width={130}
          height={130}
        />
      </Box>
      <Box sx={{ ml: 2, marginLeft: "30px", marginTop: "15px" }}>
        <Typography
          variant="h6"
          color="text.primary"
          sx={{ marginBottom: "10px" }}
        >
          {userObj.name || userObj.username}
        </Typography>
        <Typography
          variant="subtitle2"
          color="text.primary"
          sx={{ marginBottom: "7px", fontWeight: "bold" }}
        >
          {userObj.AdministrativeRole}
        </Typography>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ marginBottom: "10px", fontWeight: "medium", fontSize: "12px" }}
        >
          {userObj.email || userObj.username}
        </Typography>
        {show_edit && (
          <KrgTooltip tooltip="Edit profile email">
            <KrgButton
              theme={BUTTON_THEME_ENUM.v2}
              variant={BUTTON_VARIANT_ENUM.outlined}
              className="button-height-35"
              text="Edit Profile"
              onClick={() => {
                setSelectedUser(user);
                setUserFormModalIsOpen(true);
              }}
            />
          </KrgTooltip>
        )}
      </Box>
    </Box>
  );
}
