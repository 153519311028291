import { ReactNode } from 'react';
import { VARIANT_ENUM } from '@kargo/shared-components.krg-button';
export interface ModalButtonConfig {
  width?: string;
  variant?: VARIANT_ENUM;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export interface ModalButtonsConfig {
  submitButton?: ModalButtonConfig;
  cancelButton?: ModalButtonConfig;
}

export interface LinkI {
  url: string;
  label?: string;
  copyText?: string;
}
