import { makeStyles } from '@mui/styles';
import { FONT, BACKGROUND_ENUM } from '@kargo/shared-components.krg-shared';
import { SIZE_ENUM } from '../shared/enums';

export type Props = {
  background: BACKGROUND_ENUM,
  size: SIZE_ENUM,
  isEnabled: boolean,
};

const useBaseStyles = makeStyles({
  /* Tabs container */
  root: {
    alignItems: 'center',
    display: 'flex',
    fontSize: FONT.SIZE.BASE,
  },
});

export default useBaseStyles;
