import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import {
  Check as CheckIcon,
  DriveFileRenameOutline as EditIcon,
} from '@mui/icons-material';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgTextInput from '@kargo/shared-components.krg-text-input';
import KrgTooltip from '@kargo/shared-components.krg-tooltip';
import { DataTableRow } from './../../shared/interfaces';
import { DataTableCol } from '../../shared/interfaces';
import { getTablePopperProps } from '../../shared/utils';
import useStyles, { Props as StyleProps } from './subtitle-cell.style';
import { Link } from 'react-router-dom';

type Props = {
  /**
   * @summary
   * Row of button for onClick function
   */
  row: DataTableRow,
  /**
   * @summary
   * Table column
   */
  column: DataTableCol,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
};

const SubtitleCell = ({
  row,
  column,
  theme,
}: Props) => {
  const props: StyleProps = {
    theme,
  };
  const classes = useStyles(props);
  const {
    isEditable,
    editFinishedIconDuration = 2000,
    onEditFinished,
    onEditCanceled,
  } = column;
  const link = row[column.field].link;
  const cellTitle = row[column.field].title;
  const cellSubtitle = row[column.field].subtitle;

  const [text, setText] = useState(cellTitle);
  const [editing, setEditing] = useState<boolean>(false);
  const [isInputEnabled, setIsInputEnabled] = useState<boolean>(true);
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
  const [showCheckMarkIcon, setShowCheckMarkIcon] = useState<boolean>(false);
  const [isTitleOverflowed, setIsTitleOverflowed] = useState<boolean>(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  useEffect(
    () => {
      if (textElementRef.current) {
        setIsTitleOverflowed(
          textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
        );
      }
    },
    []);

  const handleOnMouseEnter = () => {
    setShowEditIcon(true);
  };

  const handleOnMouseMove = () => {
    if (!showEditIcon) {
      setShowEditIcon(true);
    }
  };

  const handleMouseLeave = () => {
    setShowEditIcon(false);
  };

  const handleEditFinished = async () => {
    setIsInputEnabled(false);

    const successful = await onEditFinished?.(text, column, row);
    // Revert input text if operation is not successful
    if (successful === false) {
      setText(cellTitle);
    } else if (successful === true) {
      // Show checkmark for the successful operation
      setShowCheckMarkIcon(true);
      setTimeout(
        () => { setShowCheckMarkIcon(false); },
        editFinishedIconDuration,
      );
    }

    // Reset states
    setIsInputEnabled(true);
    setShowEditIcon(false);
    setEditing(false);
  };

  const handleEditCanceled = () => {
    onEditCanceled?.(text, column, row);
    setShowEditIcon(false);
    setEditing(false);
    setText(cellTitle);
  };

  const editingCellElem = (
    <KrgTextInput
      text={text}
      hasAutoFocus
      isAutoHeight
      isFullWidth
      isEnabled={isInputEnabled}
      theme={theme}
      onClick={e => e.stopPropagation()}
      onTextChange={setText}
      onBlur={async () => await handleEditFinished()}
      onKeyDown={async ({ key }) => {
        if (key === 'Enter') {
          await handleEditFinished();
        } else if (key === 'Escape') {
          handleEditCanceled();
        }
      }}
    />
  );

  const getTitleStyle = () => {
    const style: CSSProperties = {};

    if (!editing && column.width) {
      style.maxWidth = `calc(${column.width} - 1.85em)`;
    }

    return style;
  };

  const cellElem = (
    <>
      <div
        className={`
          ${classes.title}
          ${!!row.isChecked ? classes.titleChecked : ''}
        `}
      >
        <KrgTooltip
          tooltip={cellTitle}
          position={KrgTooltip.POSITION_ENUM.top}
          tooltipProps={{
            PopperProps: getTablePopperProps(),
            disableHoverListener: !isTitleOverflowed,
          }}
          hasArrow={false}
          theme={theme}
        >
          <div ref={textElementRef} className={classes.titleText} style={getTitleStyle()}>
            {cellTitle}
          </div>
        </KrgTooltip>
        {(showEditIcon && isEditable)
          ? <KrgTooltip
              tooltip="Rename"
              position={KrgTooltip.POSITION_ENUM.top}
              tooltipProps={{
                PopperProps: getTablePopperProps(),
              }}
              hasArrow={false}
              theme={theme}
            >
              <EditIcon
                className={`${classes.editIcon} ${classes.icon}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditing(true);
                }}
              />
            </KrgTooltip>
          : showCheckMarkIcon
            ? <CheckIcon className={`${classes.checkIcon} ${classes.icon}`} />
            : <div className={classes.editIconPlaceholder} />
        }
      </div>
      <div className={classes.subtitle}>
        {cellSubtitle}
      </div>
    </>
  );

  return (
    <div
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleOnMouseMove}
    >
      {editing
        ? editingCellElem
        : link
          ? <Link to={link} style={{ textDecoration: 'none' }}>{cellElem}</Link>
          : cellElem}
    </div>
  );
};

export default SubtitleCell;
