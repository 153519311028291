import React from 'react';
import KrgMenu, { MenuItemData } from '@kargo/shared-components.krg-menu';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  menu: {
    zIndex: 1301,
    border: 0,
    color: 'black',
  },
  button: {
    color: 'black',
    '& svg': {
      fontSize: '20px',
    },
  },
  paper: {
    border: 0,
  },
  popper: {
    zIndex: 1301,
  },
});

type Props = { menuItems: MenuItemData[] };
export default function Menu({ menuItems }: Props) {
  const classes = useStyles();
  return (
    <KrgMenu
      classNames={{
        button: classes.button,
        menuList: classes.menu,
        paper: classes.paper,
        popper: classes.popper,
      }}
      menuItems={menuItems}
      buttonVariant={KrgMenu.BUTTON_VARIANT_ENUM.icon}
    />
  );
}
