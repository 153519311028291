import { makeStyles } from '@mui/styles';
import { FONT } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from '../shared/enums';

export type Props = {
  type: TYPE_ENUM,
};

const useBaseStyles = makeStyles({
  root: {
    fontSize: FONT.SIZE.BASE,
  },

  alert: {
    alignItems: 'center',
    borderRadius: '0.25em',
    boxShadow: '0em 0.1875em 0.3125em rgba(0, 0, 0, 0.2), 0em 0.0625em 1.125em rgba(0, 0, 0, 0.12), 0em 0.375em 0.625em rgba(0, 0, 0, 0.14)',
    display: 'flex',
    minHeight: '3em',
    minWidth: '16em',
    padding: '0.5em 1em',
  },

  textContainer: {
    display: 'flex',
    flexFlow: 'column',
  },

  text: {
    fontSize: FONT.SIZE.SEMI_LARGE,
    lineHeight: 1.2,
    marginRight: '0.625em',
  },

  icon: {
    display: 'flex',
    marginRight: '0.625em',
  },

  button: {
    fontSize: FONT.SIZE.REGULAR,
    marginLeft: 'auto',
    minWidth: 'unset',
  },
});

export default useBaseStyles;
