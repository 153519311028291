import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';
import { IStorage } from './storage';

export const ISharedId: BaseJsonSchema = {
  type: 'object',
  properties: {
    name: createSchemaByBasicTypes('string', '', {
      enum: ['sharedId'],
    }),
    storage: IStorage,
  },
};
