import { TooltipProps, TooltipClasses } from '@mui/material';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { POSITION_ENUM, TYPE_ENUM } from './enums';

export interface TooltipPropsI {
  /**
   * @summary
   * Classes name following Tooltip type to override / extend commponent styles
   */
  classNames?: Partial<TooltipClasses>;
  /**
   * @summary
   * Style
   */
  style?: React.CSSProperties;
  /**
   * @summary
   * React Node to Wrap with Tooltip
   */
  children?: React.ReactNode;
  /**
   * @summary
   * Plain text string to Wrap with Tooltip (if not childrenElement)
   */
  text?: string;
  /**
   * @summary
   *  Content of tooltip when open, can be plain text or ReactNode with ad-hoc configurations
   */
  tooltip: React.ReactNode;
  /**
   * @summary
   * Allow pass-through Mui Tooltip properties that are not exposed in this component
   */
  tooltipProps?: Partial<TooltipProps>;
  /**
   * @summary
   * Tooltip position in relation to the origin.
   * @default
   * POSITION_ENUM.bottom
   */
  position?: POSITION_ENUM;
  /**
   * @summary
   * Sets background, text and button color according to the selection.
   * @default
   * TYPE_ENUM.default
   */
  type?: TYPE_ENUM;
  /**
   * @summary
   * Configures to show or not the tooltip if handle by the parent of this component
   * @default
   * undefined
   */
  isOpen?: boolean;
  /**
   * @summary
   * Configures to show or not the linking arrow between origin and tooltip element
   * @default
   * true
   */
  hasArrow?: boolean;
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM;
}
