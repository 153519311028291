import React, { useState, useEffect } from "react";
import { generatePath, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import { useDomains } from "../../../hooks/useDomains";
import CreatePropertyModal from "../../../components/Modals/CreatePropertyModal";
import useSnackbar from "../../../hooks/useSnackbar";
import LoadingCircle from "../../../components/LoadingCircle";
import { usePublisherContext } from "../../../hooks/usePublisherProvider";
import Table from "../../../components/Table";
import TrackedKrgButton from "../../../components/TrackedButton/TrackedKrgButton";
import { useSuperAdminContext } from "../../../routes/super_admin";

import "./styles.scss";
import { PATHS } from "../../../constants/paths";

type IDomainPage = {
  asAdmin: boolean;
};
export default function DomainsPage(props: IDomainPage) {
  const { slug, addPathToBreadCrumb } = useSuperAdminContext();
  const { asAdmin = false } = props;
  const { snackbarSuccess, snackbarError } = useSnackbar();
  const { publisherUsername, publisherSlug } = usePublisherContext(asAdmin);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const query = asAdmin && { slug: publisherSlug || slug };
  const { domains, query: domainsQuery } = useDomains({ enabled: true, query });

  const history = useHistory();

  useEffect(() => {
    addPathToBreadCrumb([{ path: PATHS.ADMIN_DOMAIN, pathName: "Domains" }]);
  }, []);

  if (domainsQuery.isFetching) {
    return (
      <div className="krg-loading-wrapper">
        <LoadingCircle />
      </div>
    );
  }

  const columns = [
    {
      field: "domain",
      headerName: "Domains",
      onClick: (__, row) => {
        history.push(
          generatePath(PATHS.ADMIN_DOMAIN_CONFIG, { domainToken: row.token }),
          {
            config: row.configs,
          },
        );
      },
      renderCell: (row) => (
        <span style={{ textDecoration: "underline" }}>{row.domain}</span>
      ),
    },
    {
      field: "updated_at",
      headerName: "Last update",
      renderCell: (row) => (
        <p>
          {dayjs(row.updated_at).format("DD/MMM/YY ")}
          {dayjs(row.updated_at).format(" HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "",
      headerName: "No. of Players Configs",
      onClick: (__, row) => {
        addPathToBreadCrumb([
          {
            path: generatePath(PATHS.ADMIN_DOMAIN_CONFIG, {
              domainToken: row.token,
            }),
            pathName: row.domain,
          },
        ]);
        history.push(
          generatePath(PATHS.ADMIN_DOMAIN_CONFIG, { domainToken: row.token }),
          {
            config: row.configs,
          },
        );
      },
      renderCell: (row) => (
        <p style={{ textAlign: "end", width: "122px" }}>{row.configs.length}</p>
      ),
    },
  ];
  const rows = domains;

  return (
    <Box padding="20px">
      <Box padding="0 30px 0 0" display="flex" justifyContent="end">
        <TrackedKrgButton
          id="add-domain"
          text="Add domain"
          onClick={() => setModalIsOpen(true)}
          variant={BUTTON_VARIANT_ENUM.contained}
          size={BUTTON_SIZE_ENUM.small}
        />
      </Box>
      <CreatePropertyModal
        username={publisherUsername}
        title="Add New Domain"
        label="Domain"
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onSubmit={() => setModalIsOpen(false)}
        createOptions={{
          onSuccess: () => {
            snackbarSuccess("Created New Domain");
          },
          onError: () => {
            snackbarError(
              "Error creating domain, check the console for more information.",
            );
          },
        }}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Table columns={columns} rows={rows} size={12} />
      </Box>
    </Box>
  );
}
