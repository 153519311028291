import { makeStyles } from '@mui/styles';

export type Props = {
  buttonCount: number,
};

const useStyles = makeStyles({
  root: {
    minWidth: ({ buttonCount }: Props) => `${buttonCount * 2.4}em`,
  },
});

export default useStyles;
