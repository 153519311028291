export enum VARIANT_ENUM {
  standard = 'standard',
  scrollable = 'scrollable',
  fullWidth = 'fullWidth',
}

export enum SIZE_ENUM {
  small = 'small',
  large = 'large',
}
