import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { Events, BACKGROUND_ENUM, THEME_ENUM, DEPRECATED } from '@kargo/shared-components.krg-shared';
import { VARIANT_ENUM, SIZE_ENUM } from './shared/enums';
import { TabItem } from './shared/interfaces';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Class name
   */
  className?: string,
  /**
   * @summary
   * Style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Variant of tabs
   * @default
   * VARIANT_ENUM.standard
   */
  variant?: VARIANT_ENUM,
  /**
   * @summary
   * Button parent container background (for style buttons)
   * @default
   * BACKGROUND_ENUM.white
   */
  background?: BACKGROUND_ENUM,
  /**
   * @summary
   * Button size
   * @default
   * SIZE_ENUM.small
   */
  size?: SIZE_ENUM,
  /**
   * @summary
   * Index of selected tab
   */
  selectedTab: number,
  /**
   * @summary
   * List of tab items
   */
  tabs: TabItem[],
  /**
   * @summary
   * Tabs are active to be clicked if 'true'
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * Callback fired when selected tab is changed
   */
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.33 in favour of 'background'
   */
  color?: DEPRECATED,
};

const KrgTabs = ({
  className = '',
  style,
  background = BACKGROUND_ENUM.white,
  variant = VARIANT_ENUM.standard,
  size = SIZE_ENUM.small,
  selectedTab,
  tabs,
  isEnabled = true,
  theme = THEME_ENUM.v1,
  onChange,
  onClick,
  onBlur,
  onFocus,
}: Props) => {

  const props: StyleProps = {
    background,
    size,
    isEnabled,
  };
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  return (
    <div
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      className={`${baseClasses.root} ${className}`}
      style={style}
    >
      <Tabs
        classes={{
          root: classes.tabs,
          indicator:
            `${classes.indicator} ` +
            `${!isEnabled || tabs[selectedTab]?.isEnabled === false ? classes.indicatorDisabled : undefined} `,
        }}
        onChange={onChange}
        value={selectedTab}
        variant={variant}
        centered={variant !== VARIANT_ENUM.scrollable}
      >
        {
          tabs.map((item: TabItem, index) => {
            return (
              <Tab
                key={index}
                classes={{
                  root: `${classes.tab} ${!!item.icon !== !!item.label ? classes.tabTextOrIcon : classes.tabTextAndIcon}`,
                }}
                label={item.label}
                icon={item.icon}
                disabled={!isEnabled || item.isEnabled === false}
              />
            );
          })
        }
      </Tabs>
    </div>
  );
};

KrgTabs.BACKGROUND_ENUM = BACKGROUND_ENUM;
KrgTabs.VARIANT_ENUM = VARIANT_ENUM;
KrgTabs.SIZE_ENUM = SIZE_ENUM;
KrgTabs.THEME_ENUM = THEME_ENUM;

export default KrgTabs;
