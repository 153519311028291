import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { ArrowRight } from "../Icons";

type Props = {
  buttonText: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function ScrollPaddle({ buttonText, disabled, onClick }: Props) {
  const buttonRotation = buttonText === "left" ? "180deg" : "0deg";
  const useStyles = makeStyles({
    arrow: {
      width: "10.1px",
      height: "16.67px",
      transform:
        /* eslint-disable-next-line prefer-template */
        "rotate(" + buttonRotation + ")",
      filter:
        "invert(91%) sepia(18%) saturate(81%) hue-rotate(160deg) brightness(90%) contrast(97%)",
    },
    paddle: {
      width: "30px",
      minWidth: "30px",
      height: "100%",
      color: "#000000",
      border: "1px solid #000000",
      padding: "0px",
      "&:hover": {
        color: "#0033FF",
        border: "1px solid #0033FF",
        "& img": {
          filter:
            "invert(10%) sepia(100%) saturate(7051%) hue-rotate(235deg) brightness(99%) contrast(112%)",
        },
      },
      "&:disabled": {
        color: "#D1D9DE",
        border: "1px solid #D1D9DE",
        "& img": {
          filter:
            "invert(0%) sepia(100%) saturate(8%) hue-rotate(161deg) brightness(96%) contrast(102%)",
        },
      },
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      className={classes.paddle}
      disabled={disabled}
      onClick={onClick}
    >
      <ArrowRight alt={buttonText} className={classes.arrow} />
    </Button>
  );
}
