import React from "react";
/* import useCategories from '../../hooks/useCategories'; */
import { MULTIPLE_TYPE_ENUM } from "@kargo/shared-components.krg-select/shared/enums";
import Select from "../Select";

type Props = {
  asAdmin?: boolean;
  isEnabled?: boolean;
  value?: number[];
  onChange?: (dur: number) => any;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function DurationSelect({
  asAdmin,
  isEnabled,
  value,
  onChange,
}: Props) {
  let query;
  /* if (asAdmin) query = { asAdmin }; */
  /* const { categories } = useCategories(query); */
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const durations = [
    {
      text: "< 30 seconds",
      value: 30,
    },
    {
      text: "< 1 minute",
      value: 60,
    },
    {
      text: "< 5 minutes",
      value: 300,
    },
    {
      text: "< 10 minutes",
      value: 600,
    },
    {
      text: "> 10 minutes",
      value: -1,
    },
  ];
  return (
    <Select
      label="Content Length"
      isEnabled={isEnabled}
      value={value}
      items={durations.map((d) => ({
        text: d.text,
        value: d.value,
      }))}
      isMultiple
      multipleType={MULTIPLE_TYPE_ENUM.checkmark}
      onChange={onChange}
    />
  );
}
