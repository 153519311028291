import { makeStyles } from '@mui/styles';
import { FONT } from '@kargo/shared-components.krg-shared';

const useBaseStyles = makeStyles({
  /*
    Paper
  */
  paper: {
    boxShadow: 'unset',
  },

  /*
    Table
  */
  table: {
    fontSize: FONT.SIZE.BASE,
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em 0',
    width: '100%',
  }
});

export default useBaseStyles;
