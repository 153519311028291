import React from 'react';
import { Box } from '@mui/material';
import { LookerEmbeddedDashboard } from '../../components';

export default function Analytics({ asAdmin }: { asAdmin: boolean } = { asAdmin: false }) {
  const padding = '20px';
  return (
    <Box padding={padding}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <LookerEmbeddedDashboard asAdmin={asAdmin} />
      </Box>
    </Box>
  );
}
