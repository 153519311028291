function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string): Record<string, string> | null {
  if (!name) return null;
  let splitName = [];
  let shownName = '';
  if (name.includes('-')) {
    splitName = name.split('-');
  } else if (name.includes(' ')) splitName = name.split(' ');
  shownName = (splitName.length > 1)
    ? `${splitName[0][0]}${splitName[1][0]}` : name.substring(0, 2);
  return {
    color: stringToColor(name),
    text: `${shownName}`,
  };
}

export const ellipesisString = (text: string, maxLength: number) => (
  text?.length > maxLength
    ? text.slice(0, 30) + String.fromCharCode(0x2026)
    : text
);
