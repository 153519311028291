const categories = {
  'Food and Drink': 'food and drink',
  'Business and Industrial': 'business and industrial',
  Pets: 'pets',
  Science: 'science',
  'Health and Fitness': 'health and fitness',
  'Style and Fashion': 'style and fashion',
  Politics: 'law, govt and politics',
  Society: 'society',
  'Automotive and Vehicles': 'automotive and vehicles',
  'Family and Parenting': 'family and parenting',
  Sports: 'sports',
  'Art and Entertainment': 'art and entertainment',
  Education: 'education',
  Technology: 'technology and computing',
  Shopping: 'shopping',
  Finance: 'finance',
  Travel: 'travel',
  'Home and Garden': 'home and garden',
  News: 'news',
};

export default categories;
