import { makeStyles } from '@mui/styles';
import { FONT, BACKGROUND_ENUM, COLORS_V2 } from '@kargo/shared-components.krg-shared';
import { VARIANT_ENUM } from '../shared/enums';
import  { Props } from './base-style';

const useStylesV2 = ({
  hasIconBackground,
  isEnabled,
  isSquaredBorder,
}: Props) => makeStyles({

  /*
    Default button style
  */
  button: {
    borderRadius: isSquaredBorder ? 0 : '3.125em',
    fontWeight: FONT.WEIGHT.SEMI_BOLD,

    // Icon background style
    '& .MuiSvgIcon-root':
      hasIconBackground ? {
        background: isEnabled ? COLORS_V2.NEUTRAL_BLACK : COLORS_V2.NEUTRAL_INACTIVE,
        borderRadius: '50%',
        color: COLORS_V2.NEUTRAL_WHITE,
        fontSize: `${FONT.SIZE.MEDIUM} !important`,
        padding: '0.3125em',
        overflow: 'visible',
      }
      : null,

    // Icon background style on button hover
    '&:hover .MuiSvgIcon-root':
      hasIconBackground
        ? { backgroundColor: COLORS_V2.PRIMARY_700 }
        : null,
  },

  textContainer: {
    textTransform: 'capitalize',
    padding: ({ hasIcon, variant }: Props) => hasIcon
      ? `0 0 0 ${variant === VARIANT_ENUM.text ? '0.8em' : '1.2em'}`
      : undefined,
  },

  /*
  /* SIZE */
  /* Small size */
  smallButton: {
    height: '1.9375em',
    padding: isSquaredBorder ? '0 0.5em' : '0 1.9375em',

    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '1.1em',
    },
  },

  /* Medium size */
  mediumButton: {
    height: '2.3125em',
    padding: isSquaredBorder ? '0 1em' : '0 2.3125em',

    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '1.2em',
    },
  },

  /* Large size */
  largeButton: {
    height: '2.8125em',
    padding: isSquaredBorder ? '0 1.5em' : '0 2.8125em',
    width: ({ isFullWidth }: Props) => isFullWidth ? '100%' : 'auto',

    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '1.2em',
    },
  },

  /* xLarge size */
  xlargeButton: { /* not apply */ },

  /*
  /* VARIANTS */
  /* Text variant */
  textButton: {
    color: COLORS_V2.NEUTRAL_BLACK,
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
      color: COLORS_V2.PRIMARY_700,
    },

    '&:disabled': {
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    },
  },

  /* Outlined variant */
  outlinedButton: {
    backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    borderColor: COLORS_V2.NEUTRAL_BLACK,
    color: COLORS_V2.NEUTRAL_BLACK,

    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_WHITE,
      borderColor: COLORS_V2.PRIMARY_700,
      color: COLORS_V2.PRIMARY_700,
    },

    '&:disabled': {
      borderColor: COLORS_V2.NEUTRAL_INACTIVE,
      color: COLORS_V2.NEUTRAL_INACTIVE,
    },
  },

  /* Contained variant */
  containedButton: {
    backgroundColor: COLORS_V2.NEUTRAL_BLACK,
    color: COLORS_V2.NEUTRAL_WHITE,

    '&:hover': {
      backgroundColor: COLORS_V2.PRIMARY_700,
    },

    '&:disabled': {
      backgroundColor: COLORS_V2.NEUTRAL_INACTIVE,
      color: COLORS_V2.NEUTRAL_WHITE,
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    },
  },

  /*
  /* KNOCKOUT (background not white) */
  /* Text variant */
  textKnockoutButton: {
    color: COLORS_V2.NEUTRAL_WHITE,

    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ background }: Props) => background === BACKGROUND_ENUM.black
        ? COLORS_V2.PRIMARY_700
        : COLORS_V2.NEUTRAL_BLACK,
    },

    '&:disabled': {
      color: ({ background }: Props) => background === BACKGROUND_ENUM.black
        ? COLORS_V2.NEUTRAL_DARK
        : COLORS_V2.PRIMARY_400,
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: 'transparent',
    },
  },

  /* Outlined variant */
  outlinedKnockoutButton: {
    borderColor: COLORS_V2.NEUTRAL_WHITE,
    color: COLORS_V2.NEUTRAL_WHITE,

    '&:hover': {
      backgroundColor: COLORS_V2.NEUTRAL_WHITE,
      borderColor: COLORS_V2.NEUTRAL_WHITE,
      color: COLORS_V2.PRIMARY_700,
    },

    '&:disabled': {
      borderColor: ({ background }: Props) => background === BACKGROUND_ENUM.black
        ? COLORS_V2.NEUTRAL_DARK
        : COLORS_V2.PRIMARY_400,
      color: ({ background }: Props) => background === BACKGROUND_ENUM.black
      ? COLORS_V2.NEUTRAL_DARK
      : COLORS_V2.PRIMARY_400,
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: COLORS_V2.PRIMARY_400,
    },
  },

  /* Contained variant */
  containedKnockoutButton: {
    backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.black
      ? COLORS_V2.PRIMARY_700
      : COLORS_V2.NEUTRAL_WHITE,
    color: ({ background }: Props) => background === BACKGROUND_ENUM.black
      ? COLORS_V2.NEUTRAL_WHITE
      : COLORS_V2.NEUTRAL_BLACK,

    '&:hover': {
      backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.black
        ? COLORS_V2.NEUTRAL_WHITE
        : COLORS_V2.NEUTRAL_BLACK,
      color: ({ background }: Props) => background === BACKGROUND_ENUM.black
        ? COLORS_V2.PRIMARY_700
        : COLORS_V2.NEUTRAL_WHITE,
    },

    '&:disabled': {
      backgroundColor: COLORS_V2.NEUTRAL_INACTIVE,
      color: COLORS_V2.NEUTRAL_DARK,
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.black
        ? COLORS_V2.PRIMARY_400
        : COLORS_V2.NEUTRAL_WHITE,
    },
  },

  /* TEXT by SIZE */
  /* Small size */
  smallTextContainer: {
    fontSize: FONT.SIZE.REGULAR,
    lineHeight: FONT.SIZE.REGULAR,
  },

  /* Medium size */
  mediumTextContainer: {
    fontSize: FONT.SIZE.REGULAR,
    lineHeight: FONT.SIZE.REGULAR,
  },

  /* Large size */
  largeTextContainer: {
    fontSize: FONT.SIZE.LARGE,
    lineHeight: FONT.SIZE.LARGE,
  },

  /* xLarge size */
  xlargeTextContainer: { /* not apply */ },

  /* Ripple effect */
  buttonRipple: {},
});

export default useStylesV2;
