import React from "react";
import { IconButton } from "@mui/material";
import { Apps, Menu } from "@mui/icons-material";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";

type ViewTypes = "card" | "table";

export default function ViewSelector({
  onViewChange,
  viewType,
}: {
  onViewChange: (viewType: ViewTypes) => void;
  viewType: ViewTypes;
}) {
  return (
    <div className="view-selector">
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          onViewChange("table");
        }}
        style={{ padding: "2.5px" }}
      >
        <KrgTooltip
          theme={KrgTooltip.THEME_ENUM.v2}
          tooltip="Switch to List View"
        >
          <Menu
            style={{
              fontSize: "20px",
              color: viewType === "table" ? "#0033FF" : "black",
            }}
          />
        </KrgTooltip>
      </IconButton>
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          onViewChange("card");
        }}
        style={{ padding: "2.5px " }}
      >
        <KrgTooltip
          theme={KrgTooltip.THEME_ENUM.v2}
          tooltip="Switch to Card View"
        >
          <Apps
            style={{
              fontSize: "20px",
              color: viewType === "card" ? "#0033FF" : "black",
            }}
          />
        </KrgTooltip>
      </IconButton>
    </div>
  );
}
