import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAPI from '../api/useAPI';
import useSnackbar from './useSnackbar';
import { MRSSFeed } from '../api/types';

export default function useMRSSFeeds(inputQuery: {}) {
  const queryClient = useQueryClient();
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const { api, didLoad } = useAPI();
  const query = useQuery({
    queryKey: ['mrss-feeds', inputQuery],
    queryFn: () => api.getMRSSFeeds(inputQuery),
    enabled: didLoad,
    staleTime: Infinity,
  });
  const createMutation = useMutation({
    mutationFn: api.createMRSSFeed,
    onSuccess: () => {
      snackbarSuccess('MRSS Feed Successfully Created');
      queryClient.invalidateQueries(['mrss-feeds']);
    },
    onError: () => snackbarError('Error While Creating MRSS Feed'),
  });
  const updateMutation = useMutation({
    mutationFn: ({ token, data }: { token: string, data: MRSSFeed }) => api.updateMRSSFeed(token, data),
    onSuccess: () => {
      snackbarSuccess('MRSS Feed Successfully Updated');
      queryClient.invalidateQueries(['mrss-feeds']);
    },
    onError: () => snackbarError('Error While Updating MRSS Feed'),
  });
  const deleteMutation = useMutation({
    mutationFn: ({ token }: { token: string }) => api.deleteMRSSFeed(token),
    onSuccess: () => {
      snackbarSuccess('MRSS Feed Successfully Deleted');
      queryClient.invalidateQueries(['mrss-feeds']);
    },
    onError: () => snackbarError('Error While Deleting MRSS Feed'),
  });
  return {
    query,
    data: query?.data,
    MRSSFeeds: query?.data || [],
    api: {
      create: createMutation.mutate,
      update: updateMutation.mutate,
      delete: deleteMutation.mutate,
    },
    mutations: {
      createMutation,
    },
  };
}
