import { makeStyles } from '@mui/styles';
import { COLORS, BACKGROUND_ENUM, FONT } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';

const useStylesV1 = makeStyles({

  /* Tabs */
  tabs: {
    backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? COLORS.WHITE
      : COLORS.PRIMARY_500_ACCENT,
  },

  /* Tab */
  tab: {
    fontSize: FONT.SIZE.MEDIUM,
    color: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? COLORS.BLACK_MEDIUM_EMPHASIS
      : COLORS.WHITE_MEDIUM_EMPHASIS,

    borderBottom: ({ background }: Props) => background === BACKGROUND_ENUM.white
     ? `0.1667em solid ${COLORS.GRAY_LIGHT_4}`
     : 'none',

    '&.Mui-selected': {
      color: ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? COLORS.PRIMARY_500_ACCENT
        : COLORS.WHITE,
    },

    '&:hover': {
      backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? 'rgb(250, 251, 251)'
        : 'inherit',
      color:  ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? COLORS.PRIMARY_500_ACCENT
        : COLORS.WHITE,
    },

  },

  /* Tab text or icon, smaller height for one element */
  tabTextOrIcon: {},

  /* Tab text and icon, bigger height for two elements */
  tabTextAndIcon: {},

  indicator: {
    backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? COLORS.PRIMARY_500_ACCENT
      : COLORS.WHITE,
    marginBottom: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? 0
      : '0.3125em',
  },
  indicatorDisabled: {},
});

export default useStylesV1;
