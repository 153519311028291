import * as React from 'react';
import { Avatar, Box } from '@mui/material';
import './styles.scss';

type UsersAvatarProps = {
  width: number,
  height: number,
  text: string,
  color: string
};
export default function UserAvatar({
  width, height, text, color,
}: UsersAvatarProps) {
  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: '50%',
        backgroundColor: color, // Use your theme's color
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // Add any additional styling you might need
      }}
    >
      <Box
        sx={{
          width: width * 0.95,
          height: height * 0.95,
          borderRadius: '50%',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Avatar sx={{
          width: width * 0.90, height: height * 0.90, bgcolor: color, fontSize: width / 2, textTransform: 'uppercase',
        }}
        >
          {text}
        </Avatar>
      </Box>
    </Box>
  );
}
