import tracker from "../helpers/tracker";
import { USER_API } from "./endpoints";
import {
  Domain,
  EditableConfig,
  MRSSFeed,
  PlaylistInput,
  Video,
  VideoInput,
  UserInput,
  DomainResponse,
  EApiStatusError,
  IApiError,
  CreatePublisherInput,
  CreatedPublisherResponseData,
  BulkDomainsProperties,
} from "./types";

export function fetchUserAPI(
  endpoint: string,
  accessToken: string,
  init: RequestInit = {},
) {
  return fetch(`${USER_API}/${endpoint}`, {
    ...init,
    headers: {
      ...init.headers,
      Authorization: accessToken,
    },
  });
}

export default function createUserAPI(
  accessToken: string,
  username?: string,
  groups?: string[],
) {
  function fetchAPI(endpoint: string, init: RequestInit = {}) {
    return fetchUserAPI(endpoint, accessToken, init).then((res) => {
      if (!res.ok) {
        return res
          .json()
          .then((body) => {
            const errorMessage = body.message || "No error message provided";
            tracker.track("Error", {
              type: "api",
              endpoint,
              status: res.status,
              errorMessage,
            });
            throw new Error(
              `API returned error ${res.status}: ${errorMessage}`,
            );
          })
          .catch((e: IApiError) => {
            tracker.track("Error", {
              type: "api",
              endpoint,
              status: res.status,
              errorMessage: "Error message could not be parsed",
            });
            switch (res.status) {
              case EApiStatusError.CONFLITED: {
                const responseString = e.message.split("409: ")[1];
                throw new Error(responseString);
              }
              case EApiStatusError.DUPLICATED:
                throw new Error(e.message);
              default:
                throw new Error(
                  `API returned error ${res.status} and the error message could not be parsed.`,
                );
            }
          });
      }
      return res.json();
    });
  }
  const deleteAPI = (endpoint: string, data?: Record<string, any>) => {
    tracker.track("API Delete Request", {
      resource: endpoint,
      data,
    });
    return fetchAPI(endpoint, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
  };
  const postAPI = (endpoint: string, data?: Record<string, any>) => {
    tracker.track("API Create Request", {
      resource: endpoint,
      data,
    });
    return fetchAPI(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
  };
  const putAPI = (endpoint: string, data?: Record<string, any>) => {
    tracker.track("API Update Request", {
      resource: endpoint,
      data,
    });
    return fetchAPI(endpoint, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
  };

  function getMRSSFeedAPI() {
    const resource = "mrss-feeds";
    return {
      createMRSSFeed: (data: any) => postAPI(resource, data),
      deleteMRSSFeed: (token: string) => deleteAPI(`${resource}/${token}`),
      getMRSSFeeds: (query): Promise<MRSSFeed[]> =>
        fetchAPI(
          `${resource}/${query ? `?${new URLSearchParams(query)}` : ""}`,
        ).then((r) => r?.mrss_feeds),
      getMRSSFeed: (token: string) => fetchAPI(`${resource}/${token}`),
      updateMRSSFeed: (token: string, data: MRSSFeed) =>
        putAPI(`${resource}/${token}`, data),
    };
  }

  const api = {
    ...getMRSSFeedAPI(),
    createDomain: (data: { domain: string; username: string }) =>
      postAPI("domains", data).then((res: { data: { token: string } }) => ({
        ...data,
        token: res.data.token,
      })),
    getRevisionByConfigToken: (configToken: string) =>
      fetchAPI(`domains/revisions/${configToken}`).then((res) => res),
    saveTargetingUrls: (
      data: { targeting_urls: string[]; domain: string },
      token: string,
      configToken: string,
    ) =>
      postAPI(`domains/targetingurl/${token}/${configToken}`, data).then(
        (res) => ({
          ...data,
          ...res.data,
        }),
      ),
    deleteTargetingUrls: (
      query: { url: string },
      token: string,
      configToken: string,
    ) =>
      deleteAPI(
        `domains/targetingurl/${token}/${configToken}?${new URLSearchParams(query)}`,
      ).then((res) => ({
        ...res.data,
      })),
    bulkUpdateDomains: (data: BulkDomainsProperties) =>
      putAPI(`domains/bulk-update`, data).then((res) => ({
        ...res.data,
      })),
    bulkDeleteDomains: (data: BulkDomainsProperties) =>
      deleteAPI(`domains/bulk-delete`, data).then((res) => ({
        ...res.data,
      })),
    deleteDomain: (token: string): Promise<{ message: "deleted" }> =>
      deleteAPI(`domains/${token}`),
    getDomains: (query): Promise<Domain[]> =>
      fetchAPI(`domains${query ? `?${new URLSearchParams(query)}` : ""}`).then(
        (res: { configs: Domain[] }) => res.configs,
      ),
    getDomain: (token: string): Promise<{ domain: Domain }> =>
      fetchAPI(`domains/${token}`),
    domain: (d: string) => ({
      createConfig: (data: Partial<EditableConfig>) =>
        postAPI(`domains/${d}/configs`, data),
      deleteConfig: (token: string): Promise<{ message: "deleted" }> =>
        deleteAPI(`domains/${d}/configs/${token}`),
      getConfig: (c: string) => fetchAPI(`domains/${d}/configs/${c}`),
      setConfig: (
        c: string,
        config: EditableConfig,
      ): Promise<DomainResponse | IApiError> =>
        putAPI(`domains/${d}/configs/${c}`, config),
      update: (data: Partial<Domain>) => putAPI(`domains/${d}`, data),
    }),
    createPlaylist: (d: PlaylistInput): Promise<{ token: string }> =>
      postAPI("playlists", d).then(
        ({ data }: { message: string; data: { token: string } }) => data,
      ),
    deletePlaylist: (token: string) => deleteAPI(`playlists/${token}`),
    updatePlaylist: (token: string, data: PlaylistInput) =>
      putAPI(`playlists/${token}`, data),
    getPlaylists: (query) =>
      fetchAPI(`playlists${query ? `?${new URLSearchParams(query)}` : ""}`),
    createUser: (user: UserInput): Promise<{ token: string }> =>
      postAPI("users", user).then(({ data }) => data),
    inviteUser: (user: UserInput): Promise<{ token: string }> =>
      postAPI("users/invite-user", user).then(({ data }) => data),
    readUser: (select_user: string) => fetchAPI(`users/${select_user}`),
    deleteUser: (select_user: string) => deleteAPI(`users/${select_user}`),
    updateUser: (select_user: string, data: UserInput) =>
      putAPI(`users/${select_user}`, data),
    getUsers: (query) =>
      fetchAPI(`users${query ? `?${new URLSearchParams(query)}` : ""}`),
    getCategories: (query) =>
      fetchAPI(
        `medias/categories${query ? `?${new URLSearchParams(query)}` : ""}`,
      ),
    getPublishers: (query) =>
      fetchAPI(`publishers${query ? `?${new URLSearchParams(query)}` : ""}`),
    createPublisher: (data: CreatePublisherInput) =>
      postAPI("publishers", data)
        .then((res: CreatedPublisherResponseData) => ({
          publisher: res,
          message: null,
        }))
        .catch((err) => ({
          publisher: null,
          message:
            err?.message || `Error occurred when tried to onboard publisher`,
        })),
    Media: {
      get: (token: string) => fetchAPI(`medias/${token}`),
      index: (query) =>
        fetchAPI(
          `medias?${new URLSearchParams({
            ...query,
            ...{
              group: query.slug || query.group || groups?.join(",") || username,
            },
          })}`,
        ),
      remove: (token: string) => deleteAPI(`medias/${token}`),
      update: (token: string, data) => putAPI(`medias/${token}`, data),
    },
    Looker: {
      getUserObjects: () => fetchAPI("dashboards/looker-get-objects"),
      acquireEmbed: () => fetchAPI("dashboards/looker-acquire-embed-session"),
      // eslint-disable-next-line max-len
      getTokens: (data: { apiToken: string; navigationToken: string }) =>
        putAPI("dashboards/looker-generate-embed-tokens", {
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
        }),
    },
    createVideo: async ({
      data,
      file,
      image,
      publisherUsername,
      onCreate,
      onProgress,
    }: {
      data: VideoInput;
      file: File;
      image?: File;
      publisherUsername?: string;
      onCreate?: (data: VideoInput & { token: string }) => any;
      onProgress?: (token: string, ev: ProgressEvent<EventTarget>) => any;
    }) => {
      if (!data && !file) throw new Error("Needs video data and/or file");
      if (file && !(file instanceof File)) {
        throw new Error("Invalid video file");
      }
      const createResponse: {
        message: string;
        data: { image_url: string; token: string; url: string };
      } = await postAPI("medias", {
        group: publisherUsername || username,
        ...data,
      });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { token, url, image_url } = createResponse.data;
      const dataWithToken = { ...data, token };
      if (onCreate) onCreate(dataWithToken);
      if (!file) return dataWithToken;
      const response = await Promise.allSettled([
        api.uploadFile(url, file, (e) => {
          if (!onProgress) return;
          onProgress(token, e);
        }),
        image && api.uploadFile(image_url, image),
      ]);
      if (response[0].status === "rejected")
        throw new Error(response[0].reason);
      return api.confirmVideo(
        token,
        !!image && response[1].status === "fulfilled",
      );
    },
    uploadFile: async (
      url: string,
      file: File,
      onprogress?: (ev: ProgressEvent<EventTarget>) => any,
    ) =>
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.upload.onprogress = onprogress;
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) return;
          if (xhr.status !== 200) reject();
          else resolve(xhr.responseText);
        };
        xhr.send(file);
      }),
    // eslint-disable-next-line max-len
    confirmVideo: (token: string, confirm_image?: boolean): Promise<Video> =>
      postAPI(`medias/${token}/confirm`, { confirm_image }).then(
        (res) => res.media,
      ),
  };

  return api;
}
