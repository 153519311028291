export function getPublisherAccessPayload(
  userList: any,
  isAdmin: boolean,
  admin: string,
  username: string,
  group: string,
) {
  if (isAdmin) {
    return {
      isPublisherAdmin: false,
      publisherGroup: null,
      publisherUsername: null,
    };
  }
  const publisherUser =
    userList &&
    userList?.find((user) => user.role === admin && user.username === username);
  return {
    isPublisherAdmin: publisherUser?.role === admin,
    publisherUsername: publisherUser?.username,
    publisherGroup: group,
  };
}
