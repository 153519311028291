import { makeStyles } from '@mui/styles';
import { COLORS } from '@kargo/shared-components.krg-shared';

const useStylesV1 = makeStyles({
  root: {},

  colorPrimary: {
    backgroundColor: COLORS.PRIMARY_50,
    color: COLORS.PRIMARY_500_ACCENT,

    '&:hover, &:focus': {
      backgroundColor: COLORS.PRIMARY_10,
      color: COLORS.PRIMARY_DARK_TEXT,
    },
  },

  iconColorPrimary: {
    color: `${COLORS.PRIMARY_500_ACCENT} !important`,
  },

  deleteIconColorPrimary: {
    color: `${COLORS.PRIMARY_500_ACCENT} !important`,

    '&:hover, &:active': {
      color: `${COLORS.PRIMARY_DARK_TEXT} !important`,
    },
  },
});

export default useStylesV1;
