import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';
import  { Props } from './base-style';

const useStylesV1 = makeStyles({
  /*
    Default button style
  */
  button: {
    borderRadius: 0,
  },

  textContainer: {},

  /*
    Text button style
  */
  textButton: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_500_ACCENT,
    '&:hover': {
      backgroundColor: COLORS.PRIMARY_50,
    },
    '&:disabled': {
      color: COLORS.BLACK_DISABLED,
    },
  },

  /*
    Outlined button style
  */
  outlinedButton: {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.BLACK_HIGH_EMPHASIS,
    color: COLORS.BLACK_HIGH_EMPHASIS,
    '&:hover': {
      backgroundColor: COLORS.WHITE,
      borderColor: COLORS.PRIMARY_500_ACCENT,
      color: COLORS.PRIMARY_500_ACCENT,
    },
    '&:disabled': {
      borderColor: COLORS.GRAY_DIVIDER,
      color: COLORS.GRAY_DIVIDER,
    },
  },

  /*
    Knockout button style
  */
  outlinedKnockoutButton: {
    borderColor: COLORS.WHITE,
    color: COLORS.WHITE,
    '&:hover': {
      borderColor: COLORS.PRIMARY_500_ACCENT,
      color: COLORS.PRIMARY_500_ACCENT,
    },
    '&:disabled': {
      borderColor: COLORS.GRAY_DIVIDER,
      color: COLORS.GRAY_DIVIDER,
    },
  },

  containedKnockoutButton: { /* no customized in V1 */ },
  textKnockoutButton: { /* no customized in V1 */ },

  /*
    Contained button style
  */
  containedButton: {
    backgroundColor: COLORS.BLACK_HIGH_EMPHASIS,
    color: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.PRIMARY_500_ACCENT,
    },
    '&:disabled': {
      backgroundColor: COLORS.BLACK_INACTIVE,
      color: COLORS.BLACK_DISABLED,
    },
  },

  /*
    Button Size
  */
  smallButton: {
    minHeight: '1.5em',
    padding: '0.2em 1em',
    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '0.8em',
    },
  },
  mediumButton: {
    minHeight: '2.25em',
    padding: '0.5em 1em',
    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '1em',
    },
  },
  largeButton: {
    minHeight: '3em',
    padding: '0.8em 1em',
    width: ({ isFullWidth, isIconButton }: Props) =>
      isFullWidth ? '100%' : isIconButton ? 'auto' : '17.5em',
    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '1.2em',
    },
  },
  xlargeButton: {
    minHeight: '3.75em',
    padding: '1.1em 1em',
    width: ({ isFullWidth, isIconButton }: Props) =>
      isFullWidth ? '100%' : isIconButton ? 'auto' : '17.5em',
    // Start icon style
    '& .MuiSvgIcon-root': {
      fontSize: ({ iconFontSize }: Props) => iconFontSize || '1.3em',
    },
  },

  /*
    Text container and its sizes
  */
  smallTextContainer: {
    fontSize: '0.8em',
    padding: ({ hasIcon }: Props) => (
      hasIcon ? '0.09375em 0.625em 0 0.8125em' : '0.0625em 0 0'
    ),
    lineHeight: '0.8em',
  },
  mediumTextContainer: {
    fontSize: '0.8em',
    padding: '0.125em 1.5625em 0',
  },
  largeTextContainer: {
    fontSize: FONT.SIZE.EXTRA_LARGE,
    lineHeight: '1em',
    padding: '0.125em 1.5625em 0',
  },
  xlargeTextContainer: {
    fontSize: FONT.SIZE.EXTRA_LARGE,
    lineHeight: '1em',
    padding: '0.125em 1.5625em 0',
  },

  /* Ripple effect */
  buttonRipple: {
    opacity: 0.5,
  },
});

export default useStylesV1;
