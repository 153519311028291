import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';

const useStylesV1 = makeStyles({
  /*
    Table body
  */
  tableRow: {
    '&:hover': {
      backgroundColor: COLORS.GRAY_LIGHT,
    },
  },
  tableRowSelected: {
    backgroundColor: COLORS.PRIMARY_50,
    '&:hover': {
      backgroundColor: COLORS.PRIMARY_50,
    },
  },
  tableRowCell: {
    borderColor: COLORS.GRAY_DIVIDER,
    color: COLORS.BLACK_MEDIUM_EMPHASIS,
    fontSize: FONT.SIZE.EXTRA_LARGE,
    lineHeight: 1.2,
    padding: '0.5em',
  },
});

export default useStylesV1;
