import React, {
  createContext, PropsWithChildren, useEffect, useMemo,
} from "react";
import useSession from '../hooks/useSession';
import useUserSession from '../hooks/useUserSession';
import createUserAPI from './api';
import { IUserSession } from './types';

export const context = createContext<{
  api: ReturnType<typeof createUserAPI>,
  didLoad: boolean,
  userProfile: IUserSession,
  setUserProfile:(obj?: IUserSession) => any
}>(null);

export function UserAPIProvider({ children }: PropsWithChildren) {
  const session = useSession();
  const userSession = useUserSession();
  const [userProfile, setUserProfile] = React.useState<IUserSession>(userSession);
  useEffect(() => {
    setUserProfile(userSession);
  }, [userSession]);
  const accessToken = session?.getAccessToken().getJwtToken();
  const api = createUserAPI(
    accessToken,
    session?.getAccessToken().decodePayload().username,
    session?.getAccessToken().decodePayload()['cognito:groups'],
  );
  const value = useMemo(() => ({
    api, didLoad: !!accessToken, userProfile, setUserProfile,
  }), [accessToken, userProfile]);
  return api && <context.Provider value={value}>{children}</context.Provider>;
}

export default context;
