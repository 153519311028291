import React, { MouseEvent, ReactNode } from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import KrgButton, { VARIANT_ENUM as BUTTON_VARIANT_ENUM, SIZE_ENUM as BUTTON_SIZE_ENUM } from '@kargo/shared-components.krg-button';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { LinkI, ModalButtonsConfig } from '../../shared/interfaces';
import { TYPE_ENUM, SIZE_ENUM, STATUS_ENUM } from '../../shared/enums';
import { useBaseStyles, useStylesV1, useStylesV2, Props as StyleProps } from '../styles/';
import { HeaderSection, TitleSection, BodySection, LinksSection, InputSection } from '../modal-sections';

type Props = {
  /**
   * @summary
   * Determines what kind of modal will be rendered. (i.e continue, info, submit)
   */
  type?: TYPE_ENUM,
  /**
   * @summary
   * Custom input element
   */
  inputContainer?: ReactNode,
  /**
   * @summary
   * Text of submit/save action button of modal.
   */
  submitButtonText: string,
  /**
   * @summary
   * Text of discard action button of modal.
   */
  cancelButtonText: string,
  /**
   * @summary
   * Customizes button(s)
   */
  buttonConfig?: ModalButtonsConfig,
  /**
   * @summary
   * Adds icon and colorises header
   */
  headerIconType?: STATUS_ENUM,
  /**
   * @summary
   * Header text
   */
  header?: string,
  /**
   * @summary
   * Title of modal.
   */
  title?: string,
  /**
   * @summary
   * Body text of modal.
   */
  body?: string,
  /**
   * @summary
   * Link element of modal.
   */
  links?: LinkI[],
  /**
   * @summary
   * Adjusts maximum width of modal
   */
  maxWidth?: SIZE_ENUM,
  /**
   * @summary
   * Enabled / disabled cancel button
   */
  isCancelButtonEnabled?: boolean,
  /**
   * @summary
   * Enabled / disabled submit button
   */
  isSubmitButtonEnabled?: boolean,
  /**
   * @summary
   * Modal theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * This is triggered when submit/save action button is clicked
   */
  onSubmitClick?: (ev: MouseEvent<HTMLButtonElement>) => void,
  /**
   * @summary
   * This is triggered when X/discard button is clicked at the top right corner.
   */
  onClose?: (ev: MouseEvent<SVGSVGElement | HTMLButtonElement>) => void,
};

const SaveContinueModal = ({
  type,
  headerIconType,
  header = '',
  title = '',
  body = '',
  links = [],
  inputContainer,
  submitButtonText,
  cancelButtonText,
  buttonConfig,
  maxWidth,
  isCancelButtonEnabled,
  isSubmitButtonEnabled,
  theme = THEME_ENUM.v1,
  onSubmitClick,
  onClose,
}: Props) => {
  const props: StyleProps = {
    headerIconType,
    maxWidth,
    type,
  };
  const isV2Theme = theme === THEME_ENUM.v2;
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = isV2Theme ? classesV2 : classesV1;
  const hasInputContainer = !!inputContainer;

  return (
    <>
      { header &&
        <HeaderSection {...{ baseClasses, classes, isV2Theme, header, headerIconType }} />
      }

      { links?.length > 0 && isV2Theme &&
        <LinksSection {...{ classes, theme, links, maxWidth }} />}

      { body && isV2Theme && <BodySection {...{ classes, body }} />}

      { title && <TitleSection {...{ classes, title, hasInputContainer, isSubmit: false }} />}

      { inputContainer && <InputSection {...{ baseClasses, classes, inputContainer }} />}

      { !inputContainer && <div className={classes.spacer}/>}

      { /* BUTTON SECTION from here */}
      {
        type !== TYPE_ENUM.info &&
          <DialogContent className={`${baseClasses.buttons} ${classes.buttons}`}>
            {
              type === TYPE_ENUM.confirmation && cancelButtonText &&
              <DialogActions className={classes.button}>
                <KrgButton
                  text={cancelButtonText}
                  size={isV2Theme ? BUTTON_SIZE_ENUM.large : undefined}
                  style={{ width: buttonConfig?.cancelButton?.width }}
                  variant={buttonConfig?.cancelButton?.variant || BUTTON_VARIANT_ENUM.outlined}
                  startIcon={buttonConfig?.cancelButton?.startIcon}
                  endIcon={buttonConfig?.cancelButton?.endIcon}
                  isEnabled={isCancelButtonEnabled}
                  theme={theme}
                  onClick={onClose}
                />
              </DialogActions>
            }
            {
              submitButtonText &&
              <DialogActions className={classes.button}>
                <KrgButton
                  text={submitButtonText}
                  size={isV2Theme ? BUTTON_SIZE_ENUM.large : undefined}
                  style={{ width: buttonConfig?.submitButton?.width }}
                  variant={buttonConfig?.submitButton?.variant || BUTTON_VARIANT_ENUM.contained}
                  startIcon={buttonConfig?.submitButton?.startIcon}
                  endIcon={buttonConfig?.submitButton?.endIcon}
                  isEnabled={isSubmitButtonEnabled}
                  theme={theme}
                  onClick={onSubmitClick}
                />
              </DialogActions>
            }
          </DialogContent>
      }
    </>
  );
};

export default SaveContinueModal;
