import React from "react";
import { Box, Typography, Button } from "@mui/material";

type Props = {
  title?: string;
  publishers?: string[];
  domains?: string[];
  totalChange?: number;
  actions?: string;
  onOk: () => void;
};
export default function BulkSuccessConfirmation({
  title,
  publishers,
  domains,
  totalChange,
  actions,
  onOk,
}: Props) {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        boxShadow: 3,
        p: 5,
        m: 2,
        borderRadius: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="h6" component="h2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="textPrimary" sx={{ mt: 2 }}>
        Successfully {actions} the following publishers:
      </Typography>
      {publishers?.length > 0 && (
        <Typography variant="body2" gutterBottom color="textSecondary">
          {publishers?.join(" ")}
        </Typography>
      )}
      <Typography variant="body1" color="textPrimary" sx={{ mt: 2 }}>
        Successfully {actions} the following domains:
      </Typography>
      {domains?.length > 0 && (
        <Typography variant="body2" gutterBottom color="textSecondary">
          {domains?.join(" ")}
        </Typography>
      )}
      <Typography variant="body1" color="textPrimary" sx={{ mt: 2 }}>
        Total number of configuration change:
      </Typography>
      <Typography variant="body2" gutterBottom color="textSecondary">
        {totalChange}
      </Typography>
      <Button variant="contained" color="primary" onClick={onOk} sx={{ mt: 2 }}>
        OK
      </Button>
    </Box>
  );
}
