import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';

const useStylesV2 = makeStyles({
  inputContainer: {
    height: '2.3125em',
  },
  textField: {
    '& input': {
      fontSize: FONT.SIZE.REGULAR,
      fontWeight: FONT.WEIGHT.MEDIUM,
      color: ({ textColor }: Props) => textColor || COLORS_V2.NEUTRAL_BLACK,
      paddingLeft: '1em',
      paddingRight: '1em',
    },

    '& fieldset': {
      borderRadius: ({ isPillShaped }: Props) => isPillShaped ? '50px' : 0,
    },

    '& svg': {
      marginRight: '-0.25em',
    },

    // Helper text
    '& .MuiFormHelperText-root': {
      margin: '0.545em 0 0.0625em 0',
      fontSize: FONT.SIZE.SMALL,
      color: COLORS_V2.NEUTRAL_DARK,

      '&.Mui-error': {
        color: COLORS_V2.ERROR_DARK,
      },
    },

    // Label style
    '& .MuiInputLabel-root': {
      fontSize: FONT.SIZE.REGULAR,
      lineHeight: '1.625em',
      color: ({ labelColor }: Props) => labelColor || COLORS_V2.NEUTRAL_DARK,

      '&.MuiInputLabel-shrink': {
        fontSize: '0.83333em',
      },

      '&.Mui-disabled': {
        color: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },

    '& .MuiInputLabel-root.MuiFormLabel-filled': {
      fontSize: '0.9167em',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      fontSize: '0.9167em',
    },
  },

  textFieldStandard: {
    '& > .MuiInputBase-adornedStart' : {
      paddingLeft: 0,

      '& > svg': {
        margin: 0,
        marginBottom: '-0.685em',
      },
    },

    '& > .MuiInputBase-adornedEnd' : {
      '& > svg': {
        margin: 0,
        marginBottom: '-0.625em',
      },
    },

    '& > label': {
      marginTop: '-0.625em',

      '&.MuiInputLabel-shrink': {
        marginTop: '0.375em',
      },
    },
    '& .MuiInputBase-input': {
      margin: 0,
      padding: 0,
      marginTop: '1.5em',
    },
  },

  textFieldEnabled: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):not(.Mui-focused):before': {
      borderBottomColor: ({ borderColor }: Props) => borderColor || COLORS_V2.NEUTRAL_INACTIVE,
      borderWidth: '0.0625em',
    },
    '& .MuiInput-underline.Mui-focused:hover:before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      border: 'none',
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: ({ borderColor }: Props) => borderColor || COLORS_V2.PRIMARY_700,
    },
    // Label style
    '& .MuiInputLabel-root': {
      color: ({ labelColor }: Props) => labelColor || COLORS_V2.NEUTRAL_DARK,
    },
    '& .MuiInputLabel-root.MuiFormLabel-filled': {
      color: ({ labelColor }: Props) => labelColor || COLORS_V2.NEUTRAL_BLACK,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: ({ labelColor }: Props) => labelColor || COLORS_V2.PRIMARY_700,
    },
    // Border style for outline
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: ({ borderColor }: Props) => borderColor || COLORS_V2.NEUTRAL_DARK,
        '& legend': {
          width: ({ mediaLabel }: Props) => mediaLabel ? 0 : undefined,
        },
      },
      '&:hover fieldset': {
        borderColor: ({ borderColor }: Props) => borderColor || COLORS_V2.NEUTRAL_BLACK,
        opacity: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: ({ borderColor }: Props) => borderColor || COLORS_V2.PRIMARY_700,
        opacity: 1,
      },
    },
  },

  textFieldDisabled: {
    '& .MuiInput-underline:before': {
      borderBottomColor: COLORS_V2.NEUTRAL_INACTIVE,
    },
    '& .MuiInputBase-input': {
      color: COLORS_V2.NEUTRAL_INACTIVE,
      WebkitTextFillColor: COLORS_V2.NEUTRAL_INACTIVE,

      '&::placeholder': {
        WebkitTextFillColor: COLORS_V2.NEUTRAL_DARK,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },

    '& > .MuiInputBase-adornedStart' : {
      color: COLORS_V2.NEUTRAL_INACTIVE,

      '& *': {
        color: COLORS_V2.NEUTRAL_INACTIVE,
      },
    },
  },

  textFieldEmpty: {
    '& svg': {
      color: ({ iconColor }: Props) => iconColor || COLORS_V2.NEUTRAL_DARK,
    },
    '& .Mui-focused': {
      '& svg': {
        color: ({ iconColor }: Props) => iconColor || COLORS_V2.PRIMARY_700,
      },
    },
  },

  textFieldFilled: {
    '& svg': {
      color: ({ iconColor }: Props) => iconColor || COLORS_V2.NEUTRAL_DARK,
    },
    '& .Mui-focused': {
      '& svg': {
        color: ({ iconColor }: Props) => iconColor || COLORS_V2.PRIMARY_700,
      },
    },
  },

  textFieldError: {
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS_V2.ERROR_DARK, // Solid underline on focus
    },
    '& .MuiFormHelperText-root': {
      color: COLORS_V2.ERROR_DARK,
    },
    '& svg': {
      color: COLORS_V2.ERROR_DARK,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: COLORS_V2.ERROR_DARK,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.ERROR_DARK,
        borderWidth: '0.125em',
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS_V2.ERROR_DARK,
        opacity: 1,
      },
    },
  },
});

export default useStylesV2;
