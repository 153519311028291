/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow */
import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import _, { debounce } from "lodash";
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import { Config } from "../../../api/types";
import LoadingCircle from "../../../components/LoadingCircle";
import tracker from "../../../helpers/tracker";
import { useDomain } from "../../../hooks/useDomain";
import CreatePlayerConfigModal from "../../../components/Modals/CreatePlayerConfigModal";
import SearchDropdown from "../../../components/SearchDropdown";
import Table from "../../../components/Table";
import TrackedKrgButton from "../../../components/TrackedButton/TrackedKrgButton";
import { useSuperAdminContext } from "../../../routes/super_admin";
import "./styles.scss";
import { ISuperAdminRouteParams } from "../../../routes/superadmin_types";
import { PATHS } from "../../../constants/paths";

export default function PlayerConfig() {
  const { addPathToBreadCrumb } = useSuperAdminContext();
  const history = useHistory();
  const params = useParams<ISuperAdminRouteParams>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, isFetched } = useDomain(params.domainToken || "");

  const configs = data?.configs || [];
  const [selectedDomain, setSelectedDomain] = React.useState<Config>();
  const [updatedSelectedDomain, setUpdatedSelectedDomain] =
    React.useState<Config>();
  const [search, setSearch] = React.useState("");
  const [isDomainSelected, setIsDomainSelected] = React.useState(false);

  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );

  React.useEffect(() => {
    if (configs?.length) {
      const selectedDomainData = configs.find(
        (d) => d.token === selectedDomain?.token,
      );
      if (selectedDomainData) {
        setUpdatedSelectedDomain(_.cloneDeep(selectedDomainData));
      } else {
        setSelectedDomain(configs[0]);
      }
    }
  }, [configs, selectedDomain]);

  React.useEffect(() => {
    if (search) {
      debouncedTrack("Search", {
        resource: "property",
        text: search,
      });
    } else {
      setIsDomainSelected(false);
    }
  }, [search]);

  const filterDomainList = (ctxSelectedDomain: Config) => {
    if (!isDomainSelected) return configs;
    return configs.filter((config) =>
      config?.key
        .toLowerCase()
        .startsWith(ctxSelectedDomain?.key?.toLowerCase()),
    );
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    text: string,
  ) => setSearch(text);

  if (!configs && !isFetched) {
    return (
      <div className="krg-loading-wrapper">
        <LoadingCircle />
      </div>
    );
  }

  React.useEffect(() => {
    if (data) {
      addPathToBreadCrumb([
        {
          path: generatePath(PATHS.ADMIN_DOMAIN_CONFIG, {
            domainToken: params.domainToken,
          }),
          pathName: data.domain,
        },
      ]);
    }
  }, [data]);

  const columns = [
    {
      field: "key",
      headerName: "Player Config",
      onClick: (_, row) => {
        history.push(
          generatePath(PATHS.ADMIN_PLAYER_EDIT, {
            domainToken: params.domainToken,
            playerToken: row.token,
          }),
        );
      },
      renderCell: (row) => <p className="krg-ellipses-text">{row.key}</p>,
    },
    {
      field: "notes",
      headerName: "Notes",
      renderCell: (row) => (row.notes ? <p>{row.notes}</p> : null),
    },
    {
      field: "revision",
      headerName: "Revision",
      onClick: (_, row) => {
        history.push(
          generatePath(PATHS.ADMIN_PLAYER_CONFIG, {
            domainToken: params.domainToken,
            playerToken: row.token,
          }),
        );
      },
      renderCell: (row) => <p>{row.revision}</p>,
    },
    {
      field: "updated_at",
      headerName: "Last update",
      renderCell: (row) => (
        <p>
          {dayjs(row.updated_at).format("DD/MMM/YY ")}
          {dayjs(row.updated_at).format(" HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "created_by_user",
      headerName: "Updated by",
      renderCell: (row) => <p>{row.created_by_user}</p>,
    },
    {
      field: "",
      headerName: "Clone",
      onClick: (_, row) => {
        history.push(
          generatePath(PATHS.ADMIN_PLAYER_CLONE, {
            domainToken: params.domainToken,
            playerToken: row.token,
          }),
          {
            config: row,
          },
        );
      },
      renderCell: (row) => <FileCopyIcon color="secondary" />,
    },
    {
      field: "",
      headerName: "Edit",
      onClick: (_, row) => {
        history.push(
          generatePath(PATHS.ADMIN_PLAYER_EDIT, {
            domainToken: params.domainToken,
            playerToken: row.token,
          }),
          {
            config: row,
          },
        );
      },
      renderCell: (row) => <EditIcon color="primary" />,
    },
  ];
  const rows = filterDomainList(updatedSelectedDomain)?.map((d) => d) || [];

  return (
    <Box padding="20px">
      <Box padding="0 30px 0 0" display="flex" justifyContent="space-between">
        <SearchDropdown
          searchValue={search}
          widthSize="minWidth"
          placeholder="search player config"
          options={configs?.map((d) => d.key)}
          onSearchChange={handleSearchChange}
          onOptionClick={(value: string) => {
            setSelectedDomain(
              configs?.find(
                (d) => d?.key?.toLowerCase() === value?.toLowerCase(),
              ),
            );
            setIsDomainSelected(true);
          }}
        />
        <TrackedKrgButton
          id="add-player"
          text="Add config"
          onClick={() => {
            history.push(
              generatePath(PATHS.ADMIN_CONFIG_EDIT, {
                domainToken: params.domainToken,
              }),
              {
                config: {},
              },
            );
          }}
          variant={BUTTON_VARIANT_ENUM.contained}
          size={BUTTON_SIZE_ENUM.small}
        />
      </Box>
      <CreatePlayerConfigModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onSubmit={() => setModalIsOpen(false)}
        domainToken={params.domainToken}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Table columns={columns} rows={rows} size={20} />
      </Box>
    </Box>
  );
}
