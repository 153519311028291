import React from 'react';
import { makeStyles } from '@mui/styles';
import KrgDataTable, { DataTableCol, DataTableRow } from '@kargo/shared-components.krg-data-table';

const useStyles = makeStyles({
  table: {
    overflow: 'auto',
    height: 'auto',
    '& th, & td': {
      paddingLeft: '10px',
    },
    '& thead': {
      boxShadow: '0 1px black',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      '& th': {
        background: 'transparent',
        color: 'black',
        fontSize: '11px',
        fontWeight: 600,
      },
      background: 'white',
    },
    '& tbody': {
      '& tr': {
        height: '50px',
        '&:nth-of-type(even)': {
          '&:hover': {
            background: '#EEF3F7',
          },
          background: '#EEF3F7',
        },
        '&:hover': {
          background: 'white',
        },
      },
      '& td': {
        border: 0,
        '& div': {
          fontSize: '13px',
          textTransform: 'uppercase',
          fontWeight: 500,
        },
      },
    },
  },
  selected: {
    color: '#0033FF',
  },
});

type ITableProps = {
  columns: DataTableCol[],
  rows: DataTableRow[],
  size: number,
};
export default function Table(props : ITableProps) {
  const {
    columns,
    rows,
    size = 10,
  } = props;
  const classes = useStyles();
  return (
    <KrgDataTable
      containerClassName={classes.table}
      config={{
        data: {
          columns,
          rows,
        },
        pagination: {
          initialPageSize: size,
          isHidden: false,
        },
      }}
    />
  );
}
