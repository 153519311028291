import React from 'react';

export const useDeletePressed = (ref: any, callback: () => void) => {
  React.useEffect(() => {
    /**
     * Alert if delete pressed
     */
    const handleDeletePressed = (event: any) => {
      const isDeleteKey = event.key === 'Delete' || event.key === 'Backspace';
      if (
        isDeleteKey &&
        ref.current &&
        (ref.current === event.target || ref.current.contains(event.target))
      ) {
        callback();
      }
    };

    // Bind the event listener
    document.addEventListener('keydown', handleDeletePressed);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleDeletePressed);
    };
  });
};
