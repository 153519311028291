import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';
import { IStorage } from './storage';

export const IUnifiedId: BaseJsonSchema = {
  type: 'object',
  properties: {
    name: createSchemaByBasicTypes('string', '', {
      enum: ['unifiedId'],
    }),
    params: {
      type: 'object',
      properties: {
        partner: createSchemaByBasicTypes('string', 'vu6qg20'),
      },
      required: ['partner'],
      storage: IStorage,
    },
  },
};
