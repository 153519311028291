import React from "react";
import { debounce } from "lodash";
import { Box } from "@mui/material";
import KrgSnackbar from "@kargo/shared-components.krg-snackbar";
import usePlaylists from "../../hooks/usePlaylists";
import PlaylistAccordion from "./PlaylistAccordion";
import CreatePlaylistForm from "./CreatePlaylistForm";
import { PlaylistInput } from "../../api/types";
import tracker from "../../helpers/tracker";
import SearchInput from "../../components/SearchInput";
import LoadingCircle from "../../components/LoadingCircle";
import Snackbar from "../../components/Snackbar";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  publisherGroup?: string;
  publisherUsename?: string;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: (playlist: PlaylistInput) => void;
  };
};
export default function Playlists({
  asAdmin,
  publisherSlug,
  publisherGroup,
  publisherUsename,
  createOptions: copt,
}: Props) {
  const [search, setSearch] = React.useState("");
  const {
    playlists,
    query: { isFetching, isFetched },
  } = usePlaylists({
    ...(asAdmin && { asAdmin }),
    ...(search && { title: search }),
    ...(publisherSlug && { slug: publisherSlug }),
  });
  const [copyPlaylist, setCopyPlaylist] = React.useState("");
  const [showPlaylist, setShowPlaylist] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(true);
  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );
  React.useEffect(() => {
    setShowPlaylist((asAdmin && Boolean(publisherSlug)) || !asAdmin);
  }, [asAdmin, publisherSlug]);

  React.useEffect(() => {
    if (search) {
      debouncedTrack("Search", {
        resource: "playlist",
        title: search,
      });
    }
  }, [search]);

  return (
    <>
      <SearchInput placeholder="Playlist" onDebounceEnd={setSearch} />
      {!isFetched && isFetching ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 219px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingCircle />
        </div>
      ) : (
        <>
          {copt?.isVisible && (
            <CreatePlaylistForm
              publisherGroup={publisherGroup}
              username={publisherUsename}
              onClose={copt?.onClose}
              onSubmit={copt?.onSubmit}
            />
          )}
          {showPlaylist &&
            playlists
              .filter(
                (pl) =>
                  pl.title?.toLowerCase().indexOf(search.toLowerCase()) >= 0,
              )
              .map((playlist) => (
                <Box key={playlist.token}>
                  <PlaylistAccordion
                    playlist={playlist}
                    onCopy={() => setCopyPlaylist(playlist.token)}
                  />
                  {copyPlaylist === playlist.token && (
                    <PlaylistAccordion
                      playlist={playlist}
                      onCopy={() => setCopyPlaylist("")}
                      isCopy
                    />
                  )}
                </Box>
              ))}
        </>
      )}
      {asAdmin && !showPlaylist && (
        <Snackbar
          autoHideDuration={6000}
          type={KrgSnackbar.TYPE_ENUM.warning}
          isOpen={isOpen}
          theme={KrgSnackbar.THEME_ENUM.v2}
          button={{
            text: "continue",
            onClick: () => {
              setIsOpen(false);
              console.warn(
                "Please switch to a publisher account at the top right-hand \n corner to view uploaded videos, MRSS feeds and playlists",
              );
            },
          }}
          text={
            <div style={{ fontSize: "13px" }}>
              <p>Please switch to a publisher account at the top right-hand</p>
              <p>corner to view uploaded videos, MRSS feeds and playlists</p>
            </div>
          }
        />
      )}
    </>
  );
}
