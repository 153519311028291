import KrgModal from './krg-modal.react';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { VARIANT_ENUM as BUTTON_VARIANT_ENUM }from '@kargo/shared-components.krg-button';

export { THEME_ENUM };
export { BUTTON_VARIANT_ENUM };
export * from './shared/interfaces';
export * from './shared/enums';

export default KrgModal;
