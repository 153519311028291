import React, { CSSProperties, useState } from 'react';
import {
  Check as CheckIcon,
  DriveFileRenameOutline as EditIcon,
} from '@mui/icons-material';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgTextInput from '@kargo/shared-components.krg-text-input';
import KrgTooltip from '@kargo/shared-components.krg-tooltip';
import useStyles, { Props as StyleProps } from './text-cell.style';
import { DataTableRow } from './../../shared/interfaces';
import { DataTableCol } from '../../shared/interfaces';
import { getTablePopperProps } from '../../shared/utils';
import { TEXT_ALIGNMENT } from '../../shared/enums';

type Props = {
  /**
   * @summary
   * Row of button for onClick function
   */
  row: DataTableRow,
  /**
   * @summary
   * Table column
   */
  column: DataTableCol,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
};

const TextCell = ({
  row,
  column,
  theme,
}: Props) => {
  const props: StyleProps = {
    theme,
  };
  const classes = useStyles(props);
  const {
    isEditable,
    editFinishedIconDuration = 2000,
    onEditFinished,
    onEditCanceled,
  } = column;
  const cellText = row[column.field];

  const [text, setText] = useState(cellText);
  const [editing, setEditing] = useState<boolean>(false);
  const [isInputEnabled, setIsInputEnabled] = useState<boolean>(true);
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
  const [showCheckMarkIcon, setShowCheckMarkIcon] = useState<boolean>(false);

  const handleOnMouseEnter = () => {
    setShowEditIcon(true);
  };

  const handleOnMouseMove = () => {
    if (!showEditIcon) {
      setShowEditIcon(true);
    }
  };

  const handleMouseLeave = () => {
    setShowEditIcon(false);
  };

  const handleEditFinished = async () => {
    setIsInputEnabled(false);

    const successful = await onEditFinished?.(text, column, row);
    // Revert input text if operation is not successful
    if (successful === false) {
      setText(cellText);
    } else if (successful === true) {
      // Show checkmark for the successful operation
      setShowCheckMarkIcon(true);
      setTimeout(
        () => { setShowCheckMarkIcon(false); },
        editFinishedIconDuration,
      );
    }

    setIsInputEnabled(true);
    setShowEditIcon(false);
    setEditing(false);
  };

  const handleEditCanceled = () => {
    onEditCanceled?.(text, column, row);
    setShowEditIcon(false);
    setEditing(false);
    setText(cellText);
  };

  const getCellStyle = () => {
    const style: CSSProperties = {};

    if (!editing && column.width) {
      style.maxWidth = `calc(${column.width} - 1.85em)`;
    }

    if (!editing) {
      style.paddingRight = column.isSortable ? '2em' : 0;
    }

    return style;
  };

  const editingCellElem = (
    <KrgTextInput
      text={text}
      hasAutoFocus
      isAutoHeight
      isFullWidth
      isEnabled={isInputEnabled}
      theme={theme}
      onClick={e => e.stopPropagation()}
      onTextChange={setText}
      onBlur={async () => await handleEditFinished()}
      onKeyDown={async ({ key }) => {
        if (key === 'Enter') {
          await handleEditFinished();
        } else if (key === 'Escape') {
          handleEditCanceled();
        }
      }}
    />
  );

  const cellElem = (
    <div className={classes.cell} style={getCellStyle()}>
      {cellText}
      {
        (showEditIcon && isEditable) ?
        <KrgTooltip
          tooltip="Rename"
          position={KrgTooltip.POSITION_ENUM.top}
          tooltipProps={{
            PopperProps: getTablePopperProps(),
          }}
          hasArrow={false}
          theme={theme}
        >
          <EditIcon
            className={`${classes.editIcon} ${classes.icon}`}
            onClick={(e) => {
              e.stopPropagation();
              setEditing(true);
            }}
          />
        </KrgTooltip> :
        showCheckMarkIcon ?
        <CheckIcon className={classes.checkIcon} /> :
        null
      }
    </div>
  );

  return (
    <div
      className={classes.root}
      style={{
        justifyContent: column?.textAlignment || TEXT_ALIGNMENT.left,
      }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleOnMouseMove}
    >
      {editing ? editingCellElem : cellElem}
    </div>
  );
};

export default TextCell;
