import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';

import './style.scss';
import { Link } from "react-router-dom";

export type IPath = {
  path: string
  pathName: string
};
type IBreadCrumbs = {
  paths: IPath[];
  updatePaths: (idx: number) => void
};
export function KrgBreadcrumbs({ paths, updatePaths }: IBreadCrumbs) {
  return (
    <Breadcrumbs className="krg-breadcrumbs" aria-label="breadcrumb">
      {
        paths.map((item, idx, arr) => {
          if (idx === arr.length - 1) {
            return <Typography key={item.path} color="inherit">{item.pathName}</Typography>;
          }
          return (
            <Link
              key={item.path}
              onClick={() => updatePaths(idx)}
              to={item.path}
            >
              {item.pathName}
            </Link>
          );
        })
      }
    </Breadcrumbs>
  );
}
