import { makeStyles } from '@mui/styles';

export type Props = {
  fontSize?: string | number | (string & {}) | undefined,
};

const useBaseStyles = makeStyles({
  tablePaginationDisabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

});

export default useBaseStyles;
