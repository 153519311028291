import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';

export const IFloatingOptions: BaseJsonSchema = {
  type: 'object',
  properties: {
    'starts-paused': createSchemaByBasicTypes('boolean', true),
    position: createSchemaByBasicTypes('string', undefined),
    threshold: createSchemaByBasicTypes('number', 0.3),
    static: createSchemaByBasicTypes('boolean', true),
    sidebar: createSchemaByBasicTypes('boolean'),
    floatingonly: createSchemaByBasicTypes('boolean'),
    closeable: createSchemaByBasicTypes('boolean', true),
    hideplayeronclose: createSchemaByBasicTypes('boolean'),
    showcompanionad: createSchemaByBasicTypes('boolean'),
    noFloatOnDesktop: createSchemaByBasicTypes('boolean'),
    noFloatOnMobile: createSchemaByBasicTypes('boolean'),
    noFloatIfAbove: createSchemaByBasicTypes('boolean'),
    noFloatIfBelow: createSchemaByBasicTypes('boolean'),
    mobile: {
      type: 'object',
      properties: {
        position: createSchemaByBasicTypes('string', 'top'),
        height: createSchemaByBasicTypes('number', 175),
        sidebar: createSchemaByBasicTypes('boolean', true),
        companionad: createSchemaByBasicTypes('boolean', true),
        bottom: createSchemaByBasicTypes('number'),
        size: createSchemaByBasicTypes('string'),
        availablesizes: {
          type: 'object',
          properties: {
            x: createSchemaByBasicTypes('number'),
            s: createSchemaByBasicTypes('number'),
            m: createSchemaByBasicTypes('number'),
            l: createSchemaByBasicTypes('number'),
            xl: createSchemaByBasicTypes('number'),
          },
        },
      },
    },
    desktop: {
      type: 'object',
      properties: {
        position: createSchemaByBasicTypes('string', 'bottom-right'),
        sidebar: createSchemaByBasicTypes('boolean'),
        height: createSchemaByBasicTypes('number'),
        companionad: createSchemaByBasicTypes('boolean'),
        bottom: createSchemaByBasicTypes('number', 30),
        size: createSchemaByBasicTypes('string', 'm'),
        availablesizes: {
          type: 'object',
          properties: {
            x: createSchemaByBasicTypes('number', 169),
            s: createSchemaByBasicTypes('number', 183),
            m: createSchemaByBasicTypes('number', 197),
            l: createSchemaByBasicTypes('number', 211),
            xl: createSchemaByBasicTypes('number', 225),
          },
        },
      },
    },
  },
  additionalProperties: {
    type: ['number', 'string', 'boolean', 'array', 'object', 'null'],
    description: 'no type declaration on this property, you are adding a new one',
  },
};
