import * as React from "react";
import {
  AppBar, Box, Button, Container, Toolbar,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useHistory } from "react-router-dom";
import KrgHeaderMenuButton, { MenuButtonItemV1 } from "@kargo/shared-components.krg-header-menu-button";
import useAPI from "../../api/useAPI";
import UserAvatar from "../UserAvatar";
import { stringAvatar } from "../../helpers/ui";
import tracker from "../../helpers/tracker";
import FabrikLogo from "../Icons/FabrikLogo";
import { setRecentSearchPublisher } from "../../helpers/recentSearchPublisher";
import { setUsername, setPublisherGroup } from "../../helpers/publisherUsername";

import PublisherDropdownWithSearch from "../PublisherDropdownWithSearch";

export function Header({ asAdmin }: { asAdmin: boolean }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const history = useHistory();
  const { userProfile } = useAPI();
  if (!userProfile) return <> </>;
  const profileProps = stringAvatar(
    (userProfile.attributes?.name as string) || userProfile.username,
  );

  const menuItems: MenuButtonItemV1[] = [
    {
      text: "Signout",
      handleClick: () => {
        signOut();
        setRecentSearchPublisher("");
        setUsername("");
        setPublisherGroup("");
        tracker.reset();
      },
    },
  ];

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{
        background: "linear-gradient(90deg, #1035C9 0%, #2550FA 100%)",
        height: "70px",
        justifyContent: "center",
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Button onClick={() => history.push("/")}>
            <FabrikLogo />
          </Button>
          {profileProps && (
            <Box sx={{ display: "flex", rowGap: "20px" }}>
              { process.env.LATEST_COMMIT_HASH
                  && (
                  <p style={{ marginTop: "12px", marginRight: "10px", fontSize: "12px" }}>
                    {process.env.LATEST_COMMIT_HASH}
                  </p>
                  )}
              {asAdmin && <PublisherDropdownWithSearch asAdmin={asAdmin} />}
              <KrgHeaderMenuButton
                isOpen={isOpen}
                onVisibilityChange={setIsOpen}
                icon={(
                  <UserAvatar
                    width={35}
                    height={35}
                    text={profileProps.text}
                    color={profileProps.color}
                  />
                )}
                menuItems={menuItems}
              />
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
