/* eslint-disable react/prop-types */
import React from "react";
import KrgSelect from "@kargo/shared-components.krg-select";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  select: {
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      backgroundColor: "transparent",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
  },
});

export default function Select({
  className,
  classNames,
  theme,
  ...props
}: React.ComponentProps<typeof KrgSelect>) {
  const classes = useStyles();
  return (
    <KrgSelect
      className={className || classes.select}
      classNames={classNames || { selectContainer: classes.select }}
      theme={theme || KrgSelect.THEME_ENUM.v2}
      {...props}
    />
  );
}
