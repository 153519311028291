import { LookerEmbedSDK, PagePropertiesChangedEvent } from '@looker/embed-sdk';
import { LOOKER_HOST, USER_API } from '../api/endpoints';

let initialized = false;
export async function pagePropertiesChangedHandler(
  { height }: PagePropertiesChangedEvent,
  element: HTMLElement,
) {
  if (height && height > 100) {
    const iframe = element.querySelector(
      'iframe',
    ) as HTMLIFrameElement;
    if (iframe) {
      iframe.style.height = `${height}px`;
    }
  }
}

export function initLookerSDK(sessionToken: string) {
  if (!initialized) {
    LookerEmbedSDK.initCookieless(
      LOOKER_HOST,
      {
        url: `${USER_API}/dashboards/looker-acquire-embed-session`,
        headers: {
          Authorization: sessionToken,
        },
      },
      {
        url: `${USER_API}/dashboards/looker-generate-embed-tokens`,
        headers: {
          Authorization: sessionToken,
          'content-type': 'application/json',
        },
        method: 'PUT',
      },
    );
    initialized = true;
  }
  return LookerEmbedSDK;
}
