import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';

const useBaseStyles = makeStyles({
  // Checkbox container
  container: {
    alignItems: 'center',
    display: 'flex',
    fontSize: FONT.SIZE.BASE,
  },

  // Checkbox classes
  root: {
    color: COLORS.GRAY_DARK,
    fontSize: FONT.SIZE.EXTRA_LARGE,
    padding: '0.3em',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
    },
  },

  // Label text
  label: {
    cursor: 'pointer',
  },
});

export default useBaseStyles;
