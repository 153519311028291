import React, { ReactNode } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

type Props = {
    disabled?: boolean,
    items?: Array<{
        key: string,
        onClick: () => any,
        inner: ReactNode
    }>
};
export default function MenuButton({
    disabled,
    items
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ padding: 0, color: '#303237', fontSize: '20px' }} disableRipple disabled={disabled} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        sx={{
            borderRadius: 0,
            '& .MuiPaper-root': {
                minWidth: '180px',
                borderRadius: 0,
            },
            '& .MuiMenuItem-root': {
                fontSize: '11px',
                '& .MuiSvgIcon-root': {
                    fontSize: '20px',
                    marginRight: '18px',
                }
            }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {items?.map(i => (
            <MenuItem key={i.key} onClick={(e) => {
              e.stopPropagation();
                handleClose();
                i.onClick();
            }}>
                { i.inner }
            </MenuItem>
        ))}
      </Menu>
    </>
  );
}
