import React from 'react';
import { Typography } from '@mui/material';
import KrgModal from '@kargo/shared-components.krg-modal';
import KrgTextInput from '@kargo/shared-components.krg-text-input';
import './styles.scss';

type Props = { id?: string, elementClassName?: string, onSubmit: (v: string) => any }
    & Pick<React.ComponentProps<typeof KrgModal>, 'isOpen' | 'onClose' | 'title' | 'submitButtonText' | 'cancelButtonText'>
    & Pick<React.ComponentProps<typeof KrgTextInput>, 'text' | 'label'>;
export default function InputModal({
  id, elementClassName, isOpen, onClose, onSubmit, label, text, title, submitButtonText, cancelButtonText,
}: Props) {
  const [value, setValue] = React.useState<string>();
  return (
    <KrgModal
      className={`modal${elementClassName ? ` ${elementClassName}` : ''}`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmitClick={() => onSubmit(value || text)}
      theme={KrgModal.THEME_ENUM.v2}
      type={KrgModal.TYPE_ENUM.submit}
      inputContainer={(
        <>
          <KrgTextInput
            text={text}
            theme={KrgTextInput.THEME_ENUM.v2}
            onDebounceEnd={setValue}
            debounceTime={0}
            isFullWidth
            label={label}
          />
          { id && (
          <Typography className="id" color="#91A0AD" fontSize="10px">
            ID:
            {' '}
            {id}
          </Typography>
          )}
        </>
      )}
      title={title}
      submitButtonText={submitButtonText || 'Save'}
      cancelButtonText={cancelButtonText || 'Cancel'}
    />
  );
}
