import { useCallback } from 'react';
import { useQuery } from 'react-query';
import useAPI from '../api/useAPI';

export default function useCategories(inputQuery?: any) {
  const { api, didLoad } = useAPI();
  const fetchCategories = useCallback(() => api && api.getCategories(inputQuery), [api, inputQuery]);
  const query = useQuery(['categories', inputQuery], () => fetchCategories(), { enabled: didLoad, staleTime: Infinity });
  return {
    query,
    categories: query.data?.categories || [],
  };
}
