import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";

createRoot(document.getElementById("root")).render(
  // KrgButton is throwing the following error with StrictMode: https://github.com/mui/material-ui/issues/20182
  // <StrictMode>
  <App />
  // </StrictMode>,
);
