import { makeStyles } from '@mui/styles';
import { STATUS_ENUM, SIZE_ENUM, TYPE_ENUM } from '../../shared/enums';

export type Props = {
  headerIconType?: STATUS_ENUM,
  maxWidth?: SIZE_ENUM,
  type?: TYPE_ENUM,
};

const useBaseStyles = makeStyles({
  /* Dialog Header */
  header: {
    display: 'flex',
    alignItems: 'center',
  },

  /* Content input */
  input: {
    '& > div': {
      height: 'auto',
    },
  },

  /* Buttons container */
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '@media (max-width: 499.95px)': {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
});

export default useBaseStyles;
