import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQueryClient } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallBack/ErroFallBack";
import tracker from "../helpers/tracker";
import { NotFound } from "../components";
import Template from "./template";
import AdminAuthenticator from "./admin";
import MediaGallery from "../pages/media-gallery";
import Users from "../pages/users";
import PublisherHub from "../pages/publisher_hub";
import Analytics from "../pages/analytics";
import Reporting from "../pages/reporting";
import useSession from "../hooks/useSession";
import useUser from "../hooks/useUser";
import { getPublisherAccessPayload } from "../helpers/getPublisherAccessPayload";
import { SuperAdminProvider } from "./super_admin";
import DomainsPage from "../pages/admin/domains";
import PlayerConfig from "../pages/admin/players";
import EditPage from "../pages/admin/Edit";
import ClonePage from "../pages/admin/clone";
import BulkEditPage from "../pages/admin/bulk-edit";
import RevisionPage from "../pages/admin/revisions";
import AdminPublisherOnBoardPage from "../pages/admin/publisher-onboard";
import { PATHS } from "../constants/paths";

tracker.init({
  debug: process.env.NODE_ENV !== "production",
  persistence: "localStorage",
});

export default function AppRouter() {
  const admin = "admin";
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const queryClient = useQueryClient();
  const session = useSession();
  const userAccessPayload = session && session.getAccessToken().payload;
  const userName = userAccessPayload && (userAccessPayload.username as string);
  const userGroups = userAccessPayload && userAccessPayload["cognito:groups"];
  const isAdmin = userGroups && userGroups.includes(admin);
  const { user, group, slug } = useUser(userName);
  const { isPublisherAdmin, publisherGroup } = getPublisherAccessPayload(
    user,
    isAdmin,
    admin,
    userName,
    group,
  );

  useEffect(() => {
    if (authStatus === "unauthenticated") queryClient.removeQueries();
  }, [authStatus]);

  return (
    <Router>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(e) => {
          tracker.track("Error", {
            type: "runtime",
            errorName: e.name,
            errorMessage: e.message,
          });
        }}
      >
        <Template asAdmin={isAdmin} isPublisherAdmin={isPublisherAdmin}>
          <Switch>
            <Route path={PATHS.ROOT} exact>
              <Redirect to={PATHS.PUBLISHER_HUB} />
            </Route>
            <Route path={PATHS.PUBLISHER_HUB}>
              <PublisherHub asAdmin={isAdmin} slug={slug} />
            </Route>
            <Route path={PATHS.MEDIA_GALLERY}>
              <MediaGallery asAdmin={isAdmin} publisherGroup={publisherGroup} />
            </Route>
            <Route path={PATHS.DASHBOARDS}>
              <Analytics asAdmin={isAdmin} />
            </Route>
            <Route path={PATHS.REPORTING}>
              <Reporting asAdmin={isAdmin} />
            </Route>
            <Route path={PATHS.ADMIN}>
              {/* @ts-ignore */
              /* @aws-amplify/ui-react/Loader conflict with string types. Will be better to upgrade */}
              <AdminAuthenticator
                admin={admin}
                isPublisherAdmin={isPublisherAdmin}
              >
                <Switch>
                  <Route path={PATHS.ADMIN_USERS}>
                    <Users slug={slug} isAdmin={isAdmin} user={user} />
                  </Route>
                  <SuperAdminProvider isAdmin={isAdmin} slug={slug}>
                    <Route path={PATHS.ADMIN_DOMAIN} exact>
                      <DomainsPage asAdmin={isAdmin} />
                    </Route>
                    <Route path={PATHS.ADMIN_DOMAIN_CONFIG} exact>
                      <PlayerConfig />
                    </Route>
                    <Route path={PATHS.ADMIN_PLAYER_CONFIG} exact>
                      <RevisionPage />
                    </Route>
                    <Route
                      path={[
                        PATHS.ADMIN_PLAYER_EDIT,
                        PATHS.ADMIN_REVISION_EDIT,
                      ]}
                      exact
                    >
                      <EditPage />
                    </Route>
                    <Route path={PATHS.ADMIN_PUBLISHER_ONBOARD} exact>
                      <AdminPublisherOnBoardPage />
                    </Route>
                    <Route path={PATHS.ADMIN_PLAYER_CLONE} exact>
                      <ClonePage />
                    </Route>
                    <Route path={PATHS.ADMIN_BULK_UPDATE} exact>
                      <BulkEditPage actions="update" />
                    </Route>
                    <Route path={PATHS.ADMIN_BULK_DELETE} exact>
                      <BulkEditPage actions="delete" />
                    </Route>
                  </SuperAdminProvider>
                </Switch>
              </AdminAuthenticator>
            </Route>
            <Route path={PATHS.NOT_FOUND}>
              <NotFound />
            </Route>
          </Switch>
        </Template>
      </ErrorBoundary>
    </Router>
  );
}
