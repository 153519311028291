import React from "react";
import { Auth, I18n } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  translations,
} from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { HelpOutline } from "@mui/icons-material";
import awsconfig from "../../aws-exports";
import tracker from "../../helpers/tracker";
import { FabrikLogo } from "../Icons";

I18n.putVocabularies(translations);

I18n.putVocabularies({
  en: {
    // username cannot have whitespace
    "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
      "Username cannot have whitespace.",
  },
});

function Header() {
  return (
    <Box
      style={{
        width: "464px",
        height: "90px",
        background: "linear-gradient(90deg, #1035C9 0%, #2550FA 100%)",
        color: "white",
        textTransform: "uppercase",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        gap: "12px",
        fontSize: "28px",
        fontWeight: 700,
      }}
    >
      <FabrikLogo height="25" width="82.26" />
      <div style={{ marginTop: 4 }}>Video Player</div>
    </Box>
  );
}

function Footer() {
  const [isChecked, setIsChecked] = React.useState(
    localStorage.getItem("remember_user") !== "no",
  );
  const { toResetPassword } = useAuthenticator();
  if (isChecked) {
    Auth.configure({ ...awsconfig, storage: localStorage });
    localStorage.setItem("remember_user", "yes");
  } else {
    Auth.configure({ ...awsconfig, storage: sessionStorage });
    localStorage.setItem("remember_user", "no");
  }
  return (
    <div
      className="data-amplify-footer"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "60px",
      }}
    >
      <KrgTooltip
        style={{
          position: "absolute",
          left: "max(380px, calc(50vw + 143px))",
        }}
        classNames={{
          popper: "passwordtooltip",
        }}
        position={KrgTooltip.POSITION_ENUM.topEnd}
        theme={KrgTooltip.THEME_ENUM.v2}
        tooltip="Your password contains at least: 8 characters, 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter"
      >
        <HelpOutline
          style={{
            position: "absolute",
            top: "max(calc(50vh + 14px), 260px)",
            left: "max(410px, calc(50vw + 170px))",
            color: "#91A0AD",
            fontSize: "20px",
          }}
        />
      </KrgTooltip>
      <KrgCheckbox
        isChecked={isChecked}
        onToggle={setIsChecked}
        label="Remember Me"
        className="login-checkbox"
        theme={KrgCheckbox.THEME_ENUM.v2}
        style={{
          marginTop: "-132px",
          alignSelf: "flex-start",
          marginLeft: "52px",
          color: "#91A0AD",
        }}
      />
      <button
        type="button"
        className="amplify-button--link amplify-button--small"
        style={{
          marginTop: "106px",
        }}
        onClick={toResetPassword}
      >
        Forgot your Password?
      </button>
    </div>
  );
}

function AuthenticatorInner({ children }: React.PropsWithChildren) {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const { _state, error, user } = useAuthenticator();
  React.useEffect(() => {
    if (user?.username) tracker.identify(user.username);
    Auth.currentSession().then((session) => {
      const userGroups = session.getAccessToken().payload["cognito:groups"];
      userGroups?.forEach((group) => tracker.add_group("Publisher", group));
      const userIsAdmin = userGroups?.includes("admin");
      tracker.people.set({ Role: userIsAdmin ? "Administrator" : "Publisher" });
    });
  }, [user]);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  if (_state.event && _state.event.type === "CHANGE") {
    if (
      _state.event.data.name === "username" &&
      _state.event.data.value !== username
    )
      setUsername(_state.event.data.value);
    if (
      _state.event.data.name === "password" &&
      _state.event.data.value !== password
    )
      setPassword(_state.event.data.value);
  }
  const services = {
    async handleSignIn(formData) {
      /* eslint-disable @typescript-eslint/no-shadow */
      const { password } = formData;
      const username = formData?.username.toLowerCase().trim();
      try {
        return await Auth.signIn(
          {
            username,
            password,
          },
          null,
          { referrer: window.location.href },
        );
      } catch (e) {
        const error = String(e);
        if (/NotAuthorizedException/.test(error))
          throw new Error("Incorrect username or password.");
        if (/InvalidParameterException/.test(error))
          throw new Error(
            "Username and password can only contain alphanumeric and symbol.",
          );
        if (/AuthError/.test(error))
          throw new Error("Username and password can not be empty.");

        throw new Error("Unknown error");
      }
      /* eslint-enable @typescript-eslint/no-shadow */
    },
  };
  return (
    <Authenticator
      className={`${!username ? "" : "small-username-label"} ${!password ? "" : "small-password-label"} ${error ? "has-error" : ""} sign-in`}
      hideSignUp
      services={services}
      components={{
        Header,
        SignIn: {
          Footer,
        },
      }}
      formFields={{
        signIn: {
          username: {
            placeholder: "",
            label: "User Name",
            labelHidden: false,
          },
          password: {
            placeholder: "",
            label: "Password",
            labelHidden: false,
          },
        },
      }}
    >
      {children}
    </Authenticator>
  );
}

export default function KrgAuthenticator({
  children,
}: React.PropsWithChildren) {
  return (
    <Authenticator.Provider>
      <AuthenticatorInner>{children}</AuthenticatorInner>
    </Authenticator.Provider>
  );
}
