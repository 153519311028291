
import React, { FC, useState, useEffect } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from '@mui/material';
import defaultTheme from './krg-provider.style';
import { STORE_KEY_FONT_FAMILY } from '../constants';
import { FONT_FAMILY_ENUM } from '../enums';
import '../styles/base/base.scss';

type Props = {
  /**
   * @summary
   * React Node to Wrap with Provider
   */
  children?: React.ReactNode,
  /**
   * @summary
   * Theme object to pass Mui ThemeProvider
   * @default
   * Default Kargo theme with Colfax fonts and breakpoints
   */
  theme?: Theme,
};

const KrgProvider: FC<Props> = ({
  children,
  theme,
}) => {
  const demoFontFamily = localStorage.getItem(STORE_KEY_FONT_FAMILY) || FONT_FAMILY_ENUM.colfax;
  const [fontFamily, setFontFamily] = useState<string>(demoFontFamily);

  const storageCallback = (e: any) => {
    setFontFamily(localStorage.getItem(STORE_KEY_FONT_FAMILY) || FONT_FAMILY_ENUM.colfax);
  };

  useEffect(
    () => {
      if (demoFontFamily) {
        window.addEventListener('storage', storageCallback);
      }
      return () => {
        if (demoFontFamily) {
          window.removeEventListener('storage', storageCallback);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme ? theme : defaultTheme(fontFamily)}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default KrgProvider;
