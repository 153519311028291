import React from "react";
import { generatePath, useParams } from "react-router";
import { Box } from "@mui/material";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import { useSuperAdminContext } from "../../../routes/super_admin";
import JsonEditor from "../../../components/JsonEditor";
import SectionNavBar from "../../../components/SectionNavBar";
import { usePublisherContext } from "../../../hooks/usePublisherProvider";
import { usePublishers } from "../../../hooks/usePublishers";
import { useDomains } from "../../../hooks/useDomains";
import LoadingCircle from "../../../components/LoadingCircle";
import "./styles.scss";
import Select from "../../../components/Select";
import { getPlayerSchema } from "../Edit/schemas/playerConfig";
import { BaseJsonSchema } from "../Edit/schemas/helpers";
import { ISuperAdminRouteParams } from "../../../routes/superadmin_types";
import useSnackbar from "../../../hooks/useSnackbar";
import { PATHS } from "../../../constants/paths";

let previousPublisher = "";
export default function ClonePage() {
  const {
    setShowEditButtons,
    editConfig,
    setEditConfig,
    setInitialData,
    setHasValidationsErrors,
    slug,
    setHttpMethod,
    addPathToBreadCrumb,
    selectedDomainToken,
    setSelectedDomainToken,
    editDomainConfig,
    setEditDomainConfig,
  } = useSuperAdminContext();
  const { publisherSlug } = usePublisherContext(true);
  const { publishers } = usePublishers({});
  const { domainToken, playerToken } = useParams<ISuperAdminRouteParams>();
  const [playerSchema, setPlayerSchema] = React.useState({} as BaseJsonSchema);
  const { snackbarWarning } = useSnackbar();

  const [selectedPublisher, setSelectedPublisher] = React.useState<string>(
    publisherSlug || slug,
  );
  const [isEdit, setIsEditing] = React.useState<boolean>(false);
  const query = { slug: selectedPublisher };
  const { domains } = useDomains({ enabled: !!selectedPublisher, query });
  const [selectedTab, setSelectedTab] = React.useState(0);

  const selectedDomainList = domains?.map((d) => ({
    name: d.domain,
    token: d.token,
  }));

  React.useEffect(() => {
    setShowEditButtons(true);

    return () => setShowEditButtons(false);
  }, [setShowEditButtons]);

  React.useEffect(() => {
    if (domains && !isEdit) {
      const domain = domains?.find((d) => d.token === domainToken);
      const config = domain?.configs.find((c) => c.token === playerToken);
      setHttpMethod("create");
      setSelectedDomainToken(domain?.token || "");
      setEditConfig(config?.config || {});
      setInitialData(config || {});
      setEditDomainConfig({ ...config, notes: "" } || {});
      addPathToBreadCrumb([
        {
          path: generatePath(PATHS.ADMIN_DOMAIN_CONFIG, { domainToken }),
          pathName: domain?.domain || "",
        },
        {
          path: generatePath(PATHS.ADMIN_PLAYER_CONFIG, {
            domainToken,
            playerToken,
          }),
          pathName: config?.key || "",
        },
        {
          path: window.location.pathname,
          pathName: "clone",
        },
      ]);
      setPlayerSchema(getPlayerSchema(domain?.domain || ""));
      previousPublisher = selectedPublisher;
    } else {
      snackbarWarning(
        `${selectedPublisher} has no domain yet, please create a domain there first!`,
      );
      setTimeout(() => {
        setSelectedPublisher(previousPublisher);
      }, 3000);
    }
  }, [domains, isEdit, editDomainConfig.key]);

  const handleOnChanges = (value: string) => setEditConfig(JSON.parse(value));
  const handleNameChange = (name) => {
    setIsEditing(true);
    setEditDomainConfig({ ...editDomainConfig, key: name });
  };
  const handleDomainChange = (newDomainToken) =>
    setSelectedDomainToken(newDomainToken);

  const handlePublisherChange = (newPublisher) => {
    setIsEditing(true);
    setSelectedPublisher(newPublisher);
  };

  const renderInputs = () => (
    <Box sx={{ display: "flex", marginLeft: 3 }}>
      <Box sx={{ display: "flex", flexFlow: "column", width: "30%" }}>
        <KrgTextInput
          text={editDomainConfig.key}
          name="name"
          isFullWidth
          theme={KrgTextInput.THEME_ENUM.v2}
          label="Player Name"
          onTextChange={handleNameChange}
          debounceTime={0}
          onDebounceEnd={handleNameChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "30%",
          marginLeft: "25px",
        }}
      >
        <Select
          name="publishers"
          value={selectedPublisher || "empty"}
          label="Publisher destination:"
          items={[
            {
              text: "Publishers",
              value: "empty",
              isHidden: publishers && publishers.length > 0,
            },
            /* eslint-disable-next-line no-unsafe-optional-chaining */
            ...publishers?.map((p) => ({
              text: p.name,
              value: p.slug,
              isHidden: false,
            })),
          ]}
          onChange={handlePublisherChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "30%",
          marginLeft: "25px",
        }}
      >
        <Select
          name="domains"
          className="select-no-padding"
          value={selectedDomainToken || "empty"}
          label="Domain destination:"
          items={[
            {
              text: "Domains",
              value: "empty",
              isHidden: !!selectedDomainToken,
            },
            ...selectedDomainList.map((d) => ({
              text: d.name,
              value: d.token,
              isHidden: false,
            })),
          ]}
          onChange={handleDomainChange}
        />
      </Box>
    </Box>
  );
  const renderEditor = () => (
    <Box sx={{ display: "flex", flexFlow: "column", height: "90%" }}>
      <JsonEditor
        editValue={editConfig ? JSON.stringify(editConfig, null, 2) : ""}
        onChange={handleOnChanges}
        onValidate={(values) => setHasValidationsErrors(values.length > 0)}
        schemaValidation={playerSchema}
      />
    </Box>
  );
  return (
    <div style={{ height: "90%", paddingTop: 10 }}>
      <SectionNavBar
        buttonsConfig={[]}
        tabsConfig={{
          onChange: (_e, value) => setSelectedTab(value),
          selectedTab,
          tabs: [{ label: "Clone Editor" }],
        }}
      />
      {domains?.length > 0 ? (
        <>
          {renderInputs()}
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                width: "30%",
                marginLeft: 3,
              }}
            >
              <KrgTextInput
                text={editDomainConfig.type}
                name="type"
                isFullWidth
                theme={KrgTextInput.THEME_ENUM.v2}
                label="Config Type"
                onTextChange={(type) =>
                  setEditDomainConfig({ ...editDomainConfig, type })
                }
                debounceTime={0}
                onDebounceEnd={(type) =>
                  setEditDomainConfig({ ...editDomainConfig, type })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                width: "30%",
                marginLeft: 3,
              }}
            >
              <KrgTextInput
                text={editDomainConfig.notes}
                name="notes"
                isFullWidth
                theme={KrgTextInput.THEME_ENUM.v2}
                label="Notes"
                onTextChange={(notes) =>
                  setEditDomainConfig({ ...editDomainConfig, notes })
                }
                debounceTime={0}
                maxCharacter={56}
                onDebounceEnd={(notes) =>
                  setEditDomainConfig({ ...editDomainConfig, notes })
                }
              />
            </Box>
          </Box>
          {renderEditor()}
        </>
      ) : (
        <div className="krg-loading-shell">
          <LoadingCircle />
        </div>
      )}
    </div>
  );
}
