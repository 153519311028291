import { useRef } from 'react';
import { useMonaco } from '@monaco-editor/react';

export default function useDiffEditorSync(
  onChange: (value: string) => void,
  onValidate: (value: any[]) => void,
) {
  const editorRef = useRef<any>(null);
  const monaco = useMonaco();

  return {
    onMount(editor) {
      const modified = editor.getModifiedEditor();
      editorRef.current = modified;

      modified.onDidChangeModelContent(() => {
        onChange(modified.getValue());
      });
      monaco.editor.onDidChangeMarkers(() => {
        onValidate(monaco.editor.getModelMarkers({}));
      });
    },
  };
}
