import React, { useContext } from "react";
import { DragContext } from "./DraggableContext";

type IDraggableList = {
  draggableList: any[];
  children: React.ReactNode;
  itemIdKey: string;
  onDragEnd: (drag: number, drop: number) => {};
  isDraggable?: boolean;
};
export function DraggableList({
  draggableList,
  children,
  itemIdKey,
  onDragEnd,
  isDraggable,
}: IDraggableList) {
  /* eslint-disable-next-line react/jsx-no-constructed-context-values */
  const value = {
    itemIdKey,
    draggableList: draggableList.map((i) => ({ [itemIdKey]: i[itemIdKey] })),
    onDragEnd,
    isDraggable,
  };
  return <DragContext.Provider value={value}>{children}</DragContext.Provider>;
}

export const useDrag = () => useContext(DragContext);
