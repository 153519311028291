import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { capitalize, TextField } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { Events, THEME_ENUM, INPUT_VARIANT_ENUM as VARIANT_ENUM, DEPRECATED } from '@kargo/shared-components.krg-shared';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Input id
   */
  id?: string,
  /**
   * @summary
   * Input class name
   */
  className?: string,
  /**
   * @summary
   * Input style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Input label
   */
  label?: string,
  /**
   * @summary
   * Input placeholder
   */
  placeholder?: string,
  /**
   * @summary
   * Debounce time of the component in milliseconds
   * @default
   * 300
   */
  debounceTime?: number,
  /**
   * @summary
   * Set an error message to make input component in error state
   */
  errorMessage?: string,
  /**
   * @summary
   * Textfield variant
   * @default
   * VARIANT_ENUM.outlined
   */
  variant?: VARIANT_ENUM,
  /**
   * @summary
   * Start `InputAdornment` for this component
   */
  startAdornment?: ReactNode,
  /**
   * @summary
   * End `InputAdornment` for this component
   */
  endAdornment?: ReactNode,
  /**
   * @summary
   * Value of TextField.
   * @description
   * Set text for controlled input.
   */
  text?: any,
  /**
   * @summary
   * Text color.
   */
  textColor?: string,
  /**
   * @summary
   * Label color.
   */
  labelColor?: string,
  /**
   * @summary
   * Placeholder color.
   */
  placeholderColor?: string,
  /**
   * @summary
   * Icon color.
   */
  iconColor?: string,
  /**
   * @summary
   * Border color.
   */
  borderColor?: string,
  /**
   * @summary
   * Parent's input ref.
   */
  inputRef?: React.Ref<any>,
  /**
   * @summary
   * Maximum allowed text length
   * @description
   * Set this prop to show character counter helper
   * text at the bottom of the component.
   * If input length exceeds the max length,
   * helper text will be in the error state automatically.
   */
  maxCharacter?: number,
  /**
   * @summary
   * Maximum allowed text length
   * @description
   * This prop will only display the error text if
   * over 500 characters
   */
  maxCharacterOnly?: number,
  /**
   * @summary
   * Determines the min number of number input
   */
  min?: number,
  /**
   * @summary
   * Determines the max number of number input
   */
  max?: number,
  /**
   * @summary
   * Determines the type of input
   */
  inputType?: string,
  /**
   * @summary
   * TextField validations
   */
  validations?: {
    url: boolean,
  },
  /**
   * @summary
   * Suggested input label used if start adorment is used
   */
  mediaLabel?: string,
  /**
   * @summary
   * Shows error icon if there is an errorMessage and hasErrorIcon is true
   */
  hasErrorIcon?: boolean,
  /**
   * @summary
   * When `true` input will get the initial focus
   * @default false
   */
  hasAutoFocus?: boolean,
  /**
   * @summary
   * When `true` input will show auto-complete options
   * @default false
   */
  hasAutoComplete?: boolean,
  /**
   * @summary
   * Set this `false` to hide input caret.
   */
  hasCaret?: boolean,
  /**
   * @summary
   * If `true`, the input will take up the full width of its container.
   * @default
   * false
   */
  isFullWidth?: boolean,
  /**
   * @summary
   * Determines whether height of the input auto.
   * @default false
   */
  isAutoHeight?: boolean,
  /**
   * @summary
   * Determines whether the input readonly.
   * @default false
   */
  isReadOnly?: boolean,
  /**
   * @summary
   * Set this `true` to hide start adornment icon when input is focused or has value.
   * @default
   * false
   */
  isHiddenStartAdornmentOnFocus?: boolean,
  /**
   * @summary
   * Set this `true` to hide start adornment icon when has placeholder
   * @default
   * false
   */
  isHiddenStartAdornmentWithPlaceholderOnFocus?: boolean,
  /**
   * @summary
   * Set this `true` to show styled as pill shaped
   * @default
   * false
   */
  isPillShaped?: boolean,
  /**
   * @summary
   * Is input enabled / disabled
   * @default
   * true
   */
  isEnabled?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * This function is triggered when debounce ends
   */
  onDebounceEnd?: (text: string) => void,
  /**
   * @summary
   * This function is triggered when debounce is restarted
   */
  onDebounceRestart?: () => void,
  /**
   * @summary
   * Input's onKeyDown event
   */
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void,
  /**
   * @summary
   * Input's blur event
   */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
  /**
   * @summary
   * On text change function.
   * @description
   * Set this function for controlled input.
   */
  onTextChange?: (text: string) => void,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'hasAutoFocus'
   */
  autoFocus?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'hasAutoComplete'
   */
  autoComplete?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isFullWidth'
   */
  fullWidth?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isAutoHeight'
   */
  autoHeight?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isReadOnly'
   */
  readOnly?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'hasCaret'
   */
  hideCaret?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isHiddenStartAdornmentOnFocus'
   */
  hideStartAdornmentOnFocus?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isHiddenStartAdornmentWithPlaceholderOnFocus'
   */
  placeholderFocus?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.45 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
};

const KrgTextInput = ({
  id = '',
  className = '',
  style,
  label,
  placeholder,
  debounceTime = 300,
  errorMessage = '',
  variant = VARIANT_ENUM.outlined,
  startAdornment,
  endAdornment,
  text,
  textColor,
  labelColor,
  placeholderColor,
  iconColor,
  borderColor,
  inputRef,
  inputType,
  maxCharacter,
  maxCharacterOnly,
  min,
  max,
  validations,
  mediaLabel,
  hasErrorIcon,
  hasCaret = true,
  hasAutoFocus = false,
  hasAutoComplete = false,
  isFullWidth = false,
  isAutoHeight = false,
  isReadOnly = false,
  isHiddenStartAdornmentOnFocus = false,
  isHiddenStartAdornmentWithPlaceholderOnFocus = false,
  isPillShaped = false,
  isEnabled = true,
  theme = THEME_ENUM.v1,
  onTextChange,
  onDebounceEnd,
  onDebounceRestart,
  onKeyDown,
  onClick,
  onFocus,
  onBlur,
}: Props) => {
  const [innerText, setInnerText] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const timer = useRef<any>(null);
  const isControlled = text !== undefined;
  const inputValue = isControlled ? text : innerText;
  const hideStartAdornment =
    (isHiddenStartAdornmentOnFocus && (focused || text))
    || isHiddenStartAdornmentWithPlaceholderOnFocus;
  const [errorMsg, setErrorMsg] = useState('');
  const mediaLabelCap = capitalize(mediaLabel || '');
  const errorMessageCap = capitalize(errorMessage);
  const labelCap = capitalize(label || '');
  const placeholderCap = capitalize(placeholder || '');

  const props: StyleProps = {
    textColor,
    labelColor,
    placeholderColor,
    iconColor,
    borderColor,
    mediaLabel,
    hasCaret,
    isAutoHeight,
    isPillShaped,
    hasStartAdornment: !!startAdornment && !hideStartAdornment,
    hasEndAdornment: !!endAdornment,
  };
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  useEffect(
    () => setErrorMsg(errorMessageCap),
    [errorMessageCap],
  );

  useEffect(
    () => {
      // Max character only checks character length, is not concerned with validating url
      if (handleMaxCharacterOnly()) {
        setErrorMsg(`Max character limit of ${maxCharacterOnly} was exceeded`);
      } else if (validations?.url) {
        if (!handleUrlValidation()) {
          setErrorMsg('This is not a valid URL. Only URLs beginning with "https" are valid.');
        } else {
          setErrorMsg('');
        }
      } else {
        setErrorMsg('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
  );

  const handleUrlValidation = () => {
    // validate url imp trackers
    const trackerUrl = inputValue.trim();
    // eslint-disable-next-line
    const match = inputValue.match(/^https:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+\|,;=.%\{\}]+$/) !== null;
    return match || trackerUrl === '';
  };

  const handleMaxCharacterOnly = () => {
    return maxCharacterOnly &&
      inputValue.length > maxCharacterOnly;
  }

  const onTextValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isControlled) {
      if (onTextChange) {
        onTextChange(value);
      }
      if (onDebounceEnd) {
        handleDebounce(value);
      }
    } else {
      setInnerText(value);
      if (onDebounceEnd) {
        handleDebounce(value);
      }
    }
  };

  const handleDebounce = (value: string) => {
    if (timer && timer.current) {
      clearTimeout(timer.current);
    }
    if (onDebounceRestart) onDebounceRestart();

    timer.current = setTimeout(
      () => {
        if (onDebounceEnd) onDebounceEnd(value);
      },
      debounceTime,
    );
  };

  const hasMaxError = () => {
    return typeof max === 'number' &&
      inputValue > max;
  }

  const hasMinError = () => {
    return typeof min === 'number' &&
      inputValue < min;
  }

  const getHelperText = () => {
    if (maxCharacter) {
      const remainingLength = maxCharacter - inputValue.length;
      if (remainingLength > -1) {
        return `${remainingLength} characters remaining`;
      }
      return `${Math.abs(remainingLength)} characters over`;
    } else if (hasMaxError() || hasMinError()) {
      return `Only values between "${min}" and "${max}" are allowed`
    }
    return errorMsg;
  };

  const hasError = () => {
    if (maxCharacter) {
      return maxCharacter - inputValue.length < 0;
    } else if (hasMaxError() || hasMinError()) {
      return true;
    }
    return errorMsg !== '';
  };

  const textFieldVariantClass =
    variant === VARIANT_ENUM.outlined
      ? baseClasses.textFieldOutlined
      : `${baseClasses.textFieldStandard} ${classes.textFieldStandard}`;

  const textFieldDisabledClass = isEnabled
    ? hasError()
      ? `${baseClasses.textFieldError} ${classes.textFieldError}`
      : classes.textFieldEnabled
    : `${baseClasses.textFieldDisabled} ${classes.textFieldDisabled}`;

  const textFieldFilledClass = isEnabled && !hasError()
    ? inputValue === ''
      ? classes.textFieldEmpty
      : classes.textFieldFilled
    : '';

  return (
    <TextField
      className={
        `${baseClasses.textField}` +
        ` ${classes.textField}` +
        ` ${textFieldVariantClass}` +
        ` ${textFieldDisabledClass}` +
        ` ${textFieldFilledClass}` +
        ` ${className}`
      }
      size={'small'}
      style={style}
      InputProps={{
        id,
        endAdornment: (
          hasErrorIcon && hasError()
            ? <ErrorIcon className={baseClasses.errorIcon}/>
            : endAdornment
        ),
        startAdornment: (
          mediaLabel
          ? (
            <div className={baseClasses.startAdornmentWrapper}>
            <div className={baseClasses.startAdormentSvgWrapper}>
              {startAdornment}
            </div>
            <span className={baseClasses.mediaLabelWrapper}>
              {mediaLabelCap}
            </span>
          </div>)
          : startAdornment
        ),
        inputProps: {
          min,
          max
        },
        className: `${baseClasses.inputContainer} ${classes.inputContainer}`,
        type: inputType,
        readOnly: isReadOnly,
        onFocus: (e: any) => {
          setFocused(true);
          onFocus?.(e);
        },
        onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setFocused(false);
          onBlur?.(e);
        },
      }}
      variant={variant}
      value={inputValue}
      label={mediaLabelCap ? '' : labelCap}
      placeholder={placeholderCap}
      fullWidth={isFullWidth}
      disabled={!isEnabled}
      error={hasError()}
      helperText={getHelperText()}
      inputRef={inputRef}
      autoFocus={hasAutoFocus}
      autoComplete={hasAutoComplete ? 'on' : 'off'}
      onWheel={(event: any) => event.target?.blur() /* any is required */}
      onChange={onTextValueChange}
      onClick={onClick}
      onKeyDown={onKeyDown}
    />
  );
};

KrgTextInput.VARIANT_ENUM = VARIANT_ENUM;
KrgTextInput.THEME_ENUM = THEME_ENUM;

export default KrgTextInput;
