import useBaseStyles, { Props } from './base-style';
import useStylesV1 from './style-v1';
import useStylesV2 from './style-v2';

export type { Props };

export {
  useBaseStyles,
  useStylesV1,
  useStylesV2,
};
