import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2, BACKGROUND_ENUM } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';
import { SIZE_ENUM } from '../shared/enums';

const useStylesV2 = makeStyles({

  tabs: {},

  /* Tab */
  tab: {
    fontWeight: FONT.WEIGHT.SEMI_BOLD,
    padding: 0,

    // By size
    minWidth: ({ size }: Props) => size === SIZE_ENUM.small ? '11.6363637em' : '11.3888888em',
    fontSize:  ({ size }: Props) => size === SIZE_ENUM.small ? FONT.SIZE.REGULAR : FONT.SIZE.H2,

    '& svg': {
      fontSize: ({ size }: Props) => size === SIZE_ENUM.small ? '2.18181818em' : '2em',
    },

    // By background
    color: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? COLORS_V2.NEUTRAL_BLACK
      : COLORS_V2.NEUTRAL_WHITE,

    '&.Mui-disabled': {
      color: ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? COLORS_V2.NEUTRAL_INACTIVE
        : background === BACKGROUND_ENUM.blue
          ? COLORS_V2.PRIMARY_400
          : COLORS_V2.NEUTRAL_DARK,
    },

    '&:not(.Mui-disabled).Mui-selected': {
      color: ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? COLORS_V2.PRIMARY_700
        : COLORS_V2.NEUTRAL_WHITE,
    },

    '&:hover': {
      color: ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? COLORS_V2.PRIMARY_700
        : COLORS_V2.NEUTRAL_WHITE,
      backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.white
        ? COLORS_V2.NEUTRAL_LIGHT
        : background === BACKGROUND_ENUM.blue
          ? COLORS_V2.PRIMARY_500
          : COLORS_V2.PRIMARY_700,
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    },

    // By size + background
    borderBottom: ({ size, background }: Props) =>
      `${size === SIZE_ENUM.small ? '0.1' : '0.1125'}em solid ${
        background === BACKGROUND_ENUM.white
          ? COLORS_V2.NEUTRAL_INACTIVE
          : background === BACKGROUND_ENUM.blue
            ? COLORS_V2.PRIMARY_400
            : COLORS_V2.NEUTRAL_DARK}`,

  },

  /* Tab text or icon, smaller height for one element */
  tabTextOrIcon: {
    minHeight: ({ size }: Props) => size === SIZE_ENUM.small ? '4.36363637em' : '4.3333333em',
  },

  /* Tab text and icon, bigger height for two elements */
  tabTextAndIcon: {
    minHeight: ({ size }: Props) => size === SIZE_ENUM.small ? '6.5454545em' : '6.1111111em',

  },

  indicator: {
    height: ({ size }: Props) => size === SIZE_ENUM.small ? '0.125em' : '0.25em',
    backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? COLORS_V2.PRIMARY_700
      : COLORS_V2.NEUTRAL_WHITE,
  },

  indicatorDisabled: {
    backgroundColor: ({ background }: Props) => background === BACKGROUND_ENUM.white
      ? COLORS_V2.NEUTRAL_INACTIVE
      : background === BACKGROUND_ENUM.blue
          ? COLORS_V2.PRIMARY_400
          : COLORS_V2.NEUTRAL_DARK,
  },
});

export default useStylesV2;
