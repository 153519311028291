import { makeStyles } from '@mui/styles';
import { COLORS } from '@kargo/shared-components.krg-shared';

const useStylesV1 = makeStyles({
  tableHeaderCell: {
    backgroundColor: COLORS.BLACK_INACTIVE,
    borderColor: COLORS.GRAY_DIVIDER,
    color: COLORS.BLACK_MEDIUM_EMPHASIS,
    fontSize: '0.8em',
    lineHeight: 1.2,
    padding: '0.7em 0.5em',
  },
  stickyColumn: {
    position: 'sticky',
    zIndex: 99,
    backgroundColor: COLORS.BLACK_INACTIVE,
  },
});

export default useStylesV1;
