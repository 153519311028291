import React, {useEffect, useRef, useState} from "react";
import { PagePropertiesChangedEvent, LookerEmbedSDK } from '@looker/embed-sdk';
import { LookerProvider } from '../../hooks/useLookerContext';
import './styles.scss';
import { useLooker } from '../../hooks/useLooker';
import { pagePropertiesChangedHandler } from '../../helpers/looker';
import LoadingCircle from '../LoadingCircle';

export function LookerEmbeddedDashboard() {
  const { dashboardId } = useLooker();
  const dashboardContainerRef = useRef(null);
  const [dashboardLoaded, setDashboardLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!dashboardId) return;
    LookerEmbedSDK.createDashboardWithId(dashboardId)
      // Allow fullscreen tile visualizations
      .withAllowAttr('fullscreen')
      .appendTo(dashboardContainerRef.current)
      .on('page:properties:changed', (event: PagePropertiesChangedEvent) => {
        pagePropertiesChangedHandler(event, dashboardContainerRef.current);
      })
      .on('dashboard:loaded', () => {
        setDashboardLoaded(true);
      })
      // Give the embedded content a class for styling purposes
      .withClassName('looker-embed')
      .withTheme('light')
      // Finalize the build
      .build()
      // Connect to Looker
      .connect();
  }, [dashboardId]);

  return (
    <LookerProvider>
      <div ref={dashboardContainerRef}>
        { !dashboardLoaded && (
          <div style={{
            width: '100%', height: 'calc(100vh - 259px)', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <LoadingCircle />
          </div>
        )}
      </div>
    </LookerProvider>
  );
}
