import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';

const useStylesV1 = makeStyles({
  inputContainer: {
    height: '2.25em',
  },
  textField: {
    '& input': {
      fontSize: FONT.SIZE.LARGE,
    },

    // Helper text
    '& .MuiFormHelperText-root': {
      margin: '0.25em 0.0625em',
    },
  },
  textFieldStandard: {},
  textFieldEnabled: {
    // Border style
    '& .MuiInput-underline:before': {
      // Semi-transparent underline
      borderBottomColor: ({ borderColor }: Props) => borderColor || COLORS.GRAY_DARK,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: ({ borderColor }: Props) => borderColor || COLORS.GRAY_DARK,
      opacity: 0.4,
    },
    '& .MuiInput-underline:after': {
      // Solid underline on focus
      borderBottomColor: ({ borderColor }: Props) => borderColor || COLORS.PRIMARY_500_ACCENT,
    },
    // Label style
    '& .MuiInputLabel-root': {
      color: ({ labelColor }: Props) => labelColor || COLORS.BLACK_DISABLED,
    },
    '& .MuiInputLabel-root.MuiFormLabel-filled': {
      color: ({ labelColor }: Props) => labelColor || COLORS.GRAY_DARK,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: ({ labelColor }: Props) => labelColor || COLORS.PRIMARY_500_ACCENT,
    },
    // Border style for outline
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: ({ borderColor }: Props) => borderColor || COLORS.GRAY_DARK,
        '& legend': {
          width: ({ mediaLabel }: Props) => mediaLabel ? 0 : undefined,
        },
      },
      '&:hover fieldset': {
        borderColor: ({ borderColor }: Props) => borderColor || COLORS.GRAY_DARK,
        opacity: 0.7,
      },
      '&.Mui-focused fieldset': {
        borderColor: ({ borderColor }: Props) => borderColor || COLORS.PRIMARY_500_ACCENT,
      },
    },
  },
  textFieldDisabled: {},
  textFieldEmpty: {
    '& svg': {
      color: ({ iconColor }: Props) => iconColor || COLORS.GRAY_DIVIDER,
    },
    '& .Mui-focused': {
      '& svg': {
        color: ({ iconColor }: Props) => iconColor || COLORS.PRIMARY_500_ACCENT,
      },
    },
  },
  textFieldFilled: {
    '& svg': {
      color: ({ iconColor }: Props) => iconColor || COLORS.GRAY_DARK,
    },
    '& .Mui-focused': {
      '& svg': {
        color: ({ iconColor }: Props) => iconColor || COLORS.PRIMARY_500_ACCENT,
      },
    },
  },
  textFieldError: {
    '& .MuiFormHelperText-root': {
      fontSize: FONT.SIZE.REGULAR,
      lineHeight: 1.4,
    },
  },
});

export default useStylesV1;
