import React, { ReactNode } from 'react';
import { Divider, DialogContent, Typography } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import {
  CheckCircle as ConfirmationIcon,
  Warning as WarningIcon,
  Error as AlertIcon,
  RemoveCircleOutlined as ErrorIcon,
  OpenInNew as OpenInNewIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import KrgButton,
  {
    SIZE_ENUM as BUTTON_SIZE_ENUM,
    VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  } from '@kargo/shared-components.krg-button';
import KrgTooltip from '@kargo/shared-components.krg-tooltip';
import { STATUS_ENUM, SIZE_ENUM } from '../shared/enums';
import { LinkI } from '../shared/interfaces';

type baseClassesT = ClassNameMap<'header' | 'input' | 'buttons'>;
type classesT = ClassNameMap<'header' | 'input' | 'buttons' | 'link' | 'title' | 'body' | 'button' | 'titleSubmit' | 'linksWrapper' | 'linkContainer' | 'linkWrapper' | 'linkLabel' | 'linkCopyText' | 'divider' | 'tooltip'>;

export const HeaderSection = ({
  baseClasses,
  classes,
  isV2Theme,
  header,
  headerIconType,
}: {
  baseClasses: baseClassesT,
  classes: classesT,
  isV2Theme: boolean,
  header: string,
  headerIconType?: STATUS_ENUM,
}) => (
  <DialogContent className={`${baseClasses.header} ${classes.header}`}>
    {
      headerIconType === STATUS_ENUM.confirmation
        ? <ConfirmationIcon/>
        : headerIconType === STATUS_ENUM.warning
          ? isV2Theme
            ? <AlertIcon/>
            : <WarningIcon/>
          : headerIconType === STATUS_ENUM.error
            ? isV2Theme
              ? <ErrorIcon/>
              : <AlertIcon/>
            : null
    }
    <Typography variant="h6">{header}</Typography>
  </DialogContent>
);

export const TitleSection = ({ classes, title, hasInputContainer, isSubmit }: {
  classes: classesT,
  title: string,
  hasInputContainer: boolean,
  isSubmit: boolean,
}) => (
  <DialogContent
    className={`${classes.title} ${isSubmit ? classes.titleSubmit : ''} ${hasInputContainer}`}>
    <Typography dangerouslySetInnerHTML={{ __html: title }}/>
  </DialogContent>
);

export const BodySection = ({ classes, body } : { classes: classesT, body: string }) => (
  <DialogContent className={classes.body}>
    <Typography dangerouslySetInnerHTML={{ __html: body }}/>
  </DialogContent>
);

export const InputSection = ({ baseClasses, classes, inputContainer }: {
  baseClasses: baseClassesT,
  classes: classesT,
  inputContainer: ReactNode,
}) => (
  <DialogContent className={`${baseClasses.input} ${classes.input}`}>
    {inputContainer}
  </DialogContent>
);

export const LinksSection = ({ classes, theme, links, maxWidth }: {
  classes: classesT,
  theme: THEME_ENUM,
  links: LinkI[],
  maxWidth?: SIZE_ENUM,
}) => {
  return (
    <div className={classes.linksWrapper}>
      {links.map(({ url, label, copyText }, i) => (
        <div key={`link-${i}`}>
          <LinkSection
            classes={classes}
            theme={theme}
            maxWidth={maxWidth}
            url={url}
            label={label}
            copyText={copyText} />
        </div>
      ))}
    </div>
  );
};

export const LinkSection = ({ classes, theme, maxWidth, url, label, copyText }: {
  classes: classesT,
  theme: THEME_ENUM,
  maxWidth?: SIZE_ENUM,
  url: string,
  label: string | undefined,
  copyText: string | undefined,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onCopyClick = () => {
    navigator.clipboard.writeText(url);
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 2000);
  };

  return (
    <div>
      <Typography className={classes.linkLabel}>{label}</Typography>
      <DialogContent className={classes.linkContainer}>
        <span className={classes.linkWrapper}>
          <KrgButton
            className={classes.link}
            variant={BUTTON_VARIANT_ENUM.text}
            startIcon={<OpenInNewIcon />}
            text={url}
            theme={theme}
            size={BUTTON_SIZE_ENUM.large}
            onClick={() => window.open(url, '_blank')}
          />
        </span>
        <Divider
          className={classes.divider}
          orientation={maxWidth === SIZE_ENUM.xs ? 'horizontal' : 'vertical'} />
        <KrgTooltip
          classNames={{ tooltip: classes.tooltip }}
          tooltip="Copied"
          tooltipProps={{
            PopperProps: { disablePortal: true },
            disableFocusListener: true,
            disableHoverListener: true,
            disableTouchListener: true,
          }}
          position={KrgTooltip.POSITION_ENUM.top}
          hasArrow={false}
          isOpen={isOpen}
          theme={theme}
        >
          <span>
            <KrgButton
              className={classes.linkCopyText}
              variant={BUTTON_VARIANT_ENUM.text}
              startIcon={<ContentCopyIcon />}
              text={copyText || 'Copy Link'}
              theme={theme}
              size={BUTTON_SIZE_ENUM.large}
              onClick={onCopyClick}
            />
          </span>
        </KrgTooltip>
      </DialogContent>
    </div>
  );
};
