import {
  BaseJsonSchema,
  createArraySchemaByItemType,
  createSchemaByBasicTypes,
  getRegexDomainPattern,
} from "../helpers";
import { IFloatingOptions } from "./floatingOptions";
import { IPrebidConfig, IPrebidGlobals } from "./prebidConfig";
import {
  I33acrossId,
  IId5Id,
  ILiveIntentId,
  ISharedId,
  IUnifiedId,
} from "./prebidUserIds";
import IRollConfig from "./pre-mid-pos-rollConfig";
import { removePrefix } from "../../../../../helpers/validateUrl";
import { IGoogleAdTagDefaults } from "./googleAdTagDefaults";
import { AsterioBidAnalytics } from "./prebidAnalytics/AsteriobidAnalytics";

const IPrerollConfig = IRollConfig;
const IMidrollConfig = IRollConfig;
const IPosrollConfig = IRollConfig;
const IQuery: BaseJsonSchema = {
  type: "object",
  properties: {
    group: createSchemaByBasicTypes("string"),
    importted_from: createSchemaByBasicTypes("string"),
    limit: createSchemaByBasicTypes("number"),
    categories: createArraySchemaByItemType("string"),
  },
};

export const getPlayerSchema = (domain: string, withTopLevel?: boolean): BaseJsonSchema => {
  const cleanDomain = removePrefix(domain);
  const escapedDomain = cleanDomain.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regexPattern = getRegexDomainPattern(escapedDomain);

  const playerConfig: BaseJsonSchema = {
    type: "object",
    properties: {
      ads_source_init_timeout: createSchemaByBasicTypes("number"),
      adsposition: createSchemaByBasicTypes("string"),
      adtagurl: createSchemaByBasicTypes("string"),
      adtagurlfallbacks: createSchemaByBasicTypes("string"),
      airplay: createSchemaByBasicTypes("boolean"),
      allowpip: createSchemaByBasicTypes("boolean"),
      aspectratio: createSchemaByBasicTypes(["string", "number"]),
      autoplay: createSchemaByBasicTypes("boolean"),
      autoplaywhenvisible: createSchemaByBasicTypes("boolean"),
      broadcasting: createSchemaByBasicTypes("boolean"),
      bypagecontent: createSchemaByBasicTypes("boolean"),
      cdnhost: createSchemaByBasicTypes("string"),
      chromecast: createSchemaByBasicTypes("boolean"),
      companionad: createSchemaByBasicTypes("boolean"),
      contenturl: createSchemaByBasicTypes("string"),
      continuousplayback: createSchemaByBasicTypes("boolean"),
      currentstream: createSchemaByBasicTypes("string"),
      customnonlinear: createSchemaByBasicTypes("boolean"),
      description: createSchemaByBasicTypes("string"),
      disablepause: createSchemaByBasicTypes("boolean"),
      disableseeking: createSchemaByBasicTypes("boolean"),
      fitonheight: createSchemaByBasicTypes("boolean"),
      fitonwidth: createSchemaByBasicTypes("boolean"),
      floating: createSchemaByBasicTypes("boolean"),
      floatingoptions: IFloatingOptions,
      floatstyle: createSchemaByBasicTypes("string", "", {
        enum: ["v2", "v3-1", "v3-2"],
      }),
      fullscreenmandatory: createSchemaByBasicTypes("boolean"),
      google_ad_tag_defaults: IGoogleAdTagDefaults,
      globalStyles: createSchemaByBasicTypes("string"),
      hasnext: createSchemaByBasicTypes("boolean"),
      hidebarafter: createSchemaByBasicTypes("number", 5000),
      hidebeforeadstarts: createSchemaByBasicTypes("boolean"),
      hidebrandinfo: createSchemaByBasicTypes("boolean"),
      hideoninactivity: createSchemaByBasicTypes("boolean"),
      imatagurl: createSchemaByBasicTypes("string"),
      initialseek: createSchemaByBasicTypes("string"),
      inlinevastxml: createSchemaByBasicTypes("string"),
      irisconfig: IQuery,
      irisplaylist: createSchemaByBasicTypes("boolean"),
      linearadplayer: createSchemaByBasicTypes("boolean"),
      loop: createSchemaByBasicTypes("boolean"),
      loopall: createSchemaByBasicTypes("boolean"),
      minadintervals: createSchemaByBasicTypes("number", 5),
      muted: createSchemaByBasicTypes("boolean"),
      next_active: createSchemaByBasicTypes("boolean"),
      nextadtagurls: createSchemaByBasicTypes("string"),
      nextwidget: createSchemaByBasicTypes("boolean"),
      noengagenext: createSchemaByBasicTypes(["number", "string"], 10),
      nofullscreen: createSchemaByBasicTypes("boolean"),
      outstream: createSchemaByBasicTypes("boolean"),
      pauseonclick: createSchemaByBasicTypes("boolean"),
      playfullscreenonmobile: createSchemaByBasicTypes("boolean"),
      playlist: createArraySchemaByItemType("string"),
      playlisttoken: createSchemaByBasicTypes("string"),
      playonclick: createSchemaByBasicTypes("boolean"),
      playwhenvisible: createSchemaByBasicTypes("boolean"),
      popup: createSchemaByBasicTypes("boolean"),
      poster: createSchemaByBasicTypes("string"),
      preferredextensions: createArraySchemaByItemType("string"),
      preload: createSchemaByBasicTypes("boolean"),
      preload_ads: createSchemaByBasicTypes("boolean"),
      preroll: createSchemaByBasicTypes("boolean"),
      presetkey: createSchemaByBasicTypes("string"),
      preventinteraction: createSchemaByBasicTypes("boolean"),
      query: IQuery,
      recentvideoshourstimeout: createSchemaByBasicTypes("number"),
      reloadonplay: createSchemaByBasicTypes("boolean"),
      rerecordable: createSchemaByBasicTypes("boolean"),
      rotated: createSchemaByBasicTypes("boolean"),
      showcaption: createSchemaByBasicTypes("boolean"),
      shownext: createSchemaByBasicTypes("number", 30),
      showplayercontentafter: createSchemaByBasicTypes("number"),
      skipinitial: createSchemaByBasicTypes("boolean"),
      skipseconds: createSchemaByBasicTypes("number", 5),
      source: createSchemaByBasicTypes("string"),
      sources: createArraySchemaByItemType("string"),
      state: createSchemaByBasicTypes("string"),
      stayengaged: createSchemaByBasicTypes("boolean"),
      streams: createArraySchemaByItemType("string"),
      submittable: createSchemaByBasicTypes("boolean"),
      targeting_urls: createArraySchemaByItemType("string", undefined, {
        pattern: regexPattern,
      }),
      theme: createSchemaByBasicTypes("string", "kargo"),
      thumbnailurl: createSchemaByBasicTypes("string"),
      title: createSchemaByBasicTypes("string"),
      topmessage: createSchemaByBasicTypes("string"),
      totalduration: createSchemaByBasicTypes("number"),
      tracktextvisible: createSchemaByBasicTypes("boolean"),
      type: createSchemaByBasicTypes("string"),
      unmuteonclick: createSchemaByBasicTypes("boolean"),
      uploaddate: createSchemaByBasicTypes("string"),
      video: createSchemaByBasicTypes("string"),
      visibilityfraction: createSchemaByBasicTypes("number", 0.5),
      vmapads: createSchemaByBasicTypes("boolean"),
      volume: createSchemaByBasicTypes("number", 1.0),
      vpaidhidecontrolbar: createSchemaByBasicTypes("boolean"),
      enable_bid_cache: createSchemaByBasicTypes('boolean'),
      ad_player_size_override: {
        type: "object",
        properties: {
          small: createArraySchemaByItemType("number"),
          medium: createArraySchemaByItemType("number"),
          large: createArraySchemaByItemType("number"),
        },
      },
      sticky: createSchemaByBasicTypes("boolean", true, {
        description: "sticky is deprecated, use `floating` instead",
        deprecated: true,
      }),
      prebidconfig: IPrebidConfig,
      prebiduserids: createArraySchemaByItemType(undefined, [
        I33acrossId,
        IId5Id,
        IUnifiedId,
        ISharedId,
        ILiveIntentId,
      ]),
      prebid_analytics: createArraySchemaByItemType(undefined, [
        AsterioBidAnalytics,
      ]),
      prebidglobals: createArraySchemaByItemType(undefined, [IPrebidGlobals]),
      prerollconfig: createArraySchemaByItemType(undefined, [IPrerollConfig]),
      midrollconfig: createArraySchemaByItemType(undefined, [IMidrollConfig]),
      posrollconfig: createArraySchemaByItemType(undefined, [IPosrollConfig]),
    },
    additionalProperties: {
      type: ["number", "string", "boolean", "array", "object", "null"],
      description:
        "no type declaration on this property, you are adding a new one",
    },
  };

  const domainConfig: BaseJsonSchema = {
    type: 'object',
    properties: {
      config: playerConfig,
    },
    additionalProperties: {
      type: ["number", "string", "boolean", "array", "object", "null"],
      description:
        "no type declaration on this property, you are adding a new one",
    },
  };

  if (withTopLevel) return domainConfig;
  return playerConfig;
};
