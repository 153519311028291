import React from "react";
import KrgButton, {
  THEME_ENUM as BUTTON_THEME_ENUM,
} from "@kargo/shared-components.krg-button";
import { GetApp } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

type Props = React.PropsWithChildren<{
  onDrop: (acceptedFiles: File[]) => void;
}>;

export default function VideoDropzone({ children, onDrop }: Props) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "video/*": [],
    },
  });

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        height: 130,
        backgroundColor: "#E0E3E7",
        width: "100%",
      }}
    >
      <input {...getInputProps()} />
      <KrgButton
        theme={BUTTON_THEME_ENUM.v2}
        startIcon={<GetApp style={{ fontSize: "10px" }} />}
        style={{ backgroundColor: "transparent" }}
        text="Drag & Drop to Upload Video Files"
        type="file"
      />
      {children}
    </div>
  );
}
