import { createTheme } from '@mui/material/styles';

const defaultTheme = (selectedFontFamily: string) => {
  let typography: any;
  if (selectedFontFamily) {
    typography = {
      fontFamily: [
        selectedFontFamily,
        'Arial',
        'sans-serif',
      ].join(','),
    };
  }
  return createTheme({
    typography,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
};

export default defaultTheme;
