import { makeStyles } from '@mui/styles';
import { COLORS_V2 } from '@kargo/shared-components.krg-shared';

const useStylesV2 = makeStyles({
  tableHeaderCell: {
    backgroundColor: COLORS_V2.NEUTRAL_WHITE,
    borderColor: COLORS_V2.NEUTRAL_INACTIVE,
    color: COLORS_V2.NEUTRAL_BLACK,
    fontSize: '0.8em',
    lineHeight: 1.2,
    padding: '0.7em 0.5em',
  },
  stickyColumn: {
    position: 'sticky',
    zIndex: 99,
    backgroundColor: COLORS_V2.NEUTRAL_WHITE,
  },
});

export default useStylesV2;
