import React from 'react';
import InputModal from './InputModal';

type Props = { token: string } & Pick<React.ComponentProps<typeof InputModal>, 'isOpen' | 'onClose' | 'onSubmit'>;
export default function EditPropertyModal({
  token, isOpen, onClose, onSubmit,
}: Props) {
  return (
    <InputModal
      id={token}
      title="Edit Property"
      label="Property Name"
      submitButtonText="Save"
      cancelButtonText="Cancel"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
