export const createNormailsationProperties = (type: any): PropertyJsonSchema => {
  const devices = ['&mobile', '&desktop', '&windows', '&mac', '&ios', '&android', '&safari', '&chrome', '&firefox', '&ie'];
  const schema = {} as { [x: string]: BaseJsonSchema };
  devices.forEach((key) => {
    schema[key] = { type };
  });
  return schema;
};

export const createSchemaByBasicTypes = (
  type: IBasicTypes | IBasicTypes[],
  defaultValue?: string | number | boolean | null,
  options?: SchemaOptions,
): BaseJsonSchema => {
  const schema = {
    type: [...(typeof type === 'string' ? [type] : type), 'object'],
    default: type === 'boolean' ? true : defaultValue,
    properties: createNormailsationProperties([type]),
    ...(options && options),
  } as BaseJsonSchema;
  if (type !== 'boolean' && !defaultValue) {
    delete schema.default;
  }

  return schema;
};

export const createArraySchemaByItemType = (
  itemType?: IBasicTypes,
  oneOf?: BaseJsonSchema[],
  options?: SchemaOptions,
): BaseJsonSchema => {
  if (oneOf?.length) {
    return {
      type: ['array', 'object'],
      items: { oneOf },
    };
  }

  return {
    type: ['array', 'object', itemType],
    items: { type: itemType, ...options },
    properties: createNormailsationProperties(['array']),
  };
};

// type for options on JSON Schema
interface SchemaOptions {
  description?: string;
  deprecated?: boolean;
  pattern?: string;
  readOnly?: boolean,
  enum?: string[];
}

// types accepted on JSON Schema
type IBasicTypes = 'string' | 'boolean' | 'number' | 'null';
export type ITypes = IBasicTypes | 'array' | 'object' | string[];

interface PropertyJsonSchema {
  [x: string]: BaseJsonSchema,
}
export interface BaseJsonSchema {
  type?: ITypes,
  $ref?: string,
  default?: any;
  readOnly?: boolean,
  enum?: string[],
  items?: {
    type?: ITypes,
    oneOf?: any[];
  }
  properties?: PropertyJsonSchema,
  required?: string[],
  [x: string]: unknown, // to be able to accept new params
}

export const getRegexDomainPattern = (domain: string) => (
  `^(https?:\\/\\/)?(www\\.)?${domain.toLowerCase()}(\\/[\\w-.~%]*)*(\\?[\\w-.~%&=]*)?(#[\\w-.~%]*)?(\\/[\\w-.~%]*\\*)?\\/?$|^(\\/[\\w-.~%]+)*(\\/[\\w-.~%]*\\*)?(\\?[\\w-.~%&=]*)?(#[\\w-.~%]*)?$`
);
