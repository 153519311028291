import React from 'react';
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from '@kargo/shared-components.krg-button';
import TrackedKrgButton from '../TrackedButton/TrackedKrgButton';
import './styles.scss';
import LoadingCircle from '../LoadingCircle';

type IFloatEditButtonsProps = {
  show?: boolean;
  save: () => void;
  cancel: () => void;
  savingLoading?: boolean
};
export default function FloatEditButtons(props: IFloatEditButtonsProps) {
  const {
    show,
    save,
    cancel,
    savingLoading,
  } = props;
  if (show) {
    return (
      <div className="krg-float-buttons">
        <TrackedKrgButton
          id={`krg-float-cancel-${Math.random()}`}
          text="Cancel"
          onClick={cancel}
          size={BUTTON_SIZE_ENUM.small}
          variant={BUTTON_VARIANT_ENUM.outlined}
        />
        {
          savingLoading ? (
            <LoadingCircle style={{ width: 28, height: 28 }} />
          ) : (
            <TrackedKrgButton
              id={`krg-float-cancel-${Math.random()}`}
              text="Save changes"
              onClick={save}
              size={BUTTON_SIZE_ENUM.small}
              variant={BUTTON_VARIANT_ENUM.contained}
            />
          )
        }
      </div>
    );
  }
  return null;
}
