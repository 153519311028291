import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';
import { Props } from './base-style';
import { STATUS_ENUM, SIZE_ENUM } from '../../shared/enums';

const MARGIN_DEFAULT = '1.875em';
const MARGIN_WITH_ICON = '6.3em';
const MARGIN_WITH_ICON_HEADER = '5.6em';

const useStylesV2 = makeStyles({
  /* Dialog Header */
  header: {
    alignItems: 'flex-start',
    padding: 0,
    marginBottom: MARGIN_DEFAULT,
    minHeight: ({ headerIconType }: Props) => headerIconType ? '4.25em' : 'auto',

    '& > .MuiSvgIcon-root': {
      position: 'absolute',
      fontSize: '5.2em',
      marginLeft: '-0.08em', // Align icon with text, no with box
      marginTop: '0.035em',

      color: ({ headerIconType }: Props) => headerIconType === STATUS_ENUM.confirmation
        ? COLORS_V2.SUCCESS_LIGHT
        : headerIconType === STATUS_ENUM.warning
          ? COLORS_V2.WARNING_DARK
          : headerIconType === STATUS_ENUM.error
            ? COLORS_V2.ERROR_DARK
            : COLORS_V2.NEUTRAL_BLACK,
    },

    '& > .MuiTypography-root': {
      fontSize: FONT.SIZE.H2,
      fontWeight: FONT.WEIGHT.NORMAL,
      lineHeight: MARGIN_DEFAULT,
      color: COLORS_V2.NEUTRAL_BLACK,
      marginLeft: ({ headerIconType }: Props) => headerIconType ? MARGIN_WITH_ICON_HEADER : 0,
    },
  },

  /* Content body */
  body: {
    padding: 0,
    marginBottom: MARGIN_DEFAULT,
    marginLeft: ({ headerIconType }: Props) => headerIconType ? MARGIN_WITH_ICON : 0,

    '& > .MuiTypography-root': {
      fontSize: FONT.SIZE.MEDIUM,
    },
  },

  /* Content title */
  title: {
    padding: 0,
    marginBottom: MARGIN_DEFAULT,
    marginLeft: ({ headerIconType }: Props) => headerIconType ? MARGIN_WITH_ICON : 0,
    overflow: 'revert',

    '& > .MuiTypography-root': {
      fontWeight: FONT.WEIGHT.NORMAL,
      fontSize: FONT.SIZE.H2,
      lineHeight: MARGIN_DEFAULT,
      color: COLORS_V2.NEUTRAL_BLACK,
    },
  },

  /* Spacer with no content, only used in V1 layout spacing */
  spacer: {
    display: 'none',
  },

  /* Content title modifier for Submit*/
  titleSubmit: { /* only V1 */ },

  /* Content input */
  input: {
    padding: '0.5em 0 0.1em 0',
    marginBottom: MARGIN_DEFAULT,
    marginLeft: ({ headerIconType }: Props) => headerIconType ? MARGIN_WITH_ICON : 0,
  },

  /* Content link */
  linksWrapper: {
    border: `0.03125em solid ${COLORS_V2.NEUTRAL_INACTIVE}`,
    borderRadius: '0.3125em',
    padding: '0.4375em 1.5em 0 0.6875em ',
    marginLeft: ({ headerIconType }: Props) => headerIconType ? MARGIN_WITH_ICON : 0,
    marginBottom: '1em',
    maxHeight: '19em',
    overflowY: 'auto',
  },
  linkContainer: {
    display: 'flex',
    padding: '0 1em',
    backgroundColor: COLORS_V2.NEUTRAL_LIGHT,
    borderRadius: '0.3em',
    border: `0.03125em solid ${COLORS_V2.NEUTRAL_INACTIVE}`,
    marginBottom: '0.5em',
    flexFlow: ({ maxWidth }: Props) => maxWidth === SIZE_ENUM.xs ? 'column' : 'row',

    '& > .MuiDivider-root': {
      height: 'inherit',
      margin: '0.4em 1em',
    },
  },
  linkWrapper: {
    flex: '1',
    overflow: 'hidden',
  },
  linkLabel: {
    marginLeft: '1.5em',
    lineHeight: '2em',
    fontSize: FONT.SIZE.REGULAR,
    fontWeight: FONT.WEIGHT.MEDIUM,
  },
  link: {
    color: `${COLORS_V2.PRIMARY_700} !important`, // Override standard button behaviour

    '& div': {
      fontSize: '0.75rem', // link font is smaller,
      fontWeight: FONT.WEIGHT.NORMAL, // link font is not bold
      textTransform: 'none', // link does not have capitalize style
    },

    '&:hover': {
      color: `${COLORS_V2.NEUTRAL_BLACK} !important`, // Override standard button behaviour
    },
  },
  linkCopyText: {
    fontSize: '0.85714em',
    marginTop: '0.25em',
  },
  divider: {
    border: `0.025em solid ${COLORS_V2.NEUTRAL_DARK}`,
  },
  tooltip: {
    '.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
      marginTop: '-0.25em',
    },
  },

  /* Buttons container */
  buttons: {
    marginTop: MARGIN_DEFAULT,
    padding: 0,
    flexWrap: 'wrap',
    overflow: 'revert',
  },

  /* Button */
  button: {
    padding: 0,
    marginLeft: '1em',
    marginBottom: MARGIN_DEFAULT,
  },
});

export default useStylesV2;
