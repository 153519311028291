import React from "react";
import useMRSSFeeds from "../../hooks/useMRSSFeeds";
import Select from "../Select";

type Props = {
  isEnabled?: boolean;
  value?: string;
  query?: any;
  onChange?: (pl: string) => any;
};
export default function MRSSFeedsSelect({
  query,
  isEnabled,
  value,
  onChange,
}: Props) {
  const { MRSSFeeds } = useMRSSFeeds(query);
  return (
    <Select
      isEnabled={isEnabled}
      value={value}
      items={[
        {
          text: "None",
          value: "None",
        },
      ].concat(
        MRSSFeeds.map((m) => ({
          text: m.name || m.feed_url || m.token,
          value: `${m.token}#${m.user}`,
        })),
      )}
      onChange={onChange}
    />
  );
}
