import React from "react";
import dayjs from "dayjs";
import { Box, Divider, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateRange } from "@mui/icons-material";
import { VARIANT_ENUM as BUTTON_VARIANT_ENUM } from "@kargo/shared-components.krg-button";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import usePlaylists from "../../hooks/usePlaylists";
import SearchInput from "../../components/SearchInput";
import useSnackbar from "../../hooks/useSnackbar";
import LoadingCircle from "../../components/LoadingCircle";
import TrackedKrgButton from "../../components/TrackedButton/TrackedKrgButton";

const useStyles = makeStyles({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    borderRadius: 10,
    width: 280,
  },
  button: {
    alignSelf: "end",
    marginTop: 17,
  },
  checkbox: {
    marginLeft: 11,
    "& .MuiCheckbox-root:not(.Mui-checked)": { color: "#91A0AD" },
  },
});
type Props = {
  asAdmin?: boolean;
  anchorEl: any;
  publisherSlug?: string;
  videos: string[];
  onClose: () => void;
  onSubmit: (playlists: string[]) => void;
};
export default function AddToPlaylist({
  videos,
  onClose,
  onSubmit,
  anchorEl,
  asAdmin,
  publisherSlug,
}: Props) {
  const classes = useStyles();
  const { snackbarSuccess, snackbarError } = useSnackbar();
  const [search, setSearch] = React.useState("");
  const query = {
    ...(asAdmin && { asAdmin, slug: publisherSlug }),
    ...(search && { title: search }),
  };

  const {
    playlists,
    api,
    query: { isFetching },
  } = usePlaylists(query);
  const [selectedPlaylists, setSelectedPlaylists] = React.useState<string[]>(
    [],
  );
  const components = playlists?.map((p) => (
    <Box
      display="flex"
      key={p.token}
      sx={{
        height: "40px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ fontSize: 11 }}>{p.title || p.key || p.token}</Box>
      <Box
        display="flex"
        sx={{ color: "#91A0AD", fontSize: 10, alignItems: "center" }}
      >
        <DateRange sx={{ fontSize: 10, marginRight: 1 }} />
        {dayjs(p.creation_date).format("MM-DD-YY")}
        <KrgCheckbox
          className={classes.checkbox}
          theme={KrgCheckbox.THEME_ENUM.v2}
          isChecked={selectedPlaylists.includes(p.token)}
          onToggle={(v) => {
            const index = selectedPlaylists.indexOf(p.token);
            if (v && index === -1) selectedPlaylists.push(p.token);
            else if (!v && index > -1) selectedPlaylists.splice(index, 1);
            setSelectedPlaylists([...selectedPlaylists]);
          }}
        />
      </Box>
    </Box>
  ));
  const handleClose = () => {
    setSelectedPlaylists([]);
    onClose();
  };
  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: -5,
        horizontal: "center",
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Typography
        sx={{
          marginBottom: "21px",
          fontWeigth: "500",
          fontSize: "18px",
        }}
      >
        Add to Playlist
      </Typography>
      <SearchInput placeholder="Search" onDebounceEnd={setSearch} isFullWidth />
      {isFetching && (
        <LoadingCircle
          style={{
            width: "100px",
            margin: "20px 20px 3px 20px",
            alignSelf: "center",
          }}
        />
      )}
      {playlists?.length > 0 && (
        <>
          <Typography sx={{ color: "#91A0AD", fontSize: "10px" }}>
            Recently Created
          </Typography>
          {components?.[components.length - 1]}
          <Divider sx={{ borderColor: "#91A0AD" }} />
          {components?.slice(0, components.length - 1)}
        </>
      )}
      <TrackedKrgButton
        id="confirm-add-to-playlist"
        variant={BUTTON_VARIANT_ENUM.contained}
        className={classes.button}
        text="Save"
        isEnabled={videos.length > 0 && selectedPlaylists.length > 0}
        onClick={() => {
          const createPromises = selectedPlaylists.map(
            (p) =>
              new Promise((resolve) => {
                let newPlaylist = videos;
                playlists.forEach((playlist) => {
                  if (playlist.token === p) {
                    newPlaylist = playlist.medias.concat(videos);
                  }
                });
                api.update(
                  {
                    token: p,
                    data: {
                      medias: newPlaylist,
                    },
                  },
                  {
                    onSuccess: resolve,
                  },
                );
              }),
          );
          Promise.all(createPromises)
            .then(() => {
              const videoText = videos.length > 1 ? "Videos" : "Video";
              const playlistText =
                selectedPlaylists.length > 1 ? "Playlists" : "Playlist";
              snackbarSuccess(`${videoText} Added to ${playlistText}`);
            })
            .catch((error) => {
              console.error(error);
              snackbarError(
                "Error while updating playlists. Check the console for more information.",
              );
            });
          onSubmit(selectedPlaylists);
          setSelectedPlaylists([]);
          handleClose();
        }}
      />
    </Popover>
  );
}
