/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import usePlaylists from "../../hooks/usePlaylists";
import Select from "../Select";

type Props = {
  asAdmin?: boolean;
  isEnabled?: boolean;
  value?: string;
  query?: any;
  onChange?: (pl: string) => any;
};
export default function PlaylistsSelect({
  asAdmin,
  isEnabled,
  value,
  onChange,
  query,
}: Props) {
  const { playlists } = usePlaylists(query);
  if (value && value !== "None" && !playlists.find((p) => p.token === value)) {
    playlists.push({ token: value });
  }
  return (
    <Select
      isEnabled={isEnabled}
      value={value}
      items={[
        {
          text: "None",
          value: "None",
        },
      ].concat(
        playlists.map((p) => ({
          text: p.title || p.key || p.token,
          value: p.token,
        })),
      )}
      onChange={onChange}
    />
  );
}
