import React, { MouseEvent, ReactNode } from 'react';
import { Dialog, DialogActions } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { THEME_ENUM, Events, DEPRECATED } from '@kargo/shared-components.krg-shared';
import { VARIANT_ENUM as BUTTON_VARIANT_ENUM } from '@kargo/shared-components.krg-button';
import SubmitModal from './modals/submit/';
import SaveContinueModal from './modals/save-continue/';
import { LinkI, ModalButtonsConfig } from './shared/interfaces';
import { TYPE_ENUM, SCRIM_ENUM, STATUS_ENUM, SIZE_ENUM } from './shared/enums';
import { useBaseStyles, useStylesV1, useStylesV2, Props as StyleProps } from './styles/';

type Props = Events & {
  /**
   * @summary
   * Modal class name
   */
  className?: string,
  /**
   * @summary
   * Classname to target dialog paper
   */
  paperClassName?:string,
  /**
   * @summary
   * Modal style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Determines what kind of modal will be rendered. (i.e continue, info, submit)
   * TYPE_ENUM.info: This type of modal does not have any buttons except close icon
   * TYPE_ENUM.confirmation: This type of modal has continue and cancel buttons.
   * TYPE_ENUM.continue: This type of modal only has continue button.
   * TYPE_ENUM.submit: This type of modal has inputContainer in it alongside with action btn
   * @default
   * TYPE_ENUM.info
   */
  type?: TYPE_ENUM,
  /**
   * @summary
   * Custom input element
   */
  inputContainer?: ReactNode,
  /**
   * @summary
   * Customizes button(s)
   */
  buttonConfig?: ModalButtonsConfig,
  /**
   * @summary
   * Text of submit/save action button of modal.
   */
  submitButtonText?: string,
  /**
   * @summary
   * Text of discard action button of modal.
   */
  cancelButtonText?: string,
  /**
   * @summary
   * Determines background colour of Dialog
   * @default
   * SCRIM_ENUM.light
   */
  scrim?: SCRIM_ENUM,
  /**
   * @summary
   * Adds icon and colorises header
   */
  headerIconType?: STATUS_ENUM,
  /**
   * @summary
   * Header text
   */
  header?: string,
  /**
   * @summary
   * Title of modal.
   */
  title?: string,
  /**
   * @summary
   * Body text of modal.
   */
  body?: string,
  /**
   * @summary
   * Array of link items to be displayed.
   */
  links?: LinkI[],
  /**
   * @summary
   * Adjusts maximum width of modal
   */
  maxWidth?: SIZE_ENUM,
  /**
   * @summary
   * This controls whether modal should be seen or not
   */
  isOpen: boolean,
  /**
   * @summary
   * Enabled / disabled cancel button
   * @default
   * true
   */
  isCancelButtonEnabled?: boolean,
  /**
   * @summary
   * Enabled / disabled submit button
   * @default
   * true
   */
  isSubmitButtonEnabled?: boolean,
  /**
   * @summary
   * Allow close modal with close X icon
   * @default
   * true
   */
  isCloseIconDismissible?: boolean,
  /**
   * @summary
   * Allow close modal click backdrop overlay
   * @default
   * true
   */
  isBackDropDismissible?: boolean,
  /**
   * @summary
   * Allow close modal with click esc key
   * @default
   * true
   */
  isEscKeyDismissible?: boolean,
  /**
   * @summary
   * Modal theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * This is triggered when submit/save action button is clicked
   */
  onSubmitClick?: (ev: MouseEvent<HTMLButtonElement>) => void,
  /**
   * @summary
   * This is triggered when X button is clicked at the top right corner.
   */
  onClose: (ev: MouseEvent<SVGSVGElement | HTMLButtonElement>) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.60 in favour of 'isCancelButtonEnabled'
   */
  disabledCancelButton?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.60 in favour of 'isSubmitButtonEnabled'
   */
  disabledSubmitButton?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.74 in favour of 'links'
   */
  link?: DEPRECATED,
};

const KrgModal = ({
  className = '',
  paperClassName = '',
  style,
  type = TYPE_ENUM.info,
  inputContainer,
  submitButtonText = '',
  cancelButtonText = '',
  buttonConfig,
  scrim = SCRIM_ENUM.light,
  headerIconType,
  header,
  title,
  body = '',
  links = [],
  maxWidth,
  isOpen = false,
  isCancelButtonEnabled = true,
  isSubmitButtonEnabled = true,
  isCloseIconDismissible = true,
  isBackDropDismissible = true,
  isEscKeyDismissible = true,
  theme = THEME_ENUM.v1,
  onSubmitClick,
  onClose,
  onBlur,
  onFocus,
}: Props) => {
  const props: StyleProps = {
    scrim,
    headerIconType,
  };

  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  const onDialogClose = (evt: Event) => {
    if ((evt.type === 'click' && isBackDropDismissible)
      || (evt.type === 'keydown' && isEscKeyDismissible)) {
      onClose(evt as any);
    }
  };

  return (
    <Dialog
      style={style}
      open={isOpen}
      classes={{ root: `${baseClasses.dialogContainer} ${className}` }}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      PaperProps={{ classes: { root: `${baseClasses.dialogPaper} ${classes.dialogPaper} ${paperClassName}` } }}
      maxWidth={maxWidth}
      disableScrollLock
      disableEscapeKeyDown={!isEscKeyDismissible}
      onFocus={onFocus}
      onBlur={onBlur}
      onClose={onDialogClose}
    >
        <DialogActions className={classes.closeActionContainer}>
          {
            isCloseIconDismissible
              ? <CloseIcon className={baseClasses.closeActionButton} onClick={onClose} />
              : null
          }
        </DialogActions>

      { type === TYPE_ENUM.submit
        ? <SubmitModal
            title={title}
            inputContainer={inputContainer}
            submitButtonText={submitButtonText}
            cancelButtonText={cancelButtonText}
            buttonConfig={buttonConfig}
            maxWidth={maxWidth}
            isCancelButtonEnabled={isCancelButtonEnabled}
            isSubmitButtonEnabled={isSubmitButtonEnabled}
            theme={theme}
            onClick={onSubmitClick}
            onClose={onClose}
          />
        : <SaveContinueModal
            type={type}
            header={header}
            title={title}
            body={body}
            links={links}
            submitButtonText={submitButtonText}
            cancelButtonText={cancelButtonText}
            buttonConfig={buttonConfig}
            maxWidth={maxWidth}
            inputContainer={inputContainer}
            headerIconType={headerIconType}
            isCancelButtonEnabled={isCancelButtonEnabled}
            isSubmitButtonEnabled={isSubmitButtonEnabled}
            theme={theme}
            onSubmitClick={onSubmitClick}
            onClose={onClose}
          />
      }
    </Dialog>
  );
};

KrgModal.BUTTON_VARIANT_ENUM = BUTTON_VARIANT_ENUM;
KrgModal.SIZE_ENUM = SIZE_ENUM;
KrgModal.STATUS_ENUM = STATUS_ENUM;
KrgModal.SCRIM_ENUM = SCRIM_ENUM;
KrgModal.TYPE_ENUM = TYPE_ENUM;
KrgModal.THEME_ENUM = THEME_ENUM;

export default KrgModal;
