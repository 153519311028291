import { BaseJsonSchema, createSchemaByBasicTypes } from '../../helpers';

export const IStorage: BaseJsonSchema = {
  type: 'object',
  properties: {
    name: createSchemaByBasicTypes('string', '', {
      enum: ['id5Id', '33acrossId', 'sharedId', 'pbjs-unifiedid', 'pbjs_li_nonid'],
    }),
    type: createSchemaByBasicTypes('string', 'html5'),
    expires: createSchemaByBasicTypes('number', 90),
    refreshInSeconds: createSchemaByBasicTypes('number', 28800),
  },
};
