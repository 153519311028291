import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAPI from '../api/useAPI';
import useSnackbar from './useSnackbar';
import { Video } from '../api/types';

export default function useVideo(video: string, initialData?: any) {
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const query = useQuery({
    queryKey: ['video', video],
    queryFn: () => video && api.Media.get(video).then((data) => data.media),
    initialData,
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!video && didLoad,
  });

  const updateMutation = useMutation({
    mutationFn: (data: Partial<Video>) => api.Media.update(video, data),
    onSuccess: ({ media }: { media: Video }) => {
      snackbarSuccess('Updated Video Title');
      queryClient.setQueryData(['video', video], media);
      queryClient.invalidateQueries({ queryKey: ['videos'] });
    },
    onError: (e) => {
      console.error(e);
      snackbarError('Error while updating the video. Check the console for more information.');
    },
  });

  return {
    ...query,
    video: query.data,
    api: {
      update: updateMutation.mutate,
    },
    mutations: {
      updateMutation,
    },
  };
}
