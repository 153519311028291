import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';
import { Props } from './table-pagination-base-style';

const useV2Styles = makeStyles({
  tablePagination: {
    fontSize: ({ fontSize }: Props) => fontSize || FONT.SIZE.BASE,

    '& .MuiTablePagination-toolbar': {
      minHeight: '2.8em',
    },

    '& .MuiIconButton-label .MuiSvgIcon-root': {
      fontSize: FONT.SIZE.SEMI_LARGE,
    },

    '& .MuiTablePagination-caption': {
      fontSize: FONT.SIZE.SEMI_LARGE,
      color: COLORS_V2.NEUTRAL_BLACK,
    },

    '& .MuiTablePagination-select': {
      fontSize: FONT.SIZE.SEMI_LARGE,
      color: COLORS_V2.NEUTRAL_BLACK,
      borderStyle: 'solid',
      borderColor: COLORS_V2.NEUTRAL_BLACK,
      borderRadius: '4px',
      borderWidth: '1px',
    },
  },

  tablePaginationMenuItem: {
    color: COLORS_V2.NEUTRAL_BLACK,
    fontSize: FONT.SIZE.SEMI_LARGE,
    '&.Mui-selected': {
      backgroundColor: COLORS_V2.PRIMARY_50,
      color: COLORS_V2.PRIMARY_700,
    },
  },
  tablePaginationMenuPaper: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: COLORS_V2.PRIMARY_700,
  },
  tablePaginationOpen: {
    '& .MuiTablePagination-select': {
      borderColor: COLORS_V2.PRIMARY_700,
    },
    '& .MuiTablePagination-selectIcon': {
      color: COLORS_V2.PRIMARY_700,
    },
  },
});

export default useV2Styles;
