import { makeStyles } from '@mui/styles';
import { FONT, COLORS } from '@kargo/shared-components.krg-shared';

const useStyles = makeStyles({
  /*
    Button
   */
  button: {
    color: COLORS.WHITE,
    fontSize: FONT.SIZE.BASE,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonIcon: {
    fontSize: '1.6em',
  },
  buttonRippleClass: {
    opacity: 0,
  },

  /*
    Menu
   */
  menuPaper: {
    borderRadius: 0,
    marginTop: '0.2em',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    color: COLORS.BLACK_HIGH_EMPHASIS,
    whiteSpace: 'normal',
    fontSize: '0.9em',
    lineHeight: 1.2,
    minWidth: '8em',
    maxWidth: '25em',
    padding: '0.6em 0.8em',
    '&:hover': {
      backgroundColor: COLORS.BLACK_HIGH_EMPHASIS,
      color: COLORS.WHITE,
    },
  },
});

export default useStyles;
