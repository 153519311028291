import { makeStyles } from '@mui/styles';
import { FONT, COLORS_V2 } from '@kargo/shared-components.krg-shared';

const useStylesV2 = makeStyles({
  tableContainer: {
    bgColor: COLORS_V2.NEUTRAL_WHITE,
    fontFamily: FONT.FAMILY.POPPINS,
  },
  tableHeader: {
    fontWeight: FONT.WEIGHT.SEMI_BOLD,
    fontSize: FONT.SIZE.REGULAR,
    backgroundColor: COLORS_V2.NEUTRAL_WHITE,
  },
});

export default useStylesV2;
