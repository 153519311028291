import imageCompression from 'browser-image-compression';

import { FILE_TYPE_ENUM } from './enums';

export const compressImages = async (files: File[]) => {
  if (files.every(file => file.type.includes(FILE_TYPE_ENUM.video))) return files;

  const compressionPromises: Promise<File>[] = [];
  const compressionOptions = {
    maxSizeMB: 0.2929, // ~300KB
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const validBlobs = files.filter(
      file => file.type.includes(FILE_TYPE_ENUM.image) && file instanceof Blob,
    );

    if (validBlobs.length < 1) throw new Error('File(s) format unsupported!');

    validBlobs.forEach(
      blob => compressionPromises.push(imageCompression(blob, compressionOptions)),
    );

    const compressedBlobs = await Promise.all(compressionPromises);
    return compressedBlobs;
  } catch (err) {
    console.log(err);
    return files;
  }
};
