import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  /*
    Cell
  */
  cell: {
    display: 'flex',
  },
  container: {
    padding: '0em 0.5em',
  },
  thumbnail: {
    border: '1px solid #7B7B7B',
    height: '1.4375em',
    width: '1.4375em',
  },
});

export default useStyles;
