import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import { useDomains } from "../../../hooks/useDomains";
import ContentModal from "../../../components/Modals/ContentModal";
import useSnackbar from "../../../hooks/useSnackbar";
import LoadingCircle from "../../../components/LoadingCircle";
import PublishersSelect from "../../../components/PublishersSelect/PublishersSelect";
import FloatEditButtons from "../../../components/FloatEditButtons/FloatEditButtons";
import BulkEditConfirmation from "../../../components/BulkEditConfirmation/BulkEditConfirmation";
import BulkSuccessConfirmation from "../../../components/BulkEditConfirmation/BulkSuccessConfirmation";
import { useSuperAdminContext } from "../../../routes/super_admin";
import { getPlayerSchema } from "../Edit/schemas/playerConfig";
import JsonEditor from "../../../components/JsonEditor";
import { BaseJsonSchema } from "../Edit/schemas/helpers";
import { capitalizeStrings } from "../../../helpers/capitilizeStrings";
import staticEditor from "../../../components/StaticEditor/staticEditor";
import AlertBox from "../../../components/Alert/Alert";
import "./styles.scss";

export type BulkEdit = {
  actions: "update" | "delete";
};
export default function BulkEditPage({ actions }: BulkEdit) {
  const capitalizeActions = capitalizeStrings(actions, "-");
  const { addPathToBreadCrumb } = useSuperAdminContext();
  const { snackbarError, snackbarWarning } = useSnackbar();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [response, setIsResponse] = useState<{
    domains: string[];
    publishers: string[];
    totalConfigsChanged: number;
  }>({ domains: [], publishers: [], totalConfigsChanged: 0 });
  const [selectedPublishers, setSelectedPublishers] = React.useState<string[]>(
    [],
  );
  const [notes, setNotes] = React.useState<string>("");
  const [editConfig, setEditConfig] = React.useState<Record<string, any>>({});
  const [hasValidationErros, setHasValidationsErrors] = React.useState(false);
  const [playerSchema, setPlayerSchema] = React.useState({} as BaseJsonSchema);

  const {
    domains,
    query: domainsQuery,
    bulkUpdateDomain,
    bulkDeleteDomain,
  } = useDomains({ enabled: true });
  useEffect(() => {
    addPathToBreadCrumb([{ path: "/admin/bulk-edit", pathName: "Bulk Edit" }]);
  }, []);
  useEffect(() => {
    if (domains) {
      setPlayerSchema(getPlayerSchema(domains[0]?.domain));
    }
  }, [domains]);

  if (domainsQuery.isFetching) {
    return (
      <div className="krg-loading-wrapper">
        <LoadingCircle />
      </div>
    );
  }
  const handleBulkDeleteDomain = () => {
    bulkDeleteDomain(
      {
        publishers: selectedPublishers,
        notes,
        changes: editConfig,
      },
      {
        onSuccess: (res) => {
          setIsResponse(res);
          setIsOpen(true);
        },
        onError: () => {
          snackbarError(`Error occur while ${actions} bulk domains`);
        },
      },
    );
  };
  const handleBulkUpdateDomain = () => {
    bulkUpdateDomain(
      {
        publishers: selectedPublishers,
        notes,
        changes: editConfig,
      },
      {
        onSuccess: (res) => {
          setIsResponse(res);
          setIsOpen(true);
        },
        onError: () => {
          snackbarError(`Error occur while ${actions} bulk domains`);
        },
      },
    );
  };
  const handleConfirm = () => {
    if (actions === "update") {
      handleBulkUpdateDomain();
    } else {
      handleBulkDeleteDomain();
    }
    setModalIsOpen(false);
    setSelectedPublishers([]);
    setNotes("");
    setEditConfig({});
  };
  const handleOnChanges = (value: string) => setEditConfig(JSON.parse(value));
  const renderInputs = () => (
    <Box sx={{ display: "flex", marginLeft: 3 }}>
      <ContentModal
        content={
          <BulkEditConfirmation
            isEnabled={selectedPublishers.length > 0}
            actions={capitalizeActions}
            discriptions={selectedPublishers.join(", ")}
            show={modalIsOpen}
            onSave={handleConfirm}
            onCancel={() => {
              setModalIsOpen(false);
            }}
          />
        }
        onClose={() => setModalIsOpen(false)}
        isOpen={modalIsOpen}
      />
      <Box sx={{ display: "flex", flexFlow: "column", width: "40%" }}>
        <KrgTextInput
          text={notes}
          name="notes"
          isFullWidth
          theme={KrgTextInput.THEME_ENUM.v2}
          label="Notes"
          onTextChange={setNotes}
          debounceTime={0}
          maxCharacter={56}
          onDebounceEnd={setNotes}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "40%",
          marginLeft: "25px",
        }}
      >
        <PublishersSelect
          value={selectedPublishers}
          onChange={(cat: string) => {
            setSelectedPublishers(cat);
          }}
        />
      </Box>
    </Box>
  );
  const renderEditor = () => (
    <Box sx={{ display: "flex", flexFlow: "column", height: "90%" }}>
      <JsonEditor
        editValue={editConfig ? JSON.stringify(editConfig, null, 2) : ""}
        onChange={handleOnChanges}
        onValidate={(values) => setHasValidationsErrors(values.length > 0)}
        schemaValidation={playerSchema}
      />
    </Box>
  );
  const renderSnackBar = () => (
    <ContentModal
      content={
        <BulkSuccessConfirmation
          actions={`${actions}ed`}
          title={`${capitalizeActions} Successful`}
          domains={response?.domains}
          publishers={response?.publishers}
          totalChange={response.totalConfigsChanged}
          onOk={() => {
            setIsOpen(false);
          }}
        />
      }
      onClose={() => {
        setIsOpen(false);
        setIsResponse({
          domains: [],
          publishers: [],
          totalConfigsChanged: 0,
        });
      }}
      isOpen={isOpen}
    />
  );
  return (
    <Box padding="20px">
      <AlertBox
        hideButton
        severity="warning"
        title={`Bulk ${capitalizeActions} mode`}
        message="Each action performed here will be applied in ALL domain's player configs in a selected publishers"
      />
      {renderInputs()}
      <Box mt={5}>
        <Box>{staticEditor(capitalizeActions)}</Box>
        <Box mt={4}>
          <Typography
            variant="h6"
            component="h1"
            fontWeight="bold"
            sx={{ color: "#333", marginBottom: "3px", marginLeft: "14px" }}
          >
            Bulk {capitalizeActions} Editor
          </Typography>
          {renderEditor()}
        </Box>
      </Box>

      <FloatEditButtons
        show
        save={() => {
          if (hasValidationErros) {
            snackbarWarning("Fix syntax erros before saving it!");
            return;
          }
          if (!notes) {
            snackbarWarning(
              "Please leave a note for this changes of maximun 56 characteris.",
            );
            return;
          }
          setModalIsOpen(true);
        }}
        cancel={() => {
          setEditConfig({});
        }}
      />
      {isOpen && renderSnackBar()}
    </Box>
  );
}
