import React, { MouseEvent, ReactNode } from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import KrgButton, { VARIANT_ENUM as BUTTON_VARIANT_ENUM, SIZE_ENUM as BUTTON_SIZE_ENUM } from '@kargo/shared-components.krg-button';
import { THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { ModalButtonsConfig } from '../../shared/interfaces';
import { useBaseStyles, useStylesV1, useStylesV2, Props as StyleProps } from '../styles/';
import { SIZE_ENUM } from '../../shared/enums';
import { TitleSection, InputSection } from '../modal-sections';

type Props = {
  /**
   * @summary
   * Title of modal.
   */
  title?: string,
  /**
   * @summary
   * Text of submit/save action button of modal.
   */
  submitButtonText: string,
   /**
    * @summary
    * Text of discard action button of modal.
    */
  cancelButtonText: string,
  /**
   * @summary
   * Customizes button(s)
   */
  buttonConfig?: ModalButtonsConfig,
  /**
   * @summary
   * Custom input element
   */
  inputContainer: ReactNode,
  /**
   * @summary
   * Adjusts maximum width of modal
   */
  maxWidth?: SIZE_ENUM,
  /**
   * @summary
   * Enabled / disabled cancel button
   */
  isCancelButtonEnabled?: boolean,
   /**
    * @summary
    * Enabled / disabled submit button
    */
  isSubmitButtonEnabled?: boolean,
  /**
   * @summary
   * Modal theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * This is triggered when X/discard button is clicked at the top right corner.
   */
  onClose?: (ev: MouseEvent<SVGSVGElement | HTMLButtonElement>) => void,
  /**
   * @summary
   * This is triggered when action button is clicked
   */
  onClick?: (ev: MouseEvent<HTMLButtonElement>) => void,
};

const SubmitModal = ({
  title = '',
  inputContainer,
  maxWidth,
  submitButtonText,
  cancelButtonText,
  buttonConfig,
  isCancelButtonEnabled,
  isSubmitButtonEnabled,
  theme = THEME_ENUM.v1,
  onClick,
  onClose,
}: Props) => {
  const props: StyleProps = {
    maxWidth,
  };
  const isV1Theme = theme === THEME_ENUM.v1;
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = isV1Theme ? classesV1 : classesV2;
  const hasInputContainer = !!inputContainer;

  return (
    <>
      { title && <TitleSection {...{ classes, title, hasInputContainer, isSubmit: true }} /> }

      { inputContainer && <InputSection {...{ baseClasses, classes, inputContainer }} /> }

      <DialogContent className={`${baseClasses.buttons} ${classes.buttons}`}>
        {
          cancelButtonText &&
            <DialogActions className={classes.button}>
              <KrgButton
                text={cancelButtonText}
                size={isV1Theme ? undefined : BUTTON_SIZE_ENUM.large}
                style={{ width: buttonConfig?.cancelButton?.width }}
                variant={buttonConfig?.cancelButton?.variant || isV1Theme
                  ? BUTTON_VARIANT_ENUM.contained
                  : BUTTON_VARIANT_ENUM.outlined
                }
                startIcon={buttonConfig?.cancelButton?.startIcon}
                endIcon={buttonConfig?.cancelButton?.endIcon}
                isEnabled={isCancelButtonEnabled}
                theme={theme}
                onClick={onClose}
              />
            </DialogActions>
        }
        {
          submitButtonText &&
            <DialogActions className={classes.button}>
              <KrgButton
                text={submitButtonText}
                size={isV1Theme ? undefined : BUTTON_SIZE_ENUM.large}
                style={{ width: buttonConfig?.submitButton?.width }}
                variant={buttonConfig?.submitButton?.variant || BUTTON_VARIANT_ENUM.contained}
                startIcon={buttonConfig?.submitButton?.startIcon}
                endIcon={buttonConfig?.submitButton?.endIcon}
                isEnabled={isSubmitButtonEnabled}
                theme={theme}
                onClick={onClick}
              />
            </DialogActions>
        }
      </DialogContent>
    </>
  );
};

export default SubmitModal;
