import React, { ReactNode, SyntheticEvent } from 'react';
import { Button, Snackbar, SnackbarCloseReason, IconButton, SnackbarOrigin as PositionType } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DEPRECATED, Events, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import { TYPE_ENUM } from './shared/enums';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';

type Props = Events & {
  /**
   * @summary
   * Snackbar id
   */
  id?: string,
  /**
   * @summary
   * Snackbar class name
   */
  className?: string,
  /**
   * @summary
   * Snackbar style
   */
  style?: React.CSSProperties,
  /**
   * @summary
   * Sets background, text and button color according to the selection.
   * @default
   * TYPE_ENUM.white
   */
  type?: TYPE_ENUM,
  /**
   * @summary
   * Sets position of the snackbar inside the window
   * @default
   * ```
   * {
   *   horizontal: 'center',
   *   vertical: 'bottom',
   * }
   * ```
   */
  position?: PositionType,
  /**
   * @summary
   * Snackbar title
   */
  title?: ReactNode,
  /**
   * @summary
   * Snackbar text
   */
  text: ReactNode,
  /**
   * @summary
   * The number of milliseconds to wait before automatically calling the `onClose` function.
   * Set the value to `infinity` to disable auto hide.
   * @default
   * 2000
   */
  autoHideDuration?: number | 'infinity',
  /**
   * @summary
   * Alert icon which stands before the text
   */
  icon?: ReactNode,
  /**
   * @summary
   * Alert button which stands after the text
   */
  button?: {
    text?: string,
    onClick?: (e: SyntheticEvent) => void,
  },
  /**
   * @summary
   * If `true`, Snackbar is open.
   */
  isOpen?: boolean,
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /**
   * @summary
   * On close function
   */
  onClose?: (e: Event | SyntheticEvent<any, Event>) => void,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.31 in favour of 'isOpen'
   */
  open?: DEPRECATED,
};

const KrgSnackbar = ({
  id,
  className = '',
  style,
  type = TYPE_ENUM.white,
  position = {
    horizontal: 'center',
    vertical: 'bottom',
  },
  title,
  text,
  isOpen,
  autoHideDuration,
  icon,
  button,
  theme = THEME_ENUM.v1,
  onClose,
  onClick,
  onFocus,
  onBlur,
}: Props) => {
  const props: StyleProps = {
    type,
  };
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;

  const handleClose = (e: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose?.(e);
  };

  const handleButtonClick = (e: SyntheticEvent<any, Event>) => {
    if (!button) return;

    if (button.onClick) {
      button.onClick(e);
    } else {
      onClose?.(e);
    }
  };

  return (
    <Snackbar
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      id={id}
      className={`${baseClasses.root} ${className}`}
      style={style}
      autoHideDuration={autoHideDuration === 'infinity' ? undefined : autoHideDuration}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={position}
    >
      <div className={`${baseClasses.alert} ${classes.alert}`}>
        {/* Icon */}
        { icon && <div className={`${baseClasses.icon} ${classes.icon}`}>{icon}</div> }

        <div className={`${baseClasses.textContainer} ${classes.textContainer}`}>
          {/* Title */}
          { title && <span className={classes.title}>{title}</span> }

          {/* Text */}
          <span className={`${baseClasses.text} ${classes.text}`}>{text}</span>
        </div>

        {/* Button */}
        { button
          ? button.text
              ? <Button
                  className={`${baseClasses.button} ${classes.button}`}
                  onClick={handleButtonClick}
                >
                  {button.text}
                </Button>
              : <IconButton className={classes.closeIcon} onClick={handleButtonClick}>
                  <CloseIcon/>
                </IconButton>
            : null
          }
      </div>
    </Snackbar>
  );
};

KrgSnackbar.TYPE_ENUM = TYPE_ENUM;
KrgSnackbar.THEME_ENUM = THEME_ENUM;

export default KrgSnackbar;
