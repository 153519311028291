import React, { PropsWithChildren } from "react";
// import { Outlet } from 'react-router-dom';
import { Loader } from "@aws-amplify/ui-react";
import { NotFound } from "../components";
import useSession from "../hooks/useSession";

type IAuthProps = {
  isPublisherAdmin: boolean;
  admin: string;
};

type IAdminAuthenticationProps = PropsWithChildren & IAuthProps;

export default function AdminAuthenticator({
  children,
  isPublisherAdmin,
  admin,
}: IAdminAuthenticationProps) {
  const session = useSession();
  if (!session) return <Loader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
  if (
    !session.getAccessToken().payload["cognito:groups"].includes(admin)
    && !isPublisherAdmin
  ) return <NotFound />;
  return children;
}
