export enum CREATIVE_EDITOR_ENUM {
  lite = 'lite',
  advanced = 'advanced',
}

export enum CREATIVE_FORMAT_ENUM {
  StandardBanner = 1,
  LogoEnhancedBanner = 2,
  ScrollReactiveBanner = 3,
  AdhesiveBottomBanner = 4,
  AdhesiveSideBanner = 5,
  StandardVideo = 6,
  ScrollReactiveVideo = 7,
  IGStandardBanner = 8,
  IGStandardVideo = 9,
  IGScrollReactiveBanner = 10,
  IGScrollReactiveVideo = 11,
  FBStandardBanner = 12,
  FBStandardVideo = 13,
  FBScrollReactiveBanner = 14,
  FBScrollReactiveVideo = 15,
  TiktokVideo = 16,
  InstagramReelsVideo = 17,
  IGCarousel = 18,
  MiddleBanner = 19, // Advanced
  FBCarousel = 20,
  DynamicScrollReactiveBanner = 21,
  Venti = 22, // Advanced
  Breakaway = 23,
  Runway = 24,

  /* Uncomment for A/B testing */
  // StandardBannerIframe = 101,
  // StandardVideoIframe = 102,
}

export enum CREATIVE_FORMAT_IFRAME_ENUM {
  StandardBannerIframe = 101,
  StandardVideoIframe = 102,
}

export enum CREATIVE_DESTINATION_ENUM {
  EntireCreative = 1,
  MainAsset = 2,
  Description = 3,
  Cta = 4,
  SocialLogo = 5,
  WatchOnSocial = 6,
}

export enum CREATIVE_SOCIAL_TYPE_ENUM {
  Standard = 1,
  Social = 2,
  Dynamic = 3,
}

export enum THEME_ENUM {
  v1 = 'v1',
  v2 = 'v2',
}

export enum FONT_FAMILY_ENUM {
  colfax = 'Colfax',
  poppins = 'Poppins',
}

export enum PROJECT_ENUM {
  composer = 'composer',
  pegasus = 'pegasus',
}

export enum INPUT_VARIANT_ENUM {
  standard = 'standard',
  outlined = 'outlined',
}

export enum CSS_POS_ENUM {
  fixed = 'fixed',
  absolute = 'absolute',
  relative = 'relative',
  static = 'static',
  sticky = 'sticky',
}

export enum TARGET_ENUM {
  blank = '_blank',
  self = '_self',
}

export enum DATA_UNIT_ENUM {
  B = 'B',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
  PB = 'PB',
}

export enum BACKGROUND_ENUM {
  white = 'white',
  black = 'black',
  blue = 'blue',
}

export enum ADVANCED_CREATIVE_SIZES_ENUM {
  // Middle Banner
  _320x250 = '320x250',
  _390x250 = '390x250',
  _428x250 = '428x250',
  // Venti
  _320x460 = '320x460',
  _390x460 = '390x460',
  _428x460 = '428x460',
  // Breakaway Initial and Runway
  _320x150 = '320x150',
  _390x150 = '390x150',
  _428x150 = '428x150',
  // Breakaway Collapsed
  _130x150 = '130x150',
}

export enum FILE_TYPE_ENUM {
  image = 'image',
  video = 'video',
  logo = 'logo',
}
