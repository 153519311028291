import React from "react";
import { MULTIPLE_TYPE_ENUM } from "@kargo/shared-components.krg-select/shared/enums";
import { usePublishers } from "../../hooks/usePublishers";
import Select from "../Select";

type Props = {
  isEnabled?: boolean;
  value?: string[];
  onChange?: (publisher: string) => any;
};
export default function PublisherSelect({
  isEnabled = true,
  value,
  onChange,
}: Props) {
  const { publishers } = usePublishers({});
  const sortedPublisher = publishers
    .map(({ name, slug }) => ({ name, slug }))
    ?.sort((name, slug) => {
      if (name.name > slug.name) {
        return 1;
      }
      if (name.name < slug.name) {
        return -1;
      }
      return 0;
    });

  return (
    <Select
      label="Select Publishers"
      isEnabled={isEnabled && sortedPublisher.length > 0}
      value={value}
      items={sortedPublisher.map((c) => ({
        text: c.name,
        value: c.slug,
      }))}
      isMultiple
      multipleType={MULTIPLE_TYPE_ENUM.checkmark}
      onChange={onChange}
    />
  );
}
