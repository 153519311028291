import { BaseJsonSchema, createSchemaByBasicTypes } from '../../../helpers';

export const IRubiconBid: BaseJsonSchema = {
  type: 'object',
  properties: {
    bidder: {
      type: 'string',
      enum: ['rubicon'],
    },
    params: {
      type: 'object',
      properties: {
        accountId: createSchemaByBasicTypes('string'),
        siteId: createSchemaByBasicTypes('string'),
        zoneId: createSchemaByBasicTypes('string'),
      },
      required: ['accountId', 'siteId', 'zoneId'],
    },
    additionalProperties: {
      type: ['number', 'string', 'boolean', 'array', 'object', 'null'],
      description: 'no type declaration on this property, you are adding a new one',
    },
  },
  required: ['bidder', 'params'],
};
