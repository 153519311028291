import React from "react";
import { useDomains } from "../../hooks/useDomains";
import InputModal from "./InputModal";

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit: (domain: string) => void;
  username?: string;
  title?: string;
  label?: string;
  createOptions: Parameters<ReturnType<typeof useDomains>["create"]>[1];
} & React.ComponentProps<typeof InputModal>;
export default function CreatePropertyModal({
  isOpen,
  onClose,
  onSubmit,
  title,
  label,
  createOptions,
  username,
}: Props) {
  const { create } = useDomains({ enabled: false });
  return (
    <InputModal
      elementClassName="create-property-modal"
      title={title || "Create Property"}
      label={label || "Property Name"}
      submitButtonText="Save"
      cancelButtonText="Cancel"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={(domain) => {
        create({ domain, username }, createOptions || {});
        onSubmit(domain);
      }}
    />
  );
}
