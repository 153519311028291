import React from 'react';
import KrgModal from '@kargo/shared-components.krg-modal';
import './styles.scss';

// eslint-disable-next-line max-len
type Props = Pick<React.ComponentProps<typeof KrgModal>, 'isOpen' | 'onClose' | 'header' | 'body' | 'submitButtonText' | 'cancelButtonText' | 'onSubmitClick'>;
export default function ConfirmModal({
  isOpen, onClose, onSubmitClick, header, body, submitButtonText, cancelButtonText,
}: Props) {
  return (
    <KrgModal
      className="confirm-modal"
      isOpen={isOpen}
      onClose={onClose}
      onSubmitClick={onSubmitClick}
      theme={KrgModal.THEME_ENUM.v2}
      type={KrgModal.TYPE_ENUM.confirmation}
      header={header}
      body={body}
      submitButtonText={submitButtonText || 'Save'}
      cancelButtonText={cancelButtonText || 'Cancel'}
    />
  );
}
