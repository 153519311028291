export enum POSITION_ENUM {
  topStart = 'top-start',
  top = 'top',
  topEnd = 'top-end',
  rightEnd = 'right-end',
  right = 'right',
  rightStart = 'right-start',
  bottomEnd = 'bottom-end',
  bottom = 'bottom',
  bottomStart = 'bottom-start',
  leftStart = 'left-start',
  left = 'left',
  leftEnd  = 'left-end',
}

export enum TYPE_ENUM {
  default = 'default',
  warning = 'warning',
  error = 'error',
  success = 'success',
  neutral = 'neutral'
}
