import { makeStyles } from '@mui/styles';
const useStylesV1 = makeStyles({
  /*
    Table container
  */
  tableContainer: {
    // This is for scrollbar height
    paddingBottom: '.5em',
  },
  tableHeader: {},
});

export default useStylesV1;
