import React, { FC } from 'react';
import { KrgStyleSelector } from '../';
import { THEME_ENUM } from '../enums';
import { STORE_KEY_THEME } from '../constants';

type Props = {
  setTheme: (theme: THEME_ENUM) => void,
};

const KrgThemeSelector: FC<Props> = ({ setTheme }) => {

  return (
    <KrgStyleSelector
      label="Select Theme"
      options={[THEME_ENUM.v1, THEME_ENUM.v2]}
      storageKey={STORE_KEY_THEME}
      onChange={setTheme} />
  );
};

export default KrgThemeSelector;
