import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import LoadingCircle from "../../../components/LoadingCircle";
import Table from "../../../components/Table";
import { useSuperAdminContext } from "../../../routes/super_admin";

import "./styles.scss";
import { useDomainConfig } from "../../../hooks/useDomainConfig";
import { useDomain } from "../../../hooks/useDomain";
import { ISuperAdminRouteParams } from "../../../routes/superadmin_types";
import { PATHS } from "../../../constants/paths";

export default function RevisionPage() {
  const { addPathToBreadCrumb } = useSuperAdminContext();
  const history = useHistory();
  const params = useParams<ISuperAdminRouteParams>();
  const [revisions, setRevisions] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getRevisions } = useDomainConfig(
    params.playerToken,
    params.domainToken || "",
  );
  const { data } = useDomain(params.domainToken || "");

  React.useEffect(() => {
    if (data?.domain) {
      addPathToBreadCrumb([
        {
          path: generatePath(PATHS.ADMIN_DOMAIN_CONFIG, {
            domainToken: params.domainToken,
          }),
          pathName: data?.domain,
        },
        {
          path: generatePath(PATHS.ADMIN_PLAYER_CONFIG, {
            domainToken: params.domainToken,
            playerToken: params.playerToken,
          }),
          pathName: "No revision yet",
        },
      ]);
    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      const latestConfig = data.configs
        .filter((c) => c.token === params.playerToken)
        .shift();
      getRevisions(params.playerToken, {
        onSuccess: (res: any[]) => {
          res.push({ ...latestConfig, isLatest: true });
          setRevisions(
            res.sort((a, b) => Number(a.revision) - Number(b.revision)),
          );
        },
        onError: () => {
          setRevisions([{ ...latestConfig, isLatest: true }]);
        },
        onSettled: () => {
          addPathToBreadCrumb([
            {
              path: generatePath(PATHS.ADMIN_DOMAIN_CONFIG, {
                domainToken: params.domainToken,
              }),
              pathName: data?.domain,
            },
            {
              path: generatePath(PATHS.ADMIN_PLAYER_CONFIG, {
                domainToken: params.domainToken,
                playerToken: params.playerToken,
              }),
              pathName: latestConfig.key,
            },
          ]);
          setIsLoading(false);
        },
      });
    }
  }, [params.playerToken, data]);

  if (isLoading) {
    return (
      <div className="krg-loading-wrapper">
        <LoadingCircle />
      </div>
    );
  }

  const columns = [
    {
      field: "revision",
      headerName: "Revision number",
      onClick: (e, row) => {
        if (row.isLatest) {
          history.push(
            generatePath(PATHS.ADMIN_PLAYER_EDIT, {
              domainToken: params.domainToken,
              playerToken: params.playerToken,
            }),
          );
          return;
        }
        history.push(
          generatePath(PATHS.ADMIN_REVISION_EDIT, {
            domainToken: params.domainToken,
            playerToken: params.playerToken,
            revision: row.revision,
          }),
        );
      },
      renderCell: (row) => <p>{row.revision}</p>,
    },
    {
      field: "notes",
      headerName: "Notes",
      renderCell: (row) => (row.notes ? <p>{row.notes}</p> : null),
    },
    {
      field: "updated_at",
      headerName: "Last update",
      renderCell: (row) => (
        <p>
          {dayjs(row.updated_at).format("DD/MMM/YY ")}
          {dayjs(row.updated_at).format(" HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "created_by_user",
      headerName: "Updated by",
      renderCell: (row) => <p>{row.created_by_user}</p>,
    },
    {
      field: "",
      headerName: "Edit",
      onClick: (_, row) => {
        if (row.isLatest) {
          history.push(
            generatePath(PATHS.ADMIN_PLAYER_EDIT, {
              domainToken: params.domainToken,
              playerToken: params.playerToken,
            }),
          );
          return;
        }
        history.push(
          generatePath(PATHS.ADMIN_REVISION_EDIT, {
            domainToken: params.domainToken,
            playerToken: row.token,
            revision: row.revision,
          }),
          {
            config: row,
          },
        );
      },
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      renderCell: (row) => <EditIcon color="primary" />,
    },
  ];
  const rows = revisions?.map((d) => d) || [];

  return (
    <Box padding="20px">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Table columns={columns} rows={rows} size={14} />
      </Box>
    </Box>
  );
}
