import { makeStyles } from '@mui/styles';
import { COLORS, FONT } from '@kargo/shared-components.krg-shared';

const useStylesV1 = makeStyles({
  selectContainer: {
    fontSize: FONT.SIZE.BASE,
    height: '2.25em',
  },
  select: {
    '&.Mui-focused > .MuiSelect-icon': {
      color: COLORS.PRIMARY_500_ACCENT,
    },
  },
  selectStandard: {
    '&.MuiInput-underline:after': {
      borderBottomColor: COLORS.PRIMARY_500_ACCENT,
    },
  },
  selectOutlined: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.0625em solid ${COLORS.PRIMARY_500_ACCENT}`,
    },
  },
  label: {
    '&.MuiInputLabel-shrink.Mui-focused': {
      color: COLORS.PRIMARY_500_ACCENT,
    },
  },
  labelStandard: {
    marginTop: '-0.7em',
  },
  labelDisabled: {},
  menu: {},
  menuOutlined: {},
  menuError: {},
  menuItemIcon: {},
  outlinedRoundedGap: {},
  chip: {
    '&.MuiChip-root': {
      height: '1.5625em',
      margin: '0 0.3125em',
    },
  },
  helperText: {},
  selectAll: {
    '&:hover.MuiMenuItem-root': {
      color: COLORS.PRIMARY_500_ACCENT,
    },
  },
  clearAll: {
    '&.MuiMenuItem-root': {
      marginLeft: '20%',
    },
  },
});

export default useStylesV1;
