import React, { MouseEvent } from 'react';
import {
  ClickAwayListener,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  MenuItem,
  IconButton,
} from '@mui/material';
import {
  ExpandLessSharp as ExpandLessSharpIcon,
  ExpandMoreSharp as ExpandMoreSharpIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { DEPRECATED, Events, THEME_ENUM } from '@kargo/shared-components.krg-shared';
import useBaseStyles, { Props as StyleProps } from './styles/base-style';
import useStylesV1 from './styles/style-v1';
import useStylesV2 from './styles/style-v2';
import { BUTTON_ICON_ENUM, BUTTON_SIZE_ENUM, BUTTON_VARIANT_ENUM } from './shared/enums';
import { MenuItemData } from './shared/interfaces';
import KrgButton, { SIZE_ENUM, VARIANT_ENUM } from '@kargo/shared-components.krg-button';

type Props = Events & {
  /**
   * @summary
   * Classes name (button, menuList, popper and paper)
   */
  classNames?: {
    button?: string,
    menuList?: string,
    popper?: string,
    paper?: string,
  }
  /**
   * @summary
   * Button text
   * @default
   * ''
   */
  buttonText?: string,
  /**
   * @summary
   * Button icon
   * @default
   * BUTTON_ICON_ENUM.moreVert
   */
  buttonIconType?: BUTTON_ICON_ENUM,
  /**
   * @summary
   * Button variant
   * @default
   * BUTTON_VARIANT_ENUM.contained
   */
  buttonVariant?: VARIANT_ENUM | BUTTON_VARIANT_ENUM.icon,
  /**
   * @summary
   * Button and menu size
   * @default
   * BUTTON_SIZE_ENUM.medium
   */
  size?: SIZE_ENUM,
  /**
   * @summary
   * Menu placement
   * @default
   * "bottom-end"
   */
  menuPlacement?: PopperPlacementType,
  /**
   * @summary
   * Menu items data
   * Set isHidden `true` to hide options from the list
   */
  menuItems: MenuItemData[],
  /**
   * @summary
   * Set `false` to disable the menu
   * @default
   * true
   */
  isEnabled?: boolean;
  /**
   * @summary
   * Set `true` to use hover to display menu
   * @default
   * false
   */
  isHoverEnabled?: boolean;
  /**
   * @summary
   * Component theme
   * @default
   * THEME_ENUM.v1
   */
  theme?: THEME_ENUM,
  /*
    DEPRECATED
  */
  /**
   * @summary
   * Deprecated on 2.0.35 in favour of 'isEnabled'
   */
  disabled?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.42 in favour of 'classNames.button'
   */
  buttonClassName?: DEPRECATED,
  /**
   * @summary
   * Deprecated on 2.0.42 in favour of 'classNames.menuList'
   */
  menuClassName?: DEPRECATED,
};

const KrgMenu = ({
  id,
  classNames = {
    button: '',
    menuList: '',
    popper: '',
    paper: '',
  },
  buttonText = '',
  buttonIconType = BUTTON_ICON_ENUM.moreVert,
  buttonVariant = VARIANT_ENUM.contained,
  size = SIZE_ENUM.medium,
  menuItems,
  menuPlacement = 'bottom-end',
  isEnabled = true,
  isHoverEnabled = false,
  theme = THEME_ENUM.v1,
  onClick,
  onBlur,
  onFocus,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const props: StyleProps = {
    isMenuOpen: open,
  };
  const baseClasses = useBaseStyles(props);
  const classesV1 = useStylesV1(props);
  const classesV2 = useStylesV2(props);
  const classes = theme === THEME_ENUM.v2 ? classesV2 : classesV1;
  const isIconButton = buttonVariant === BUTTON_VARIANT_ENUM.icon;
  const buttonIcon = buttonIconType === BUTTON_ICON_ENUM.moreVert
    ? <MoreVertIcon />
    : <FilterListIcon />;

  const handleToggle = () => setOpen(!open);

  const innerContainerTextSizeClass =
    size === SIZE_ENUM.small ? classes.smallInnerContainerText :
    size === SIZE_ENUM.medium ? classes.mediumInnerContainerText :
    size === SIZE_ENUM.large ? classes.largeInnerContainerText :
    theme === THEME_ENUM.v2 ? classes.largeInnerContainerText : classes.xLargeInnerContainerText;

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const renderMenuList = () => {
    const containsItemIcon = menuItems.some(item => item.icon);

    return (
      <MenuList className={`${baseClasses.list} ${classNames.menuList}`} autoFocusItem={open}>
        {menuItems &&
          menuItems.filter(({ isHidden }) => !isHidden).map((item: MenuItemData, index: number) => (
            <MenuItem
              classes={{
                root: classes.listItem,
              }}
              key={index}
              onClick={(e: MouseEvent<HTMLLIElement>) => {
                item.onClick?.(e);
                handleClose();
              }}
              disabled={!item.isEnabled}
            >
              {
                item.content ?
                item.content :
                <div className={baseClasses.innerContainer}>
                  { containsItemIcon
                      ? <div className={`${innerContainerTextSizeClass} ${baseClasses.innerContainerIcon}`}>
                        {item.icon}
                      </div>
                      : null
                  }
                  <div
                    className={`${classes.innerContainerText}  ${innerContainerTextSizeClass}`}
                  >
                    {item.name}
                  </div>
                </div>
              }
            </MenuItem>
          ))}
      </MenuList>
    );
  };

  const renderButton = () => {
    return (
      isIconButton ?
      <IconButton
        className={classNames.button}
        ref={anchorRef}
        aria-haspopup="true"
        disabled={!isEnabled}
        onClick={handleToggle}
      >
        {buttonIcon}
      </IconButton> :
      <KrgButton
        ref={anchorRef}
        className={classNames.button}
        aria-haspopup="true"
        text={isIconButton ? '' : buttonText}
        variant={buttonVariant}
        size={size}
        theme={theme}
        startIcon={isIconButton ? buttonIcon : ''}
        isEnabled={isEnabled}
        endIcon={
          !isIconButton ?
            open ?
            <ExpandLessSharpIcon/> :
            <ExpandMoreSharpIcon/> :
            ''
        }
        onClick={handleToggle}
      />
    );
  };

  const popperProps = {
    onResize: undefined,
    onResizeCapture: undefined,
  };

  return (
    <div
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      onMouseEnter={isHoverEnabled ? handleOpen : undefined}
      onMouseLeave={isHoverEnabled ? handleClose : undefined}
    >
     {renderButton()}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={menuPlacement}
        className={`${classNames.popper} ${baseClasses.popper}`}
        {...popperProps}
      >
        <Paper classes={{ root: `${classNames.paper} ${classes.paper}` }}>
          <ClickAwayListener onClickAway={handleClose}>
            {renderMenuList()}
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

KrgMenu.BUTTON_ICON_ENUM = BUTTON_ICON_ENUM;
KrgMenu.BUTTON_SIZE_ENUM = BUTTON_SIZE_ENUM;
KrgMenu.BUTTON_VARIANT_ENUM = BUTTON_VARIANT_ENUM;

export default KrgMenu;
