import React from "react";
import { useDrag } from "./index";
import "./dragging.style.scss";

type IDraggableItem = {
  id: string;
  children: React.ReactNode;
  direction?: "X" | "Y";
};
export function DraggableItem(props: IDraggableItem) {
  const { id, children, direction } = props;
  const dragRef = React.useRef(0);
  const dropRef = React.useRef(0);
  const [isdropped, setIsDropped] = React.useState(false);
  const { draggableList, itemIdKey, onDragEnd, isDraggable } = useDrag();

  function findDragAndDropIndex(target: any, dropTarget: any) {
    const dragIdx = draggableList.findIndex((v) => v[itemIdKey] === target?.id);
    const dropIdx = draggableList.findIndex(
      (v) => v[itemIdKey] === dropTarget?.id,
    );
    return { dragIdx, dropIdx };
  }

  function fixDropIndexByMoveDiretion(moveDirection: boolean, dropIdx: number) {
    if (moveDirection) {
      if (dropIdx >= 0) {
        return dropIdx - 1;
      }
      return draggableList.length - 1;
    }
    return dropIdx;
  }

  function moveWithAnimation(target, dropTarget) {
    const moveDirection =
      !dropTarget ||
      dropTarget.previousElementSibling === target.nextElementSibling;
    let animationTarget;

    if (moveDirection) {
      animationTarget = dropTarget
        ? dropTarget.previousElementSibling
        : target.nextElementSibling;
    } else {
      animationTarget = target.previousElementSibling;
    }

    if (animationTarget) {
      animationTarget.style.transform = `translate${direction}(${moveDirection ? "-100%" : "100%"})`;
      animationTarget.style.transition = "transform .2s ease-out";
      animationTarget.ontransitionend = () => {
        animationTarget.style.transition = "";
        animationTarget.style.transform = "";
        if (target && target.parentNode) {
          target.parentNode.insertBefore(target, dropTarget);
          const { dragIdx, dropIdx } = findDragAndDropIndex(target, dropTarget);
          dragRef.current = dragIdx;
          dropRef.current = fixDropIndexByMoveDiretion(moveDirection, dropIdx);
        }
      };
    }
  }

  function listItemDragged(e) {
    setIsDropped(false);
    e.target.classList.add("dragging");
    let dropTarget =
      document.elementFromPoint(e.clientX, e.clientY) === null
        ? e.target
        : document.elementFromPoint(e.clientX + 100, e.clientY + 50);

    if (e.target.parentNode === dropTarget.parentNode) {
      dropTarget =
        dropTarget !== e.target.nextElementSibling
          ? dropTarget
          : dropTarget.nextElementSibling;
      if (e.target !== dropTarget) {
        // move target with animation
        moveWithAnimation(e.target, dropTarget);
      }
    }
  }

  React.useEffect(() => {
    if (dragRef.current !== dropRef.current && isdropped) {
      onDragEnd(dragRef.current, dropRef.current);
      dragRef.current = 0;
      dropRef.current = 0;
    }
  }, [isdropped]);

  function listItemDropped(e) {
    setIsDropped(true);
    e.target.classList.remove("dragging");
    e.preventDefault();
  }

  React.useEffect(() => {
    const listItems = document.querySelectorAll(".draggable");
    Array.prototype.map.call(listItems, (option) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-param-reassign
      option.ondrag = listItemDragged;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-param-reassign
      option.ondragend = listItemDropped;
    });
    window.addEventListener("dragover", (e) => e.preventDefault());
  }, []);

  return (
    <div className="draggable" id={id} draggable={isDraggable}>
      {children}
    </div>
  );
}
